import { API, IResponse } from "./API";
interface IContent {
  _contentId: string;
  _type: string;
}
export interface IAssignLosRequest {
  _contents: IContent[];
  _gradeId: number;
  _startDate: string;
  _students: number[];
}
const assignmentsServices = API.injectEndpoints({
  endpoints(build) {
    return {
      assignLos: build.mutation<IResponse, IAssignLosRequest>({
        query(payload) {
          return {
            url: `ams/assign`,
            method: "POST",
            body: payload,
          };
        },
      }),
    };
  },
});

export const { useAssignLosMutation } = assignmentsServices;
