import { MouseEventHandler, useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { EvaluationIcon } from "components/SVGs";
import Pagination from "components/Pagination/index";
import { useHistory } from "react-router-dom";
import Badge from "@mui/material/Badge";
import Print from "components/Print";
import ExportReport from "components/ExportReport";
import { MasterDataContext } from "contexts/MasterDataContext";
import SelectInput from "components/SelectInput";
import {
  selectReport,
  reportSet,
  domainSet,
  selectDomain,
  classSet,
  selectGrade,
  selectClass,
  selectPagination,
  paginationSet,
  selectExam,
  examSet,
} from "features/classPerformance";
import { toDomainOptions, toClassOptions } from "utils/ReportsHelpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import {
  useFetchClassesOfGradeQuery,
  useFetchLanguageDomainsQuery,
} from "services/common";
import { useFetchClassAssessmentsQuery } from "services/evaluation";
import {
  assessmentsSet,
  completedEvaluationTabOption,
  isRedirectingSet,
  pendingEvaluationTabOption,
  tabSet,
  totalCompletedAssessmentsCount,
  totalPendingAssessmentsCount,
} from "features/evaluation";
import { Assessment, IOption } from "features/common/Interfaces";
import { useFetchExamsQuery } from "services/exams";

const reportsType = [
  { label: Assessment.FORMATIVE, value: Assessment.FORMATIVE },
  { label: Assessment.BASELINE, value: Assessment.BASELINE },
  { label: Assessment.EXAM, value: Assessment.EXAM },
];

function Toolbar({ setPrinting }: { setPrinting(value: boolean): void }) {
  const { t, i18n } = useTranslation();
  const selectedGrade = useAppSelector(selectGrade);
  const selectedClass = useAppSelector(selectClass);
  const selectedExam = useAppSelector(selectExam);
  const selectedReport = useAppSelector(selectReport);
  const selectedDomain = useAppSelector(selectDomain);
  const pendingAssessmentsCount = useAppSelector(totalPendingAssessmentsCount);
  const completedAssessmentsCount = useAppSelector(
    totalCompletedAssessmentsCount
  );
  const report = useAppSelector(selectReport);
  const pagination = useAppSelector(selectPagination);
  const [exams, setExams] = useState<IOption[]>([]);
  const [domains, setDomains] = useState<IOption[]>([]);
  const [classes, setClasses] = useState<IOption[]>([]);
  const dispatch = useAppDispatch();
  const { data: examsList } = useFetchExamsQuery(selectedGrade?.id as number, {
    skip: !selectedGrade?.id,
  });
  const { data: languageDomainsData } = useFetchLanguageDomainsQuery();
  const { data: classesOfGradeData } = useFetchClassesOfGradeQuery(
    selectedGrade?.id!,
    { skip: !selectedGrade?.id }
  );
  const { data: classAssessmentData } = useFetchClassAssessmentsQuery(
    selectedClass?.value as number,
    {
      skip: !selectedClass?.value,
    }
  );

  useEffect(() => {
    if (examsList?._statusCode === 200) {
      const updateList: IOption[] = examsList._entity.map((exam) => ({
        label: exam._title,
        value: exam._id,
        grade: exam._gradeCode,
      }));
      updateList.unshift({ value: -1, label: t("All Exams") });
      setExams(updateList);
      dispatch(examSet(updateList[0]));
    } else {
      setExams([]);
    }
  }, [examsList]);

  useEffect(() => {
    if (exams.length) {
      const updateList: IOption[] = JSON.parse(JSON.stringify(exams));
      updateList.shift();
      updateList.unshift({ value: -1, label: t("All Exams") });
      setExams(updateList);
    }
  }, [i18n.dir()]);

  useEffect(() => {
    if (classAssessmentData?._statusCode === 200) {
      const data = classAssessmentData._entity?._classOfflineAssessments;
      const assessments = data.map((assessment) => ({
        loId: assessment._loId,
        loTitle: assessment._loTitle,
        lessonTitle: assessment._lessonTitle ?? assessment._loTitle ?? "",
        loCode: assessment._loCode,
        thumb: assessment._loThumbURL,
        domainName: assessment._domainName,
        assessmentType: assessment._assessmentType,
        studentDetails: assessment._studentDetails.map((item) => ({
          name: item._name,
          id: item._id,
          evaluationStatus: item._evaluationStatus,
          avatar: item._avatar,
        })),
      }));
      dispatch(assessmentsSet(assessments));
    }
  }, [classAssessmentData]);

  useEffect(() => {
    if (languageDomainsData?._statusCode === 200) {
      const domains = toDomainOptions(languageDomainsData._entity);
      dispatch(domainSet(domains[0]));
      setDomains(domains);
    }
  }, [languageDomainsData]);
  useEffect(() => {
    if (classesOfGradeData?._statusCode === 200) {
      const classes = toClassOptions(classesOfGradeData._entity);
      dispatch(classSet(classes[0]));
      setClasses(classes);
    }
  }, [classesOfGradeData]);

  const history = useHistory();
  const { logEvent } = useContext(MasterDataContext);

  const getPage = (value: number) => {
    dispatch(paginationSet({ ...pagination, currentPage: value }));
  };
  const evaluateHandler: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    localStorage.setItem("grade-evaluation", JSON.stringify(selectedGrade));
    localStorage.setItem("class-evaluation", JSON.stringify(selectedClass));
    dispatch(isRedirectingSet(true));
    if (pendingAssessmentsCount && pendingAssessmentsCount > 0)
      dispatch(tabSet(pendingEvaluationTabOption));
    else dispatch(tabSet(completedEvaluationTabOption));

    history.push("/evaluation");
  };

  const setIsPrint = (printState: boolean) => {
    logEvent("ClassReportPrintOut", {
      ClassId: selectedClass?.value!,
    });
    setPrinting(printState);
  };

  const reportTypeChangeHandler = ({
    currentTarget,
  }: {
    currentTarget: IOption;
  }) => {
    dispatch(reportSet(currentTarget));
    dispatch(
      paginationSet({
        ...pagination,
        currentPage: 1,
      })
    );
    logEvent("ClassPerformanceAssessmentFilter", {
      selectedAssessment: currentTarget.value,
    });
  };

  const examChangeHandler = ({ currentTarget }: { currentTarget: IOption }) => {
    logEvent("ClassPerformanceABTitleFilter", {
      AssessmentId:
        currentTarget.value === -1 ? "All Assessments" : currentTarget.value,
    });
    dispatch(examSet(currentTarget));
  };

  const allDomains = selectedDomain?.value === -1;
  const isThereSubmittedAsessements =
    (pendingAssessmentsCount ?? 0) + (completedAssessmentsCount ?? 0) > 0;

  return (
    <>
      {classes?.length > 0 ? (
        <Grid
          container
          rowSpacing={1}
          sx={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "fit-content",
          }}
        >
          <Grid item xs={2}>
            <SelectInput
              defaultValue={selectedReport.value}
              valueList={[
                { label: t(Assessment.EXAM), value: Assessment.EXAM },
                { label: t(Assessment.FORMATIVE), value: Assessment.FORMATIVE },
              ]}
              getOption={reportTypeChangeHandler}
            />
          </Grid>
          {exams.length > 0 && selectedReport.value === Assessment.EXAM ? (
            <Grid item xs={3} xl={2}>
              <SelectInput
                defaultValue={selectedExam?.value}
                valueList={exams}
                getOption={examChangeHandler}
              />
            </Grid>
          ) : null}
          <Grid item xs={3} xl={2}>
            <SelectInput
              defaultValue={selectedClass?.value}
              valueList={classes}
              getOption={({ currentTarget }) => {
                dispatch(classSet(currentTarget));
                dispatch(paginationSet({ ...pagination, currentPage: 1 }));
                logEvent("ClassPerformanceClassFilter", {
                  classId: currentTarget?.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={2} xl={2}>
            <SelectInput
              defaultValue={selectedDomain ? selectedDomain.value : undefined}
              valueList={JSON.parse(JSON.stringify(domains)).map(
                (domain: IOption) => {
                  domain.label = t(domain.label);
                  return domain;
                }
              )}
              getOption={({ currentTarget }) => {
                dispatch(domainSet(currentTarget));
                dispatch(paginationSet({ ...pagination, currentPage: 1 }));
                logEvent("ClassPerformanceDomainFilter", {
                  selectedDomain: currentTarget?.label,
                });
              }}
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid container justifyContent="flex-end" alignItems="center">
              {!allDomains && selectedReport.value !== "baseline" && (
                <Grid item>
                  <Pagination
                    total={pagination?.totalPages}
                    getPage={getPage}
                    defaultPage={pagination?.currentPage}
                  />
                </Grid>
              )}
              {isThereSubmittedAsessements &&
              selectedReport.value !== Assessment.EXAM ? (
                <Grid
                  item
                  onClick={evaluateHandler}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: "0 10px",
                  }}
                >
                  <Badge
                    badgeContent={pendingAssessmentsCount}
                    sx={{
                      "& .MuiBadge-colorPrimary": {
                        background: "rgb(255 255 255)",
                        color: "rgb(98 195 238)",
                        border: "1px solid rgb(98 195 238)",
                        fontWeight: "bold",
                      },
                    }}
                    color="primary"
                  >
                    <EvaluationIcon style={{ color: "rgb(98 195 238)" }} />
                  </Badge>
                </Grid>
              ) : null}
              {allDomains &&
              selectedClass &&
              selectedReport.value !== Assessment.EXAM ? (
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alingItem: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <ExportReport
                    style={{ margin: "0 15px" }}
                    selectedClass={selectedClass}
                    type={
                      report?.value === Assessment.FORMATIVE
                        ? Assessment.FORMATIVE
                        : Assessment.BASELINE
                    }
                  />
                  <Print setIsPrint={setIsPrint} />
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
}

export default Toolbar;
