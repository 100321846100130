import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  Assessment,
  IGrade,
  IOption,
  IPagination,
  Sort,
} from "../common/Interfaces";
export interface IStudent {
  studentId: number;
  name?: string;
  assigned?: number;
  completed?: number;
  avatar?: string;
  average?: string;
  score?: string | number;
  offline?: boolean;
  ene?: boolean | null;
  questions?: (boolean | null)[];
}

export interface IDomainReport {
  id: number;
  name?: string;
  average?: number | string;
  scores?: number[];
  score?: number | string;
  students?: IStudent[];
  lessonTitle?: string;
  loTitle?: string;
}
interface IClassPerformanceState {
  report: IOption;
  grade?: IGrade;
  class?: IOption;
  exam?: IOption;
  domain?: IOption;
  sort: Sort;
  students: IStudent[];
  classReport: IDomainReport[];
  baselineReport: IDomainReport[];
  pagination: IPagination;
}

const initialState: IClassPerformanceState = {
  report: { label: Assessment.FORMATIVE, value: Assessment.FORMATIVE },
  sort: Sort.asc,
  students: [],
  classReport: [],
  baselineReport: [],
  pagination: { currentPage: 1, totalPages: 1, pageSize: 5 },
};
export const classPerformanceSlice = createSlice({
  name: "classPerformance",
  initialState,
  reducers: {
    reportSet: (state, action: PayloadAction<IOption>) => {
      state.report = action.payload;
    },
    gradeSet: (state, action: PayloadAction<IGrade>) => {
      state.grade = action.payload;
    },
    classSet: (state, action: PayloadAction<IOption>) => {
      state.class = action.payload;
    },
    examSet: (state, action: PayloadAction<IOption>) => {
      state.exam = action.payload;
    },
    domainSet: (state, action: PayloadAction<IOption>) => {
      state.domain = action.payload;
    },
    studentsSet: (state, action: PayloadAction<IStudent[]>) => {
      state.students = action.payload;
    },
    classReportSet: (state, action: PayloadAction<IDomainReport[]>) => {
      state.classReport = action.payload;
    },
    baselineReportSet: (state, action: PayloadAction<IDomainReport[]>) => {
      state.baselineReport = action.payload;
    },
    selectedSortSet: (state, action: PayloadAction<Sort>) => {
      state.sort = action.payload;
    },
    paginationSet: (state, action: PayloadAction<IPagination>) => {
      state.pagination = action.payload;
    },
  },
});

export const selectReport = (state: RootState) => state.classPerformance.report;
export const selectGrade = (state: RootState) => state.classPerformance.grade;
export const selectClass = (state: RootState) => state.classPerformance.class;
export const selectExam = (state: RootState) => state.classPerformance.exam;
export const selectDomain = (state: RootState) => state.classPerformance.domain;
export const selectStudents = (state: RootState) =>
  state.classPerformance.students;
export const selectClassReport = (state: RootState) =>
  state.classPerformance.classReport;
export const selectBaseLineReport = (state: RootState) =>
  state.classPerformance.baselineReport;
export const selectSelectedSort = (state: RootState) =>
  state.classPerformance.sort;
export const selectPagination = (state: RootState) =>
  state.classPerformance.pagination;

export const {
  gradeSet,
  classSet,
  examSet,
  domainSet,
  reportSet,
  studentsSet,
  classReportSet,
  baselineReportSet,
  selectedSortSet,
  paginationSet,
} = classPerformanceSlice.actions;

export default classPerformanceSlice.reducer;
