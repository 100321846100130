import { styled } from "@mui/material/styles";
import { LinearProgress } from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";

interface IProgress {
  progress: number;
}
function Progress({ progress }: IProgress) {
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "rgba(98, 195, 238, 1)",
    },
  }));

  return <BorderLinearProgress variant="determinate" value={progress} />;
}

export default Progress;
