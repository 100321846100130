import Content from "./Content";
import Header from "./Header";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { IStudent } from "features/common/Interfaces";

const useStyles = makeStyles({
  modal: {
    width: "500px",
    position: "absolute",
    left: 0,
    right: 0,
    top: "35%",
    transform: "translateY(-50%)",
    margin: "auto",
  },
});

interface IQRSendProps {
  open: boolean;
  onClose(): void;
  data: IStudent;
}

function QRSend({ open, onClose, data }: IQRSendProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} sx={{ direction: i18n.dir() }}>
      <div className={classes.modal}>
        <Header onClose={onClose} title={t("Share with Parents")} />
        <Content onClose={onClose} data={data} />
      </div>
    </Modal>
  );
}

export default QRSend;
