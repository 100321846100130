import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import StudentInfo from "./StudentInfo";
import { IDomainReport, IStudent } from "features/classPerformance";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginTop: "30px",
    paddingRight: "50px",
    paddingLeft: "50px",
    paddingBottom: "20px",
  },
  printableHeader: {
    display: "flex",
    width: "100%",
    paddingRight: "30%",
    paddingBottom: "15px",
    marginBottom: "15px",
    flexWrap: "wrap",
  },
  printableRow: {
    width: "50%",
    paddingLeft: "15px",
    "&:last-child": {
      paddingLeft: 0,
      paddingRight: "15px",
    },
  },
  domain: {
    width: "25%",
    fontSize: "15px",
    fontWeight: "bold",
    textAlign: "center",
    padding: "0.5rem",
    minHeight: "60px",
  },
});

interface IStudentListProps {
  reportData: IDomainReport[];
  studentsInfo: IStudent[];
}
function StudentList({ reportData, studentsInfo }: IStudentListProps) {
  const classes = useStyles();
  const [studentsData, setStudentsData] = useState<IStudent[][]>([]);

  useEffect(() => {
    const chunk = Math.ceil(studentsInfo.length / 2);
    const students = [];
    students.push(studentsInfo.slice(0, chunk));
    students.push(studentsInfo.slice(chunk, chunk + studentsInfo.length));
    setStudentsData(students);
  }, []);

  return (
    <div className={classes.root}>
      {studentsData.length
        ? studentsData.map((stuInfo, stuIdx) => (
            <div className={classes.printableRow} key={stuIdx}>
              <div className={classes.printableHeader}>
                {reportData.map((item, idx) => {
                  return (
                    <div className={classes.domain} key={idx}>
                      {item.name}
                    </div>
                  );
                })}
              </div>
              {stuInfo.length
                ? stuInfo.map((student, index) => (
                    <StudentInfo
                      key={index}
                      student={student}
                      reportData={reportData}
                      index={
                        stuIdx === 1
                          ? Math.ceil(studentsInfo.length / 2) + index
                          : index
                      }
                    />
                  ))
                : null}
            </div>
          ))
        : null}
    </div>
  );
}

export default StudentList;
