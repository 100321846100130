import { useContext, useEffect, useState } from "react";
import Sections from "./Sections";
import Content from "./Content";
import TooltipBar from "./TooltipBar";
import Breadcrumb from "components/Breadcrumb";
import DataNotFound from "components/DataNotFound";
import { useAppDispatch, useAppSelector } from "app/hooks";
import ConfirmMessage from "components/ConfirmMessage";
import {
  allGradesSwitchSet,
  selectAllGradesSwitch,
  selectGrades,
} from "features/filters";
import {
  gradeSet,
  moeViewSet,
  searchBySet,
  searchSet,
  selectGrade,
} from "features/content";
import {
  allClassesSet,
  assignedLessonsSet,
  selectAssignedLessons,
  selectedClassesSet,
} from "features/assignments";
import { useTranslation } from "react-i18next";
import {
  useFetchAllGradesClassesQuery,
  useFetchClassesQuery,
} from "services/common";
import { IGrade } from "features/common/Interfaces";
import { handleFetchedClasses } from "utils/HomeHelpers";
import GradesList from "components/GradeList";
import { MasterDataContext } from "contexts/MasterDataContext";
import { examRemoved, selectAssignExam } from "features/exams";

function Home() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { logEvent } = useContext(MasterDataContext);
  const loadedGrades = useAppSelector(selectGrades);
  const allGradesSwitch = useAppSelector(selectAllGradesSwitch);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { assignedLosCount } = useAppSelector(selectAssignedLessons);
  const selectedGrade = useAppSelector(selectGrade);
  const [currentGrade, setCurrentGrade] = useState<IGrade>();
  const [mounted, setMounted] = useState(false);
  const assignExam = useAppSelector(selectAssignExam);
  const { data: classesData } = useFetchClassesQuery(
    selectedGrade?.id as number,
    {
      skip: !selectedGrade?.id,
    }
  );

  const { data: allClassesData } = useFetchAllGradesClassesQuery();

  useEffect(() => {
    logEvent("VisitHomePage");
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);
  useEffect(() => {
    const updatedClassesSet = allGradesSwitch ? allClassesData : classesData;
    if (updatedClassesSet) {
      const { allClasses, selectedClasses } =
        handleFetchedClasses(updatedClassesSet);
      dispatch(allClassesSet(allClasses));
      dispatch(selectedClassesSet(selectedClasses));
    }
    if (!allGradesSwitch) {
      const ownedGrades = loadedGrades.filter((g) => g.owned);
      dispatch(gradeSet(selectedGrade?.owned ? selectedGrade : ownedGrades[0]));
    }
  }, [classesData, allClassesData, allGradesSwitch]);

  const changeGradeHandler = (grade: IGrade) => {
    if (
      ((assignedLosCount && !allGradesSwitch) || assignExam.examId) &&
      mounted
    ) {
      setModalOpen(true);
      setCurrentGrade(grade);
    } else gradeChangeHandler(grade);
  };

  const deleteHandler = (answer: "yes" | "no") => {
    if (answer === "yes") {
      gradeChangeHandler(currentGrade!);
    }
    setModalOpen(false);
  };

  const gradeChangeHandler = (grade: IGrade) => {
    dispatch(gradeSet(grade));
    dispatch(moeViewSet(false));
    if (!allGradesSwitch && mounted)
      dispatch(
        assignedLessonsSet({
          losList: [],
          unitList: [],
          assignedLosCount: 0,
        })
      );
    dispatch(examRemoved());
    dispatch(searchSet(""));
    dispatch(searchBySet({ label: t("Name"), value: "name" }));
  };

  const sessionExpired = localStorage.getItem("expired");
  const allgradesSwitchHandler = (value: boolean) => {
    dispatch(allGradesSwitchSet(value));
  };
  return (
    <>
      {loadedGrades?.length === 0 && !isLoading ? (
        <DataNotFound
          text={sessionExpired === "true" ? undefined : "No students exists"}
        />
      ) : (
        <>
          <Breadcrumb />
          <GradesList
            onGradeChange={changeGradeHandler}
            selectedGrade={selectedGrade}
            getIsGradeLoading={setIsLoading}
            withSwitch
            allGradesSwitch={allGradesSwitch}
            getAllGradesSwitch={allgradesSwitchHandler}
          />
          {loadedGrades.filter((grade) => grade.owned).length ? (
            <>
              <Sections isLoading={isLoading} />
              <Content isLoading={isLoading} />
              <TooltipBar />
              <ConfirmMessage
                open={modalOpen}
                onClose={deleteHandler}
                description={t("Data will be lost on change grade")}
                title={t("Warning")}
              />
            </>
          ) : !isLoading ? (
            <DataNotFound text={t("no classes")} />
          ) : null}
        </>
      )}
    </>
  );
}

export default Home;
