import { toast } from "react-toastify";
import { AlertIcon, CloseIcon } from "components/SVGs";

interface IToastErrorProps {
  label: string;
  message: string;
  rtl?: boolean;
  toastId?: string;
  autoClose?: number;
  "data-test"?: string;
}
export const toastError = ({
  label,
  message,
  rtl = true,
  toastId,
  autoClose = 2500,
  ...rest
}: IToastErrorProps) =>
  toast(
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
      {...rest}
    >
      <AlertIcon style={{ fontSize: "1.5rem" }} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: rtl ? "right" : "left",
          justifyContent: "space-between",
          flexGrow: 1,
          padding: "5px 0",
        }}
      >
        <span style={{ color: "rgb(0 0 0)", fontSize: "1rem" }}>{label}</span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "0.75rem",
            color: "rgb(81 81 81)",
          }}
        >
          {message}
        </span>
      </div>
      <CloseIcon style={{ fontSize: "1.75rem" }} />
    </div>,
    {
      bodyStyle: { textAlign: rtl ? "right" : "left", padding: 0, margin: 0 },
      style: {
        border: "1px solid rgb(194 219 241)",
        borderRadius: "8px",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.19)",
        direction: rtl ? "rtl" : "ltr",
        padding: 0,
        margin: "15px 10px 0",
      },
      toastId,
      autoClose,
      position: toast.POSITION.TOP_CENTER,
    }
  );
