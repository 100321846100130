import { Grid, Skeleton } from "@mui/material";

function DomainSkeleton() {
  return (
    <>
      {Array.from(new Array(4)).map((data, index) => (
        <Grid
          xs={3}
          item
          key={index}
          style={{ width: "13%", position: "relative" }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Skeleton variant="circular" width={150} height={140} />
          </div>
        </Grid>
      ))}
    </>
  );
}

export default DomainSkeleton;
