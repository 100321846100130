import { useState, useEffect, ReactElement } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { renderSocial } from "../Elements";
import { BigLogoIcon } from "components/SVGs/MainMenuIcons";
import ForgotPasswordHeader from "assets/img/ForgotPasswordHeader.svg";
import SetNewPassword from "./SetNewPassword";
import ExpiredLink from "./ExpiredLink";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
// import { useVerifyTokenMutation } from "";
// import { useCallback } from "react";

function ResetPassword(): ReactElement {
  const { i18n } = useTranslation();
  const [verifiedToken, setVerifiedToken] = useState<string | null>(null);
  const { search } = useLocation();
  const history = useHistory();
  // const [verifyResetToken, { isLoading, data }] = useVerifyTokenMutation();
  // const verifyTokenHandler = useCallback(
  //   (token) => verifyResetToken(token),
  //   [verifyResetToken]
  // );

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get("token");
    if (token) setVerifiedToken(token);
    else history.push("/login");
    // verifyTokenHandler(token);
  }, []);

  // useEffect(() => {
  //   if (!isLoading && data) {
  //     if (data._statusCode === 200) setVerifiedToken(data._entity._token);
  //     else if (data._statusCode === 551) setVerifiedToken("expired");
  //     else if (data._statusCode === 552) history.push("/");
  //     else {
  //       console.log({ data });
  //       history.push("/");
  //     }
  //   }
  // }, [isLoading, data]);

  const renderedComp = () => {
    if (verifiedToken === "expired") return <ExpiredLink />;
    if (verifiedToken) return <SetNewPassword token={verifiedToken} />;
    return <Loading />;
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "rgb(255 255 255)",
        width: "100%",
        height: "fit-content",
        minHeight: "100vh",
        "& > :not(:last-child)": {
          marginBottom: "30px",
        },
      }}
    >
      <Grid
        item
        sx={{
          background: `no-repeat url(${ForgotPasswordHeader})`,
          backgroundSize: "100% 100%",
          height: "250px",
          width: "100%",
          "@media only screen and (max-width: 1024px)": {
            height: "150px",
          },
        }}
      >
        {renderSocial(i18n.dir())}
        <BigLogoIcon
          sx={{
            position: "absolute",
            [i18n.dir() === "rtl" ? "left" : "right"]: "40px",
            cursor: "pointer",
            top: "5px",
            margin: 0,
            fontSize: "100px",
          }}
          onClick={() => history.push("/")}
        />
        <div
          style={{
            background: "rgb(236 249 255)",
            width: "100%",
            height: "50px",
          }}
        ></div>
      </Grid>
      {renderedComp()}
    </Grid>
  );
}

export default ResetPassword;
