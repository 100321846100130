import { IValidationErrors } from "features/common/Interfaces";
import Joi from "joi";

export const validate =
  (schema: Joi.ObjectSchema<any>) =>
  (dataRow: { [key: string]: number | string | boolean }) => {
    const options = { abortEarly: false };
    const { error } = schema.validate(dataRow, options);
    if (!error) return {};
    let errors: IValidationErrors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

export const getSchema = (list: number[]) =>
  Joi.object({
    student_id: Joi.number()
      .required()
      .valid(...list)
      .messages({
        "any.only": "invalid student no",
        "any.required": "student no required",
      }),
    student_name: Joi.any().required(),
    recipient_email: Joi.string()
      .trim()
      .email({ tlds: { allow: false } })
      .required()
      .label("Recipient Email")
      .messages({
        "any.required": "Email Id is required",
        "string.empty": "Email Id is required",
        "string.email": "EmailValidation",
      }),
  });

export const binaryToOct = (s: string) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};
