import { API, IResponse } from "./API";
export interface ILoginRequest {
  email: string;
  password: string;
}

interface ILoginEntity {
  _schoolId: number;
  _userTypeId: number;
  _schoolName: string;
  _classIds: number[];
  _noOfStudents: number;
  _noOfClasses: number;
  _learningAreaId: number;
  _id: number;
  _teacherId: number;
  _name: string;
  _email: string;
  _mobileNumber: string;
  _status: number;
  _token: string;
  _refreshToken: string;
}

export interface ILoginResponse extends IResponse {
  _entity: ILoginEntity;
}

export interface ISetNewPasswordRequest {
  token: string;
  password: string;
}
interface IResetPasswordRequest {
  _email: string;
  _lang: string;
}

interface IUserInfoEntity {
  _educationalYearEndDate: string;
  _educationalYearStartDate: string;
  _id: number;
  _isActive: boolean;
  _isDefault: boolean;
  _name: string;
  _restrictedUse: boolean;
  _schoolLeaderEmail: string;
  _schoolLeaderFirstName: string;
  _schoolLeaderLastName: string;
  _schoolLeaderMobile: string;
  _schoolLeaderUserId: number;
  _schoolLogoUrl: string | null;
}
export interface IUserInfoResponse extends IResponse {
  _entity: IUserInfoEntity;
}
const authServices = API.injectEndpoints({
  endpoints(build) {
    return {
      login: build.mutation<ILoginResponse, ILoginRequest>({
        query(data) {
          return {
            url: `/ams/login-teacher`,
            method: "POST",
            body: { _email: data.email, _password: data.password },
          };
        },
      }),
      googleLogin: build.mutation<ILoginResponse, string>({
        query(_token) {
          return {
            url: `/ams/google/login`,
            method: "POST",
            body: {
              _token,
            },
          };
        },
      }),
      requestResetPassword: build.mutation<IResponse, IResetPasswordRequest>({
        query(payload) {
          return {
            url: `/ams/request-reset-password`,
            method: "POST",
            body: payload,
          };
        },
      }),
      setNewPassword: build.mutation<IResponse, ISetNewPasswordRequest>({
        query(data) {
          return {
            url: `/ams/reset-password`,
            method: "POST",
            body: { _token: data.token, _password: data.password },
          };
        },
      }),
      verifyToken: build.mutation<IResponse, string>({
        query(_token) {
          return {
            url: `/ams/reset-password/verify-token`,
            method: "POST",
            body: { _token },
          };
        },
      }),
      userInfo: build.query<IUserInfoResponse, string>({
        query(schoolId) {
          return `/ams/school/${schoolId}`;
        },
      }),
    };
  },
});

export const {
  useLoginMutation,
  useRequestResetPasswordMutation,
  useSetNewPasswordMutation,
  useGoogleLoginMutation,
  useVerifyTokenMutation,
  useUserInfoQuery,
} = authServices;
