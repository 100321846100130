import { useEffect, useState } from "react";
import AudioPlayer from "components/AudioPlayer";
import {
  Player,
  ControlBar,
  PlaybackRateMenuButton,
  BigPlayButton,
  LoadingSpinner,
} from "video-react";
import "video-react/dist/video-react.css";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import ModalImage from "components/ModalImage";
import { selectSelectedLo, IAssessment } from "features/evaluation";
import { useAppSelector } from "app/hooks";
import completedThumb from "assets/img/img_tumb_completed.svg";
import notCompletedThumb from "assets/img/img_tumb_not_completed.svg";
import { AssetTypes, selectAssets } from "features/assessment";
import { IAssessmentAssets } from "services/evaluation";
import { StatusTypes } from "features/common/Interfaces";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "525px",
  },
  root: {
    width: "100%",
    maxWidth: "450px",
    "& > div": {
      paddingBottom: "10px",
    },
  },
  VIDEO: {
    direction: "ltr",
    "& > div": {
      height: "330px",
      borderRadius: "1.25rem",
      "&  video": {
        borderRadius: "1.1rem",
        border: "0.25rem solid rgb(98 195 238)",
      },
      "& > div": {
        borderBottomRightRadius: "1.1rem",
        borderBottomLeftRadius: "1.1rem",
        border: "0.15rem solid rgb(98 195 238)",
      },
    },
  },

  IMAGE: {
    cursor: "pointer",
    "& > img": {
      height: "325px",
      width: "100%",
      border: "2px solid rgba(98, 195, 238, 1)",
      borderRadius: "8px",
    },
  },
  imagesGallery: {
    width: "100px",
    margin: "10px 5px",
    cursor: "pointer",
    "& > img": {
      border: "2px solid rgba(98, 195, 238, 1)",
      borderRadius: "8px",
      maxWidth: "100%",
    },
  },
  selectedImage: {
    cursor: "default",
    "& > img": {
      border: "4px solid rgba(98, 195, 238, 1)",
    },
  },
  imageList: {
    display: "flex",
    flexWrap: "wrap",
  },
  AUDIO: {
    "& > img": {
      height: "325px",
      width: "100%",
      marginBottom: "30px",
    },
  },
  loTitle: {
    color: "rgba(88, 88, 88, 1)",
    fontWeight: "bold",
    fontSize: "14px",
    "@media screen and (max-width: 1024px)": {
      fontSize: "12px",
    },
  },
  loDomain: {
    fontSize: "14px",
    color: "rgba(98, 195, 238, 1)",
    "@media screen and (max-width: 1024px)": {
      fontSize: "12px",
    },
  },
});

const AssetType = () => {
  const classes = useStyles();
  const assets = useAppSelector(selectAssets);
  const [selectedImage, setSelectedImage] = useState("");
  const selectedLo = useAppSelector(selectSelectedLo);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const type = assets?.[0]?._type;
    if (type === AssetTypes.IMAGE) {
      setSelectedImage(assets[0]._url);
    } else {
      setSelectedImage("");
    }
  }, [assets]);

  const renderContent = (
    assets: IAssessmentAssets[],
    selectedLo: IAssessment,
    selectedImage: string
  ) => {
    const type = assets?.[0]?._type;
    switch (type) {
      case AssetTypes.IMAGE:
        return (
          <>
            <div className={classes.IMAGE}>
              <img
                src={selectedImage}
                onClick={() => setOpen(true)}
                alt="Big"
                key={selectedImage + selectedLo?.loCode}
              />
            </div>
            <div className={classes.imageList}>
              {assets &&
                assets?.map((item, index) => {
                  return (
                    <div
                      key={item._url}
                      className={classNames(
                        classes.imagesGallery,
                        selectedImage === item._url
                          ? classes.selectedImage
                          : false
                      )}
                    >
                      <img
                        onClick={() => setSelectedImage(item._url)}
                        alt="gallery"
                        src={item._url}
                      />
                    </div>
                  );
                })}
            </div>
          </>
        );
      case AssetTypes.AUDIO:
        return (
          <div className={classes.AUDIO} key={assets[0]?._url}>
            <img
              key={assets[0]?._url}
              src={
                selectedLo?.thumb
                  ? selectedLo?.thumb
                  : selectedLo?.status === StatusTypes.PENDING
                  ? notCompletedThumb
                  : completedThumb
              }
              alt="lesson thumb"
            />
            <AudioPlayer url={assets[0]?._url} style={{ width: "100%" }} />
          </div>
        );
      case AssetTypes.VIDEO:
        return (
          <div className={classes.VIDEO} key={assets[0]._url}>
            <Player src={assets[0]._url}>
              <BigPlayButton position="center" />
              <LoadingSpinner />
              <ControlBar>
                <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
              </ControlBar>
            </Player>
          </div>
        );
      default:
        return null;
    }
  };

  return selectedLo ? (
    <>
      <div className={classes.container}>
        <div className={classes.root}>
          {renderContent(assets, selectedLo, selectedImage)}
          <div className={classes.loTitle}>{selectedLo?.lessonTitle}</div>
          <div className={classes.loDomain}>
            <b>{selectedLo?.domainName}</b>
          </div>
        </div>
      </div>
      <ModalImage open={open} setOpen={setOpen} selectedImage={selectedImage} />
    </>
  ) : null;
};

export default AssetType;
