import defaultAvatar from "assets/img/ic_avatar_default.svg";
import { Avatar } from "@mui/material";
import Pagination from "components/Pagination";
import { IStudentDetails } from "features/evaluation";
import { IPagination } from "features/common/Interfaces";

const customeStyles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 10px ",
    marginBottom: "10px",
    fontSize: "14px",
  },
  studentDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  studentName: {
    fontWeight: "bold",
    margin: "0 10px",
  },
};

interface IStudentSliderProps {
  onChangeStudent(currentPage: number): void;
  studentInfo?: IStudentDetails;
  pagination: IPagination;
}

function StudentSlider({
  onChangeStudent,
  studentInfo,
  pagination,
}: IStudentSliderProps) {
  return studentInfo ? (
    <>
      <div style={customeStyles.container}>
        <div style={customeStyles.studentDetails}>
          <Avatar src={studentInfo?.avatar ?? defaultAvatar} />
          <p style={customeStyles.studentName}>{studentInfo?.name}</p>
        </div>
        <Pagination
          getPage={onChangeStudent}
          total={pagination?.totalPages}
          defaultPage={pagination?.currentPage}
        />
      </div>
    </>
  ) : null;
}

export default StudentSlider;
