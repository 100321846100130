import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import EnhancedTableHead, { ITableHeadColumn } from "./EnhancedTableHead";
import EnhancedTableBody from "./EnhancedTableBody";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  table: {
    tableLayout: "fixed",
  },
});

interface IDataTableProps {
  tableHead: ITableHeadColumn[];
  tableData: (string | number | JSX.Element)[][];
  "data-test"?: string;
}

export default function DataTable({
  tableHead,
  tableData,
  ...rest
}: IDataTableProps) {
  const classes = useStyles();
  return (
    <div className={classes.root} {...rest}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead tableHead={tableHead} />
          <EnhancedTableBody tableData={tableData} columns={tableHead.length} />
        </Table>
      </TableContainer>
    </div>
  );
}
