import { MouseEvent, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "18px",
    color: "rgba(255,255,255,1)",
    userSelect: "none",
  },
  spreadRoot: {
    padding: "0.25rem",
    marginTop: "1rem",
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(60, 180, 229, 1)",
    color: "rgba(60,180,229,1)",
    borderRadius: "0.5rem",
    background: "rgba(255,255,255,1)",
    cursor: "pointer",
    margin: "0 0.75rem",
    width: "36px",
    height: "36px",
    "&:hover": {
      background: "rgba(230, 247, 255, 1)",
      color: "rgba(105, 123, 140, 1)",
      boxShadow: "0px 2px 6px 0px rgba(64, 160, 203, 0.5)",
    },
    overflow: "hidden",
  },
  spreadBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid rgba(218, 218, 218, 1)",
    color: "rgba(111, 117, 138, 1)",
    borderRadius: "5px",
    background: "rgba(255,255,255,1)",
    cursor: "pointer",
    width: "36px",
    height: "36px",
    margin: "0 5px",
    "&:hover": {
      background: "rgba(119, 204, 241, 1)",
      color: "rgba(255,255,255,1)",
      boxShadow: "0px 2px 6px 0px rgba(98, 195, 238, 0.4)",
    },
  },
  disabled: {
    pointerEvents: "none",
    cursor: "default",
    opacity: 0.3,
  },
  disabledSpread: {
    pointerEvents: "none",
    cursor: "default",
    background: "rgba(98, 195, 238, 1)",
    color: "rgba(255,255,255,1)",
  },
  actionBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    background: "rgba(207, 207, 207, 1)",
    cursor: "pointer",
    width: "36px",
    height: "36px",
    overflow: "hidden",
  },
  densRoot: {
    color: "rgb(87 87 87)",
  },
});

interface IPaginationProps {
  getPage(currentPage: number): void;
  total: number;
  defaultPage?: number;
  spreadStyle?: boolean;
  hideOnePage?: boolean;
  "data-test"?: string;
}

export default function Pagination({
  getPage,
  total,
  defaultPage = 1,
  spreadStyle = false,
  hideOnePage = true,
  ...rest
}: IPaginationProps) {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [totalPages, setTotalPages] = useState(1);
  const { i18n } = useTranslation();
  const handleClick = (
    event: MouseEvent<HTMLDivElement>,
    isSpreadStyle: boolean = false
  ) => {
    let newPage = 1;
    if (!spreadStyle || isSpreadStyle) {
      if (event.currentTarget?.getAttribute("id") === "next")
        newPage = currentPage + 1;
      if (event.currentTarget?.getAttribute("id") === "previous")
        newPage = currentPage - 1;
    } else newPage = Number(event.currentTarget.getAttribute("id"));
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (!total) setTotalPages(1);
    else setTotalPages(total);
  }, [total]);

  useEffect(() => {
    setCurrentPage(defaultPage);
  }, [defaultPage]);

  useEffect(() => {
    getPage(currentPage);
  }, [currentPage]);

  const classes = useStyles();
  const direction = i18n.dir();
  const rightBtn = (
    <div
      id="previous"
      onClick={(event) => handleClick(event, true)}
      className={classNames(
        spreadStyle ? classes.actionBtn : classes.btn,
        currentPage === 1 ? classes.disabled : false
      )}
    >
      {direction === "rtl" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
    </div>
  );
  const leftBtn = (
    <div
      id="next"
      onClick={(event) => handleClick(event, true)}
      className={classNames(
        spreadStyle ? classes.actionBtn : classes.btn,
        currentPage >= totalPages ? classes.disabled : false
      )}
    >
      {direction === "rtl" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </div>
  );

  return hideOnePage && totalPages === 1 ? null : (
    <div
      className={classNames(
        classes.root,
        spreadStyle ? classes.spreadRoot : classes.densRoot
      )}
      {...rest}
    >
      {!spreadStyle && (
        <>
          {rightBtn}
          <span>{`${currentPage}${
            i18n.dir() === "ltr" ? "/" : "\\"
          }${totalPages}`}</span>
          {leftBtn}
        </>
      )}
      {spreadStyle && (
        <>
          {rightBtn}
          {Array.from(Array(totalPages).keys()).map((index) => (
            <div
              className={classNames(
                classes.spreadBtn,
                currentPage === index + 1 ? classes.disabledSpread : false
              )}
              id={`${index + 1}`}
              onClick={handleClick}
              key={index}
            >
              {index + 1}
            </div>
          ))}
          {leftBtn}
        </>
      )}
    </div>
  );
}
