import {
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
  ChangeEventHandler,
  useContext,
} from "react";
import SelectInput from "components/SelectInput";
import { Divider } from "@mui/material";
import { SearchIcon, CloseIcon } from "components/SVGs";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import TagsList from "./TagsList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  ITag,
  searchBySet,
  searchSet,
  selectGrade,
  selectLevelOneFilter,
  selectSearch,
} from "features/content";
import { useTranslation } from "react-i18next";
import { useFetchGrBookTagsQuery } from "services/content";
import { IOption, LessonTypes } from "features/common/Interfaces";
import { MasterDataContext } from "contexts/MasterDataContext";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: "50%",
    height: "38px",
    width: "fit-content",
    zIndex: 2,
    transform: "translateY(-50%)",
    "@media screen and (max-width: 768px)": {
      //
    },
  },
  hideTags: {
    background: "rgb(249 249 249)",
  },
  searchContainer: {
    width: "100%",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(217 216 216)",
    borderRadius: "6px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      borderColor: "rgb(98 195 238)",
    },
  },
  container: {
    padding: "3px",
    border: "1px solid rgb(98 195 238)",
    borderRadius: "8px",
    background: "rgb(255 255 255)",
    width: "87%",
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "195px",
    "& *": {
      border: "none",
    },
    "& input": {
      width: "100%",
      fontSize: "16px",
    },
  },
  closeIcon: {
    padding: "0 8px",
    alignItems: "center",
    display: "inline-flex",
    "& svg": {
      width: "10px",
      height: "10px",
      color: "rgb(169 175 187)",
    },
  },
  exitIcon: {
    position: "absolute",
    height: "38px",
    width: "38px",
    backgroundColor: "rgb(98 195 238)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& svg": { width: "10px", height: "10px", color: "rgb(255 255 255)" },
  },
  collapseMode: {
    position: "absolute",
    left: 0,
    top: "-20px",
    display: "flex",
    alignItems: "center",
    height: "38px",
    width: "38px",
    padding: "0 0.75rem",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(217 216 216)",
    borderRadius: "8px",
    cursor: "pointer",
    marginRight: "auto",
    marginLeft: "10px",
    "&:hover": {
      borderColor: "rgb(98 195 238)",
    },
    "@media screen and (max-width: 768px)": {
      marginRight: 0,
    },
  },
  searchMode: {
    marginRight: "45px",
    width: "auto",
  },
  searchContainerMode: {
    marginRight: "45px",
    "& div:first-of-type": {
      marginRight: 0,
    },
  },
});
interface ISearchByProps {
  selectedSection: LessonTypes;
}
function SearchBy({ selectedSection }: ISearchByProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const byName = { label: t("Name"), value: "name" };
  const byTag = { label: t("Tag"), value: "tag" };
  const options = [byName, byTag];
  const [searchText, setSearchText] = useState<string>("");
  const [searchOpt, setSearchOpt] = useState<IOption>(options[0]);
  const [disableSearch, setDisableSearch] = useState(true);
  const [showDropdownList, setShowDropdownList] = useState(false);
  const [tagList, setTagList] = useState<ITag[]>([]);
  const levelOneFitler = useAppSelector(selectLevelOneFilter);
  const currentGrade = useAppSelector(selectGrade);
  const search = useAppSelector(selectSearch);
  const selectedTags = (searchOpt.value === "tag" ? search : []) as ITag[];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { data: grBookTagsData } = useFetchGrBookTagsQuery();
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    if (grBookTagsData?._statusCode === 200) {
      const tags: ITag[] = [
        ...grBookTagsData._entity?.map((item) => ({
          id: item._id,
          name: item._textAr,
          code: item._code,
        })),
      ];
      setTagList(tags);
    }
  }, [grBookTagsData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSearchText("");
    dispatch(searchBySet(options[0]));
    setSearchOpt(options[0]);
  }, [currentGrade, selectedSection, levelOneFitler]);

  useEffect(() => {
    setDisableSearch(true);
    if (
      (searchOpt.value === "name" && (searchText as string).trim().length) ||
      (searchOpt.value === "tag" && selectedTags.length)
    )
      setDisableSearch(false);
    dispatch(searchBySet(searchOpt));
    dispatch(searchSet(searchOpt.value === "name" ? searchText : selectedTags));
  }, [searchText, selectedTags]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!dropdownRef.current?.contains(event.target as Node)) {
      setShowDropdownList(false);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (searchOpt.value === "tag") setShowDropdownList(true);
    setSearchText(event.target.value as string);
  };

  const handleChangeSearch = (event: { currentTarget: IOption }) => {
    setShowDropdownList(false);
    setSearchText("");
    setSelectedTags([]);
    setSearchOpt(event.currentTarget);
  };

  const handleFocus = () => {
    if (searchOpt.value === "tag") setShowDropdownList(true);
  };

  const handleSubmit = (title: string) => {
    if (!disableSearch) {
      setShowDropdownList(false);
      if (title !== searchText) setSearchText(title);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter" && searchOpt.value === "name")
      handleSubmit(searchText as string);
  };

  const handleClick = (section: LessonTypes) => {
    logEvent(`ClickOnSearch`, { section });
  };

  const setSelectedTags = (selectedTags: ITag[]) => {
    dispatch(searchSet(selectedTags));
  };

  const classes = useStyles();
  const customStyles = {
    searchIcon: {
      width: "26px",
      height: "26px",
      ...(i18n.dir() === "rtl"
        ? { marginRight: "16px" }
        : { marginLeft: "16px" }),
    },
    diabledSearchIcon: {
      cursor: "default",
      color: "#838383",
    },
    activeSearchIcon: {
      color: "rgb(98 195 238)",
      cursor: "pointer",
    },
    divider: {
      height: "70%",
      margin: "5px 0",
      width: "2px",
      backgroundColor: "rgb(217 216 216)",
      borderRightWidth: 0,
    },
  };

  return (
    <div
      className={classNames(
        classes.root,
        !showDropdownList && classes.hideTags
      )}
      ref={dropdownRef}
      style={{
        direction: i18n.dir(),
        ...(i18n.dir() === "rtl" ? { left: "10px" } : { right: "10px" }),
      }}
    >
      <div className={showDropdownList ? classes.container : undefined}>
        <div className={classes.searchContainer}>
          <div className={classes.searchText}>
            <SearchIcon
              sx={{
                ...customStyles.searchIcon,
                ...(disableSearch
                  ? customStyles.diabledSearchIcon
                  : customStyles.activeSearchIcon),
              }}
              onClick={() => handleSubmit(searchText)}
            />
            <input
              type="text"
              onClick={() => handleClick(selectedSection)}
              placeholder={t("Search")}
              value={searchText}
              onChange={handleChange}
              onKeyPress={handleKeyDown}
              onFocus={handleFocus}
            />
            {searchText.length ? (
              <div
                className={classes.closeIcon}
                onClick={() => setSearchText("")}
              >
                <CloseIcon />
              </div>
            ) : null}
          </div>
          {(selectedSection === LessonTypes.GUIDED_READING ||
            (selectedSection === LessonTypes.FREEPLAY &&
              levelOneFitler?.code === "E-Books")) && (
            <>
              <Divider sx={customStyles.divider} orientation="vertical" />
              <div
                style={{
                  width: "90px",
                  pointerEvents: "auto",
                }}
              >
                <SelectInput
                  valueList={options}
                  defaultValue={searchOpt.value}
                  getOption={handleChangeSearch}
                  plain
                  disabled={showDropdownList}
                />
              </div>
            </>
          )}
        </div>
        {searchOpt.value === "tag" ? (
          <TagsList
            tagList={tagList}
            searchText={searchText}
            showDropdownList={showDropdownList}
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
            handleSubmit={handleSubmit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default SearchBy;
