import { useEffect, useState, useContext } from "react";
import ListView from "./ListView";
import { GridViewIcon, ListViewIcon } from "components/SVGs/HomeIcons";
import { makeStyles } from "@mui/styles";
import GridView from "./GridView";
import {
  addLesson,
  IAssignedUnit,
  removeLesson,
  selectAssignedLessons,
} from "features/assignments";
import Skeleton from "@mui/material/Skeleton";
import Pagination from "components/Pagination";
import ContentPlayer, { IContentPlayerProps } from "components/ContentPlayer";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  ILo,
  ITag,
  IUnit,
  pageSet,
  selectGrade,
  selectLoList,
  selectPage,
  selectSearch,
  selectSearchBy,
  selectSection,
  selectTotalPages,
  selectUnitList,
  totalPagesSet,
} from "features/content";
import { useTranslation } from "react-i18next";
import {
  punctuation,
  punctuationWithoutNumbers,
  tashkilRegx,
  whitespaces,
} from "features/common/Constants";
import { LessonTypes } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    borderRadius: "6px",
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px",
  },
  toggle: {
    display: "flex",
    background: "rgba(238, 240, 244, 1)",
    borderRadius: "6px",
    padding: "6px",
    alignItems: "center",
    "& svg": {
      fontSize: "20px",
    },
    "& > *": {
      display: "flex",
      width: "40px",
      height: "30px",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "6px",
    },
  },
  active: {
    background: "rgba(98, 195, 238, 1)",
    "& svg": {
      color: "rgba(255 255 255)",
    },
  },
  inActive: {
    cursor: "pointer",
    "& svg": {
      color: "rgba(105, 123, 140, 1)",
    },
  },
});

const pageSize = 10;

enum ViewTypes {
  "list" = "list",
  "grid" = "grid",
}
interface ILosListProps {
  isLoading?: boolean;
}

function LosList({ isLoading = true }: ILosListProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [view, setView] = useState<ViewTypes>(ViewTypes.list);
  const [show, setShow] = useState(false);
  const [lessons, setLessons] = useState<(ILo | IUnit)[]>([]);
  const [searchedLos, setSearchedLos] = useState<ILo[]>([]);
  const [searchedUnits, setSearchedUnits] = useState<IUnit[]>([]);
  const { assignedLosCount } = useAppSelector(selectAssignedLessons);
  const losList = useAppSelector(selectLoList);
  const unitList = useAppSelector(selectUnitList);
  const selectedGrade = useAppSelector(selectGrade);
  const selectedSection = useAppSelector(selectSection);
  const page = useAppSelector(selectPage);
  const totalPages = useAppSelector(selectTotalPages);
  const searchBy = useAppSelector(selectSearchBy);
  const search = useAppSelector(selectSearch);

  const [contentToPlay, setContentToPlay] = useState<IContentPlayerProps>();

  const dispatch = useAppDispatch();
  const toast = useToast();
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    setView(
      selectedSection !== LessonTypes.LESSON ? ViewTypes.grid : ViewTypes.list
    );
  }, [selectedSection]);

  useEffect(() => {
    let filteredLosList = [...losList];
    let filteredUnitList: IUnit[] = [];
    filteredLosList =
      searchBy?.value === "name" && typeof search === "string"
        ? filteredLosList.filter(
            (lo) =>
              lo.loTitle
                .replace(tashkilRegx, "")
                .replace(punctuation, "")
                .replace(whitespaces, " ")
                .includes(
                  search
                    .trim()
                    .replace(tashkilRegx, "")
                    .replace(punctuation, "")
                    .replace(whitespaces, " ")
                ) ||
              (lo.moeLoCode &&
                lo.moeLoCode
                  .replace(tashkilRegx, "")
                  .replace(punctuationWithoutNumbers, "")
                  .replace(whitespaces, " ")
                  .toLowerCase()
                  .includes(
                    search
                      .trim()
                      .replace(tashkilRegx, "")
                      .replace(punctuationWithoutNumbers, "")
                      .replace(whitespaces, " ")
                      .toLowerCase()
                  ))
          )
        : filteredLosList.filter((lo) =>
            search?.length
              ? lo.tags?.some((tag) =>
                  search.length
                    ? (search as ITag[]).map((t) => t.code).includes(tag)
                    : true
                )
              : lo
          );
    unitList.forEach((unit) => {
      if (searchBy?.value === "name" && typeof search === "string") {
        let containSearch = false;
        unit.childLos.forEach((lo) => {
          if (
            lo.loTitle
              .replace(tashkilRegx, "")
              .replace(punctuation, "")
              .replace(whitespaces, " ")
              .includes(
                search
                  .trim()
                  .replace(tashkilRegx, "")
                  .replace(punctuation, "")
                  .replace(whitespaces, " ")
              )
          ) {
            containSearch = true;
          }
        });
        if (containSearch) filteredUnitList.push(unit);
      } else {
        //No unites with tags
      }
    });
    let lessonsData: (ILo | IUnit)[] = [];
    setSearchedLos(filteredLosList);
    setSearchedUnits(filteredUnitList);
    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    let total;
    if (view === ViewTypes.grid) {
      lessonsData = [...filteredLosList] as ILo[];
      filteredUnitList.forEach((unit) => {
        (lessonsData as ILo[]).push.apply(lessonsData, unit.childLos);
      });
      total = filteredLosList.length;
    } else {
      lessonsData = [...filteredLosList, ...filteredUnitList];
      total = lessonsData.length;
    }
    const totalPages = total === 0 ? 1 : Math.ceil(total / pageSize);
    dispatch(totalPagesSet(totalPages));
    if (lessonsData.length > 0)
      lessonsData = lessonsData.slice(startIndex, endIndex);
    setLessons(lessonsData);
  }, [losList, unitList, page, view, search]);

  useEffect(() => {
    setLessons([]);
    setShow(false);
  }, [selectedSection, selectedGrade]);

  const handleChange = (nextView: ViewTypes) => {
    dispatch(pageSet(1));
    setView(nextView);
  };

  const handleChangePage = (page: number) => {
    dispatch(pageSet(page));
  };

  const assignHandler = (lo: IAssignedUnit, type: string) => {
    if (type === "add") {
      if (assignedLosCount < 12) dispatch(addLesson(lo));
      else
        toast({
          label: t("Info"),
          message: t("Can't add more than 12"),
          rtl: i18n.dir() === "rtl",
          type: "info",
          toastId: "LimitExceeded",
        });
    } else dispatch(removeLesson(lo));
  };

  const handleContentFinished = () => {
    setContentToPlay(undefined);
  };

  // to prevent showing noData early
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!isLoading || !show) {
      id = setTimeout(() => setShow(true), 300);
    }
    if (isLoading) setShow(false);

    return () => clearTimeout(id);
  }, [isLoading, show]);
  return (
    <>
      {contentToPlay ? (
        <ContentPlayer
          contentTitle={contentToPlay.contentTitle}
          contentId={contentToPlay.contentId}
          playEne={contentToPlay?.playEne}
          grBook={contentToPlay?.grBook}
          contentFinished={handleContentFinished}
        />
      ) : null}
      {isLoading || !show ? (
        <div style={{ margin: "15px 0" }}>
          <Skeleton variant="rectangular" height={200} />
        </div>
      ) : (
        <>
          <div className={classes.root} style={{ direction: i18n.dir() }}>
            <div className={classes.toggle}>
              <div
                className={
                  view === ViewTypes.grid ? classes.active : classes.inActive
                }
                onClick={() => {
                  handleChange(ViewTypes.grid);
                  logEvent("GridViewBrowsing", { ContentType: "Lessons" });
                }}
              >
                <GridViewIcon />
              </div>
              <div
                className={
                  view === ViewTypes.list ? classes.active : classes.inActive
                }
                onClick={() => {
                  handleChange(ViewTypes.list);
                  logEvent("ListViewBrowsing", { ContentType: "Lessons" });
                }}
              >
                <ListViewIcon />
              </div>
            </div>
          </div>
          {view === ViewTypes.list ? (
            <ListView
              lessons={lessons}
              count={[...searchedLos, ...searchedUnits].length}
              assignHandler={assignHandler}
              getContentToPlay={setContentToPlay}
              selectedSection={selectedSection}
            />
          ) : (
            <GridView
              lessons={lessons}
              assignHandler={assignHandler}
              getContentToPlay={setContentToPlay}
              selectedSection={selectedSection}
            />
          )}
        </>
      )}
      <Pagination
        total={totalPages}
        getPage={handleChangePage}
        defaultPage={page}
        spreadStyle
      />
    </>
  );
}

export default LosList;
