import { SpreadSheetDownloadIcon } from "components/SVGs";
import { utils, writeFile } from "xlsx";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { MasterDataContext } from "contexts/MasterDataContext";
import { IStudentInfo } from "features/students";

interface IDownloadExcelProps {
  studentList: IStudentInfo[];
  selectedDataList: number[];
}

function DownloadExcel({ studentList, selectedDataList }: IDownloadExcelProps) {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const { logEvent } = useContext(MasterDataContext);

  const logQRsDownload = (status: string) => {
    logEvent("QRStudentsBulkDownload", {
      Status: status,
    });
  };
  const handleDownladFile = () => {
    if (selectedDataList?.length === 0) {
      toast({
        label: t("Note"),
        message: t("Select students to send QRcode"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "no-student-selected",
      });
      logQRsDownload("Failure");
      return;
    }
    logQRsDownload("Success");
    // const data = [
    //   ["student_id", "student_name", "recipient_email", "email_purpose"],
    // ];
    const data = [["student_id", "student_name", "recipient_email"]];
    studentList.forEach((stu) => {
      if (selectedDataList?.includes(stu._id)) {
        data.push([`${stu._id}`, stu._name, "", ""]);
      }
    });

    // data[1][3] =
    //   "ضع أحد القيم التالية: " + Object.values(emailPurpose).toString();
    const studentDetailsSheet = utils.aoa_to_sheet(data);
    const dwonloadWorkbook = utils.book_new();
    utils.book_append_sheet(
      dwonloadWorkbook,
      studentDetailsSheet,
      "student-details"
    );
    writeFile(dwonloadWorkbook, "QRcodes-" + Date.now() + ".xlsx");
  };

  const useStyles = makeStyles({
    toolTip: {
      border: "1px solid rgba(218, 218, 218, 1)",
      boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
      background: "rgba(255, 255, 255, 1) !important",
      color: "rgba(105, 123, 140, 1) !important",
      fontSize: "12px !important",
      padding: "8px !important",
      textAlign: i18n.dir() === "ltr" ? "left" : "right",
      whiteSpace: "pre-line",
    },
    arrow: {
      color: "rgba(255, 255, 255, 1)",
      "&:before": {
        border: "1px solid transparent",
        color: "rgba(255, 255, 255, 1)",
        borderBottom: "1px solid rgba(218, 218, 218, 1)",
      },
      "&:after": {
        border: "1px solid transparent",
        borderBottom: "1px solid rgba(218, 218, 218, 1)",
      },
    },
  });
  const classes = useStyles();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Tooltip
        title={t("To send QRcode")}
        classes={{
          tooltip: classes.toolTip,
          arrow: classes.arrow,
        }}
        arrow
        placement="top"
      >
        <span style={{ padding: "0 10px" }}>{t("To send QRcode")}</span>
      </Tooltip>
      <Tooltip
        title={t("Instrcutions to send QRcode")}
        classes={{
          tooltip: classes.toolTip,
          arrow: classes.arrow,
        }}
        arrow
        placement="top"
      >
        <div>
          <SpreadSheetDownloadIcon
            sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            onClick={handleDownladFile}
          />
        </div>
      </Tooltip>
    </div>
  );
}

export default DownloadExcel;
