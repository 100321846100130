import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Pagination from "components/Pagination";
import { AssignIcon } from "components/SVGs/HomeIcons";
import showView from "assets/img/showView.png";
import {
  allClassesSet,
  examAdded,
  examRemoved,
  IExam,
  selectAssignExam,
  selectedClassesSet,
  selectedGrade,
} from "features/exams";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import useToast from "hooks/useToast";
import {
  useFetchExamClassesQuery,
  useFetchExamQuestionsQuery,
} from "services/exams";
import { selectAllGradesSwitch } from "features/filters";
import { handleFetchedClasses } from "utils/ExamHelpers";
import ContentPlayer, { IContentPlayerProps } from "components/ContentPlayer";
import useClientDevice from "hooks/useClientDevice";
import { contentIdsSet, showPlayerSet } from "features/ui";
import { MasterDataContext } from "contexts/MasterDataContext";

const customStyles = {
  table: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "row",
    padding: "10px 20px",
    width: "100%",
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "300px",
      fontSize: "14px",
      fontWeight: "bold",
      padding: "0 20px",
    },
  },
  title: { width: "300px !important", padding: "0 55px !important" },
  question: { flex: 1 },
  preview: { width: "60px !important" },
  body: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    padding: "0 20px 10px",
    "& > :nth-of-type(odd)": {
      background: "rgb(249 249 249)",
    },
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    height: "40px",
    padding: "0 10px",
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "300px",
      fontSize: "14px",
      height: "100%",
      padding: "0 10px",
    },
  },
};
interface IExamsTableProps {
  list: IExam[];
}
const headers = [
  { title: "examTitle", id: 1, styles: "title" },
  { title: "examQuestions", id: 2, styles: "question" },
  { title: "examPreview", id: 3, styles: "preview" },
];
function ExamsTable({ list }: IExamsTableProps) {
  const { logEvent } = useContext(MasterDataContext);
  const assignExam = useAppSelector(selectAssignExam);
  const allGradesSwitch = useAppSelector(selectAllGradesSwitch);
  const dispatch = useAppDispatch();
  const currentGrade = useAppSelector(selectedGrade);
  const { t, i18n } = useTranslation();
  const [currentList, setCurrentList] = useState<IExam[]>([]);
  const [examToPlay, setExamToPlay] = useState<IContentPlayerProps>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { isTablet } = useClientDevice();
  const toast = useToast();

  const { data } = useFetchExamClassesQuery(
    {
      examId: assignExam.examId as string,
      gradeId: allGradesSwitch ? undefined : currentGrade?.id!,
    },
    {
      skip: !assignExam.examId,
    }
  );

  // const { data: examContent } = useFetchExamQuestionsQuery(
  //   previewExamId as string,
  //   {
  //     skip: !previewExamId,
  //   }
  // );

  useEffect(() => {
    paginateList();
  }, [list, page]);

  useEffect(() => {
    if (!currentList.length) setPage(1);
  }, [currentList]);

  useEffect(() => {
    if (data?._statusCode === 200) {
      const { allClasses } = handleFetchedClasses(data);
      dispatch(allClassesSet(allClasses));
      dispatch(selectedClassesSet([]));
    }
  }, [data]);

  // useEffect(() => {
  //   if (examContent?._statusCode === 200) {
  //     const questionsList = examContent._entity._questions;
  //     previewContent([{contentId:}]);
  //     }
  //   } else {
  //     setPreviewExamId(undefined);
  //   }
  // }, [examContent]);

  const paginateList = () => {
    const total = list.length;
    const pageSize = 20;
    const startIndex = (page - 1) * pageSize;
    const endIndex = page * pageSize;
    const pages = total === 0 ? 1 : Math.ceil(total / pageSize);
    setTotalPages(pages);
    setCurrentList(list.slice(startIndex, endIndex));
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  function notCompatibleWithTablets() {
    toast({
      label: t("Info"),
      message: t("Only Desktop supported"),
      rtl: i18n.dir() === "rtl",
      type: "info",
      toastId: "ContentPlayNotSupoorted",
    });
  }

  const previewExamHandler = (
    id: string,
    type: "BASELINE" | "ASSESSMENT_BANK"
  ) => {
    if (isTablet) notCompatibleWithTablets();
    else {
      logEvent("ClickOnViewAssessment", { ContentId: id });
      dispatch(contentIdsSet([{ _contentId: id, _contentType: type }]));
      dispatch(showPlayerSet(true));
    }
  };

  return (
    <>
      {examToPlay ? (
        <ContentPlayer
          contentTitle={examToPlay.contentTitle}
          contentId={examToPlay.contentId}
          exam={examToPlay.exam}
          contentFinished={() => setExamToPlay(undefined)}
        />
      ) : null}
      <Typography component="div" sx={customStyles.table}>
        <Typography component="div" sx={customStyles.header}>
          {headers.map((item) => (
            <Typography
              component="div"
              key={item.id}
              sx={customStyles[item.styles as keyof typeof customStyles]}
            >
              {t(item.title)}
            </Typography>
          ))}
        </Typography>
        <Typography component="div" sx={customStyles.body}>
          {currentList.map((item) => (
            <Typography component="div" key={item.id} sx={customStyles.item}>
              <AssignBtn
                exam={{
                  id: item.id,
                  name: item.name,
                  selected: item.id === assignExam.examId,
                }}
              />
              <Typography component="div" sx={{ padding: "0 20px !important" }}>
                {item.name}
              </Typography>
              <Typography component="div" sx={{ flex: 1 }}>
                {item.questions}
              </Typography>
              <img
                alt="Preview Exam Questions popup"
                src={showView}
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
                // onClick={() => setPreviewExamId(item.id)}
                onClick={() => previewExamHandler(item.id,item.type)}
              />
            </Typography>
          ))}
        </Typography>
        <Pagination
          total={totalPages}
          getPage={handleChangePage}
          defaultPage={page}
          spreadStyle
        />
      </Typography>
    </>
  );
}

type AssignBtnProps = {
  id: string;
  name: string;
  selected: boolean;
};

const AssignBtn = ({ exam }: { exam: AssignBtnProps }) => {
  const { logEvent } = useContext(MasterDataContext);

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const assigExam = useAppSelector(selectAssignExam);
  const [selected, setSelected] = useState(exam.selected);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setSelected(exam.selected);
  }, [exam.selected]);

  useEffect(() => {
    if (selected) dispatch(examAdded({ id: exam.id, name: exam.name }));
    else dispatch(examRemoved());
  }, [selected]);

  const assignExam = () => {
    if (!assigExam.examId) {
      logEvent("AddAssessmentToAssigingCart", { ContentId: exam.id });
      setSelected(!selected);
    } else
      toast({
        label: t("Info"),
        message: t("Only one assessment"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "OneExamAssignmentRule",
      });
  };

  return !selected ? (
    <AssignIcon onClick={assignExam} sx={{ cursor: "pointer" }} />
  ) : (
    <RemoveCircleIcon
      sx={{
        color: "rgba(105, 123, 140, 1)",
        cursor: "pointer",
      }}
      onClick={() => {
        setSelected(!selected);
      }}
    />
  );
};

export default ExamsTable;
