import { useEffect, useContext } from "react";
import BookProgress from "./BookProgress";
import StudentProgress from "./StudentProgress";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useLocation } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";

export enum ReportPathes {
  students = "students-progress",
  books = "books-progress",
}
const renderTab = (tabToRender: string) => {
  switch (tabToRender) {
    case ReportPathes.students:
      return <StudentProgress />;
    case ReportPathes.books:
    default:
      return <BookProgress />;
  }
};
function GuidedReading() {
  const { logEvent } = useContext(MasterDataContext);
  let { pathname: url } = useLocation();
  let newUrl = url.split("/");

  newUrl.shift();
  newUrl.shift();
  newUrl.shift();

  useEffect(() => {
    if (newUrl[0] === ReportPathes.students)
      logEvent("VisitGuidedReadingClassReport");
    else logEvent("VisitGuidedReadingBooksReport");
  }, [newUrl[0]]);

  return (
    <>
      <Breadcrumb />
      {renderTab(newUrl[0])}
    </>
  );
}

export default GuidedReading;
