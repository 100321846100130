import { Grid, Paper } from "@mui/material";
import Content from "./Content";
import Toolbar from "./Toolbar";
import GradesList from "components/GradeList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { gradeSet, selectGrade } from "features/grStudentsProgress";
import { IGrade } from "features/common/Interfaces";
import { useState } from "react";
import { selectGrades } from "features/filters";
import DataNotFound from "components/DataNotFound";
import { useTranslation } from "react-i18next";
const customStyles = {
  container: {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: " 0 0 18px 18px",
  },
  toolBar: {
    background: "rgba(247, 248, 250, 1)",
    margin: "30px 10px",
    padding: "0 10px",
    height: "75px",
  },
  content: {
    borderRadius: "18px",
  },
};

const StudentProgress = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const ownedGrades = useAppSelector(selectGrades).filter(
    (grade) => grade.owned
  );
  const selectedGrade = useAppSelector(selectGrade);

  const clickHandler = (grade: IGrade) => {
    dispatch(gradeSet(grade));
  };
  return (
    <>
      <GradesList
        onGradeChange={clickHandler}
        selectedGrade={selectedGrade}
        getIsGradeLoading={setIsLoading}
      />
      {ownedGrades.length > 0 ? (
        <Grid
          container
          direction="column"
          alignItems="stretch"
          sx={customStyles.container}
        >
          <Grid item xs={12}>
            <Paper elevation={0} sx={customStyles.toolBar}>
              <Toolbar />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={customStyles.content}>
              <Content />
            </Paper>
          </Grid>
        </Grid>
      ) : isLoading ? null : (
        <DataNotFound text={t("no classes")} />
      )}
    </>
  );
};

export default StudentProgress;
