import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactElement, useContext, useEffect, useState } from "react";
import BackgroundRightSide from "assets/img/LoginBackgroundRightSide.svg";
import BackgroundLeftSide from "assets/img/LoginBackgroundLeftSide.svg";
import BackgroundTablet from "assets/img/LoginBackgroundTablet.svg";
import TeachersBoard from "assets/img/LoginTeachersBoard.png";
import { BigLogoIcon } from "components/SVGs/MainMenuIcons";
import Button from "components/Button";
import { RenderInput, renderSocial } from "../Elements";
import Joi from "joi";
import LoginSchema from "./ValidationSchema";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IValidationErrors } from "features/common/Interfaces";
import useToast from "hooks/useToast";
import { ActionType, MasterDataContext } from "contexts/MasterDataContext";
import { resetPasswordRequested } from "features/auth";
import { useAppDispatch } from "app/hooks";
import { useRef } from "react";
import { useCallback } from "react";
import {
  ILoginRequest,
  ILoginResponse,
  useGoogleLoginMutation,
  useLoginMutation,
} from "services/auth";
import { IEdgeCases } from "services/API";
import googleIdentity from "components/GoogleIdentity";

export interface ILoginProps {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginBottom: "30px",
    },
  },
  link: {
    width: "100%",
    "& span": {
      userSelect: "none",
      textDecoration: "underline",
      cursor: "pointer",
      color: "rgb(60 180 229)",
      fontSize: "16px",
    },
  },
});

const initialState: ILoginRequest = {
  email: "",
  password: "",
};

const edgeCases: IEdgeCases = {
  546: "No user found",
  632: "Wrong password",
  434: "School restricted",
  630: "Not a teacher",
  500: "Bad Request",
  633: "Invalid auth token",
};

export default function Login(props: ILoginProps): ReactElement {
  const [formData, setFormData] = useState<ILoginRequest>(initialState);
  const [errors, setErrors] = useState<IValidationErrors>({});
  const dispatch = useAppDispatch();
  const { dispatch: masterDataDispatch, logEvent } =
    useContext(MasterDataContext);
  const [login] = useLoginMutation();
  const [googleLogin] = useGoogleLoginMutation();
  const history = useHistory();
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const formVars = {
    email: {
      name: "email",
      label: t("Email"),
      placeholder: t("Email"),
      value: formData.email,
      autoFocus: true,
    },
    password: {
      name: "password",
      label: t("Password"),
      placeholder: t("Password"),
      type: "password",
      value: formData.password,
    },
  };

  const credentialHandler = (response: { credential: string }) => {
    googleLoginHandler(response.credential);
  };
  const googleButton = useRef(null);

  useEffect(() => {
    const revoke = googleIdentity({ credentialHandler, nodeRef: googleButton });
    return () => {
      revoke();
    };
  }, []);

  const changeHandler = (event: {
    currentTarget: { name: "email"; value: string };
  }) => {
    const data = { ...formData };
    const { currentTarget: input } = event;
    const errorsObject = { ...errors };
    if (input.name === "email" && input.value.length > 0)
      input.value = input.value.trim();
    const errorMessage = validateProperty(input);
    if (errorMessage) errorsObject[input.name] = errorMessage;
    else delete errorsObject[input.name];
    setErrors(errorsObject);
    data[input.name] = input.value;
    setFormData(data);
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: "email";
    value: string;
  }) => {
    const obj = { [name]: value };
    const propertySchema = {
      [name]: LoginSchema[name],
    };
    const { error } = Joi.object(propertySchema).validate(obj);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { email, password } = formData;
    const { error } = Joi.object(LoginSchema).validate(
      { email, password },
      options
    );
    let errorsObject = { ...errors };
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };

  const loginHandler = useCallback(
    (data: ILoginRequest) => auth<ILoginRequest>(data, login),
    [login]
  );
  const googleLoginHandler = useCallback(
    (data: string) => auth<string>(data, googleLogin),
    [googleLogin]
  );

  const forgotPasswordHandler = () => {
    dispatch(resetPasswordRequested("started"));
    history.push("/forgot-password");
  };

  const classes = useStyles();
  const isFormNotValid = Object.keys(validate()).length > 0;
  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "fit-content",
        minHeight: "100vh",
        position: "relative",
        background: "rgb(255 255 255)",
        direction: i18n.dir(),
        "@media only screen and (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid
        item
        xs
        sx={{
          backgroundImage: `url(${
            i18n.dir() === "rtl" ? BackgroundRightSide : BackgroundLeftSide
          })`,
          backgroundSize: "cover",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          "@media only screen and (max-width: 768px)": {
            backgroundImage: `url(${BackgroundTablet})`,
            height: "35%",
            minHeight: "auto",
          },
        }}
      >
        {renderSocial(i18n.dir())}
        <Typography
          sx={{
            color: "rgb(61 69 76)",
            fontSize: "28px",
            marginBottom: "40px",
          }}
        >
          {t("Login Intro")}
        </Typography>
        <img
          alt="teachers Portal login"
          src={TeachersBoard}
          style={{ width: "40%", maxWidth: "400px", minWidth: "200px" }}
        />
      </Grid>
      <Grid
        item
        xs
        sx={{
          height: "100%",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "100%",
          "@media only screen and (max-width: 768px)": {
            justifyContent: "flex-start",
            height: "65%",
            minHeight: "auto",
          },
        }}
      >
        <BigLogoIcon
          sx={{
            fontSize: "150px",
            margin: "50px 0",
            cursor: "pointer",
            "@media only screen and (max-width: 768px)": {
              position: "absolute",
              [i18n.dir() === "rtl" ? "left" : "right"]: "40px",
              top: "5px",
              margin: 0,
              fontSize: "100px",
            },
          }}
          onClick={() => history.push("/")}
        />
        <div className={classes.root}>
          {RenderInput({
            ...formVars.email,
            errors: t(errors.email),
            changeHandler,
            styles: { width: "270px", padding: "10px 0" },
          })}
          {RenderInput({
            ...formVars.password,
            errors: t(errors.password),
            changeHandler,
            styles: { width: "270px" },
          })}
          <div className={classes.link} onClick={forgotPasswordHandler}>
            <span>{t("ForgotPassword")}</span>
          </div>
          <Button
            label={t("Login")}
            action={() => loginHandler(formData)}
            styles={{ width: "270px" }}
            disable={isFormNotValid}
          />
          <div
            style={{
              padding: "15px",
              paddingBottom: "12px",
              margin: 0,
              fontWeight: "bold",
            }}
          >
            {t("or")}
          </div>
          <div ref={googleButton}></div>
        </div>
      </Grid>
    </Grid>
  );

  async function auth<T>(payload: T, loginMethod: Function): Promise<void> {
    const toastId = "loginToastMessage";
    try {
      const data = await loginMethod(payload).unwrap();
      if (data?._statusCode === 200) {
        configureLogin(data);
      } else if (
        Object.keys(edgeCases).includes(data?._statusCode.toString())
      ) {
        toast({
          label: t("error"),
          message: t(edgeCases[data._statusCode]),
          rtl: i18n.dir() === "rtl",
          type: "error",
          toastId,
        });
      } else throw data;
    } catch (error) {
      console.log({ error });
    }
  }

  function configureLogin(data: Pick<ILoginResponse, "_entity">) {
    setFormData(initialState);
    const {
      _token,
      _schoolId,
      _schoolName,
      _teacherId,
      _name,
      _email,
      _refreshToken,
    } = data?._entity;
    localStorage.removeItem("expired");
    localStorage.setItem("abjadToken", _token);
    localStorage.setItem("refreshToken", _refreshToken);
    localStorage.setItem("school", _schoolId.toString());
    localStorage.setItem("schoolName", _schoolName.toString());
    localStorage.setItem("id", _teacherId.toString());
    localStorage.setItem("name", _name);
    localStorage.setItem("email", _email);
    masterDataDispatch({
      type: ActionType.userInfo,
      payload: {
        school: _schoolId,
        schoolName: _schoolName,
        name: _name,
        id: _teacherId,
        email: _email,
      },
    });
    history.push("/dashboard");
    logEvent("LoginStatus", {
      Status: "success",
      SchoolId: _schoolId,
      TeacherId: _teacherId,
    });
  }
}
