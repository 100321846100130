import {
  FilterTypes,
  IFecthedFilter,
  IFetchFiltersEntity,
  IFetchGrLevelsByGradeEntity,
} from "services/filters";
import {
  IExamsFilters,
  IFiltersState,
  IGrGroups,
  ILessonsFilters,
  ILevelFilter,
} from "features/filters";
import { GradeCods, IGrade } from "features/common/Interfaces";

export const transformFilters = (
  filters: IFecthedFilter[],
  type: FilterTypes
): ILevelFilter[] => {
  let fitlerVal = type === FilterTypes.EXAMS ? "ASSESSMENT_BANK" : type;
  return filters
    .filter((item) => item._filterType === fitlerVal)[0]
    ._groups!.map((group) => ({
      code: group._code,
      title: group._title,
      subGroups: group._subGroups
        ? group._subGroups.map((subGroup) => {
            let code = subGroup._code;
            let title = subGroup._title;
            if (
              group._code === "E-Books" ||
              group._code === "Printable Worksheets"
            )
              title = subGroup._title.split("-").reverse().join("-");
            return {
              code,
              title,
            };
          })
        : [],
    }));
};

export const getMoeFilters = (grade: IFetchFiltersEntity) => {
  let moeFilers = grade._filters.filter(
    (item) => item._filterType === "MOE"
  )[0];
  if (moeFilers) {
    return moeFilers?._terms?.map((term) => ({
      code: term._code,
      title: term._title,
      groups: term?._groups?.map((group) => ({
        code: group._code,
        title: group._title,
        subGroups: group?._subGroups
          ? group._subGroups?.map((subGroup) => ({
              code: subGroup._code,
              title: subGroup._title,
            }))
          : [],
      })),
    }));
  }
  return [];
};

export const handleFetchedClasses = (response: any) => {
  const transformData = (response: any) => {
    return [
      ...response.map((item: any) => ({
        classId: item._classId,
        className: item._className,
        checked: true,
        students: transformStudentData(item._students),
      })),
    ];
  };

  const transformAllClassesData = (response: any) => {
    return [
      ...response.map((item: any) => ({
        value: item._classId,
        label: item._className,
        checked: true,
        students: transformStudentData(item._students),
      })),
    ];
  };

  const transformStudentData = (students: any) => {
    return [
      ...students.map((student: any) => ({
        studentId: student._studentId,
        studentName: student._studentName,
        checked: true,
        status: null,
      })),
    ];
  };
  let allClasses = [];
  let selectedClasses = [];
  try {
    if (response?._statusCode === 200) {
      const { _entity: data } = response;
      allClasses = transformAllClassesData(data);
      selectedClasses = transformData(data);
    } else throw response;
  } catch (err) {
    console.log(err);
  }
  return { allClasses, selectedClasses };
};

export const filterEntitiesData = (
  filterList: IFetchFiltersEntity[],
  grGroupsList: IFetchGrLevelsByGradeEntity
): IFiltersState => {
  const grades: IGrade[] = filterList.map((grade, index) => ({
    id: grade._id,
    code: grade._code,
    name: grade._nameAr,
    owned: grade._owned,
  }));
  const freePlayFilters: ILevelFilter[] = transformFilters(
    filterList[0]._filters,
    FilterTypes.FREEPLAY
  );
  const guidedReadingFilters = transformFilters(
    filterList[0]._filters,
    FilterTypes.GUIDED_READING
  );
  let lessonsFilters: ILessonsFilters = {};
  filterList.forEach((grade) => {
    lessonsFilters = {
      ...lessonsFilters,
      [grade._id]: {
        moe: getMoeFilters(grade),
        domains: transformFilters(grade._filters, FilterTypes.LESSON),
      },
    };
  });

  let examsFilters: IExamsFilters = {};
  filterList.forEach((grade) => {
    examsFilters = {
      ...examsFilters,
      [grade._id]: transformFilters(grade._filters, FilterTypes.EXAMS),
    };
  });

  let selectedGrGroup: "all" | GradeCods = "all";

  let grGroups: IGrGroups = { ...grGroupsList };
  grGroups["all"] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  return {
    grades,
    lessonsFilters,
    freePlayFilters,
    guidedReadingFilters,
    examsFilters,
    grGroups,
    selectedGrGroup,
  };
};

export const resetLocalStorage = () => {
  const dir = localStorage.getItem("dir");
  const restoreId = localStorage.getItem("restoreId");
  localStorage.clear();
  if (dir) localStorage.setItem("dir", dir);
  if (restoreId) localStorage.setItem("restoreId", restoreId);
};
