import { createContext, ReactNode } from "react";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";

const init = () => {
  const subscribeKey = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;
  const uuid = process.env.REACT_APP_PUBNUB_UUID;
  const restore = true;
  const keepAlive = true;
  // const logVerbosity = true;
  const config = {
    subscribeKey,
    uuid,
    restore,
    keepAlive,
  } as PubNub.PubnubConfig;
  const client = new PubNub(config);
  return client;
};

interface INotificationsContext {}

export const NotificationsContext = createContext<INotificationsContext>({});

const NotificationsContextProvider = (props: { children: ReactNode }) => {
  const notifier = init();

  return (
    <PubNubProvider client={notifier}>
      <NotificationsContext.Provider value={{}}>
        {props.children}
      </NotificationsContext.Provider>
    </PubNubProvider>
  );
};

export default NotificationsContextProvider;
