import { useState, useEffect } from "react";
import DomainCard from "../../../shared/DomainCard";
import { Grid } from "@mui/material";
import StudentsList from "../StudentsList";
import LoCard from "../LoCard";
import ClassSkeleton from "../ClassSkeleton";
import DataNotFound from "components/DataNotFound";
import StudentScoreCell from "./StudentScoreCell";
import {
  selectClass,
  selectDomain,
  selectSelectedSort,
  studentsSet,
  classReportSet,
  selectPagination,
  selectClassReport,
  paginationSet,
  selectGrade,
  IDomainReport,
} from "features/classPerformance";
import {
  getStudentsInfo,
  paginatedOptions,
  sortArray,
  createReportRecords,
} from "utils/ReportsHelpers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IDomainDetailsEntity,
  IDomainReportEntity,
  IDomainsReportEntity,
  IStudentDomainEntity,
  useFetchFormativeReportQuery,
} from "services/reports";
import { Assessment } from "features/common/Interfaces";

const colors = [
  "rgb(68 183 253)",
  "rgb(90 107 199)",
  "rgb(134 83 203)",
  "rgb(201 97 206)",
];

const customStyles = {
  root: {
    "& > :nth-of-type(2n)": {
      background: "rgb(248 248 248)",
    },
    "& > :nth-of-type(2n+1)": {
      background: "rgb(255 255 255)",
    },
  },
  domainColumn: {
    "& > :last-child": {
      borderLeft: "1px solid rgb(214 214 214)",
      "& > div > :first-of-type": {
        width: "calc(100% + 5px)",
      },
    },
  },
  loColumn: {
    "& > :last-child": {
      borderLeft: "1px solid rgb(214 214 214)",
    },
  },
};

function Formative() {
  const selectedClass = useAppSelector(selectClass);
  const selectedGrade = useAppSelector(selectGrade);
  const selectedDomain = useAppSelector(selectDomain);
  const selectedSort = useAppSelector(selectSelectedSort);
  const pagination = useAppSelector(selectPagination);
  const classReport = useAppSelector(selectClassReport);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IDomainReport[]>([]);
  const allDomains = selectedDomain?.value === -1;
  const { startIndex, endIndex } = paginatedOptions(pagination);
  const {
    data: formativeReportData,
    isLoading,
    isUninitialized,
  } = useFetchFormativeReportQuery(
    {
      classId: selectedClass?.value as number,
      domainId: selectedDomain?.value as number,
    },
    {
      skip: !selectedClass?.value || !selectedDomain?.value,
    }
  );

  useEffect(() => {
    if (formativeReportData?._statusCode === 200) {
      let students: IStudentDomainEntity[] = [];
      let domains: IDomainDetailsEntity[] = [];

      const studentsData = JSON.parse(
        JSON.stringify(
          (formativeReportData._entity as IDomainReportEntity)
            ._studentDomainDetails ?? []
        )
      );
      const domainsData = JSON.parse(
        JSON.stringify(
          (formativeReportData._entity as IDomainsReportEntity)
            ._domainAssessmentReportRes ?? []
        )
      ) as IDomainDetailsEntity[];

      if (studentsData) {
        students = studentsData.sort(sortArray(selectedSort));
      }
      domains = domainsData?.filter((item) => item !== null);
      const studentsDomainData =
        domains?.length > 0 ? domains[0]?._studentAssessmentReports : [];
      const studentsInfo = allDomains ? studentsDomainData : students;
      dispatch(studentsSet(getStudentsInfo(studentsInfo)));
      dispatch(
        classReportSet(
          createReportRecords(
            allDomains,
            domainsData,
            studentsData,
            Assessment.FORMATIVE
          )
        )
      );
    } else {
      dispatch(classReportSet([]));
    }
  }, [formativeReportData, selectedSort]);

  useEffect(() => {
    if (selectedDomain?.value !== -1) {
      const total = classReport?.length;
      const totalPages =
        total === 0 ? 1 : Math.ceil(total / pagination.pageSize!);
      dispatch(paginationSet({ ...pagination, totalPages: totalPages }));
    }
  }, [classReport]);

  useEffect(() => {
    if (classReport?.length > 0) {
      if (allDomains && classReport[0]?.scores !== undefined)
        setData(classReport);
      if (!allDomains && classReport[0]?.students !== undefined)
        setData(classReport.slice(startIndex, endIndex));
    } else {
      setData([]);
    }
  }, [classReport, pagination.currentPage]);

  const renderSkeleton = () => (
    <Grid item xs={12}>
      <ClassSkeleton />
    </Grid>
  );
  const renderStudentMarksCell = (student: any, loId: any) => {
    return (
      <StudentScoreCell
        student={student}
        loId={loId}
        gradeInfo={selectedGrade!}
        classInfo={selectedClass!}
      />
    );
  };
  return (
    <Grid
      container
      sx={{
        ...customStyles.root,
        ...(!allDomains && data.length !== 5 ? customStyles.loColumn : {}),
        ...(!isLoading && allDomains && data.length < 4
          ? customStyles.domainColumn
          : {}),
      }}
      alignItems="stretch"
    >
      {isLoading ? renderSkeleton() : renderClassData(data, allDomains)}
    </Grid>
  );

  function renderClassData(data: IDomainReport[], isAll: boolean) {
    const compToRender = (
      <>
        <Grid
          item
          sx={{ width: isAll ? "24%" : "35%", borderTopRightRadius: "2rem" }}
        >
          <StudentsList />
        </Grid>
        {data?.map((columnData, index) => (
          <Grid
            item
            key={index}
            sx={{
              width: isAll ? "19%" : "13%",
              borderTopLeftRadius: allDomains ? "2rem" : 0,
            }}
          >
            {isAll ? (
              <DomainCard data={columnData} color={colors[index]} />
            ) : (
              <LoCard
                data={columnData}
                renderStudentMarksCell={renderStudentMarksCell}
              />
            )}
          </Grid>
        ))}
      </>
    );

    const noData = (
      <Grid item xs={12}>
        <DataNotFound />
      </Grid>
    );

    return data.length > 0
      ? compToRender
      : isLoading || isUninitialized
      ? null
      : noData;
  }
}

export default Formative;
