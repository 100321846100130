import { CSSProperties, useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { LinearProgress } from "@mui/material";
import { ExcelIcon } from "../SVGs";
import classNames from "classnames";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";
import { downloadReport } from "services/fileServices";
import { Assessment, ICSS, IOption } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    flexDirection: "column",
    height: "100%",
  },
  text: {
    display: "block",
    color: "rgb(255, 255, 255, 255)",
    textAlign: "center",
  },
  hide: {
    display: "none",
  },
  progressBar: {
    width: "100%",
    borderRadius: 5,
    backgroundColor: "rgb(255 255 255/0.4)",
    height: 4,
    marginTop: 2,
  },
  bar: {
    borderRadius: 5,
  },
});
interface IExportReportProps {
  text?: string;
  type: Assessment;
  selectedClass: IOption;
  style?: CSSProperties;
  "data-test"?: string;
}
function ExportReport({
  text,
  type,
  selectedClass,
  style = {},
  ...rest
}: IExportReportProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { logEvent } = useContext(MasterDataContext);
  const [isTablet, setIsTablet] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { value: classId, label: className } = selectedClass;
  const toast = useToast();

  useEffect(() => {
    setIsTablet(navigator.maxTouchPoints > 1);
  }, []);

  const exportHandler = async () => {
    setIsLoading(true);
    try {
      const response = await downloadReport(classId as number, type);
      if (response?.status === 200) {
        loggingEvent(type, classId as number);
        const { data } = response;
        const file = new Blob([data], { type: "application/octet-stream" });
        const url = URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = url;
        const reportName = className + "_" + type + ".xlsx";
        link.setAttribute("download", reportName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url);
      } else throw response;
    } catch (error) {
      toast({
        label: t("error"),
        message: t("Exporting report error"),
        rtl: i18n.dir() === "rtl",
        type: "error",
        toastId: "ExportingReportError",
      });
      console.log({ error });
    }
    setIsLoading(false);
  };

  const loggingEvent = (type: Assessment, classId: number) => {
    let eventName = "ClassReportExportExcel";
    if (type === Assessment.BASELINE) eventName = "BaselineReportExportExcel";
    logEvent(eventName, { ClassId: classId });
  };

  style = { ...style, ...(text ? { flexDirection: "column" } : {}) } as ICSS;

  return (
    <div
      onClick={() => exportHandler()}
      className={classNames(classes.root, isTablet && classes.hide)}
      style={style}
      {...rest}
    >
      <ExcelIcon />
      {isLoading ? (
        <LinearProgress
          classes={{ root: classes.progressBar, bar: classes.bar }}
        />
      ) : null}

      {text ? <span className={classes.text}>{text}</span> : null}
    </div>
  );
}

export default ExportReport;
