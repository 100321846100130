import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IFetchedLo,
  IFreePlayLosEntity,
  IGuidedReadingLosEntity,
  ILessonLosEntity,
  useFetchLosMutation,
} from "services/content";
import { FilterTypes } from "services/filters";
import {
  ILo,
  IUnit,
  losListLoaded,
  pageSet,
  selectGrade,
  selectMoeVeiw,
  selectSection,
  totalPagesSet,
  unitListLoaded,
} from "features/content";
import {
  IFilter,
  ILessonsFilters,
  ILevelFilter,
  ILevelMoeFilter,
  selectFreePlayFilters,
  selectGuidedReadingFilters,
  selectLessonsFilters,
} from "features/filters";
import { IGrade, LessonTypes } from "features/common/Interfaces";

export interface IChildCompProps {
  lessonsFilters: ILessonsFilters;
  freePlayFilters: ILevelFilter[];
  guidedReadingFilters: ILevelFilter[];
  levelOneFilters?: (ILevelMoeFilter | ILevelFilter)[];
  setLevelOneFilters(filters: (ILevelMoeFilter | ILevelFilter)[]): void;
  levelTwoFilters?: ILevelFilter[];
  setLevelTwoFilters(filters?: ILevelFilter[]): void;
  levelThreeFilters?: IFilter[];
  setLevelThreeFilters(filters: IFilter[]): void;
  selectedLevelOneFilter?: IFilter;
  setSelectedLevelOneFilter(filters?: IFilter): void;
  selectedLevelTwoFilter?: ILevelFilter;
  setSelectedLevelTwoFilter(filters?: ILevelFilter): void;
  selectedLevelThreeFilter?: IFilter;
  setSelectedLevelThreeFilter(filters?: IFilter): void;
  dispatchLessonsCall(values: {
    _termCode?: string;
    _groupCode?: string;
    _subGroupCode?: string;
  }): Promise<void>;
  selectedGrade?: IGrade;
  moeView: boolean;
  selectedSection: LessonTypes;
}
export type FilterChildComp = (props: IChildCompProps) => JSX.Element;
function FiltersHoc(ChildComp: FilterChildComp) {
  return function UpdatedComp() {
    const dispatch = useAppDispatch();
    const lessonsFilters = useAppSelector(selectLessonsFilters);
    const freePlayFilters = useAppSelector(selectFreePlayFilters);
    const guidedReadingFilters = useAppSelector(selectGuidedReadingFilters);
    const selectedGrade = useAppSelector(selectGrade);
    const selectedSection = useAppSelector(selectSection);
    const moeViewState = useAppSelector(selectMoeVeiw);
    const [fetchLos] = useFetchLosMutation();

    const [levelOneFilters, setLevelOneFilters] =
      useState<(ILevelMoeFilter | ILevelFilter)[]>();
    const [levelTwoFilters, setLevelTwoFilters] = useState<ILevelFilter[]>();
    const [levelThreeFilters, setLevelThreeFilters] = useState<IFilter[]>();

    const [selectedLevelOneFilter, setSelectedLevelOneFilter] =
      useState<IFilter>();
    const [selectedLevelTwoFilter, setSelectedLevelTwoFilter] =
      useState<IFilter>();
    const [selectedLevelThreeFilter, setSelectedLevelThreeFilter] =
      useState<IFilter>();

    useEffect(() => {
      dispatch(pageSet(1));
      dispatch(totalPagesSet(1));
    }, [
      selectedLevelOneFilter,
      selectedLevelTwoFilter,
      selectedLevelThreeFilter,
      selectedGrade,
      selectedSection,
      moeViewState,
    ]);

    const dispatchLessonsCall = async (values: {
      _termCode?: string;
      _groupCode?: string;
      _subGroupCode?: string;
    }) => {
      try {
        const defaultPayload = {
          _gradeId: selectedGrade?.id!,
          _termCode: "",
          _filterType: (moeViewState ? "MOE" : selectedSection) as FilterTypes,
          _groupCode: "",
          _subGroupCode: "",
        };
        const payload = { ...defaultPayload, ...values };
        const data = await fetchLos(payload).unwrap();
        if (data._statusCode === 200) {
          let losList: ILo[] = [];
          let unitList: IUnit[] = [];
          switch (payload._filterType) {
            case FilterTypes.LESSON:
            case FilterTypes.MOE:
              const { _loList, _unitList } = data._entity as ILessonLosEntity;
              if (_loList?.length) {
                losList = [
                  ..._loList.map((item) => ({
                    ...trasnformData(item),
                    type: LessonTypes.LESSON,
                  })),
                ];
              }
              if (_unitList?.length) {
                unitList = [
                  ..._unitList.map((item) => ({
                    unitId: item._unitId,
                    unitName: item._unitTitle,
                    childLos: item._loList.map((lo) => ({
                      ...trasnformData(lo),
                      unitId: item._unitId,
                      unitName: item._unitTitle,
                      type: LessonTypes.LESSON,
                    })),
                  })),
                ];
              }
              break;
            case FilterTypes.FREEPLAY:
              const freeplayEntity = (data._entity as IFreePlayLosEntity)
                ?._freeplays;
              if (freeplayEntity?.length > 0) {
                losList = [
                  ...freeplayEntity.map((item) => ({
                    loId: item._contentId,
                    loCode: item._contentId,
                    loTitle: item._title,
                    file: item._file,
                    lessonContentId: item._contentId,
                    isOfflineEvaluated: false,
                    thumbUrl: item._thumbUrl,
                    assignedClasses: [],
                    type: LessonTypes.FREEPLAY,
                  })),
                ];
              }
              break;
            case FilterTypes.GUIDED_READING:
            default:
              const grBookEntity = (data._entity as IGuidedReadingLosEntity)
                ?._grBooks;
              if (grBookEntity?.length > 0) {
                losList = [
                  ...grBookEntity.map((item) => ({
                    loId: item._bookId,
                    loCode: item._bookId,
                    loTitle: item._title,
                    lessonContentId: item._code,
                    isOfflineEvaluated: false,
                    thumbUrl: item._thumbUrl,
                    assignedClasses: [],
                    type: LessonTypes.GUIDED_READING,
                    tags: item._tags,
                  })),
                ];
              }
              break;
          }
          dispatch(losListLoaded(losList));
          dispatch(unitListLoaded(unitList));
        } else throw data;
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <ChildComp
        lessonsFilters={lessonsFilters}
        freePlayFilters={freePlayFilters}
        guidedReadingFilters={guidedReadingFilters}
        levelOneFilters={levelOneFilters}
        setLevelOneFilters={setLevelOneFilters}
        levelTwoFilters={levelTwoFilters}
        setLevelTwoFilters={setLevelTwoFilters}
        levelThreeFilters={levelThreeFilters}
        setLevelThreeFilters={setLevelThreeFilters}
        selectedLevelOneFilter={selectedLevelOneFilter}
        setSelectedLevelOneFilter={setSelectedLevelOneFilter}
        selectedLevelTwoFilter={selectedLevelTwoFilter}
        setSelectedLevelTwoFilter={setSelectedLevelTwoFilter}
        selectedLevelThreeFilter={selectedLevelThreeFilter}
        setSelectedLevelThreeFilter={setSelectedLevelThreeFilter}
        dispatchLessonsCall={dispatchLessonsCall}
        selectedGrade={selectedGrade}
        moeView={moeViewState}
        selectedSection={selectedSection}
      />
    );
  };
}

const trasnformData = (item: IFetchedLo) => {
  return {
    loId: item._loId,
    loCode: item._loCode,
    moeLoCode: item._moeLoCode,
    loTitle: item._loTitle,
    lessonContentId: item._lessonContentId,
    isOfflineEvaluated: item._isOfflineEvaluated,
    thumbUrl: item._thumbUrl,
    loStatus: item._loStatus,
    assignedClasses: item._assignedClasses,
    hasEne: item._hasEne,
  };
};

export default FiltersHoc;
