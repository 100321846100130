import Public from "layouts/Public";
import Secure from "layouts/Secure";

var indexRoutes = [
  { path: "/dashboard", name: "home", component: Secure },
  { path: "/students-list", name: "StudentsQrcode", component: Secure },
  { path: "/reports", name: "Reports", component: Secure },
  { path: "/evaluation", name: "Evaluations", component: Secure },
  { path: "/login", name: "Auth", component: Public },
  { path: "/forgot-password", name: "Auth", component: Public },
  { path: "/reset-password", name: "Auth", component: Public },
  { path: "/", name: "Auth", component: Public },
];

export default indexRoutes;
