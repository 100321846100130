import { ChangeEventHandler, useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import Joi from "joi";
import { MasterDataContext } from "contexts/MasterDataContext";
import Input from "components/Input";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { validate } from "utils/StudentListHelpers";
import useSendQRcodes from "../QRBulkSend/UploadSend/useSendQRcodes";
import { IStudent, IValidationErrors } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "250px",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "17px",
    overflowY: "auto",
    overflowX: "hidden",
    alignItems: "center",
    justifyContent: "center",
    "& > :not(:last-child)": {
      paddingBottom: "10px",
    },
  },
  name: {
    textAlign: "center",
    color: "rgb(76 76 76)",
    fontSize: "20px",
    fontWeight: "bold",
    paddingBottom: "20px",
  },
  label: {
    fontSize: "16px",
    color: "rgba(141 141 141)",
  },
});

interface IContentProps {
  onClose(): void;
  data: IStudent;
}

function Content({ onClose, data }: IContentProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const sendData = useSendQRcodes();
  const [popupForm, setPopupForm] = useState({
    recipient_email: "",
    regCode: true,
    qrCode: true,
  });
  const [errors, setErrors] = useState<string>();
  const [buttonDisable, setButtonDisable] = useState(false);
  const { logEvent } = useContext(MasterDataContext);

  const schema = Joi.object({
    recipient_email: Joi.string()
      .email({ tlds: { allow: false } })
      .trim()
      .required()
      .messages({
        "any.required": "Email Id is required",
        "string.empty": "Email Id is required",
        "string.email": "EmailValidation",
      }),
    regCode: Joi.boolean(),
    qrCode: Joi.boolean(),
  });

  const validateRecipientEamil = (input: {
    [key: string]: string | number | boolean;
  }) => {
    const errors = validate(schema)(input);
    setErrors(errors.recipient_email);
    return errors.recipient_email;
  };

  const getRecipientEmail: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { currentTarget: input } = event;
    input.value = input.value.trim();
    setPopupForm({ ...popupForm, recipient_email: input.value });
    validateRecipientEamil({ [input.name]: input.value });
  };

  const sendQRcode = async () => {
    setButtonDisable(true);
    await sendData([
      {
        student_id: data.id,
        student_name: data.name,
        recipient_email: popupForm.recipient_email,
      },
    ]);
    logEvent("QRStudentEmailSend");
    onClose();
    setButtonDisable(false);
  };

  const validatePopup = () => {
    const options = { abortEarly: false };
    const { error } = schema.validate(popupForm, options);
    let errorsObject: IValidationErrors = errors
      ? { recipient_email: errors }
      : {};
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };

  const isFormNotValid =
    Object.keys(validatePopup()).length > 0 ||
    (!popupForm.qrCode && !popupForm.regCode);

  return (
    <div className={classes.root}>
      <span className={classes.name}>{data.name}</span>
      <div style={{ width: "60%", textAlign: "center", marginBottom: "15px" }}>
        <label className={classes.label}>{t("Share studentInfo")}</label>
      </div>
      <div style={{ width: "350px", paddingBottom: "25px" }}>
        <Input
          name="recipient_email"
          onChange={getRecipientEmail}
          placeholder={t("Email placeholder")}
          errors={errors ? t(errors) : ""}
        />
      </div>
      <Button
        label={t("Share")}
        action={sendQRcode}
        disable={isFormNotValid || buttonDisable}
        styles={{ width: "200px" }}
      />
    </div>
  );
}

export default Content;
