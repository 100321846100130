import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "app/store";

export interface IAssignedUnit {
  type?: any;
  lessonContentId?: any;
  unitId: string;
  unitName: string;
  childLos?: IAssignedUnit[];
  loId?: string;
}
export interface IClass {
  error?: { icon: JSX.Element; message: string };
  disabled?: boolean;
  students: ISelectedStudent[];
  value?: number;
  label?: string;
  classId?: number;
  className?: string;
  checked: boolean;
}
interface ISelectedStudent {
  studentId: number;
  studentName: string;
  checked: boolean;
  status: string | null;
}

export interface ISelectedClass extends IClass {
  students: ISelectedStudent[];
}

interface IAssignedLessons {
  losList: IAssignedUnit[];
  unitList: IAssignedUnit[];
  assignedLosCount: number;
}

interface IAssignmentState {
  assignedLessons: IAssignedLessons;
  allClasses: IClass[];
  selectedClasses: ISelectedClass[];
  startDate: string;
}
const initialState: IAssignmentState = {
  assignedLessons: {
    losList: [],
    unitList: [],
    assignedLosCount: 0,
  },
  allClasses: [],
  selectedClasses: [],
  startDate: moment().format("YYYY-MM-DD"),
};

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    addLesson(state, action: PayloadAction<IAssignedUnit>) {
      const lo = action.payload;
      if (lo.unitId) {
        let index = state.assignedLessons.unitList.findIndex(
          (item) => item.unitId === lo.unitId
        );
        if (index >= 0) {
          if (lo.childLos) {
            state.assignedLessons.unitList.splice(index, 1);
            state.assignedLessons.unitList.push(lo);
          } else state.assignedLessons.unitList[index].childLos!.push(lo);
        } else {
          let newUnit: IAssignedUnit = {
            unitId: lo.unitId,
            unitName: lo.unitName,
            childLos: [],
          };
          if (lo.childLos) newUnit.childLos = lo.childLos;
          else newUnit.childLos!.push(lo);
          state.assignedLessons.unitList.push(newUnit);
        }
      } else state.assignedLessons.losList.push(lo);
    },
    removeLesson(state, action: PayloadAction<IAssignedUnit>) {
      const lo = action.payload;
      if (lo.unitId) {
        let index = state.assignedLessons.unitList.findIndex(
          (item) => item.unitId === lo.unitId
        );
        let unitFound = state.assignedLessons.unitList[index];
        if (!lo.childLos)
          unitFound.childLos = unitFound.childLos!.filter(
            (item) => item.loId !== lo.loId
          );
        state.assignedLessons.unitList.splice(index, 1);
        if (!lo.childLos && unitFound.childLos!.length)
          state.assignedLessons.unitList.push(unitFound);
      } else {
        let index = state.assignedLessons.losList.findIndex(
          (item) => item.loId === lo.loId
        );
        state.assignedLessons.losList.splice(index, 1);
      }
    },
    assignedLosCountSet(state, action: PayloadAction<number>) {
      state.assignedLessons.assignedLosCount = action.payload;
    },
    assignedLessonsSet(state, action: PayloadAction<IAssignedLessons>) {
      state.assignedLessons = action.payload;
    },
    selectedClassesSet(state, action: PayloadAction<ISelectedClass[]>) {
      state.selectedClasses = action.payload;
    },
    allClassesSet(state, action: PayloadAction<IClass[]>) {
      state.allClasses = action.payload;
    },
    startDateSet(state, action: PayloadAction<string>) {
      state.startDate = action.payload;
    },
  },
});

export const selectAssignedLessons = (state: RootState) =>
  state.assignment.assignedLessons;
export const selectAllClasses = (state: RootState) =>
  state.assignment.allClasses;
export const selectStartDate = (state: RootState) => state.assignment.startDate;
export const selectSelectedClasses = (state: RootState) =>
  state.assignment.selectedClasses;

export const {
  addLesson,
  removeLesson,
  assignedLosCountSet,
  selectedClassesSet,
  allClassesSet,
  startDateSet,
  assignedLessonsSet,
} = assignmentSlice.actions;

export default assignmentSlice.reducer;
