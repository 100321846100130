import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircleChart from "../CircleChart";
import { IDomainReport } from "features/classPerformance";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
  chartContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  chart: {
    display: "flex",
    width: "170px",
    height: "170px",
  },
  header: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    color: "rgb(74 74 74)",
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

const colors = [
  "rgb(68 183 253)",
  "rgb(90 107 199)",
  "rgb(134 83 203)",
  "rgb(201 97 206)",
];

function DomainCard({ reportData }: { reportData: IDomainReport[] }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {reportData.map((columnData, index) => (
        <Grid item key={index} style={{ width: "25%" }}>
          <div className={classes.chartContainer}>
            <div className={classes.chart}>
              <CircleChart
                value={Number(columnData.average as string)}
                forground={colors[index]}
                disable={isNaN(columnData.average as number)}
                animation={false}
              />
            </div>
            <div className={classes.header}>{columnData.name}</div>
          </div>
        </Grid>
      ))}
    </div>
  );
}

export default DomainCard;
