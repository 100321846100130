import { useEffect, useState } from "react";
import { Switch, useHistory, Route, useLocation } from "react-router-dom";
import routes from "routes/public";
import useCheckCompatibility from "hooks/useCheckCompatibility";
import { useTranslation } from "react-i18next";
import LanguageSwitch from "components/Header/Secure/LanguageSwitch";
import { Direction } from "features/common/Interfaces";
import { Typography } from "@mui/material";

const customStyles = {
  wrapper: {
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  fullPage: {
    height: "fit-content",
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};

const locations = ["/forgot-password", "/reset-password", "/login"];

function Layouts() {
  const [direction, setDirection] = useState<Direction>();
  const { i18n } = useTranslation();
  const isNotCompatible = useCheckCompatibility();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authToken = localStorage.getItem("abjadToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (isNotCompatible) history.push("/device-not-compatible");
    else if (authToken && refreshToken) history.push("/dashboard");
    else if (!locations.includes(location.pathname)) {
      history.push("/login");
    }
    const storedDir = (localStorage.getItem("dir") ?? i18n.dir()) as Direction;
    setDirection(storedDir);
  }, []);

  useEffect(() => {
    setDirection(i18n.dir());
  }, [i18n.dir()]);

  return (
    <Typography
      component="div"
      sx={{
        ...customStyles.wrapper,
        direction: direction!,
      }}
    >
      <Typography component="div" sx={customStyles.fullPage}>
        <LanguageSwitch
          blue
          style={{
            position: "absolute",
            top: "30px",
            [direction === "rtl" ? "right" : "left"]: "30px",
          }}
        />
        <Switch>
          {routes.map((prop, key) => (
            <Route path={prop.path} component={prop.component} key={key} />
          ))}
        </Switch>
      </Typography>
    </Typography>
  );
}

export default Layouts;
