import { makeStyles } from "@mui/styles";
import { LongArrowIcon } from "components/SVGs";
import DownloadExcel from "./DownloadExcel";
import useClientDevice from "hooks/useClientDevice";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import UploadSend from "./UploadSend";
import { useAppSelector } from "app/hooks";
import {
  selectAllStudents,
  selectSelectedDataList,
  selectStudentList,
} from "features/students";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    color: "rgba(74, 74, 74, 1)",
  },
  hide: {
    display: "none",
  },
});

function QRBulkSend() {
  const { i18n } = useTranslation();
  const allStudents = useAppSelector(selectAllStudents);
  const studentList = useAppSelector(selectStudentList);
  const selectedDataList = useAppSelector(selectSelectedDataList);
  const { isTablet } = useClientDevice();

  const classes = useStyles();

  return (
    <div className={classNames(classes.root, isTablet && classes.hide)}>
      <DownloadExcel
        studentList={studentList}
        selectedDataList={selectedDataList}
      />
      <LongArrowIcon
        sx={{
          margin: "0 20px",
          fontSize: "3rem",
          transform: i18n.dir() === "ltr" ? "rotate(-180deg)" : null,
        }}
      />
      <UploadSend allStudents={allStudents} />
    </div>
  );
}

export default QRBulkSend;
