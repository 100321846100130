import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export enum ResetPasswordRequestState {
  init = "init",
  started = "started",
  confirmed = "confirmed",
}
interface IAuthState {
  requestResetPassword: keyof typeof ResetPasswordRequestState;
  email: string;
}
const initialState: IAuthState = {
  requestResetPassword: ResetPasswordRequestState.init,
  email: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetPasswordRequested(
      state,
      action: PayloadAction<keyof typeof ResetPasswordRequestState>
    ) {
      state.requestResetPassword = action.payload;
    },
    emailSet(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
  },
});

export const resetPasswordRequestState = (state: RootState) =>
  state.auth.requestResetPassword;
export const emailValue = (state: RootState) => state.auth.email;

export const { resetPasswordRequested, emailSet } = authSlice.actions;

export default authSlice.reducer;
