import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import {
  GradeCods,
  IClassStudents,
  IGrade,
  ISelectedClassStudents,
} from "features/common/Interfaces";
import moment from "moment";

export interface IExam {
  id: string;
  type: "BASELINE" | "ASSESSMENT_BANK";
  name: string;
  questions: number;
  grade: GradeCods;
}
export enum DifficultyType {
  "AllLevel" = "AllLevel",
  "EASY" = "EASY",
  "MEDIUM" = "MEDIUM",
  "HARD" = "HARD",
}

export interface ISelectedFilters {
  groupCode?: string;
  subGroupCode?: string;
}
export interface IExamFilterType {
  code: string;
  title: string;
  icon?: string;
  subFilters?: IExamFilterType[];
}
export interface IExamFilter {
  gradeId: string;
  greade: string;
  filters: IExamFilterType[];
}
interface IExamsState {
  list: IExam[];
  filters: IExamFilter[];
  selectedFitlers?: ISelectedFilters;
  selectedGrade?: IGrade;
  searchQuestion?: string;
  allClasses: IClassStudents[];
  selectedClasses: ISelectedClassStudents[];
  assignExams: {
    examId?: string;
    name?: string;
    students?: string[];
    startDate: string;
  };
  createExam: {
    title: string;
    questionList: string[];
  };
}
const initialState: IExamsState = {
  list: [],
  filters: [],
  createExam: { title: "", questionList: [] },
  allClasses: [],
  selectedClasses: [],
  assignExams: {
    startDate: moment().format("YYYY-MM-DD"),
  },
};
export const examsSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {
    loadEaxms(state, action: PayloadAction<IExam[]>) {
      state.list = action.payload;
    },
    loadFilters(state, action: PayloadAction<IExamFilter[]>) {
      state.filters = action.payload;
    },
    selectedGradeSet(state, action: PayloadAction<IGrade>) {
      state.selectedGrade = action.payload;
    },
    searchQuestionSet(state, action: PayloadAction<string>) {
      state.searchQuestion = action.payload;
    },
    questionAdded(state, action: PayloadAction<string>) {
      if (state.createExam.questionList?.indexOf(action.payload) === -1) {
        state.createExam.questionList?.push(action.payload);
      }
    },
    questionRemoved(state, action: PayloadAction<string>) {
      const index = state.createExam.questionList?.indexOf(action.payload);
      if (index !== -1) state.createExam.questionList?.splice(index, 1);
    },
    createExamTitelSet(state, action: PayloadAction<string>) {
      state.createExam.title = action.payload;
    },
    selectedFiltersSet(state, action: PayloadAction<ISelectedFilters>) {
      state.selectedFitlers = action.payload;
    },
    examAdded(state, action: PayloadAction<{ id: string; name: string }>) {
      state.assignExams.examId = action.payload.id;
      state.assignExams.name = action.payload.name;
    },
    examRemoved(state) {
      state.assignExams.examId = undefined;
      state.assignExams.name = undefined;
    },
    studentAdded(state, action: PayloadAction<string>) {
      state.assignExams.students?.push(action.payload);
    },
    studentRemoved(state, action: PayloadAction<string>) {
      const index = state.assignExams.students?.indexOf(action.payload);
      if (typeof index === "number" && index !== -1)
        state.assignExams.students?.splice(index, 1);
    },
    startDateSet(state, action: PayloadAction<string>) {
      state.assignExams.startDate = action.payload;
    },
    examReset(state) {
      state.assignExams = initialState.assignExams;
      state.createExam = initialState.createExam;
    },
    selectedClassesSet(state, action: PayloadAction<ISelectedClassStudents[]>) {
      state.selectedClasses = action.payload;
    },
    allClassesSet(state, action: PayloadAction<IClassStudents[]>) {
      state.allClasses = action.payload;
    },
  },
});

export const selectExams = (state: RootState) => state.exams.list;
export const selectedGrade = (state: RootState) => state.exams.selectedGrade;
const selectFilters = (state: RootState) => state.exams.filters;
export const selectCreateExam = (state: RootState) => state.exams.createExam;
export const selectSearchQuestion = (state: RootState) =>
  state.exams.searchQuestion;
export const selectSelectedFitlers = (state: RootState) =>
  state.exams.selectedFitlers;
export const selectAssignExam = (state: RootState) => state.exams.assignExams;
export const selectAllClasses = (state: RootState) => state.exams.allClasses;
export const selectSelectedClasses = (state: RootState) =>
  state.exams.selectedClasses;
export const selectGradeFilters = createSelector(
  selectFilters,
  selectedGrade,
  (filters, grade) => {
    return filters.find((item) => item.greade === grade?.code)?.filters;
  }
);

export const {
  loadEaxms,
  loadFilters,
  selectedGradeSet,
  searchQuestionSet,
  questionAdded,
  questionRemoved,
  createExamTitelSet,
  selectedFiltersSet,
  examAdded,
  examRemoved,
  studentAdded,
  studentRemoved,
  startDateSet,
  allClassesSet,
  selectedClassesSet,
  examReset,
} = examsSlice.actions;

export default examsSlice.reducer;
