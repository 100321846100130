import { useEffect, useState } from "react";
import AssessmentContent from "./AssessmentContent";
import { selectContainerHeight, minHeight } from "features/evaluation";
import { useAppSelector } from "app/hooks";
import SideBar from "./SideBar";
import { Grid } from "@mui/material";
import { ICSS } from "features/common/Interfaces";

const customStyles: ICSS = {
  root: {
    position: "relative",
    padding: "5px",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    minHeight: minHeight + "px",
    width: "100%",
  },
};

function Content() {
  const [height, setHeight] = useState(minHeight);
  const containerHeight = useAppSelector(selectContainerHeight);

  useEffect(() => {
    setHeight(containerHeight);
  }, [containerHeight]);

  return (
    <div style={customStyles.root}>
      <Grid
        container
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        <Grid
          item
          sx={{
            width: "350px",
            "@media screen and (max-width: 1024px)": {
              width: "250px",
            },
          }}
        >
          <SideBar height={height + "px"} />
        </Grid>
        <Grid
          item
          sx={{
            width: "calc(100% - 350px)",
            padding: "5px 10px",
            "@media screen and (max-width: 1024px)": {
              width: "calc(100% - 250px)",
            },
          }}
        >
          <AssessmentContent />
        </Grid>
      </Grid>
    </div>
  );
}

export default Content;
