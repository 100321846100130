import { Skeleton } from "@mui/material";
const customStyles = {
  content: {
    display: "grid",
    gridColumnGap: "60px",
    gridRowGap: "20px",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px,1fr))",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
};
function GrBooksSkeleton() {
  return (
    <div style={customStyles.content}>
      {Array.from(Array(3)).map((data, index) => (
        <div style={customStyles.container} key={index}>
          <Skeleton
            sx={{ width: "175px", marginLeft: "15px" }}
            variant="rectangular"
            height={155}
          />
          <div style={{ padding: "10px" }}>
            <Skeleton
              variant="rectangular"
              width={85}
              height={15}
              sx={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rectangular"
              width={85}
              height={15}
              sx={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rectangular"
              width={180}
              height={15}
              sx={{ marginTop: "10px" }}
            />
            <Skeleton
              variant="rectangular"
              width={115}
              height={15}
              sx={{ marginTop: "10px" }}
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default GrBooksSkeleton;
