import { useLocation } from "react-router-dom";

const useActiveRoute = () => {
  const location = useLocation();
  const activeRoute = (routeName: string) => {
    return location.pathname.indexOf(routeName) > -1 ? true : false;
  };
  return activeRoute;
};

export default useActiveRoute;
