import { makeStyles } from "@mui/styles";
import Thumb from "../Thumb";
import { useAppSelector } from "app/hooks";
import { ILo, selectLevelOneFilter } from "features/content";
import { MouseEvent, ReactNode } from "react";
import { LessonTypes } from "features/common/Interfaces";
import ExamThumb from "assets/img/examDefaultThumb.png";

interface IUseStyles {
  actionName: string;
  levelOneFilter: string;
  type?: LessonTypes;
  fixedWidth?: string;
}
const useStyles = ({
  actionName,
  levelOneFilter: levelOneFitler,
  type,
  fixedWidth,
}: IUseStyles) =>
  makeStyles({
    lesson: {
      display: "flex",
      flexDirection: "column",
      padding: "0 15px",
      width: fixedWidth
        ? fixedWidth
        : (type === LessonTypes.FREEPLAY &&
            ["Printable Worksheets", "E-Books"].includes(levelOneFitler)) ||
          type === LessonTypes.GUIDED_READING
        ? "163px"
        : "220px",
    },
    thumbContainer: {
      display: "flex",
      position: "relative",
    },
    thumb: {
      overflow: "hidden",
      width: "100%",
    },
    image: {
      width: "100%",
      height: "100%",
      ...((type === LessonTypes.LESSON || type === LessonTypes.FREEPLAY) &&
      levelOneFitler !== "E-Books"
        ? { borderRadius: "6px", border: "1px solid rgba(151, 151, 151, 1)" }
        : { border: "1px solid rgba(151, 151, 151, 0.5)" }),
    },
    thumbActionIcon: {
      position: "absolute",
      top: "15px",
      right: "15px",
      width: "38px",
      height: "38px",
      background:
        actionName === "add" ? "rgba(255 255 255)" : "rgba(105, 123, 140, 1)",
      borderRadius: " 4.97px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: `0.62px solid ${
        actionName === "add"
          ? "rgba(98, 195, 238, 1)"
          : "rgba(105, 123, 140, 1)"
      }`,
      boxShadow: "0 2px 4px 0 rgba(0,0,0,0.50)",
      cursor: "pointer",
    },
    iconsContainer: {
      display: "flex",
      marginTop: "10px",
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      "& span": {
        margin: "0 10px",
      },
    },
    actionIcons: {
      background: "rgba(255 255 255)",
      borderRadius: " 4.97px",
      display: "flex",
      width: "38px",
      height: "38px",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #E3E3E3",
      cursor: "pointer",
    },
  });

interface IAction {
  actionHandler(event: MouseEvent<HTMLDivElement>): void;
  actionName: string;
  icon: ReactNode;
}
interface ILoThumbProps {
  lesson: Partial<ILo>;
  thumbActionIcon?: ReactNode;
  actions?: IAction[];
  actionName?: string;
  actionHandler?(event: MouseEvent<HTMLDivElement>): void;
  fixedWidth?: string;
  "data-test"?: string;
}

function LoThumb({
  lesson,
  thumbActionIcon,
  actions = [],
  actionName = "add",
  actionHandler,
  fixedWidth,
  ...rest
}: ILoThumbProps) {
  const { thumbUrl, loTitle, type } = lesson;

  const levelOneFilter = useAppSelector(selectLevelOneFilter);
  const classes = useStyles({
    actionName,
    levelOneFilter: levelOneFilter?.code ?? "",
    type,
    fixedWidth,
  })();
  return (
    <div className={classes.lesson} {...rest}>
      <div className={classes.thumbContainer}>
        <div className={classes.thumb}>
          <Thumb
            url={type === LessonTypes.EXAMS ? ExamThumb : thumbUrl}
            style={classes.image}
          />
        </div>
        {thumbActionIcon ? (
          <div className={classes.thumbActionIcon} onClick={actionHandler}>
            {thumbActionIcon}
          </div>
        ) : null}
      </div>
      <div className={classes.iconsContainer}>
        {actions.map((item, index) => (
          <div
            className={classes.wrapper}
            key={"actionIcon" + index}
            style={{ marginRight: index === 1 ? "10px" : 0 }}
            onClick={item.actionHandler}
          >
            <div className={classes.actionIcons}>{item.icon}</div>
            <span>{item.actionName}</span>
          </div>
        ))}
      </div>
      <p style={{ margin: "15px 0" }}>{loTitle}</p>
    </div>
  );
}

export default LoThumb;
