import { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Thumb from "components/Thumb";
import {
  ListeningDomainIcon,
  StudentsGroupIcon,
  WritingDomainIcon,
  ReadingDomainIcon,
  LanguageDomainIcon,
  PendingEvaluationIcon,
} from "components/SVGs";
import completedThumb from "assets/img/img_tumb_completed.svg";
import notCompletedThumb from "assets/img/img_tumb_not_completed.svg";
import { IAssessment } from "features/evaluation";
import { useTranslation } from "react-i18next";
import { StatusTypes } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    fontWeight: "bold",
    color: "rgb(88 88 88)",
    cursor: "pointer",
    height: "100%",
    padding: "3px",
  },
  thumb: (props: { thumb?: string }) => ({
    backgroundColor: !props.thumb ? "rgba(214, 219, 237, 1)" : undefined,
    borderRadius: "0.5rem",
    border: props.thumb ? "2px solid rgba(179,179,179,1)" : undefined,
    width: "100%",
  }),
  domainIcon: {
    position: "absolute",
    top: "5px",
    padding: "0.15rem 0.35rem",
    background: "rgba(255 255 255/81%)",
    borderRadius: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.9rem",
    fontWeight: "normal",
    "& > svg": {
      padding: "0 0.35rem",
    },
    "&> span": {
      fontSize: "90%",
    },
  },
  assessments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& > svg": {
      fontSize: "1rem",
    },
    "& > span": {
      top: "0.25rem",
      position: "relative",
      padding: "0 0.5rem",
    },
  },
  pendingIcon: {
    position: "absolute",
    top: "7px",

    background: "rgba(98, 195, 238, 0.9)",
    padding: "0.35rem 0.25rem",
    borderRadius: "0.5rem",
    display: "flex",
    "& > svg": {
      fontSize: "1.25rem",
    },
  },
  title: {
    margin: 0,
    flexGrow: 1,
    fontWeight: "bold",
    padding: "0.25rem 0",
    fontSize: "0.8rem",
    height: "100%",
    paddingBottom: "0",
  },
});

const theWritingDomain = "الكتابة";
const writingDomain = "كتابة";
const theReadingDomain = "القراءة";
const readingDomain = "قراءة";
const listeningDomain = "الاستماع والمحادثة";
const languageDomain = "اللغة";
const languageDomains = [
  theWritingDomain,
  writingDomain,
  theReadingDomain,
  readingDomain,
  listeningDomain,
  languageDomain,
];

const renderIcon = (name: string) => {
  let icon = null;
  switch (name) {
    case languageDomains[0]:
    case languageDomains[1]:
      icon = <WritingDomainIcon />;
      break;
    case languageDomains[2]:
    case languageDomains[3]:
      icon = <ReadingDomainIcon />;
      break;
    case languageDomains[4]:
      icon = <ListeningDomainIcon style={{ fontSize: "2.25rem" }} />;
      break;
    case languageDomains[5]:
      icon = <LanguageDomainIcon />;
      break;
    default:
      icon = null;
      break;
  }
  return icon;
};

interface ILoThumb {
  data: IAssessment;
  isCompleted?: boolean;
}
export default function LoThumb({ data, isCompleted = false }: ILoThumb) {
  const { t, i18n } = useTranslation();

  const {
    lessonTitle,
    domainName,
    thumb,
    studentsIds,
    status,
    assessmentType,
  } = data;
  const [lessonThumb, setLessonThumb] = useState(
    thumb
      ? thumb
      : status === StatusTypes.PENDING
      ? notCompletedThumb
      : completedThumb
  );

  useEffect(() => {
    setLessonThumb(
      thumb
        ? thumb
        : status === StatusTypes.PENDING
        ? notCompletedThumb
        : completedThumb
    );
  }, [thumb]);

  const props = { thumb };
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      {isCompleted ? null : (
        <div
          className={classes.pendingIcon}
          style={{ [i18n.dir() === "rtl" ? "left" : "right"]: "7px" }}
        >
          <PendingEvaluationIcon />
        </div>
      )}
      <div
        className={classes.domainIcon}
        style={{ [i18n.dir() === "rtl" ? "right" : "left"]: "5px" }}
      >
        {renderIcon(domainName)}
        <span>{domainName + " - " + t(assessmentType)}</span>
      </div>
      <Thumb style={classes.thumb} url={lessonThumb} />
      <Typography variant="caption" className={classes.title} paragraph>
        {lessonTitle}
      </Typography>
      <div className={classes.assessments}>
        <>
          <StudentsGroupIcon style={{ fontSize: "1.5rem" }} />
          <span>{`${studentsIds?.length} ${
            studentsIds?.length === 1 ? t("Student") : t("Students")
          }`}</span>
        </>
      </div>
    </div>
  );
}
