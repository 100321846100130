import { useHistory } from "react-router-dom";
import { INotification } from "..";
import { clearEvaluationVars } from "features/evaluation";

function useAssessmentAction(data: INotification) {
  const history = useHistory();

  const evaluateAssessment = () => {
    const selectedClass = {
      value: data.classId,
      lable: data.className,
      gradeId: data.gradeId,
    };

    const selectedGrade = {
      id: data.gradeId,
    };
    clearEvaluationVars();
    localStorage.setItem("class-evaluation", JSON.stringify(selectedClass));
    localStorage.setItem("grade-evaluation", JSON.stringify(selectedGrade));

    const redirectUrl = "/evaluation";

    if (history.location.pathname === redirectUrl) {
      window.location = redirectUrl as (string | Location) & Location;
    } else history.push(redirectUrl);
  };
  return evaluateAssessment;
}

export default useAssessmentAction;
