import { NoData } from "../SVGs";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

const defaultStyle = {
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  height: "40vh",
  justifyContent: "center",
  width: "100%",
  "& > span": {
    fontSize: "1rem",
    fontWeight: "bold",
    width: "30vw",
    textAlign: "center",
    color: "rgba(105, 123, 140, 1)",
    paddingTop: "1rem",
  },
  "&  svg": {
    fontSize: "3.5rem",
  },
};

interface IDataNotFoundProps {
  text?: string;
  icon?: JSX.Element;
  style?: React.CSSProperties;
  "data-test"?: string;
}
function DataNotFound({
  text,
  icon,
  style,
  ...rest
}: IDataNotFoundProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Typography component="div" sx={{ ...defaultStyle, ...style }} {...rest}>
      {icon}
      <span>{t(text!)}</span>
    </Typography>
  );
}

DataNotFound.defaultProps = {
  text: "Default Data not found",
  icon: <NoData />,
  style: defaultStyle,
};

export default DataNotFound;
