import { ResponsivePie } from "@nivo/pie";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { IReportData } from "features/pacing";

const useStyles = makeStyles(() => ({
  tooltip: {
    "& p": {
      color: "rgba(105, 123, 140, 1)",
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  activityType: {
    textAlign: "center",
    fontWeight: "bold",
  },
  percentRatio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tooltipContainer: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(218, 218, 218, 1)",
    borderRadius: "4px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    padding: "10px",
    maxWidth: "150px",
    "&:after": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(255, 255, 255, 1)",
      bottom: "-18px",
      right: "48px",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(218, 218, 218, 1)",
      bottom: "-19px",
      right: "48px",
    },
  },
}));
interface IPieChartProps {
  pacingData: IReportData;
}
const PieChart = ({ pacingData }: IPieChartProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const tooltip = (e: {
    datum: { data: { value: number; color: string } };
  }) => {
    const { data } = e.datum;
    const { value, color } = data;
    const customStyles = {
      ...(i18n.dir() === "rtl"
        ? { marginLeft: "15px" }
        : { marginRight: "15px" }),
    };
    const completed = pacingData.labelColor.includes(color)
      ? value
      : 100 - value;
    const uncompleted = 100 - completed;
    return (
      <div
        className={classes.tooltipContainer}
        style={{
          direction: i18n.dir(),
        }}
      >
        <p className={classes.percentRatio}>
          <span style={customStyles}>{`${t("Completed")}: `}</span>
          <span>{completed}%</span>
        </p>
        <p className={classes.percentRatio}>
          <span style={customStyles}>{`${t("Uncompleted")}: `}</span>
          <span>{uncompleted}%</span>
        </p>
      </div>
    );
  };

  const commonProperties = {
    data: pacingData.chartData,
    enableArcLinkLabels: false,
    enableArcLabels: false,
    animate: true,
    tooltip,
  };

  return (
    <div
      style={{
        position: "relative",
        width: 170,
        height: 170,
      }}
    >
      <ResponsivePie colors={{ datum: "data.color" }} {...commonProperties} />
    </div>
  );
};

export default PieChart;
