import { makeStyles } from "@mui/styles";
import { ICSS } from "features/common/Interfaces";
import { PrintIcon } from "../SVGs";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  text: {
    display: "block",
    color: "rgb(255, 255, 255, 255)",
    textAlign: "center",
  },
});
interface IPrintProps {
  setIsPrint(value: boolean): void;
  text?: string;
  "data-test"?: string;
}
function Print({ setIsPrint, text, ...rest }: IPrintProps) {
  const classes = useStyles();
  const printReport = () => {
    setIsPrint(true);
    setTimeout(() => {
      window.print();
      setIsPrint(false);
    }, 500);
  };
  return (
    <div
      onClick={printReport}
      className={classes.root}
      style={{ ...(text ? { flexDirection: "column" } : {}) } as ICSS}
      {...rest}
    >
      <PrintIcon />
      {text ? <span className={classes.text}>{text}</span> : null}
    </div>
  );
}

export default Print;
