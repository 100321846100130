import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import AccordionWithCheckbox from "./AccordionWithCheckbox";
import DataNotFound from "components/DataNotFound";
import { ClassRoom } from "components/SVGs";
import { selectedClassesSet, selectSelectedClasses } from "features/exams";
import { ChangeEvent, CSSProperties } from "react";
import { ISelectedClassStudents } from "features/common/Interfaces";

const noDataStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "2rem 0",
  justifyContent: "center",
  height: "fit-content",
  width: "100%",
  "& > span": {
    fontSize: "1rem",
    textAlign: "center",
    color: "rgba(105, 123, 140, 1)",
    paddingTop: "1rem",
  },
  "& > svg": {
    fontSize: "5rem",
  },
};

function SelectedClasses() {
  const { t } = useTranslation();
  const selectedClasses = useAppSelector(selectSelectedClasses);
  const dispatch = useAppDispatch();

  const classCheckboxHandler = (
    event: ChangeEvent<HTMLInputElement>,
    classId: number
  ) => {
    const isChecked = event.target.checked;
    const updatedClass: ISelectedClassStudents[] = [
      ...selectedClasses.map((item) => ({
        classId: item.classId,
        className: item.className,
        checked: item.classId === classId ? isChecked : item.checked,
        students: item.students.map((student) => ({
          studentId: student.studentId,
          studentName: student.studentName,
          checked:
            student.assignable && item.classId === classId
              ? isChecked
              : student.checked,
          assignable: student.assignable,
        })),
      })),
    ];
    dispatch(selectedClassesSet(updatedClass));
  };

  const studentCheckboxHandler = (
    event: ChangeEvent<HTMLInputElement>,
    studentId: number,
    classId: number
  ) => {
    const isChecked = event.target.checked;
    const updatedClass = JSON.parse(
      JSON.stringify(selectedClasses)
    ) as ISelectedClassStudents[];
    let selectedClass = updatedClass.filter(
      (data) => data.classId === classId
    )[0];
    const stuIndex = selectedClass.students.findIndex(
      (student) => student.studentId === studentId
    );
    let student = selectedClass.students[stuIndex];
    student = { ...student, checked: isChecked };
    selectedClass.students.splice(stuIndex, 1, student);
    const selectedStudents = selectedClass.students.filter(
      (student) => student.checked === true
    );
    selectedClass.checked = false;
    if (selectedClass.students.length === selectedStudents.length)
      selectedClass.checked = true;
    dispatch(selectedClassesSet(updatedClass));
  };

  return (
    <div style={{ minHeight: "170px" }}>
      {selectedClasses.length ? (
        <AccordionWithCheckbox
          selectedClasses={selectedClasses}
          classCheckboxHandler={classCheckboxHandler}
          studentCheckboxHandler={studentCheckboxHandler}
        />
      ) : (
        <DataNotFound
          icon={<ClassRoom />}
          text={t("Select class or classes from the list")}
          style={noDataStyle as CSSProperties}
        />
      )}
    </div>
  );
}

export default SelectedClasses;
