import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IGrade, IMonthOption, IOption } from "../common/Interfaces";

interface IBookProgress {
  bookContentId: string;
  bookId: number;
  bookLevelName: string;
  progress: number;
  thumbUrl: string;
  bookTitle: string;
  totalAssignedStudents: number;
  totalCompletedStudents: number;
}

interface IGrBooksProgressState {
  grade?: IGrade;
  grBooks: IBookProgress[];
  class?: IOption;
  selectedBook?: IBookProgress;
  selectedPlanMonth?: IMonthOption;
  totalPages: number;
  currentPage: number;
}

const initialState: IGrBooksProgressState = {
  grBooks: [],
  totalPages: 0,
  currentPage: 1,
};

export const grBooksProgressSlice = createSlice({
  name: "grBooksProgress",
  initialState,
  reducers: {
    gradeSet(state, action: PayloadAction<IGrade>) {
      state.grade = action.payload;
    },
    grBooksSet(state, action: PayloadAction<IBookProgress[]>) {
      state.grBooks = action.payload;
    },
    selectedBookSet(state, action: PayloadAction<IBookProgress>) {
      state.selectedBook = action.payload;
    },
    classSet(state, action: PayloadAction<IOption>) {
      state.class = action.payload;
    },
    selectedPlanMonthSet(state, action: PayloadAction<IMonthOption>) {
      state.selectedPlanMonth = action.payload;
    },
    currentPageSet(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    totalePagesSet(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.grBooksPrgress.grade;
export const selectGrBooks = (state: RootState) => state.grBooksPrgress.grBooks;
export const selectClass = (state: RootState) => state.grBooksPrgress.class;
export const selectBook = (state: RootState) =>
  state.grBooksPrgress.selectedBook;
export const selectSelectedPlanMonth = (state: RootState) =>
  state.grBooksPrgress.selectedPlanMonth;
export const selectCurrentPage = (state: RootState) =>
  state.grBooksPrgress.currentPage;
export const selectTotalPages = (state: RootState) =>
  state.grBooksPrgress.totalPages;

export const {
  gradeSet,
  grBooksSet,
  classSet,
  selectedPlanMonthSet,
  selectedBookSet,
  currentPageSet,
  totalePagesSet,
} = grBooksProgressSlice.actions;

export default grBooksProgressSlice.reducer;
