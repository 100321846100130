import { useState } from "react";
import StudentDefaultAvatar from "assets/img/studentDefaultAvatar.svg";

function StudentAvatar({
  url = StudentDefaultAvatar,
  alt = "student name",
}: {
  url?: string;
  alt?: string;
}) {
  const [src, setSrc] = useState(url || StudentDefaultAvatar);
  const handleImageLoadError = () => {
    setSrc(StudentDefaultAvatar);
  };
  return (
    <img
      alt={alt}
      src={src}
      onError={handleImageLoadError}
      style={{ height: "65%", margin: "auto" }}
    />
  );
}

export default StudentAvatar;
