import { Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import loader from "assets/img/loader.gif";
import TextCarousel from "./TextCarousel";
import { ICSS } from "features/common/Interfaces";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IContent,
  contentIdsSet,
  selectContentId,
  selectShowPlayer,
  showPlayerSet,
} from "features/ui";

interface IPlayer {
  SendMessage(
    gameManager: string,
    event: string,
    options?: number | string
  ): void;
}
declare global {
  interface Window {
    createUnityInstance?(
      canvas: Element,
      config: { [key: string]: string | (() => void) },
      onProgress: (_: any, progress: number) => void
    ): any;
    UnityLoader: {
      instantiate(
        htmlElementId: string,
        config: string,
        { onProgress }: { onProgress: (_: any, progress: number) => void }
      ): IPlayer;
    };
    loadingCompleted(): void;
    FullScreen(status: string): void;
    ContentFinished(): void;
    gameInstance: IPlayer;
  }
}
const customStyles: ICSS = {
  loader: {
    width: "64px",
    height: "64px",
    margin: "30px 0 15px",
  },
  loaderContent: {
    width: "450px",
    background: "rgb(251 251 251)",
    borderBottomLeftRadius: "17px",
    borderBottomRightRadius: "17px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "10px",
  },
  gameContainer: {
    width: "100vw",
    height: "100vh",
    zIndex: 9999,
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
  },
};

const s3Url = process.env.REACT_APP_WEBGL_URL;

const buildUrl = `${s3Url}Build`;
const PlayerConfig = {
  container: "gameContainer",
  canvasId: "unity-canvas",
  scriptId: "unityScriptId",
  scriptSrc: (version: string) =>
    `${buildUrl}/Abjadiyat_v5_Web_${version}.loader.js`,
  config: (version: string) => ({
    dataUrl: `${buildUrl}/Abjadiyat_v5_Web_${version}.data.gz`,
    frameworkUrl: `${buildUrl}/Abjadiyat_v5_Web_${version}.framework.js.gz`,
    codeUrl: `${buildUrl}/Abjadiyat_v5_Web_${version}.wasm.gz`,
    streamingAssetsUrl: "StreamingAssets",
    companyName: "Alef Education",
    productName: "Abjadiyat",
    productVersion: "6.14.2",
    showBanner: () => {},
  }),

  version: `${s3Url}Player_Config.json`,
  webJson: (version: string) =>
    `${s3Url}Build/Abjadiyat_v5_Web_${version}.loader.js`,
};

function WebGLPlayer() {
  const contentId = useAppSelector(selectContentId);
  const showPlayer = useAppSelector(selectShowPlayer);
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [version, setVersion] = useState<string>();
  const texts = [
    t("download in progress"),
    t("downloading engine"),
    t("downloading content"),
    t("downloading data"),
  ];

  function IsFullScreenCurrently() {
    const full_screen_element = document.fullscreenElement || null;
    // If no element is in full-screen
    if (full_screen_element === null) return false;
    else return true;
  }

  function GoOutFullscreen() {
    if (IsFullScreenCurrently()) document.exitFullscreen();
  }

  function GoInFullscreen(element: HTMLElement) {
    element.requestFullscreen();
  }

  const loadingCompleted = () => {
    setLoading(false);
  };

  const FullScreen = () => {
    if (IsFullScreenCurrently()) GoOutFullscreen();
    else GoInFullscreen(document.documentElement);
  };

  const ContentFinished = () => {
    GoOutFullscreen();
    dispatch(showPlayerSet(false));
    dispatch(contentIdsSet([]));
  };

  const UnityProgress = (progress: number) => {
    console.log(`Loading ${progress * 100} % ...`);
  };

  useEffect(() => {
    configureWebGLPlayer();
  }, []);

  async function instantiateUnityPlayer() {
    try {
      const canvas = document.getElementById(PlayerConfig.canvasId);
      if (canvas && version && window.createUnityInstance) {
        window.gameInstance = await window.createUnityInstance(
          canvas,
          PlayerConfig.config(version),
          UnityProgress
        );
        const envTypes = [
          "predev", //0
          "dev", //1
          "staging", //2
          "staging-2", //3
          "prod", //4
          "preprod", //5
          "perf", //6
          "localhost", //7
          "devops", //8
        ];
        const env = envTypes.indexOf(process.env.REACT_APP_ENV!);
        // if the environment is localhost then set it value manually
        const url = window.location.origin;
        window.gameInstance.SendMessage(
          "GameManager",
          "InitializeTheContentPlayer",
          url.indexOf("localhost") !== -1 ? 6 : env
        );
      }
    } catch (error) {
      console.log({ error });
    }
  }

  useEffect(() => {
    if (version && window.createUnityInstance) {
      instantiateUnityPlayer();
    }
  }, [version, window.createUnityInstance]);

  useEffect(() => {
    playContentHandler(loading, contentId);
  }, [contentId, loading]);

  const playContentHandler = (loading: boolean, contentIds: IContent[]) => {
    const play = contentIds.length > 0 && !loading;
    if (play) {
      try {
        const PlayContentInfo = {
          _contents: contentIds,
          _fullScreenBtnStatus: true,
        };
        console.log({ PlayContentInfo });
        window.gameInstance.SendMessage(
          "WebGLPlayer",
          "PlayContent",
          JSON.stringify(PlayContentInfo)
        );
      } catch (error) {
        console.log(error);
        closeHandler();
      }
    }

    showWebGLPlayer(play);
  };

  const closeHandler = () => {
    try {
      showWebGLPlayer(false);
      if (IsFullScreenCurrently()) document.exitFullscreen();
      dispatch(showPlayerSet(false));
      dispatch(contentIdsSet([]));
      // window.gameInstance.SendMessage("WebGLPlayer", "CloseContent");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={showPlayer}
      sx={{
        display: "flex",
        justifyContent: "center",
        direction: i18n.dir(),
      }}
      onClose={closeHandler}
    >
      <div
        style={{
          ...customStyles.loaderContent,
          visibility: loading ? "visible" : "hidden",
        }}
      >
        <img src={loader} style={customStyles.loader} alt="loader" />
        <TextCarousel texts={texts} />
      </div>
    </Modal>
  );

  function configureWebGLPlayer() {
    try {
      fetch(PlayerConfig.version)
        .then((response) => response.json())
        .then((result: { version: string }) => {
          setVersion(result.version);
          appendLoaderScript(result.version);
        });

      window.loadingCompleted = loadingCompleted;
      window.FullScreen = FullScreen;
      window.ContentFinished = ContentFinished;
      if (process.env.REACT_APP_ENV === "prod") {
        // window.console.log = () => {};
        // window.console.info = () => {};
        // window.console.warn = () => {};
      }

      setLoading(true);
    } catch (error) {
      console.log(error);
      closeHandler();
    }
  }

  function appendLoaderScript(version: string) {
    let unityScriptElement = document.getElementById(
      PlayerConfig.scriptId
    ) as HTMLScriptElement;

    if (!unityScriptElement) {
      unityScriptElement = document.createElement("script");
      unityScriptElement.id = PlayerConfig.scriptId;
      unityScriptElement.async = true;
      unityScriptElement.defer = true;
      unityScriptElement.src = PlayerConfig.scriptSrc(version);
      document.head.appendChild(unityScriptElement);
    }
  }
}

export default WebGLPlayer;
function showWebGLPlayer(show: boolean) {
  const playerContainer = document.getElementById(
    PlayerConfig.container
  ) as HTMLDivElement;

  playerContainer!.style.visibility = show ? "visible" : "hidden";
}
