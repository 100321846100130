import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import en from "date-fns/locale/en-GB";
import ar from "date-fns/locale/ar-SA";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

registerLocale("ar", ar);
registerLocale("en", en);

const useStyles = (showTimeSelect: boolean, showError: boolean) =>
  makeStyles({
    "@keyframes pulse-orange": {
      "0%": {
        transform: "scale(0.99)",
        boxShadow: "0 0 0 0 rgba(212,78,78, 0.7)",
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 7px rgba(212,78,78, 0)",
      },

      "100% ": {
        transform: "scale(0.99)",
        boxShadow: "0 0 0 4px rgba(212,78,78, 0)",
      },
    },
    wrapper: {
      width: "100%",
      animation: showError ? "$pulse-orange 2s infinite" : undefined,
      borderColor: showError ? "rgba(212,78,78, 1)" : undefined,
    },
    datePicker: {
      padding: "0.40rem 0.7rem",
      border: "1px solid rgba(217, 216, 216, 1)",
      borderRadius: "6px",
      width: "100%",
      fontFamily: "dubai-regular",
      "&::placeholder": {
        color: "rgb(51 51 51)",
      },
    },
    popper: {
      zIndex: 9,
    },
    "react-datepicker__day--selected": {
      background: "rgb(98 195 238)",
    },
    calender: {
      border: "none",
      color: "rgba(105, 123, 140, 1)",
      boxShadow: "0px 0px 2px 2px rgba(0, 0, 0, 0.08)",
      borderRadius: "5px",
      padding: "15px 0",
      fontFamily: "dubai-regular",
      "& [class='react-datepicker__triangle']": {
        borderBottomColor: "rgb(255 255 255) !important",
        "&:before": {
          borderBottomColor: "rgba(0, 0, 0, 0.08) !important",
        },
      },
      "& [class*='react-datepicker__navigation']": {
        marginTop: "15px",
        right: showTimeSelect ? "130px" : "5px",
      },
      "& [class*='react-datepicker__navigation--previous']": {
        left: "5px",
        right: "auto",
      },
      "& [class*='react-datepicker__header']": {
        backgroundColor: "rgb(255 255 255)",
        paddingTop: showTimeSelect ? "8px" : 0,
        borderBottom: showTimeSelect
          ? undefined
          : "1px solid rgba(239, 241, 246, 1)",
      },
      "& [class*='react-datepicker__header--has-time-select']": {
        backgroundColor: "rgb(255 255 255) !important",
        borderBottom: "1px solid rgba(239, 241, 246, 1)",
        paddingTop: "0px",
        margin: "0 5px",
      },
      "& [class*='react-datepicker__month-container']": {
        borderRight: "1px solid rgba(232, 232, 232, 1)",
      },
      "& [class*='react-datepicker__time-container']": {
        borderLeft: "none",
        width: "120px !important",
      },
      "& [class='react-datepicker__time-box']": {
        width: "100% !important",
      },
      "& [class*='react-datepicker__header--time']": {
        backgroundColor: "rgb(255 255 255) !important",
        borderBottom: "none",
      },
      "& [class='react-datepicker__current-month']": {
        padding: "8px",
        color: "rgb(98 195 238)",
        borderBottom: showTimeSelect
          ? "2px solid rgba(239, 241, 246, 1)"
          : "1px solid rgba(239, 241, 246, 1)",
      },
      "& [class*='react-datepicker__day-names']": {
        padding: "8px",
      },
      "& [class*='selected']": {
        background: "rgb(98 195 238) !important",
      },
      "& [class='react-datepicker-time__header']": {
        fontSize: "1rem",
        color: "rgba(105, 123, 140, 1)",
      },
      "& [class*='react-datepicker__time-list-item--selected']": {
        background: "none !important",
        border: " 1px solid rgba(217, 216, 216, 1)",
        color: "rgba(105, 123, 140, 1) !important",
        borderRadius: "4px",
        fontWeight: "inherit !important",
      },
      "& [class='react-datepicker__time-list']": {
        width: "auto !important",
        padding: "0 10px !important",
      },
      "& [class*='react-datepicker__day--today']": {
        fontWeight: "inherit",
      },
    },
    time: {
      direction: "rtl",
      fontSize: "1rem",
      padding: "10px !important",
      height: "40px !important",
    },
    week: {
      margin: "0 0.5rem",
    },
    day: {
      margin: "0 0.5rem",
      "&:hover": {
        background: "rgb(98 195 238)",
        color: "rgb(255 255 255)",
      },
    },
  });

const filterPassedTime = (time: Date) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);
  return currentDate.getTime() < selectedDate.getTime();
};

interface IDateTimePickerProps {
  startDate?: Date;
  setStartDate(date: Date): void;
  maxDate?: Date;
  minDate?: Date;
  showWeekNumbers?: boolean;
  highlightedDates?: Date[];
  dateFormat?: string;
  showTimeSelect?: boolean;
  showError?: boolean;
  locale?: string;
}

function DateTimePicker({
  startDate,
  setStartDate,
  maxDate,
  minDate,
  showWeekNumbers = false,
  highlightedDates,
  dateFormat = "hh:mm aa - dd MMMM yyyy",
  showTimeSelect = true,
  showError = false,
  locale,
}: IDateTimePickerProps) {
  const { t } = useTranslation();
  const classes = useStyles(showTimeSelect, showError)();

  const dateChangeHandler = (date: Date) => {
    const currentDate = new Date();
    if (currentDate.getTime() > date.getTime()) {
      date.setHours(currentDate.getHours() + 1);
      date.setMinutes(0, 0, 0);
    }
    setStartDate(date);
  };

  return (
    <DatePicker
      minDate={minDate}
      maxDate={maxDate}
      selected={startDate}
      showTimeSelect={showTimeSelect}
      className={classes.datePicker}
      onChange={dateChangeHandler}
      locale={locale}
      filterTime={filterPassedTime}
      timeIntervals={30}
      dateFormat={dateFormat}
      wrapperClassName={classes.wrapper}
      popperClassName={classes.popper}
      timeCaption={t("time")}
      dayClassName={() => classes.day}
      calendarClassName={classes.calender}
      weekDayClassName={() => classes.week}
      timeClassName={() => classes.time}
      placeholderText={t("Select date & start time")}
      showWeekNumbers={showWeekNumbers}
      shouldCloseOnSelect={false}
      highlightDates={
        highlightedDates
          ? [
              {
                "react-datepicker__day--highlighted-custom-1": highlightedDates,
              },
            ]
          : undefined
      }
    />
  );
}

export default DateTimePicker;
