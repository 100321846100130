import Home from "views/Secure/Home";
import Students from "views/Secure/Students";
import Report from "views/Secure/Reports";
import Evaluations from "views/Secure/EvaluationsDashboard";
import GuidedReading from "views/Secure/Reports/GuidedReading";

const routes = [
  {
    path: "/dashboard",
    component: Home,
  },
  {
    path: "/reports/performance/class",
    component: Report,
  },
  {
    path: "/reports/performance/student",
    component: Report,
  },
  {
    path: "/reports/pacing",
    component: Report,
  },
  {
    path: "/reports/guided-reading/students-progress",
    component: GuidedReading,
  },
  {
    path: "/reports/guided-reading/books-progress",
    component: GuidedReading,
  },
  {
    path: "/evaluation",
    component: Evaluations,
  },
  {
    path: "/students-list",
    component: Students,
  },
];
export default routes;
