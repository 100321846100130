import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: "2rem",
    borderRadius: "2rem",
  },
  studentList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "20%",
    height: "300px",
  },
  content: {
    width: "78%",
  },
});

function ClassSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.studentList}>
        <Skeleton variant="rectangular" height={70} />
        <Skeleton variant="rectangular" height={220} />
      </div>
      <div className={classes.content}>
        <Skeleton variant="rectangular" height={300} />
      </div>
    </div>
  );
}

export default ClassSkeleton;
