import Typography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import LOsGrid from "./LOsGrid";
import Tabs from "./Tabs";
import {
  pendingEvaluationTabOption,
  selectClass,
  selectGrade,
  selectStudent,
  selectTab,
  selectCompletedAssessments,
  selectIsNeedRefetching,
  selectPendingAssessments,
  selectedLoSet,
  tabSet,
  selectIsRedirecting,
  isRedirectingSet,
  IAssessment,
  resetSelectedLo,
} from "features/evaluation";
import { useTranslation } from "react-i18next";
import { StatusTypes } from "features/common/Interfaces";

interface ISideBar {
  height: string;
}

const SideBar = ({ height }: ISideBar) => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState(pendingEvaluationTabOption);
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const completedAssessments = useAppSelector(selectCompletedAssessments);
  const isNeedRefetching = useAppSelector(selectIsNeedRefetching);
  const isRedirecting = useAppSelector(selectIsRedirecting);
  const savedTab = useAppSelector(selectTab);
  const { logEvent } = useContext(MasterDataContext);
  const selectedClass = useAppSelector(selectClass);
  const selectedGrade = useAppSelector(selectGrade);
  const selectedStudent = useAppSelector(selectStudent);

  useEffect(() => {
    if (isRedirecting) {
      setSelectedTab(savedTab);
      dispatch(isRedirectingSet(false));
    } else setSelectedTab(pendingEvaluationTabOption);
  }, [selectedGrade]);

  useEffect(() => {
    if (savedTab.code === StatusTypes.COMPLETED && !isRedirecting) {
      setSelectedTab(savedTab);
      dispatch(tabSet(pendingEvaluationTabOption));
    }
  }, []);

  const dispatchLo = (dataLo: any) => {
    dispatch(
      selectedLoSet({
        ...dataLo,
        status: selectedTab.code,
      })
    );
  };
  useEffect(() => {
    if (isNeedRefetching && selectedTab.code === StatusTypes.COMPLETED) {
      dispatch(tabSet(selectedTab));
    }
    if (
      pendingAssessments?.length &&
      selectedTab.code === StatusTypes.PENDING
    ) {
      dispatchLo(pendingAssessments?.[0]);
    }
    if (
      completedAssessments?.length &&
      selectedTab.code === StatusTypes.COMPLETED
    ) {
      dispatchLo(completedAssessments?.[0]);
    }
    if (
      (!pendingAssessments?.length &&
        selectedTab.code === StatusTypes.PENDING) ||
      (!completedAssessments?.length &&
        selectedTab.code === StatusTypes.COMPLETED)
    )
      dispatch(resetSelectedLo());
  }, [selectedTab]);

  useEffect(() => {
    if (
      pendingAssessments?.length &&
      selectedTab.code === StatusTypes.PENDING
    ) {
      const autoSavedLo = localStorage.getItem("lo-evaluation");
      let autoSelectedLo;
      if (autoSavedLo) {
        autoSelectedLo = pendingAssessments.find(
          (lo: IAssessment) => lo.loId.toString() === autoSavedLo
        );
      }
      dispatchLo(autoSelectedLo ?? pendingAssessments?.[0]);
    }
    if (
      completedAssessments?.length &&
      selectedTab.code === StatusTypes.COMPLETED
    ) {
      const autoSavedLo = localStorage.getItem("lo-evaluation");
      let autoSelectedLo;
      if (autoSavedLo) {
        autoSelectedLo = completedAssessments.find(
          (lo: IAssessment) => lo.loId.toString() === autoSavedLo
        );
      }
      dispatchLo(autoSelectedLo ?? completedAssessments?.[0]);
    }
    if (
      (!pendingAssessments?.length &&
        selectedTab.code === StatusTypes.PENDING) ||
      (!completedAssessments?.length &&
        selectedTab.code === StatusTypes.COMPLETED)
    )
      dispatch(resetSelectedLo());
  }, [selectedStudent]);

  const changeHandler = (tab: any) => {
    logEvent(
      tab.code === StatusTypes.PENDING
        ? "SelectUncompletedEvaluationTab"
        : "SelectCompletedEvaluationTab",
      {
        GradeId: selectedGrade?.code,
        ClassId: selectedClass?.value,
      }
    );
    setSelectedTab(tab);
  };
  const updateHeight =
    (pendingAssessments.length > 0 &&
      selectedTab.code === StatusTypes.PENDING) ||
    (completedAssessments.length > 0 &&
      selectedTab.code === StatusTypes.COMPLETED)
      ? height
      : "100%";

  return (
    <Typography
      component="div"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        [i18n.dir() === "rtl" ? "borderLeft" : "borderRight"]:
          "1px solid #e6e6e6",
        padding: "5px",
        height: updateHeight,
      }}
    >
      <Tabs selectedTab={selectedTab} selectTab={changeHandler} />
      <LOsGrid selectedTab={selectedTab} />
    </Typography>
  );
};

export default SideBar;
