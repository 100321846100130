import Content from "./Content";
import Header from "./Header";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { IStudentQRcode } from "../StudentsList";

const useStyles = makeStyles({
  modal: {
    width: "75%",
    maxWidth: "550px",
    position: "absolute",
    left: 0,
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    margin: "auto",
  },
});

interface IQRDialogProps {
  open: boolean;
  onClose(): void;
  data: IStudentQRcode;
}
function QRDialog({ open, onClose, data }: IQRDialogProps) {
  const { i18n } = useTranslation();
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} sx={{ direction: i18n.dir() }}>
      <div className={classes.modal}>
        <Header onClose={onClose} />
        <Content qrData={data} />
      </div>
    </Modal>
  );
}

export default QRDialog;
