import { TableBody, TableCell, TableRow } from "@mui/material";

const customStyles = {
  tableRow: {
    height: "50px",
  },
  tableCell: {
    fontSize: "14px",
    padding: 0,
    color: "rgba(105, 123, 140, 1)",
    "@media screen and (max-width: 1100px)": {
      fontSize: "14px",
    },
  },
};

interface IEnhancedTableBodyProps {
  tableData: (string | number | JSX.Element)[][];
  columns: number;
}

function EnhancedTableBody({ tableData, columns }: IEnhancedTableBodyProps) {
  return (
    <TableBody>
      {tableData.map((row, rowIdx) => {
        return (
          <TableRow tabIndex={-1} hover key={rowIdx} sx={customStyles.tableRow}>
            {row.map((rowData, index) => {
              return (
                <TableCell
                  key={index}
                  align="center"
                  sx={customStyles.tableCell}
                >
                  {rowData}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableBody>
  );
}

export default EnhancedTableBody;
