import { makeStyles } from "@mui/styles";
import Tooltip from "components/Tooltip";
import classNames from "classnames";
import { IStudent } from "features/classPerformance";

const useStyels = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    userSelect: "none",
    "& span": {
      userSelect: "none",
      "@media screen and (max-width: 768px)": {
        fontSize: "0.6rem",
      },
    },
  },
  cell: {
    height: "60px",
    borderTop: "1px solid rgba(218, 218, 218, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(107 106 106)",
    fontWeight: "bold",
    fontSize: "0.8rem",
    width: "100%",
    userSelect: "none",
    "&  svg": {
      display: "flex",
      cursor: "help",
    },
  },
  header: {
    cursor: "help",
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    color: "rgb(74 74 74)",
    fontSize: "12px",
    fontWeight: "bold",
    background: "#c8eeff",
    borderBottom: "1px solid rgb(214 214 214)",
    boxShadow: "0px -1px 4px -4px rgba(0, 0, 0, 0.06)",
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  longText: {
    padding: "0 10px",
    whiteSpace: "nowrap",
  },
});
interface IDataCardProps {
  id: number;
  students: IStudent[];
  text: string;
  tooltip: JSX.Element;
  renderStudentMarksCell(student: any, loId: any): JSX.Element;
  showElipses?: boolean;
}

function DataCard({
  id,
  students,
  text,
  tooltip,
  renderStudentMarksCell,
  showElipses = false,
}: IDataCardProps) {
  const classes = useStyels();
  return (
    <div className={classes.root}>
      <Tooltip title={tooltip}>
        <div
          className={classNames(
            classes.header,
            showElipses && classes.longText
          )}
        >
          <span className={classNames(showElipses && classes.text)}>
            {text}
          </span>
        </div>
      </Tooltip>
      {students?.map((student, index) => (
        <div key={index} className={classes.cell}>
          {renderStudentMarksCell(student, id)}
        </div>
      ))}
    </div>
  );
}

export default DataCard;
