import { Grid } from "@mui/material";
import PieChartSkeleton from "./PieChartSkeleton";
import StudentSkeleton from "./StudentSkeleton";

function PacingSkeleton() {
  const customStyles = {
    pieChart: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "30px",
      padding: "0 20px",
    },
    studentData: {
      paddingTop: "30px",
    },
  };
  return (
    <>
      <Grid container sx={customStyles.pieChart}>
        <PieChartSkeleton />
      </Grid>
      <Grid container sx={customStyles.studentData}>
        <StudentSkeleton />
      </Grid>
    </>
  );
}

export default PacingSkeleton;
