import { makeStyles } from "@mui/styles";
import { Skeleton, Grid } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    padding: "0 15px",
  },
  student: { width: "100%", display: "flex", flexDirection: "column" },
});

function StudentSkeleton() {
  const classes = useStyles();
  return (
    <>
      {Array.from(new Array(2)).map((data, index) => (
        <Grid item xs={6} key={index}>
          <div className={classes.root}>
            <div style={{ padding: "0 15px" }}>
              {Array.from(new Array(4)).map((child, idx) => (
                <Skeleton
                  sx={{ width: "40px", height: "40px", marginTop: "20px" }}
                  variant="circular"
                  key={idx}
                />
              ))}
            </div>
            <div className={classes.student}>
              <Skeleton sx={{ marginBottom: "10px" }} variant="text" />
              <Skeleton variant="rectangular" height={200} />
            </div>
          </div>
        </Grid>
      ))}
    </>
  );
}

export default StudentSkeleton;
