import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    "& a:first-of-type div": {
      marginRight: 0,
    },
  },
  navLink: {
    border: "none",
    zIndex: "1",
    color: "initial",
    marginLeft: "-1px",
  },
  homeTab: {
    height: "48px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "1px solid rgba(214, 214, 214, 1)",
    borderRadius: "8px",
    "& > h3": {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#838383",
      padding: "10px !important",
      "@media screen and (max-width: 768px)": {
        fontSize: "0.8rem",
      },
    },
    "& svg": {
      width: 35,
      height: 35,
    },
    "@media screen and (max-width: 768px)": {
      minWidth: "100px",
    },
  },
  currentTab: {
    borderRadius: "8px",
    backgroundColor: "rgba(98, 195, 238, 1)",
    zIndex: 1,
    borderBottom: 0,
    "& > h3": {
      color: "#ffffff !important",
    },
  },
});
interface ITabs {
  setSelectedTab: Dispatch<
    SetStateAction<{
      id: number;
      code: string;
      name: string;
    }>
  >;
  selectedTab: {
    id: number;
    code: string;
    name: string;
  };
}
function Tabs({ setSelectedTab, selectedTab }: ITabs) {
  const { t } = useTranslation();
  const tabs = [
    { id: 0, code: "completed", name: t("Completed Assignments") },
    { id: 1, code: "incompleted", name: t("Uncompleted Assignments") },
  ];

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        {tabs?.map((tab, index) => (
          <React.Fragment key={tab.id + index}>
            <div
              className={classes.navLink}
              onClick={() => setSelectedTab(tab)}
            >
              <div
                className={classNames(
                  classes.homeTab,
                  selectedTab?.id === tab.id ? classes.currentTab : null
                )}
                style={{
                  borderTopRightRadius: index === 0 ? "8px" : undefined,
                }}
              >
                <h3
                  style={{
                    color:
                      selectedTab?.id === tab.id
                        ? "rgb(60, 180, 229)"
                        : undefined,
                  }}
                >
                  {tab.name}
                </h3>
              </div>
            </div>
            &nbsp;&nbsp;
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

export default Tabs;
