import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import AvatarGroupIcon from "assets/img/avatarGroup.svg";
import { RootState } from "app/store";
import {
  Assessment,
  IExtendedOption,
  IGrade,
  IOption,
  IOptionWithList,
  IStudent,
  StatusTypes,
} from "../common/Interfaces";

export interface IEvaluationTab {
  id: number;
  code: StatusTypes;
  name: StatusTypes;
}

export interface IStudentDetails extends IStudent {
  evaluationStatus: StatusTypes;
}

export interface IAssessment {
  status?: StatusTypes;
  loId: number;
  loTitle: string;
  lessonTitle: string;
  loCode: string;
  thumb?: string;
  domainName: string;
  assessmentType: Assessment;
  studentsIds?: number[];
  studentDetails: IStudentDetails[];
}

interface IEvaluationState {
  grade?: IGrade;
  class?: IOptionWithList;
  student?: IOption;
  assessments?: IAssessment[];
  selectedLo?: IAssessment;
  isLoadingAssessments: boolean;
  containerHeight: number;
  isNeedRefetching: boolean;
  isRedirecting: boolean;
  tab: IEvaluationTab;
}
export const defaultStudentOption: IExtendedOption = {
  value: "all",
  label: "All Students",
  avatar: AvatarGroupIcon,
  extra: {
    pending: 0,
    completed: 0,
  },
};
export const pendingEvaluationTabOption: IEvaluationTab = {
  id: 1,
  code: StatusTypes.PENDING,
  name: StatusTypes.PENDING,
};
export const completedEvaluationTabOption: IEvaluationTab = {
  id: 0,
  code: StatusTypes.COMPLETED,
  name: StatusTypes.COMPLETED,
};
export const minHeight = 600;

export const clearEvaluationVars = () => {
  localStorage.removeItem("grade-evaluation");
  localStorage.removeItem("class-evaluation");
  localStorage.removeItem("lo-evaluation");
  localStorage.removeItem("student-evaluation");
};

const initialState: IEvaluationState = {
  student: defaultStudentOption,
  isLoadingAssessments: false,
  containerHeight: minHeight,
  isNeedRefetching: false,
  isRedirecting: false,
  tab: pendingEvaluationTabOption,
};

export const evaluationSlice = createSlice({
  name: "evaluation",
  initialState,
  reducers: {
    gradeSet(state, action: PayloadAction<IGrade>) {
      state.grade = action.payload;
    },
    classSet: (state, action: PayloadAction<IOptionWithList>) => {
      state.class = action.payload;
    },
    studentSet: (state, action: PayloadAction<IOption>) => {
      state.student = action.payload;
    },
    assessmentsSet(state, action: PayloadAction<IAssessment[]>) {
      state.assessments = action.payload;
    },
    tabSet: (state, action: PayloadAction<IEvaluationTab>) => {
      state.tab = action.payload;
    },
    selectedLoSet: (state, action: PayloadAction<IAssessment>) => {
      state.selectedLo = action.payload;
    },
    resetSelectedLo: (state) => {
      state.selectedLo = undefined;
    },
    containerHeightSet(state, action: PayloadAction<number>) {
      if (action.payload) state.containerHeight = action.payload;
    },
    isLoadingAssessmentsSet(state, action: PayloadAction<boolean>) {
      state.isLoadingAssessments = action.payload;
    },
    isNeedRefetchingSet(state, action: PayloadAction<boolean>) {
      state.isNeedRefetching = action.payload;
    },
    isRedirectingSet(state, action: PayloadAction<boolean>) {
      state.isRedirecting = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.evaluation.grade;
export const selectClass = (state: RootState) => state.evaluation.class;
export const selectStudent = (state: RootState) => state.evaluation.student;
export const selectIsNeedRefetching = (state: RootState) =>
  state.evaluation.isNeedRefetching;
export const selectAssessments = (state: RootState) =>
  state.evaluation.assessments;
export const selectTab = (state: RootState) => state.evaluation.tab;
export const selectSelectedLo = (state: RootState) =>
  state.evaluation.selectedLo;
export const selecteIsLoadingAssessments = (state: RootState) =>
  state.evaluation.isLoadingAssessments;
export const selectIsRedirecting = (state: RootState) =>
  state.evaluation.isRedirecting;
export const selectContainerHeight = (state: RootState) =>
  state.evaluation.containerHeight;
const selectAssessmentsOfStatus = createSelector(
  selectAssessments,
  (assessments: IAssessment[], status: StatusTypes) => status,
  (assessments, status) => {
    const assesmentsOfStatus = assessments?.reduce(
      (pendingArray: IAssessment[], assessment) => {
        const lo = JSON.parse(JSON.stringify(assessment)) as IAssessment;
        const loPending = lo.studentDetails?.filter(
          (stu: IStudentDetails) => stu.evaluationStatus === status
        );
        if (loPending?.length) {
          lo.status = status;
          lo.studentDetails = [...loPending];
          lo.studentsIds = loPending.map((stu) => stu.id);
          pendingArray.push(lo);
        }
        return pendingArray;
      },
      []
    );
    return assesmentsOfStatus;
  }
);

export const selectPendingAssessments = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.PENDING),
  selectStudent,
  (assessments, student) => {
    const assessmentList: IAssessment[] = JSON.parse(
      JSON.stringify(assessments ?? [])
    );
    const filteredAssessment: IAssessment[] =
      student?.value === "all"
        ? assessmentList
        : assessmentList.reduce((list, assignment) => {
            if (assignment.studentsIds?.includes(student?.value as number)) {
              assignment.studentsIds = [student?.value as number];
              list.push(assignment);
            }
            return list;
          }, [] as IAssessment[]);
    return filteredAssessment;
  }
);

export const selectCompletedAssessments = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.COMPLETED),
  selectStudent,
  (assessments, student) => {
    const assessmentList: IAssessment[] = JSON.parse(
      JSON.stringify(assessments ?? [])
    );
    const filteredAssessment: IAssessment[] =
      student?.value === "all"
        ? assessmentList
        : assessmentList.reduce((list, assignment) => {
            if (assignment.studentsIds?.includes(student?.value as number)) {
              assignment.studentsIds = [student?.value as number];
              list.push(assignment);
            }
            return list;
          }, [] as IAssessment[]);
    return filteredAssessment;
  }
);

export const totalPendingAssessmentsCount = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.PENDING),
  (assessments) => {
    return countAssessments(assessments);
  }
);
export const totalCompletedAssessmentsCount = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.COMPLETED),
  (assessments) => {
    return countAssessments(assessments);
  }
);

const pendingAssessmentsStudentsIds = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.PENDING),
  (assessments) => {
    const ids = assessments?.reduce((list, assignment) => {
      const updatedList = [
        ...list,
        ...(assignment?.studentsIds ? assignment?.studentsIds : []),
      ];
      return updatedList;
    }, [] as number[]);
    return new Set(ids);
  }
);
const completedAssessmentsStudentsIds = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.COMPLETED),
  (assessments) => {
    const ids = assessments?.reduce((list, assignment) => {
      const updatedList = [
        ...list,
        ...(assignment?.studentsIds ? assignment?.studentsIds : []),
      ];
      return updatedList;
    }, [] as number[]);
    return new Set(ids);
  }
);

export const studentsPendingAssessmentsCount = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.PENDING),
  pendingAssessmentsStudentsIds,
  (assessments, ids) => {
    const studentsCounts = Array.from(ids).reduce((list, id) => {
      const studentAssessments = assessments?.filter((assessment) =>
        assessment.studentsIds?.includes(id as number)
      );
      list[id] = studentAssessments?.length ?? 0;
      return list;
    }, {} as { [key: number]: number });
    return studentsCounts as { [key: number]: number };
  }
);

export const studentsCompletedAssessmentsCount = createSelector(
  (state: RootState) =>
    selectAssessmentsOfStatus(state as any, StatusTypes.COMPLETED),
  completedAssessmentsStudentsIds,
  (assessments, ids) => {
    const studentsCounts = Array.from(ids).reduce((list, id) => {
      const studentAssessments = assessments?.filter((assessment) =>
        assessment.studentsIds?.includes(id)
      );
      list[id] = studentAssessments?.length ?? 0;
      return list;
    }, {} as { [key: number]: number });
    return studentsCounts as { [key: number]: number };
  }
);
export const {
  selectedLoSet,
  tabSet,
  gradeSet,
  classSet,
  studentSet,
  containerHeightSet,
  assessmentsSet,
  isLoadingAssessmentsSet,
  isNeedRefetchingSet,
  isRedirectingSet,
  resetSelectedLo,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;

function countAssessments(assessments: IAssessment[] | undefined) {
  const total = assessments?.reduce((count, item) => {
    if (item.studentsIds) count += item.studentsIds?.length;
    return count;
  }, 0);
  return total;
}
