import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IOption } from "../common/Interfaces";

export const defaultClassOption = { label: "All classes", value: -1 };

export interface IStudentInfo {
  _id: number;
  _name: string;
  _gradeName: string;
  _className: string;
  _deepLinkHome: string;
  _studentUUIDCode: string;
}
interface IStudentsState {
  selectedClass: IOption;
  studentList: IStudentInfo[];
  allStudents: IStudentInfo[];
  selectedDataList: number[];
  classList: IOption[];
  selectAll: boolean;
}

const initialState: IStudentsState = {
  selectedClass: defaultClassOption,
  studentList: [],
  allStudents: [],
  selectedDataList: [],
  classList: [],
  selectAll: false,
};
export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    selectedClassSet(state, action) {
      state.selectedClass = action.payload;
    },
    studentListSet(state, action) {
      state.studentList = action.payload;
    },
    allStudentsSet(state, action) {
      state.allStudents = action.payload;
    },
    selectedDataListSet(state, action) {
      state.selectedDataList = action.payload;
    },
    classListSet(state, action) {
      state.classList = action.payload;
    },
    selectAllSet(state, action: PayloadAction<boolean>) {
      state.selectAll = action.payload;
    },
  },
});

export const selectSelectedClass = (state: RootState) =>
  state.students.selectedClass;
export const selectStudentList = (state: RootState) =>
  state.students.studentList;
export const selectAllStudents = (state: RootState) =>
  state.students.allStudents;
export const selectSelectedDataList = (state: RootState) =>
  state.students.selectedDataList;
export const selectClassList = (state: RootState) => state.students.classList;
export const selectSelectAll = (state: RootState) => state.students.selectAll;
export const {
  selectedClassSet,
  studentListSet,
  selectedDataListSet,
  classListSet,
  selectAllSet,
  allStudentsSet,
} = studentsSlice.actions;

export default studentsSlice.reducer;
