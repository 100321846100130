import { useEffect, useContext, useState } from "react";
import { Grid } from "@mui/material";
import DateTimePicker from "components/DateTimePicker";
import MultiSelect from "components/MultiSelect";
import { MasterDataContext } from "contexts/MasterDataContext";
import moment from "moment";
import {
  allClassesSet,
  IClass,
  ISelectedClass,
  selectAllClasses,
  selectedClassesSet,
  selectSelectedClasses,
  selectStartDate,
  startDateSet,
} from "features/assignments";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";

interface IFiltersProps {
  resetSelectedDate?: boolean;
  invalidDate?: boolean;
  resetValid(value: boolean): void;
}
function Filters({
  resetSelectedDate = false,
  invalidDate = false,
  resetValid,
}: IFiltersProps) {
  const { i18n } = useTranslation();
  const {
    state: { academicYear },
  } = useContext(MasterDataContext);

  const allClasses = useAppSelector(selectAllClasses);
  const startDate = useAppSelector(selectStartDate);
  const selectedClasses = useAppSelector(selectSelectedClasses);

  const dispatch = useAppDispatch();

  const maxDate = new Date(academicYear?.end!);
  const [highlightedDates, setHighlightedDates] = useState<Date[]>([]);

  useEffect(() => {
    const highlightedDates = getHighlightedDates(startDate);
    setHighlightedDates(highlightedDates);
  }, [startDate]);

  useEffect(() => {
    if (resetSelectedDate)
      dispatch(startDateSet(moment().format("YYYY-MM-DD")));
  }, [resetSelectedDate]);

  const getHighlightedDates = (date: string) => {
    let dateArray = [];
    let startDate = moment(date).startOf("isoWeek");
    let endDate = moment(date).endOf("isoWeek");
    while (startDate <= endDate) {
      if (startDate >= moment()) dateArray.push(transformDate(startDate));
      startDate = moment(startDate).add(1, "days");
    }
    return dateArray;
  };

  const dateChangeHandler = (date: Date) => {
    resetValid(false);
    dispatch(startDateSet(moment(date).format("YYYY-MM-DD")));
  };

  const transformDate = (date: moment.Moment) => {
    return new Date(moment(date).format("YYYY-MM-DD"));
  };

  const setSelectedClass = (item: IClass) => {
    const updatedClasses = JSON.parse(JSON.stringify(allClasses)) as IClass[];
    const index = updatedClasses.findIndex(
      (classObj) => classObj.value === item.value
    );
    let selectedClass = updatedClasses[index];
    selectedClass.checked = !item.checked;
    dispatch(allClassesSet(updatedClasses));
    updateSelectedClassData(selectedClass);
  };

  const updateSelectedClassData = async (selectedClass: IClass) => {
    let prevSelectedClasses = JSON.parse(
      JSON.stringify(selectedClasses)
    ) as ISelectedClass[];
    if (selectedClass.checked) {
      prevSelectedClasses.push({
        classId: selectedClass.value,
        className: selectedClass.label,
        checked: true,
        students: selectedClass.students,
      });
    } else if (prevSelectedClasses.length) {
      const index = prevSelectedClasses.findIndex(
        (data) => data.classId === selectedClass.value
      );
      prevSelectedClasses.splice(index, 1);
    }
    dispatch(selectedClassesSet(prevSelectedClasses));
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: "0 0.5rem 0.5rem",
      }}
    >
      <Grid item xs={3}>
        <MultiSelect clickHandler={setSelectedClass} data={allClasses} />
      </Grid>
      <Grid item xs={3}>
        <DateTimePicker
          showError={invalidDate}
          startDate={new Date(startDate)}
          setStartDate={dateChangeHandler}
          maxDate={maxDate}
          minDate={new Date()}
          highlightedDates={highlightedDates}
          showWeekNumbers
          dateFormat="dd MMMM yyyy"
          showTimeSelect={false}
          locale={i18n.dir() === "rtl" ? "ar" : "en"}
        />
      </Grid>
    </Grid>
  );
}

export default Filters;
