import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function PasswordLock(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h88v88H0z" />
        <path
          stroke="#E9E9E9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.5"
          d="M14.667 29.333V22A7.333 7.333 0 0 1 22 14.667h7.333M14.667 58.667V66A7.333 7.333 0 0 0 22 73.333h7.333M58.667 14.667H66A7.333 7.333 0 0 1 73.333 22v7.333M58.667 73.333H66A7.333 7.333 0 0 0 73.333 66v-7.333"
        />
        <rect
          width="29.333"
          height="18.333"
          x="29.333"
          y="40.333"
          stroke="#6CCEF5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.5"
          rx="3.667"
        />
        <path
          stroke="#6CCEF5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.5"
          d="M36.667 40.333V33a7.333 7.333 0 1 1 14.666 0v7.333"
        />
      </g>
    </SvgIcon>
  );
}
