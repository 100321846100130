interface ITab {
  label: string;
  path?: string;
}
interface IBreadcrumbLabels {
  [key: string]: {
    label: string;
    tab?: ITab[];
  };
}
export const BreadcrumbLabels: IBreadcrumbLabels = {
  class: {
    label: "Class Performance",
    tab: [
      {
        label: "Class Performance",
      },
      {
        label: "Student Performance",
        path: "student",
      },
    ],
  },
  student: {
    label: "Student Performance",
    tab: [
      {
        label: "Class Performance",
        path: "class",
      },
      {
        label: "Student Performance",
      },
    ],
  },
  "students-progress": {
    label: "Students Progress",
    tab: [
      {
        label: "Students Progress",
      },
      {
        label: "Books Progress",
        path: "books-progress",
      },
    ],
  },
  "books-progress": {
    label: "Books Progress",
    tab: [
      {
        label: "Students Progress",
        path: "students-progress",
      },
      {
        label: "Books Progress",
      },
    ],
  },
  reports: { label: "Reports" },
  "guided-reading": { label: "GuidedReading" },
  evaluation: { label: "Evaluation" },
  performance: { label: "Performance" },
  dashboard: { label: "Home" },
  pacing: { label: "Pacing" },
  "students-list": { label: "Students List" },
};
