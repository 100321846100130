import { useEffect, useState, useContext } from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LosAssignment from "./Assignment";
import {
  assignedLosCountSet,
  selectAssignedLessons,
} from "features/assignments";
import { makeStyles } from "@mui/styles";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { selectAssignExam } from "features/exams";
import { selectSection } from "features/content";
import { LessonTypes } from "features/common/Interfaces";
import ExamAssignment from "./Exams/Assignment";
const useStyles = (count: number) =>
  makeStyles({
    tooltipBar: {
      userSelect: "none",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      position: "fixed",
      height: "75px",
      bottom: "45px",
      width: "100%",
      background: "rgb(255 255 255)",
      boxShadow: " 0px -2px 4px 0px rgba(197, 197, 197, 0.5)",
      left: 0,
      color: "rgb(116 116 116)",
      padding: "0 2rem",
    },
    "@keyframes pulse-blue": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(98, 195, 238, 0.7)",
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 15px rgba(98, 195, 238, 0)",
      },

      "100% ": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 5px rgba(98, 195, 238, 0)",
      },
    },
    "@keyframes pulse-orange": {
      "0%": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 0 rgba(251, 170, 144, 0.7)",
      },

      "70%": {
        transform: "scale(1)",
        boxShadow: "0 0 0 15px rgba(251, 170, 144, 0)",
      },

      "100% ": {
        transform: "scale(0.95)",
        boxShadow: "0 0 0 5px rgba(251, 170, 144, 0)",
      },
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        margin: "0 15px",
      },
    },
    count: {
      textAlign: "center",
      width: "20px",
      height: "20px",
      animation: count ? "$pulse-orange 1.1s infinite" : undefined,
      background: count ? "rgba(251, 170, 144, 1)" : "rgba(220, 220, 220, 1)",
      borderRadius: "100%",
      color: "rgba(255 255 255)",
    },
    addToCart: {
      textAlign: "center",
      borderRadius: "6px",
      border: count
        ? "1px solid rgba(98, 195, 238, 1)"
        : "1px solid rgba(98, 195, 238, 0.5)",
      width: "40px",
      height: "35px",
      animation: count ? "$pulse-blue 1.1s infinite" : undefined,
      "& svg": {
        fontSize: "2rem",
        color: count ? "rgba(98, 195, 238, 1)" : "rgba(98, 195, 238, 0.5)",
      },
      cursor: count ? "pointer" : "default",
    },
  });

function TooltipBar() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const assignedLessons = useAppSelector(selectAssignedLessons);
  const assignExam = useAppSelector(selectAssignExam);
  const selectedSection = useAppSelector(selectSection);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let count = 0;
    const { losList, unitList } = assignedLessons;
    count = losList.length;
    if (unitList.length) {
      unitList.forEach((unit) => {
        if (unit?.childLos?.length) count += unit.childLos.length;
      });
    }
    dispatch(assignedLosCountSet(count));
  }, [assignedLessons]);

  const { logEvent } = useContext(MasterDataContext);
  const classes = useStyles(
    selectedSection === LessonTypes.EXAMS && assignExam.examId
      ? 1
      : assignedLessons.assignedLosCount
  )();

  const clickHandler = () => {
    logEvent("ClickOnPreviewAndAssign");
    if (
      assignedLessons.assignedLosCount ||
      (selectedSection === LessonTypes.EXAMS && assignExam.examId)
    )
      setOpen(true);
  };

  return (
    <>
      <div className={classes.tooltipBar} style={{ direction: i18n.dir() }}>
        <div className={classes.wrapper}>
          <div className={classes.count}>
            {selectedSection === LessonTypes.EXAMS && assignExam.examId
              ? 1
              : assignedLessons.assignedLosCount}
          </div>
          <span>{t("Learning Objective")}</span>
        </div>
        <div className={classes.wrapper}>
          <span>{t("Preview & Assign")}</span>
          <div className={classes.addToCart} onClick={clickHandler}>
            <ArrowDropUpIcon />
          </div>
        </div>
      </div>
      {selectedSection === LessonTypes.EXAMS ? (
        <ExamAssignment open={open} setOpen={setOpen} />
      ) : (
        <LosAssignment open={open} setOpen={setOpen} />
      )}
    </>
  );
}

export default TooltipBar;
