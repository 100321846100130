import { Typography } from "@mui/material";
import SelectInput from "components/SelectInput";
import { AssignIcon } from "components/SVGs/HomeIcons";
import { IOption } from "features/common/Interfaces";
import {
  DifficultyType,
  questionAdded,
  questionRemoved,
  selectCreateExam,
  selectedGrade,
  selectSearchQuestion,
  selectSelectedFitlers,
} from "features/exams";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import showView from "assets/img/showView.png";
// import { questionList } from "./questionList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  punctuation,
  tashkilRegx,
  whitespaces,
} from "features/common/Constants";
import DataNotFound from "components/DataNotFound";
import useClientDevice from "hooks/useClientDevice";
import useToast from "hooks/useToast";
import { contentIdsSet, showPlayerSet } from "features/ui";
import { IFilterLosRequest, useFilterLosMutation } from "services/exams";
import { MasterDataContext } from "contexts/MasterDataContext";

const customStyles = (dir: "ltr" | "rtl") => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    color: "rgb(105 123 140)",
  },
  table: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "row",
    padding: dir === "rtl" ? "10px 70px 10px 20px" : "10px 20px 10px 70px",
    width: "100%",
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "300px",
      fontSize: "14px",
      fontWeight: "bold",
      padding: "0 10px",
    },
  },
  title: { flex: 1 },
  questionLevel: { width: "70px !important", padding: "0 5px !important" },
  preview: { width: "60px !important" },
  body: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    padding: "0 20px 10px",
    width: "100%",
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start",
    flexDirection: "column",
    width: "100%",
    paddingBottom: "15px",
    "& > div": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      fontSize: "14px",
      height: "40px",
      padding: "0 10px",
      fontWeight: "bold",
      boxShadow: "inset 0 -1px 0 0 rgba(0, 0, 0, 0.1)",
    },
    "& > div:first-of-type ": {
      background: "rgb(249 249 249)",
      boxShadow: "none",
    },
    "& > div:last-of-type ": {
      boxShadow: "none",
    },
  },
  question: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "row",
    width: "100%",
  },
});

const difficultyLevel: IOption[] = [
  { value: DifficultyType.AllLevel, label: DifficultyType.AllLevel },
  { value: DifficultyType.EASY, label: DifficultyType.EASY },
  { value: DifficultyType.MEDIUM, label: DifficultyType.MEDIUM },
  { value: DifficultyType.HARD, label: DifficultyType.HARD },
];

const headers = [
  { title: "questionLevel", id: 1, styles: "questionLevel" },
  { title: "questionTitle", id: 2, styles: "title" },
  { title: "questionPreview", id: 3, styles: "preview" },
];

interface ILosListProps {}

export interface IExamLo {
  id: number;
  loTitle: string;
  questions: {
    id: string;
    title: string;
    level: DifficultyType;
    selected: boolean;
  }[];
}

const LosList = (props: ILosListProps) => {
  const { logEvent } = useContext(MasterDataContext);
  const { isTablet } = useClientDevice();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const createExamDetails = useAppSelector(selectCreateExam);
  const [questionList, setQuestionList] = useState<IExamLo[]>([]);
  const searchQuestion = useAppSelector(selectSearchQuestion);
  const [selectedLevel, setSelectedLevel] = useState<IOption>(
    difficultyLevel[0]
  );
  const [los, setLos] = useState<IExamLo[]>([]);
  const { t, i18n } = useTranslation();
  const classes = customStyles(i18n.dir());
  const selectedFilters = useAppSelector(selectSelectedFitlers);
  const grade = useAppSelector(selectedGrade);
  const [filterLos] = useFilterLosMutation();

  function filterByDifficlutyLevel(los: IExamLo[]) {
    const updateList =
      selectedLevel?.value === DifficultyType.AllLevel
        ? los
        : los
            .map((lo) => {
              const questions = lo.questions.filter(
                (q) => q.level === selectedLevel?.value
              );
              return { ...lo, questions };
            })
            .filter((lo) => lo.questions.length > 0);
    return updateList;
  }

  function filterBySearchText(los: IExamLo[]) {
    let updateList = los;
    if (searchQuestion?.length) {
      updateList = los.filter((lo) => {
        const isTextInLoTitle = lo.loTitle
          .replace(tashkilRegx, "")
          .replace(punctuation, "")
          .replace(whitespaces, " ")
          .includes(
            searchQuestion
              .trim()
              .replace(tashkilRegx, "")
              .replace(punctuation, "")
              .replace(whitespaces, " ")
          );
        const updateQuestions = lo.questions.filter((ques) =>
          ques.title
            .replace(tashkilRegx, "")
            .replace(punctuation, "")
            .replace(whitespaces, " ")
            .includes(
              searchQuestion
                .trim()
                .replace(tashkilRegx, "")
                .replace(punctuation, "")
                .replace(whitespaces, " ")
            )
        );

        return isTextInLoTitle || updateQuestions.length > 0;
      });
    }
    return updateList;
  }

  const fetchLos = async (payload: IFilterLosRequest) => {
    try {
      const response = await filterLos(payload).unwrap();
      if (response._statusCode === 200) {
        const losList: IExamLo[] = response._entity.map((lo) => ({
          id: lo._loId,
          loTitle: lo._loTitleAr,
          questions: lo._questions.map((ques) => ({
            id: ques._questionBankContentId,
            title: ques._contentName,
            level:
              ques._questionLevelEn === "HIGH"
                ? DifficultyType.HARD
                : (ques._questionLevelEn as DifficultyType),
            selected: createExamDetails.questionList.includes(
              ques._questionBankContentId
            ),
          })),
        }));
        setQuestionList(losList);
      } else throw response;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (grade && selectedFilters?.subGroupCode) {
      const payload: IFilterLosRequest = {
        _gradeId: grade?.id,
        _groupCode: selectedFilters?.groupCode,
        _subGroupCode: selectedFilters.subGroupCode,
      };
      fetchLos(payload);
    }
  }, [grade, selectedFilters]);

  useEffect(() => {
    const filteredLos = filterBySearchText(
      filterByDifficlutyLevel(questionList)
    ).map((lo) => ({
      ...lo,
      questions: lo.questions.map((qu) => ({
        ...qu,
        selected: createExamDetails.questionList.includes(qu.id),
      })),
    }));
    setLos(filteredLos);
  }, [searchQuestion, selectedLevel, questionList]);

  function notCompatibleWithTablets() {
    toast({
      label: t("Info"),
      message: t("Only Desktop supported"),
      rtl: i18n.dir() === "rtl",
      type: "info",
      toastId: "ContentPlayNotSupoorted",
    });
  }
  const playQuestionHandler = (id: string) => {
    if (isTablet) {
      notCompatibleWithTablets();
    } else {
      logEvent("ClickOnViewQuestion", { ContentId: id });
      dispatch(contentIdsSet([{ _contentId: id }]));
      dispatch(showPlayerSet(true));
    }
  };
  return (
    <Typography component="div" sx={classes.root}>
      <Typography
        component="div"
        sx={{
          width: "180px",
          padding: "10px",
        }}
      >
        <SelectInput
          valueList={difficultyLevel.map((item) => ({
            ...item,
            label: t(item.label),
          }))}
          defaultValue={selectedLevel?.value ?? difficultyLevel[0].value}
          getOption={({ currentTarget }) => setSelectedLevel(currentTarget)}
        />
      </Typography>
      <Typography component="div" sx={classes.table}>
        <Typography component="div" sx={classes.header}>
          {headers.map((item) => (
            <Typography
              component="div"
              key={item.id}
              sx={classes[item.styles as keyof typeof classes]}
            >
              {t(item.title)}
            </Typography>
          ))}
        </Typography>
        <Typography component="div" sx={classes.body}>
          {los.length ? (
            los.map((item) => (
              <Typography component="div" key={item.id} sx={classes.item}>
                <Typography
                  component="div"
                  sx={{ padding: "0 55px !important" }}
                >
                  {item.loTitle}
                </Typography>
                {item.questions.map((question) => (
                  <Typography
                    component="div"
                    key={question.id}
                    sx={classes.question}
                  >
                    <AssignBtn question={question} />
                    <Typography
                      component="div"
                      sx={{
                        width: "90px",
                        textAlign: "center",
                        fontSize: "13px",
                      }}
                    >
                      {t(question.level)}
                    </Typography>

                    <Typography
                      component="div"
                      sx={{ flex: 1, fontSize: "13px" }}
                    >
                      {question.title}
                    </Typography>
                    <img
                      src={showView}
                      style={{
                        width: "24px",
                        height: "24px",
                        cursor: "pointer",
                      }}
                      alt={question.id}
                      className="play-ext"
                      id={question.id}
                      onClick={() => playQuestionHandler(question.id)}
                    />
                  </Typography>
                ))}
              </Typography>
            ))
          ) : (
            <DataNotFound />
          )}
        </Typography>
      </Typography>
    </Typography>
  );
};

type AssignBtnProps = {
  id: string;
  title: string;
  level: DifficultyType;
  selected: boolean;
};

const AssignBtn = ({ question }: { question: AssignBtnProps }) => {
  const { logEvent } = useContext(MasterDataContext);
  const createExamDetails = useAppSelector(selectCreateExam);
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [selected, setSelected] = useState(question.selected);
  const dispatch = useAppDispatch();
  useEffect(() => {
    setSelected(question.selected);
  }, [question.selected]);

  const addHandler = () => {
    if (createExamDetails.questionList.length < 50) {
      setSelected(!selected);
      logEvent("AddQuestion", { ContentId: question.id });
    } else
      toast({
        label: t("Info"),
        message: t("Only 50 per exam"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "exceeded-questions-count",
      });
  };

  useEffect(() => {
    if (selected) dispatch(questionAdded(question.id));
    else dispatch(questionRemoved(question.id));
  }, [selected]);

  return !selected ? (
    <AssignIcon onClick={addHandler} sx={{ cursor: "pointer" }} />
  ) : (
    <RemoveCircleIcon
      sx={{
        color: "rgba(105, 123, 140, 1)",
        cursor: "pointer",
      }}
      onClick={() => {
        setSelected(!selected);
      }}
    />
  );
};

export default LosList;
