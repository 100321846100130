import { useContext, useEffect, useState } from "react";
import { CSSProperties, makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import useLocale from "hooks/useLocale";
import { MasterDataContext } from "contexts/MasterDataContext";

const useStyles = makeStyles({
  languageSwitch: {
    borderRadius: "50%",
    color: "rgb(79 189 234)",
    fontSize: "16px",
    lineHeight: "5px",
    cursor: "pointer",
    border: "2px solid",
    width: "33px",
    height: "33px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(255 255 255)",
  },
  arText: {
    padding: "6px 0 0",
    fontSize: "20px",
    alignItems: "flex-start",
  },
  blue: {
    backgroundColor: "rgb(79 189 234)",
    color: "rgb(255 255 255)",
    borderColor: "rgb(79 189 234)",
    fontWeight: "bold",
    zIndex: 2,
  },
});
interface ILanguageSwitchProps {
  "data-test"?: string;
  blue?: boolean;
  style?: CSSProperties;
}
function LanguageSwitch({
  blue = false,
  style,
  ...rest
}: ILanguageSwitchProps) {
  const { logEvent } = useContext(MasterDataContext);
  const { dir } = useLocale();
  const [lang, setLang] = useState(dir === "ltr" ? "ar" : "en");
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const newLang =
      event.currentTarget.innerText.trim() === t("en") ? "ar" : "en";
    setLang(newLang);
    const isArabicLang = newLang === "ar";
    logEvent("ChangeLanguage", {
      selectedLanguage: isArabicLang ? "en" : "ar",
    });
    i18n.changeLanguage(isArabicLang ? "en" : "ar");
    localStorage.setItem("dir", isArabicLang ? "ltr" : "rtl");
  };

  useEffect(() => {
    const isRtlDir = dir === "rtl";
    setLang(isRtlDir ? "en" : "ar");
    i18n.changeLanguage(isRtlDir ? "ar" : "en");
  }, []);

  return (
    <div
      className={classNames(
        classes.languageSwitch,
        lang === "ar" && classes.arText,
        blue && classes.blue
      )}
      onClick={handleClick}
      style={style}
      {...rest}
    >
      <span>{t(lang)}</span>
    </div>
  );
}

export default LanguageSwitch;
