import { ChangeEvent, CSSProperties, SyntheticEvent, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
} from "@mui/material";
import { DownIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";
import { ISelectedClassStudents } from "features/common/Interfaces";
import DataNotFound from "components/DataNotFound";
import Tooltip from "components/Tooltip";

const customStyles = {
  students: {
    display: "grid",
    alignItems: "center",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    "@media screen and (max-width: 1400px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    "@media screen and (max-width: 1024px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& img": {
      height: "30px",
      width: "30px",
      marginLeft: "5px",
    },
  },
  stuName: {
    fontSize: "0.9rem",
    lineHeight: 1,
    fontWeight: "bold",
    padding: "9px 0px",
  },
  noDataStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "150px",
    justifyContent: "center",
    width: "100%",
    "& > span": {
      fontSize: "0.8rem",
      width: "30vw",
      textAlign: "center",
      color: "rgba(105, 123, 140, 1)",
      paddingTop: "1rem",
    },
    "& > svg": {
      fontSize: "4rem",
    },
  },
};

interface IAccordionWithCheckboxProps {
  selectedClasses: ISelectedClassStudents[];
  classCheckboxHandler(
    event: ChangeEvent<HTMLInputElement>,
    classId: number
  ): void;
  studentCheckboxHandler(
    event: ChangeEvent<HTMLInputElement>,
    studentId: number,
    classId: number
  ): void;
  status?: { In_Progress?: any; Completed?: any };
  completeEnable?: boolean;
}
function AccordionWithCheckbox({
  selectedClasses,
  classCheckboxHandler,
  studentCheckboxHandler,
}: IAccordionWithCheckboxProps) {
  const { t, i18n } = useTranslation();
  const classes = customStyles;
  const [expanded, setExpanded] = useState<number>();

  const handleChange =
    (panel: number) => (_: SyntheticEvent<Element>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : 0);
    };

  const studentChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
    studentId: number,
    classId: number
  ) => studentCheckboxHandler(event, studentId, classId);

  return (
    <Grid container>
      {selectedClasses.map((data, index) => (
        <Grid
          style={{
            width: "100%",
            marginTop: index !== 0 ? "0.6rem" : undefined,
          }}
          key={data.classId}
        >
          <Accordion
            sx={{ boxShadow: "none" }}
            expanded={expanded === data.classId}
            onChange={handleChange(data.classId!)}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "rgba(239, 241, 246, 1)",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
              expandIcon={
                <>
                  <DownIcon
                    className="downIcon"
                    style={{ padding: "0.5rem" }}
                  />
                </>
              }
            >
              <FormControlLabel
                sx={{
                  fontSize: "1rem",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "1rem",
                  },
                  "& svg": {
                    fontSize: "1.5rem",
                  },
                }}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    color="primary"
                    checked={data.checked}
                    disabled={!isClassAssignable(data)}
                    onChange={(event) =>
                      classCheckboxHandler(event, data.classId!)
                    }
                  />
                }
                label={t("Select all students")}
              />
              <span style={{ fontSize: "1rem" }}>{data.className}</span>
            </AccordionSummary>
            <AccordionDetails>
              {data.students.length ? (
                <Typography component="div" sx={classes.students}>
                  {data.students.map((student, idx) => (
                    <Tooltip
                      title={
                        student.assignable ? "" : t("exam already assigned")
                      }
                      placement={i18n.dir() === "rtl" ? "top-end" : "top-start"}
                      key={idx}
                    >
                      <Typography component="div" sx={classes.wrapper}>
                        <Checkbox
                          sx={{
                            "& svg": {
                              fontSize: "1.2rem",
                            },
                          }}
                          color="primary"
                          checked={student.checked}
                          onChange={
                            student.assignable
                              ? (event) =>
                                  studentChangeHandler(
                                    event,
                                    student.studentId,
                                    data.classId
                                  )
                              : undefined
                          }
                          disabled={!student.assignable}
                        />
                        <Typography
                          component="span"
                          sx={{
                            ...classes.stuName,
                            color: `rgba(105, 123, 140, ${
                              !student.assignable ? 0.6 : 1
                            })`,
                          }}
                        >
                          {student.studentName}
                        </Typography>
                      </Typography>
                    </Tooltip>
                  ))}
                </Typography>
              ) : (
                <DataNotFound
                  text={t("No students")}
                  style={classes.noDataStyle as CSSProperties}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}

function isClassAssignable(data: ISelectedClassStudents): boolean | undefined {
  // class has no students
  if (!data.students.length) return false;

  // check if some students are assignable
  let studentsAssignable = false;
  for (let i = 0; i < data.students.length; i++) {
    if (data.students[i].assignable) {
      studentsAssignable = true;
      break;
    }
  }

  return studentsAssignable;
}

export default AccordionWithCheckbox;
