import { useContext } from "react";
import Tooltip from "components/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import MinimizeIcon from "@mui/icons-material/Minimize";
import {
  NotAssignedIcon,
  EneIcon,
  PendingAssignmentIcon,
} from "components/SVGs";
import { useHistory } from "react-router-dom";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "app/hooks";
import {
  completedEvaluationTabOption,
  isRedirectingSet,
  pendingEvaluationTabOption,
  tabSet,
} from "features/evaluation";
import { IGrade, IOption } from "features/common/Interfaces";

const labels = [
  "question 1",
  "question 2",
  "question 3",
  "question 4",
  "question 5",
  "question 6",
  "question 7",
  "question 8",
  "question 9",
  "question 10",
  "question 11",
  "question 12",
  "question 13",
  "question 14",
  "question 15",
  "question 16",
  "question 17",
  "question 18",
  "question 19",
  "question 20",
  "question 21",
  "question 22",
];
interface IStudentScoreCellProps {
  student: {
    score: string;
    studentId: number;
    offline: boolean;
    ene: boolean;
    questions: (boolean | null)[];
  };
  loId: string;
  gradeInfo: IGrade;
  classInfo: IOption;
}
function StudentScoreCell({
  student,
  loId,
  gradeInfo,
  classInfo,
}: IStudentScoreCellProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { logEvent } = useContext(MasterDataContext);
  const evaluateHandle = (stuId: number) => {
    logEvent("EvaluationClickToEvaluate", {
      ClassId: classInfo.value,
      ContentId: loId,
    });
    localStorage.setItem("grade-evaluation", JSON.stringify(gradeInfo));
    localStorage.setItem("class-evaluation", JSON.stringify(classInfo));
    localStorage.setItem("lo-evaluation", loId);
    localStorage.setItem("student-evaluation", `${stuId}`);
    dispatch(isRedirectingSet(true));
    if (student.score === "Evaluation_Pending")
      dispatch(tabSet(pendingEvaluationTabOption));
    else dispatch(tabSet(completedEvaluationTabOption));
    history.push(`/evaluation`);
  };
  const renderCell = (
    data: {
      score: string;
      studentId: number;
      offline: boolean;
      ene: boolean;
      questions: (boolean | null)[];
    },
    clickHandler: (id: number) => void
  ) => {
    const { studentId, score, offline, ene, questions } = data;
    let cell;
    const style = {
      color: "rgba(98, 195, 238, 1)",
      cursor: "pointer",
      textDecoration: "underline",
    };
    const eneStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    switch (score) {
      case "NotStarted":
        cell = (
          <Tooltip title={t("pending")}>
            <div>
              <PendingAssignmentIcon />
            </div>
          </Tooltip>
        );
        break;
      case null:
        cell = (
          <Tooltip title={t("notAssigned")}>
            <div>
              <NotAssignedIcon />
            </div>
          </Tooltip>
        );
        break;
      case "Evaluation_Pending":
        cell = (
          <span onClick={() => clickHandler(studentId)} style={style}>
            {t("Evaluate")}
          </span>
        );

        break;
      default:
        cell = `${score}%`;
        if (offline)
          cell = (
            <span onClick={() => clickHandler(studentId)} style={style}>
              {cell}
            </span>
          );
        else {
          const tooltipNode =
            questions && questions.length > 0
              ? renderTooltipNode(questions)
              : "";
          cell = (
            <Tooltip title={tooltipNode}>
              <span>{cell}</span>
            </Tooltip>
          );
        }

        if (ene) {
          cell = (
            <div style={eneStyle}>
              {cell}
              <Tooltip title={t("completedEnE")}>
                <div style={{ paddingRight: "5px" }}>
                  <EneIcon
                    style={{ color: "rgb(134 189 48)", fontSize: "1.25rem" }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        }
        if (ene === false) {
          cell = (
            <div style={eneStyle}>
              {cell}
              <Tooltip title={t("pendingEnE")}>
                <div style={{ padding: "13px 5px 5px" }}>
                  <EneIcon
                    style={{ color: "rgb(249 168 37)", fontSize: "1.25rem" }}
                  />
                </div>
              </Tooltip>
            </div>
          );
        }
        break;
    }

    return cell;
  };

  function renderTooltipNode(questions: (boolean | null)[]) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "0.85rem",
          color: "rgba(107, 106, 106, 1)",
        }}
      >
        {questions?.map((question, index) => {
          let result = question ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={index}
            >
              <DoneIcon
                style={{
                  color: "rgb(134 189 48)",
                  fontSize: "1rem",
                  stroke: "rgb(134 189 48)",
                  strokeWidth: "3px",
                }}
              />
              <span style={{ padding: "0 0 0 5px" }}>{t(labels[index])}</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={index}
            >
              <CloseIcon
                style={{
                  color: "rgb(212 78 78)",
                  fontSize: "1rem",
                  stroke: "rgb(212 78 78)",
                  strokeWidth: "3px",
                }}
              />
              <span style={{ padding: "0 0 0 5px" }}>{t(labels[index])}</span>
            </div>
          );
          if (question === null)
            result = (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={index}
              >
                <MinimizeIcon
                  style={{
                    color: "rgb(255 255 255)",
                    fontSize: "1rem",
                    stroke: "rgb(255 255 255)",
                    strokeWidth: "3px",
                  }}
                />
                <span style={{ padding: "0 0 0 5px" }}>{t(labels[index])}</span>
              </div>
            );
          return result;
        })}
      </div>
    );
  }

  return renderCell(student, evaluateHandle);
}

export default StudentScoreCell;
