import { useEffect, useState, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import Filters from "./Filters";
import SelectedClasses from "./SelectedClasses";
import Header from "./Header";
import LosThumbList from "./LosThumbList";
import Button from "components/Button";
import moment from "moment";
import {
  allClassesSet,
  selectedClassesSet,
  assignedLessonsSet,
  selectAssignedLessons,
  selectAllClasses,
  selectStartDate,
  selectSelectedClasses,
  IClass,
} from "features/assignments";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectGrade } from "features/content";
import { IAssignLosRequest, useAssignLosMutation } from "services/assignments";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";

const btnStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "20px",
  marginTop: "20px",
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  boxShadow: "0px -3px 0px -2px rgba(224, 224, 224, 1)",
};

const transformSelectedClassData = (allClasses: IClass[]) => {
  return [
    ...allClasses.map((item) => ({
      classId: item.value,
      className: item.label,
      checked: true,
      students: transformStudentData(item.students),
    })),
  ];
};

const transformAllClassesData = (response: IClass[]) => {
  return [
    ...response.map((item) => ({
      value: item.value,
      label: item.label,
      checked: true,
      students: transformStudentData(item.students),
    })),
  ];
};

const transformStudentData = (
  students: { studentId: number; studentName: string }[]
) => {
  return [
    ...students.map((student) => ({
      studentId: student.studentId,
      studentName: student.studentName,
      checked: true,
      status: null,
    })),
  ];
};

interface IAssignmentProps {
  open: boolean;
  setOpen(open: boolean): void;
}
function Assignment({ open, setOpen }: IAssignmentProps) {
  const { t, i18n } = useTranslation();
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  const assignedLessons = useAppSelector(selectAssignedLessons);
  const allClasses = useAppSelector(selectAllClasses);
  const startDate = useAppSelector(selectStartDate);
  const selectedClasses = useAppSelector(selectSelectedClasses);
  const currentGrade = useAppSelector(selectGrade);
  const dispatch = useAppDispatch();
  const [assignLos, { isLoading }] = useAssignLosMutation();
  const toast = useToast();
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    setButtonDisable(true);
    if (selectedClasses.length) {
      selectedClasses.forEach((item) => {
        const students = item.students.filter(
          (student) => student.checked === true
        );
        if (students.length) setButtonDisable(false);
      });
    }
  }, [selectedClasses]);

  const submitHandler = () => {
    const { losList, unitList } = assignedLessons;
    const allSelectedLessons = [...losList];
    if (unitList.length) {
      unitList.forEach((unit) => {
        if (unit?.childLos)
          allSelectedLessons.push.apply(allSelectedLessons, unit?.childLos);
      });
    }
    const _contents = [
      ...allSelectedLessons.map((lesson) => ({
        _contentId: lesson.lessonContentId,
        _type: lesson.type,
      })),
    ];
    const _students: number[] = [];
    selectedClasses.forEach((classObj) => {
      classObj.students.forEach((student) => {
        if (student.checked === true) _students.push(student.studentId);
      });
    });
    const payload: IAssignLosRequest = {
      _contents,
      _students,
      _gradeId: currentGrade?.id as number,
      _startDate: moment(startDate).format("YYYY-MM-DD"),
    };
    submit(payload);
  };

  const logEvents = (payload: IAssignLosRequest) => {
    const ContentId = payload?._contents?.map((content) => content._contentId);
    logEvent("AssignContent", {
      ContentId,
      StudentsId: payload._students,
      AssignmentDate: payload._startDate,
    });
  };

  const submit = async (payload: IAssignLosRequest) => {
    setButtonDisable(true);
    try {
      const data = await assignLos(payload).unwrap();
      if (data._statusCode === 200) {
        toast({
          label: t("success"),
          message: t("Assignments Done Successfully"),
          rtl: i18n.dir() === "rtl",
          type: "success",
        });
        logEvents(payload);
        successCallback();
      } else if (data._statusCode === 619)
        toast({
          label: t("error"),
          message: t("Can't Assign!, acadameic year finished"),
          rtl: i18n.dir() === "rtl",
          type: "error",
          toastId: "InvalidDate",
        });
      else if (data._statusCode === 605) {
        setIsInvalid(true);
        toast({
          label: t("error"),
          message: t("Can't assign to past date"),
          rtl: i18n.dir() === "rtl",
          type: "error",
          toastId: "InvalidDate",
        });
      } else throw data;
    } catch (error) {
      console.log({ error });
    }
    setButtonDisable(false);
  };

  const successCallback = () => {
    let classesData = transformAllClassesData(allClasses);
    let selectedClassesData = transformSelectedClassData(allClasses);
    dispatch(allClassesSet(classesData));
    dispatch(selectedClassesSet(selectedClassesData));
    dispatch(
      assignedLessonsSet({
        losList: [],
        unitList: [],
        assignedLosCount: 0,
      })
    );
    setOpen(false);
  };
  return (
    <>
      <Loader open={isLoading} />
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            height: "90%",
          },
        }}
      >
        <div style={{ margin: "15px", direction: i18n.dir() }}>
          <Header setOpen={setOpen} />
          <LosThumbList />
          <Filters
            resetSelectedDate={open}
            invalidDate={isInvalid}
            resetValid={setIsInvalid}
          />
          <SelectedClasses />
          <div style={btnStyle}>
            <Button
              label={t("Assign")}
              styles={{ width: "260px" }}
              action={submitHandler}
              disable={buttonDisable}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default Assignment;
