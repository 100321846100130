import Content from "./Content";
import Header from "./Header";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  modal: {
    width: "90%",
    maxWidth: "900px",
    position: "absolute",
    heigt: "fit-content",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
});

interface IAssignmentDetailsProps {
  onClose(): void;
  open: boolean;
}
function AssignmentDetails({ onClose, open }: IAssignmentDetailsProps) {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className={classes.modal}>
        <Header onClose={onClose} />
        <Content />
      </div>
    </Modal>
  );
}

export default AssignmentDetails;
