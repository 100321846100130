import { ReactNode } from "react";
import { Modal } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import useDebounce from "hooks/useDebounce";

const useStyles = makeStyles({
  modal: {
    width: "475px",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(98, 195, 238)",
    color: "rgb(255, 255, 255)",
    fontSize: "1rem",
    padding: "0 10px 10px",
    fontWeight: "bold",
    borderRadius: "17px 17px 0 0",
    height: "65px",
    marginBottom: "-15px",
    " & span": { flexGrow: 1, textAlign: "center" },
    " & svg": {
      cursor: "pointer",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "30px 30px 0",
    height: "fit-content",
    width: "100%",
    borderRadius: "17px",
    background: "rgb(255 255 255)",
    color: "rgb(76 76 76)",
    fontSize: "16px",
    textAlign: "center",
  },
});

interface IConfirmMessageProps {
  open: boolean;
  onClose(answer: string): void;
  description: ReactNode;
  title: string;
  "data-test"?: string;
}
function ConfirmMessage({
  open,
  onClose,
  title,
  description,
  ...rest
}: IConfirmMessageProps) {
  const { starting } = useDebounce<void>();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className={classes.modal} {...rest}>
        <div className={classes.header} style={{ direction: i18n.dir() }}>
          <span>{title}</span>
          <CancelRoundedIcon onClick={() => onClose("no")} />
        </div>
        <div className={classes.content}>
          {description}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              width: "100%",
              padding: "30px 0",
              direction: i18n.dir(),
            }}
          >
            <Button
              label={t("Confirm")}
              action={starting(() => onClose("yes"))}
              styles={{ margin: "10px", width: "150px" }}
            />

            <Button
              label={t("Cancel")}
              action={() => onClose("no")}
              white
              styles={{ margin: "10px", width: "150px" }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmMessage;
