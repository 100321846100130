import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface IContent {
  _contentId: string;
  _contentType?: "BASELINE" | "ASSESSMENT_BANK";
}
interface IUIState {
  showHelp: boolean;
  showPlayer: boolean;
  contentIds: IContent[]; // selected content id to play
}

const initialState: IUIState = {
  showHelp: true,
  showPlayer: false,
  contentIds: [],
};
export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleShowHelp(state) {
      state.showHelp = !state.showHelp;
    },
    showPlayerSet(state, action: PayloadAction<boolean>) {
      state.showPlayer = action.payload;
    },
    contentIdsSet(state, action: PayloadAction<IContent[]>) {
      state.contentIds = action.payload;
    },
  },
});

export const selectShowHelp = (state: RootState) => state.ui.showHelp;
export const selectShowPlayer = (state: RootState) => state.ui.showPlayer;
export const selectContentId = (state: RootState) => state.ui.contentIds;
export const { toggleShowHelp, showPlayerSet, contentIdsSet } = uiSlice.actions;
export default uiSlice.reducer;
