import { makeStyles } from "@mui/styles";
import ClearIcon from "@mui/icons-material/ClearRounded";
import { UploadIcon, SendIcon } from "components/SVGs";
import { CircularProgress } from "@mui/material";
import Tooltip from "components/Tooltip";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { getSchema, validate } from "utils/StudentListHelpers";
import { MasterDataContext } from "contexts/MasterDataContext";
import useToast from "hooks/useToast";
import ResultErrors from "./ResultErrors";
import useSendQRcodes from "./useSendQRcodes";
import useFileBuilder, { IFile, IUploadedRow } from "./useFileBuilder";
import { IStudentInfo } from "features/students";
import { IValidationErrors } from "features/common/Interfaces";

const customStyles = {
  spreadSheetUpload: {
    fontSize: "1.25rem",
    "@media screen and (max-width: 1024px)": {
      fontSize: "1rem",
    },
  },
  clearFile: {
    cursor: "pointer",
    color: "rgba(169, 175, 187, 1)",
    stroke: "rgba(169, 175, 187, 1)",
    strokeWidth: "1",
    fontSize: "1rem",
  },
};

interface IUploadSendProps {
  allStudents: IStudentInfo[];
}
function UploadSend({ allStudents }: IUploadSendProps) {
  const sendData = useSendQRcodes();
  const { file, buildFileSelector, setFile } = useFileBuilder();
  const { t, i18n } = useTranslation();
  const [fileSelector, setFileSelector] = useState<HTMLInputElement>();
  const [isSending, setIsSending] = useState(false);
  const { logEvent } = useContext(MasterDataContext);
  const toast = useToast();

  const logQRsSend = (status: string) => {
    logEvent("QRStudentsBulkSend", {
      Status: status,
    });
  };

  useEffect(() => {
    const newfileSelector: HTMLInputElement = buildFileSelector();
    setFileSelector(newfileSelector);
  }, []);

  const useStyles = makeStyles({
    toolTip: {
      border: "1px solid rgba(218, 218, 218, 1)",
      boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
      background: "rgba(255, 255, 255, 1) !important",
      color: "rgba(105, 123, 140, 1) !important",
      fontSize: "12px !important",
      padding: "8px !important",
      textAlign: i18n.dir() === "ltr" ? "left" : "right",
      whiteSpace: "pre-line",
    },
    arrow: {
      color: "rgba(255, 255, 255, 1)",
      "&:before": {
        border: "1px solid transparent",
        color: "rgba(255, 255, 255, 1)",
        borderBottom: "1px solid rgba(218, 218, 218, 1)",
      },
      "&:after": {
        border: "1px solid transparent",
        borderBottom: "1px solid rgba(218, 218, 218, 1)",
      },
    },
    upload: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 2px",
      height: "42px",
      width: "285px",
      backgroundColor: "rgba(248, 248, 248, 1)",
      border: "1px dotted rgba(151, 151, 151, 1)",
      borderRadius: "8px",
      // "@media screen and (max-width: 1024px)": {
      //   width: "55%",
      // },
    },

    placeholder: {
      display: "inline-block",
      fontSize: "14px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "180px",
      color: "#4a4a4a",
      cursor: "default",
      padding: "0 5px",
      textAlign: i18n.dir() === "ltr" ? "left" : "right",
      // "@media screen and (max-width: 1024px)": {
      //   fontSize: "12px",
      //   width: "150px",
      //   padding: "0 5px",
      // },
    },
    uploadIcon: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      cursor: "pointer",
      color: "rgb(255 255 255)",
      fontSize: "14px",
      height: "35px",
      width: "90px",
      backgroundColor: "#62c3ee",
      borderRadius: "7px",
      // "@media screen and (max-width: 1024px)": {
      //   width: "75px",
      //   fontSize: "0.7rem",
      // },
    },
    sending: {
      backgroundColor: "rgb(134 189 48)",
    },
  });

  const classes = useStyles();

  const removeFile = () => {
    setFile({ data: [], fileName: "Upload students file" });
  };

  const uploadFile = () => {
    fileSelector?.click();
  };

  const getNamebyId = (Id: number) => {
    const [first] = allStudents.filter((stu) => stu._id === Id);
    return first && first._name;
  };

  const sendingFileHandler = async () => {
    setIsSending(true);
    try {
      const studentIDs = allStudents.map((stu) => stu._id);
      const schema = getSchema(studentIDs);
      const validData: IUploadedRow[] = [];
      const invalidData: IUploadedRow[] = [];
      const uploadedFile = JSON.parse(JSON.stringify(file)) as IFile;
      uploadedFile.data.forEach((row) => {
        let { student_id, student_name, recipient_email } = row as IUploadedRow;
        if (!student_name) student_name = getNamebyId(student_id as number);
        const rowData: IUploadedRow = {
          student_id,
          student_name,
          recipient_email,
        };
        const errors = validate(schema)(rowData);
        if (Object.keys(errors).length !== 0) {
          const translateErrors: IValidationErrors = {};
          for (const error in errors) {
            translateErrors[error] = t(errors[error]);
          }
          rowData.errors = JSON.stringify(translateErrors);
          invalidData.push(rowData);
        } else {
          validData.push(rowData);
        }
      });

      uploadedFile.invalidData = invalidData;

      setFile(uploadedFile);
      if (validData.length === 0) {
        logQRsSend("Failure");
        toast({
          label: t("error"),
          message: t("Sending failed"),
          rtl: i18n.dir() === "rtl",
          type: "error",
          toastId: "Uploads-has-failed",
        });
      } else {
        logQRsSend("Success");
        await sendData(validData, invalidData);
      }
    } catch (error) {
      console.log(error);
    }
    setIsSending(false);
  };

  const errorsCount = (file?.invalidData && file?.invalidData?.length) || 0;
  return (
    <>
      <div className={classes.upload}>
        {file?.fileName !== "Upload students file" ? (
          <>
            <Tooltip title={file?.fileName ?? ""}>
              <span className={classes.placeholder}>{t(file?.fileName!)}</span>
            </Tooltip>

            <ClearIcon onClick={removeFile} sx={customStyles.clearFile} />
            <div
              className={classNames(
                classes.uploadIcon,
                isSending && classes.sending
              )}
              onClick={sendingFileHandler}
            >
              {isSending ? (
                <CircularProgress
                  size={20}
                  style={{ color: "rgb(255 255 255)" }}
                />
              ) : (
                <SendIcon style={{ fontSize: "1.4rem" }} />
              )}

              <span>{t("Send")}</span>
            </div>
          </>
        ) : (
          <>
            <span className={classes.placeholder}>{t(file?.fileName)}</span>
            <div className={classes.uploadIcon} onClick={uploadFile}>
              <UploadIcon sx={customStyles.spreadSheetUpload} />
              <span>{t("Upload")}</span>
            </div>
          </>
        )}
      </div>
      <ResultErrors errors={errorsCount} uploadedFile={file} />
    </>
  );
}

export default UploadSend;
