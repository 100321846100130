import Joi from "joi";

const passwordLetters = new RegExp(
  "^(?=(?:.*?[-A-Za-z!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~]){6})(?=(?:.*?[-0-9!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~]){2})[-0-9a-zA-Z!\"#$%&'()*+,./:;<=>?@[\\]^_`{|}~]+$"
);

export const ResetPasswordSchema = {
  password: Joi.string()
    .trim()
    .required()
    .pattern(passwordLetters)
    .min(8)
    .max(20)
    .messages({
      "string.empty": "Password is required",
      "string.max": "PasswordLettersValidation",
      "string.min": "PasswordLettersValidation",
      "string.pattern.base": "PasswordValidation",
    }),
  confirm_password: Joi.string().valid(Joi.ref("password")).messages({
    "any.only": "DoesNotMatch",
  }),
};
