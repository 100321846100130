import { useEffect, useState, memo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import "./CircleChartStyle.css";

const fontSize = 1.25;
const duration = 4000;

const useStyles = (props: { steps: number; color: string }) =>
  makeStyles({
    "@keyframes chart-label": {
      "100%": {
        transform: `translateY(-${props.steps * fontSize}rem)`,
        "-webkit-transform": `translateY(-${props.steps * fontSize}rem)`,
      },
    },

    animateCaption: {
      color: props.color,
      "&:after": {
        "-webkit-animation":
          "$chart-label 3s steps(" + props.steps + ") forwards",
        animation: "$chart-label 3s steps(" + props.steps + ") forwards",
        animationDelay: "1s",
        "-webkit-animation-delay": "1s",
        color: props.color,
        display: "none",
      },
    },
    disable: {
      color: props.color,
      position: "absolute",
      top: "45%",
      textAlign: "center",
      left: "45%",
      fontWeight: "bold",
      fontSize: "1.24rem",
    },
  });

function animateValue(setProgress: (value: number) => void, end: number) {
  const start = 0;
  let startTimestamp = 0;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    setProgress(Math.floor(progress * (end - start) + start));
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

interface ICircleChartProps {
  value?: number;
  forground?: string;
  strokeWidth?: string;
  background?: string;
  disable?: boolean;
  animation?: boolean;
}
function CircleChart({
  value = 0,
  forground = "rgb(68, 183, 253)",
  strokeWidth = "10px",
  background = "rgb(234, 234, 234)",
  disable = false,
  animation = true,
}: ICircleChartProps) {
  const chartRef = useRef<SVGCircleElement>(null);
  const [radius, setRadius] = useState(0);
  const [circumference, setCircumference] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const classes = useStyles({ steps: value, color: forground })();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (chartRef.current)
      setRadius(chartRef.current.getBoundingClientRect().width / 2);
  }, [chartRef.current]);

  useEffect(() => {
    if (value && animation) animateValue(setProgress, value);
  }, [value]);

  useEffect(() => {
    const round = 2 * radius * Math.PI;
    setCircumference(round);
    setPercentage((value / 100) * round);
  }, [radius, value]);
  return (
    <figure className="chart animate">
      <svg role="img" xmlns="http://www.w3.org/2000/svg">
        <title></title>
        <desc></desc>
        <circle
          ref={chartRef}
          className="circle-background"
          stroke={background}
          strokeWidth={strokeWidth}
          r="100%"
        />
        {disable ? null : (
          <circle
            className="circle-foreground"
            stroke={forground}
            strokeWidth={strokeWidth}
            strokeDasharray={`${percentage}px ${circumference}px`}
            strokeDashoffset={animation && !isNaN(percentage) ? percentage : 0}
            r="100%"
          />
        )}
      </svg>
      {disable ? (
        <span className={classes.disable}>--</span>
      ) : (
        <figcaption className={classes.animateCaption}>
          {(animation ? progress : value) + "%"}
        </figcaption>
      )}
    </figure>
  );
}

export default memo(CircleChart);
