import { SvgIconProps } from "@mui/material";
import { clearEvaluationVars } from "features/evaluation";
import { History } from "history";
import { MouseEvent } from "react";
import {
  GuidedReadingIcon,
  MainIcon,
  ReportsIcon,
  EvaluationIcon,
} from "../../SVGs/MainMenuIcons";

interface IMenuConstructorProps {
  history: History<unknown>;
  clickHandler(e: MouseEvent<HTMLDivElement | HTMLLIElement>): void;
  logEvent(eventName: string, additionalData?: any): void;
}

export enum MenuItemTypes {
  "leaf" = "leaf",
  "main" = "main",
  "sub" = "sub",
}

export interface IMenuItem {
  label: string;
  name: string;
  path?: string;
  Icon?: (props: SvgIconProps<"svg", {}>) => JSX.Element;
  action?(event?: MouseEvent<HTMLDivElement | HTMLLIElement>): void;
  menuType: MenuItemTypes;
}
export interface ILeafMenuItem extends IMenuItem {
  menuType: MenuItemTypes.leaf;
}

export interface ISubMenuItem extends IMenuItem {
  menuType: MenuItemTypes.sub;
  menuItems: ILeafMenuItem[];
}

interface IMainMenuItem extends IMenuItem {
  menuType: MenuItemTypes.main;
  menuItems: (ISubMenuItem | ILeafMenuItem)[];
}

export type MenuType = ILeafMenuItem | ISubMenuItem | IMainMenuItem;

const menuConstructor = ({
  history,
  clickHandler,
  logEvent,
}: IMenuConstructorProps): MenuType[] => [
  {
    action: () => history.push("/dashboard"),
    label: "Home",
    name: "dashboard",
    Icon: MainIcon,
    menuType: MenuItemTypes.leaf,
  },
  {
    path: "/evaluation",
    label: "Evaluation",
    action: () => {
      logEvent("ClickOnEvaluationSection");
      clearEvaluationVars();
      history.push("/evaluation");
    },
    menuType: MenuItemTypes.leaf,
    name: "evaluation",
    Icon: EvaluationIcon,
  },
  {
    label: "Reports",
    action: clickHandler,
    Icon: ReportsIcon,
    name: "reports",
    menuType: MenuItemTypes.main,
    menuItems: [
      {
        label: "Performance",
        path: "/reports/performance",
        action: () => {
          clearEvaluationVars();
          history.push("/reports/performance/class");
        },
        menuType: MenuItemTypes.sub,
        name: "/reports/performance",
        menuItems: [
          {
            path: "/reports/performance/class",
            label: "Class Performance",
            action: () => {
              clearEvaluationVars();
              history.push("/reports/performance/class");
            },
            menuType: MenuItemTypes.leaf,
            name: "/reports/performance/class",
          },
          {
            path: "/reports/performance/student",
            label: "Student Performance",
            action: () => {
              history.push("/reports/performance/student");
            },
            menuType: MenuItemTypes.leaf,
            name: "/reports/performance/student",
          },
        ],
      },
      {
        label: "GuidedReading",
        path: "/reports/guided-reading",
        Icon: GuidedReadingIcon,
        action: () => {
          clearEvaluationVars();
          history.push("/reports/guided-reading/students-progress");
        },
        name: "guided-reading",
        menuType: MenuItemTypes.sub,
        menuItems: [
          {
            path: "/reports/guided-reading/students-progress",
            label: "Students Progress",
            action: () => {
              history.push("/reports/guided-reading/students-progress");
            },
            menuType: MenuItemTypes.leaf,
            name: "/reports/guided-reading/students-progres",
          },
          {
            path: "/reports/guided-reading/books-progress",
            label: "Books Progress",
            action: () => {
              history.push("/reports/guided-reading/books-progress");
            },
            menuType: MenuItemTypes.leaf,
            name: "/reports/guided-reading/books-progress",
          },
        ],
      },
      {
        path: "/reports/pacing",
        label: "Pacing",
        action: () => {
          history.push("/reports/pacing");
        },
        menuType: MenuItemTypes.leaf,
        name: "/reports/pacing",
      },
    ],
  },
];

export default menuConstructor;
