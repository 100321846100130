import { Grid } from "@mui/material";
import StudentCard from "./StudentCard";
import { useAppSelector } from "app/hooks";
import { selectSelectedDataList, selectStudentList } from "features/students";

const StudentsPrint = () => {
  const studentList = useAppSelector(selectStudentList);
  const selectedDataList = useAppSelector(selectSelectedDataList);
  const data = studentList.filter((stu) => selectedDataList?.includes(stu._id));

  return data.length === 0 ? null : (
    <Grid container sx={{ width: "100%" }}>
      {data.map((item, index) => (
        <Grid
          item
          xs={6}
          key={"key" + index}
          sx={{
            padding: "1.23cm 1cm 1cm",
          }}
        >
          <StudentCard
            name={item._name}
            grade={item._gradeName}
            className={item._className}
            QRcode={item._deepLinkHome}
            studentCode={item._studentUUIDCode}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default StudentsPrint;
