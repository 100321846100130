import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import PieChart from "../PieChart";
import StudentList from "./StudentList";
import AssignmentDetails from "../AssignmentDetails";
import PacingSkeleton from "../PacingSkeleton";
import DataNotFound from "components/DataNotFound";
import { useFetchPacingReportQuery } from "services/reports";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IPacingStudentDetails,
  IReportData,
  reportDataSet,
  selectClass,
  selectedStudentSet,
  selectReportData,
  selectSelectedPlanMonth,
  selectStudentList,
  studentListSet,
} from "features/pacing";
import { useTranslation } from "react-i18next";
import { getPacingReportData } from "utils/ReportsHelpers";

const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: "1.5rem 0.75rem",
  },
  pieContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  percentValue: {
    fontSize: "1rem",
    fontWeight: "bold",
    paddingTop: "15px",
  },
  label: {
    color: "rgba(88, 88, 88, 1)",
    fontWeight: "bold",
    fontSize: "1rem",
    paddingBottom: "14px",
  },
});

function Content() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const reportData = useAppSelector(selectReportData);
  const studentList = useAppSelector(selectStudentList);
  const selectedClass = useAppSelector(selectClass);
  const selectedPlanMonth = useAppSelector(selectSelectedPlanMonth);
  const [openAssignmentPopup, setOpenAssignmentPopup] = useState(false);

  let classes = useStyles();

  const {
    data: pacingReportData,
    isLoading,
    isUninitialized,
  } = useFetchPacingReportQuery(
    {
      classId: selectedClass?.value as number,
      monthName: (selectedPlanMonth?.value as { month: string; year: number })
        ?.month,
      year: (selectedPlanMonth?.value as { month: string; year: number })?.year,
    },
    { skip: !selectedClass?.value }
  );

  useEffect(() => {
    if (
      pacingReportData?._statusCode === 200 &&
      pacingReportData?._entity?._students?.length > 0
    ) {
      const data = getPacingReportData(pacingReportData._entity);
      dispatch(reportDataSet(data?.datareport));
      dispatch(studentListSet(data?.stundentsList ?? []));
    } else {
      dispatch(reportDataSet(undefined));
      dispatch(studentListSet([]));
    }
  }, [pacingReportData]);

  const closeHandler = () => {
    dispatch(selectedStudentSet(undefined));
    setOpenAssignmentPopup(false);
  };

  const compToRender = (
    data: IReportData,
    studentList: [IPacingStudentDetails[], IPacingStudentDetails[]] | []
  ) => (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "30px",
        }}
      >
        <Grid item xs={12}>
          <div className={classes.pieContainer}>
            <span className={classes.label}>{t(data.label)}</span>
            <PieChart pacingData={data} />
            <span
              className={classes.percentValue}
              style={{ color: data.labelColor }}
            >
              {data.value}%
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={{ paddingTop: "30px" }}>
        {studentList.map((data, index) => (
          <StudentList
            data={data}
            key={index}
            openAssignmentDetails={() => setOpenAssignmentPopup(true)}
          />
        ))}
      </Grid>
    </>
  );

  return (
    <div className={classes.root}>
      <AssignmentDetails onClose={closeHandler} open={openAssignmentPopup} />
      {isLoading || isUninitialized ? (
        <PacingSkeleton />
      ) : reportData ? (
        compToRender(reportData, studentList)
      ) : (
        <DataNotFound />
      )}
    </div>
  );
}

export default Content;
