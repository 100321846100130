import { Skeleton } from "@mui/material";

function SectionsSkeleton() {
  return (
    <>
      {Array(3)
        .fill(1)
        .map((_, index) => (
          <Skeleton
            key={"skeSection" + index}
            variant="rectangular"
            sx={{ height: "50px", width: "10%", marginX: "5px" }}
          />
        ))}
    </>
  );
}

export default SectionsSkeleton;
