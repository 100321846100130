import Tooltip from "components/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { NotAssignedIcon, PendingAssignmentIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";

const labels = [
  "question 1",
  "question 2",
  "question 3",
  "question 4",
  "question 5",
  "question 6",
  "question 7",
  "question 8",
  "question 9",
  "question 10",
  "question 11",
  "question 12",
  "question 13",
  "question 14",
  "question 15",
  "question 16",
  "question 17",
  "question 18",
  "question 19",
  "question 20",
  "question 21",
  "question 22",
];
interface IStudentScoreCellProps {
  student: {
    score: string;
    questions: boolean[];
  };
}
function StudentScoreCell({ student }: IStudentScoreCellProps) {
  const { t } = useTranslation();

  const renderCell = (data: { score: string; questions: boolean[] }) => {
    const { score, questions } = data;
    let cell;
    switch (score) {
      case "PENDING":
        cell = (
          <Tooltip title={t("pending")}>
            <div>
              <PendingAssignmentIcon />
            </div>
          </Tooltip>
        );
        break;
      case "NOT_ASSIGNED":
        cell = (
          <Tooltip title={t("notAssigned")}>
            <div>
              <NotAssignedIcon />
            </div>
          </Tooltip>
        );
        break;

      default:
        {
          const value = Number(score);
          const tooltipNode =
            questions && questions.length > 0
              ? renderTooltipNode(questions)
              : "";
          cell = (
            <Tooltip title={tooltipNode}>
              <span
                style={{
                  borderRadius: "50%",
                  height: "15px",
                  width: "15px",
                  backgroundColor:
                    value === 0
                      ? "rgba(212, 78, 78, 1)"
                      : value === 100
                      ? "rgb(134, 189, 48)"
                      : "rgba(250, 150, 69, 1)",
                  position: "absolute",
                }}
              ></span>
            </Tooltip>
          );
        }

        break;
    }

    return cell;
  };

  function renderTooltipNode(questions: boolean[]) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "0.85rem",
          color: "rgba(107, 106, 106, 1)",
        }}
      >
        {questions?.map((question, index) => {
          let result = question ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={index}
            >
              <DoneIcon
                style={{
                  color: "rgb(134 189 48)",
                  fontSize: "1rem",
                  stroke: "rgb(134 189 48)",
                  strokeWidth: "3px",
                }}
              />
              <span style={{ padding: "0 0 0 5px" }}>{t(labels[index])}</span>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
              key={index}
            >
              <CloseIcon
                style={{
                  color: "rgb(212 78 78)",
                  fontSize: "1rem",
                  stroke: "rgb(212 78 78)",
                  strokeWidth: "3px",
                }}
              />
              <span style={{ padding: "0 0 0 5px" }}>{t(labels[index])}</span>
            </div>
          );
          return result;
        })}
      </div>
    );
  }

  return renderCell(student);
}

export default StudentScoreCell;
