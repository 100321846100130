import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import MonthSelect from "../shared/MonthSelect";
import { useFetchClassesOfGradeQuery } from "services/common";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  classSet,
  selectClass,
  selectedPlanMonthSet,
  selectGrade,
} from "features/pacing";
import { toClassOptions } from "utils/ReportsHelpers";
import SelectInput from "components/SelectInput";
import {
  IExtendedOption,
  IMonthOption,
  IOption,
} from "features/common/Interfaces";
import { MasterDataContext } from "contexts/MasterDataContext";

function Toolbar() {
  const { logEvent } = useContext(MasterDataContext);
  const selectedGrade = useAppSelector(selectGrade);
  const selectedClass = useAppSelector(selectClass);
  const [classes, setClasses] = useState<IOption[]>([]);
  const dispatch = useAppDispatch();
  const { data: classesOfGradeData } = useFetchClassesOfGradeQuery(
    selectedGrade?.id as number,
    { skip: !selectedGrade?.id }
  );

  useEffect(() => {
    if (classesOfGradeData?._statusCode === 200) {
      const classes = toClassOptions(classesOfGradeData._entity);
      dispatch(classSet(classes[0]));
      setClasses(classes);
    }
  }, [classesOfGradeData]);

  const getMonth = (value: IMonthOption) => {
    dispatch(selectedPlanMonthSet(value));
    logEvent("PacingReportPeriodFilter", {
      classId: selectedClass?.value,
      selectedMonth: value?.value,
    });
  };

  const filterClassHandler = ({
    currentTarget,
  }: {
    currentTarget: IExtendedOption;
  }) => {
    dispatch(classSet(currentTarget));
    logEvent("PacingReportClassFilter", {
      classId: currentTarget.value,
    });
  };

  return (
    <>
      {classes.length > 0 && (
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          sx={{ padding: "0 0.5rem 0.5rem" }}
        >
          <Grid item xs={3} md={3} xl={2}>
            <SelectInput
              defaultValue={selectedClass?.value ?? classes[0]?.value}
              valueList={classes}
              getOption={filterClassHandler}
            />
          </Grid>
          <Grid item xs={3} md={3} xl={2}>
            <MonthSelect getMonth={getMonth} selectedClass={selectedClass} />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Toolbar;
