import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import SearchBy, { ISearchByProps } from "components/SearchBy";
import { MasterDataContext } from "contexts/MasterDataContext";
import {
  defaultClassOption,
  IStudentInfo,
  selectAllStudents,
  selectClassList,
  selectedClassSet,
  selectSelectedClass,
  studentListSet,
} from "features/students";
import SelectInput from "components/SelectInput";
import { IFilterOption, IOption } from "features/common/Interfaces";

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "0 10px 10px",
  },
};

const studentNameOption = { label: "Student Name", value: "name" };
const studentIdOption = { label: "Student Id", value: "id" };

function Toolbar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedClass = useAppSelector(selectSelectedClass);
  const allStudent = useAppSelector(selectAllStudents);
  const classList = useAppSelector(selectClassList);

  const [searchType, setSearchType] =
    useState<IFilterOption>(studentNameOption);
  const [searchStr, setSearchStr] = useState("");
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    return () => {
      dispatch(selectedClassSet(defaultClassOption));
    };
  }, []);

  useEffect(() => {
    getSearchResult();
    const eventName =
      searchType.value === "name" ? "QRSearchStudentName" : "QRSearchStudentID";
    logEvent(eventName);
  }, [searchType]);

  useEffect(() => {
    getSearchResult();
  }, [searchStr, selectedClass.value]);

  const handleSearch = (text: string) => {
    setSearchStr(text);
  };

  const handleChangeSearch = ({
    currentTarget,
  }: {
    currentTarget: IFilterOption;
  }) => {
    setSearchType(currentTarget);
  };

  const getSearchResult = () => {
    let filteredList = JSON.parse(JSON.stringify(allStudent)).filter(
      (student: IStudentInfo) =>
        student._className === selectedClass.label || selectedClass.value === -1
    ) as IStudentInfo[];
    if (searchStr)
      filteredList = filteredList.filter((item) => {
        const searchKey = ("_" + searchType.value) as keyof IStudentInfo;
        const res = item[searchKey]
          .toString()
          .toLowerCase()
          .includes(searchStr.toLowerCase());
        return res;
      });
    dispatch(studentListSet(filteredList));
  };

  const filterConfig: ISearchByProps = {
    getFilterType: handleChangeSearch,
    getSearchValue: handleSearch,
    selectedFilter: searchType,
    filterTypes: [studentNameOption, studentIdOption].map((opt) => ({
      label: t(opt.label),
      value: opt.value,
    })),
  };

  const onChange = ({ currentTarget }: { currentTarget: IOption }) => {
    dispatch(selectedClassSet(currentTarget));
    logEvent("QRFilterClass");
  };

  return (
    <Grid container spacing={2} sx={customStyles.root}>
      <Grid item xs={4} md={3} xl={2}>
        <SelectInput
          defaultValue={selectedClass?.value}
          valueList={[
            { label: t(classList?.[0]?.label), value: classList?.[0]?.value },
            ...classList?.slice(1),
          ]}
          getOption={onChange}
        />
      </Grid>
      <Grid item xs={4}>
        <SearchBy {...filterConfig} />
      </Grid>
    </Grid>
  );
}

export default Toolbar;
