import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IAssessmentAssets } from "services/evaluation";
import { IPagination } from "../common/Interfaces";
import { IStudentDetails } from "../evaluation";

export interface ICriteriaOption {
  [key: number]: number | null;
}
export interface IPercentageCalOption {
  [key: number]: string;
}
export interface IScoreOption {
  id: number;
  textAr: string;
  weight: number;
}
export interface ICriterionOption {
  id: number;
  textAr: string;
  selectedOptionId: number | null;
  scores: IScoreOption[];
}
export enum AssetTypes {
  "AUDIO" = "AUDIO",
  "VIDEO" = "VIDEO",
  "IMAGE" = "IMAGE",
}
interface IAssessmentState {
  currentStudent?: number | "all";
  criteriaMap: ICriteriaOption;
  originalMap: ICriteriaOption;
  percentageCalMap: IPercentageCalOption[];
  criterion: ICriterionOption[];
  overAllPercentage: number;
  assets: IAssessmentAssets[];
  description: string;
  changeDesc: string;
  pagination: IPagination;
  studentInfo?: IStudentDetails;
}
const initialState: IAssessmentState = {
  criteriaMap: {},
  originalMap: {},
  percentageCalMap: [],
  criterion: [],
  overAllPercentage: 0,
  assets: [],
  description: "",
  changeDesc: "",
  pagination: { currentPage: 1, totalPages: 1 },
};
export const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    currentStudentSet(state, action: PayloadAction<number | "all">) {
      state.currentStudent = action.payload;
    },
    criteriaMapSet(state, action: PayloadAction<ICriteriaOption>) {
      state.criteriaMap = action.payload;
    },
    originalMapSet(state, action: PayloadAction<ICriteriaOption>) {
      state.originalMap = action.payload;
    },
    percentageCalMapSet(state, action: PayloadAction<IPercentageCalOption[]>) {
      state.percentageCalMap = action.payload;
    },
    criterionSet(state, action: PayloadAction<ICriterionOption[]>) {
      state.criterion = action.payload;
    },
    overAllPercentageSet(state, action: PayloadAction<number>) {
      state.overAllPercentage = action.payload;
    },
    assetsSet(state, action: PayloadAction<IAssessmentAssets[]>) {
      state.assets = action.payload;
    },
    descriptionSet(state, action: PayloadAction<string>) {
      state.description = action.payload;
    },
    changeDescSet(state, action: PayloadAction<string>) {
      state.changeDesc = action.payload;
    },
    paginationSet(state, action: PayloadAction<IPagination>) {
      state.pagination = action.payload;
    },
    totalPagesSet(state, action: PayloadAction<number>) {
      state.pagination.totalPages = action.payload;
    },
    currentPageSet(state, action: PayloadAction<number>) {
      state.pagination.currentPage = action.payload;
    },
    studentInfoSet(state, action: PayloadAction<IStudentDetails>) {
      state.studentInfo = action.payload;
    },
    reset(state) {
      return initialState;
    },
  },
});

export const selectCurrentStudent = (state: RootState) =>
  state.assessment.currentStudent;
export const selectCriteriaMap = (state: RootState) =>
  state.assessment.criteriaMap;
export const selectOriginalMap = (state: RootState) =>
  state.assessment.originalMap;
export const selectPercentageCalMap = (state: RootState) =>
  state.assessment.percentageCalMap;
export const selectCriterion = (state: RootState) => state.assessment.criterion;
export const selectOverAllPercentage = (state: RootState) =>
  state.assessment.overAllPercentage;
export const selectAssets = (state: RootState) => state.assessment.assets;
export const selectDescription = (state: RootState) =>
  state.assessment.description;
export const selectChangeDesc = (state: RootState) =>
  state.assessment.changeDesc;
export const selectPagination = (state: RootState) =>
  state.assessment.pagination;
export const selectStudentInfo = (state: RootState) =>
  state.assessment.studentInfo;

export const {
  currentStudentSet,
  criteriaMapSet,
  originalMapSet,
  percentageCalMapSet,
  criterionSet,
  overAllPercentageSet,
  assetsSet,
  descriptionSet,
  currentPageSet,
  totalPagesSet,
  studentInfoSet,
  paginationSet,
  changeDescSet,
  reset,
} = assessmentSlice.actions;

export default assessmentSlice.reducer;
