import { useContext } from "react";
import { PrintAllQRIcon } from "components/SVGs";
import { makeStyles } from "@mui/styles";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";

const useStyles = makeStyles({
  print: {
    display: "flex",
    alignItems: "center",
    color: "rgba(74, 74, 74, 1)",
    "& svg": {
      fontSize: "1.8rem",
      margin: "0 10px",
      color: "rgba(98, 195, 238, 1)",
      cursor: "pointer",
      "@media screen and (max-width: 1024px)": {
        fontSize: "1.5rem",
      },
    },
  },
});

interface IPrintQRProps {
  listCount: number;
  getIsPrinting(printing: boolean): void;
}
function PrintQR({ listCount, getIsPrinting }: IPrintQRProps) {
  const { t, i18n } = useTranslation();
  const { logEvent } = useContext(MasterDataContext);
  const toast = useToast();

  const prtingHandler = () => {
    if (listCount === 0) {
      toast({
        label: t("Note"),
        message: t("Select students to print QRcodes"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "student-not-selected",
      });
      return;
    }
    getIsPrinting(true);
    logEvent("QRPrint", {
      Source: "Print QR",
    });
    const printTime = listCount * 70;
    const timeInterval = printTime < 2000 ? 2000 : printTime;
    setTimeout(() => {
      window.print();
    }, timeInterval);
    setTimeout(() => {
      getIsPrinting(false);
    }, timeInterval + 2500);
  };

  const classes = useStyles();
  return (
    <div className={classes.print}>
      <PrintAllQRIcon onClick={prtingHandler} />
      <span>{t("Print QR Code")}</span>
    </div>
  );
}

export default PrintQR;
