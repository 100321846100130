import { useRef, useEffect } from "react";

function usePrevious(props: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = props;
  }, [props]);
  return ref.current;
}

export default usePrevious;
