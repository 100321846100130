import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { moeViewSet, selectMoeVeiw } from "features/content";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
  },
  wrapper: {
    display: "flex",
    borderRadius: "6px",
    border: "1px solid rgb(236, 234, 236)",
    padding: "2px",
    background: "rgba(236, 234, 236, 1)",
  },
  filter: {
    padding: "5px 15px",
    cursor: "pointer",
  },
  activeFilter: {
    background: "rgba(255 255 255)",
    borderRadius: "6px",
    cursor: "default",
  },
});

function DomainFilter() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { logEvent } = useContext(MasterDataContext);
  const moeViewState = useAppSelector(selectMoeVeiw);

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div
          className={classNames(
            classes.filter,
            !moeViewState && classes.activeFilter
          )}
          onClick={() => {
            dispatch(moeViewSet(false));
            logEvent("LanguageDomainView");
          }}
        >
          {t("Domains")}
        </div>
        <div
          className={classNames(
            classes.filter,
            moeViewState && classes.activeFilter
          )}
          onClick={() => {
            dispatch(moeViewSet(true));
            logEvent("MinistryBookView");
          }}
        >
          {t("Moe")}
        </div>
      </div>
    </div>
  );
}

export default DomainFilter;
