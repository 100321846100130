import { makeStyles } from "@mui/styles";
import { Skeleton, Grid } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    padding: "26px 0",
  },
});

function PieChartSkeleton() {
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className={classes.root}>
          <Skeleton
            width="30%"
            variant="text"
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              margin: "0 auto",
              top: 0,
            }}
          />
          <Skeleton variant="circular" width={180} height={180} />
          <Skeleton
            width="30%"
            variant="text"
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              margin: "0 auto",
              bottom: 0,
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default PieChartSkeleton;
