import {
  ICriterionOption,
  ICriteriaOption,
  IPercentageCalOption,
} from "features/assessment";
import { IOption, IOptionWithList } from "features/common/Interfaces";
import { IFetchClassesEntity } from "services/common";

export const createSelectResponse = (
  data: IFetchClassesEntity[]
): IOptionWithList[] =>
  data.map(({ _classId, _className, _students }) => ({
    label: _className,
    value: _classId,
    students: _students.map(({ _studentId, _studentName }) => ({
      label: _studentName,
      value: _studentId,
    })),
  }));

export interface ICriterion {
  id: number;
  selectedOptionId: number;
  textAr: string;
  scores: {
    id: number;
    textAr: string;
    weight: number;
  }[];
}
export const mapStudentScore = (data: ICriterion[]) => {
  let criteriaCellMapping: ICriteriaOption = {};
  let percentageArray: IPercentageCalOption[] = [];

  data?.forEach((criteria) => {
    criteriaCellMapping[criteria.id] = criteria.selectedOptionId;
    percentageArray.push({ [criteria.id]: createScoreLabel(criteria) });
  });

  return { criteriaCellMapping, percentageArray };
};

export const allOptionsNotSelected = (
  criteriaMap: ICriteriaOption,
  originalMap: ICriteriaOption,
  changeDesc: string,
  description: string
) => {
  const checkNullValues = Object.values(criteriaMap).some((el) => !el);

  if (checkNullValues) return true;
  else if (
    JSON.stringify(criteriaMap) === JSON.stringify(originalMap) &&
    changeDesc === description
  ) {
    return true;
  }
  return false;
};

export const calculateAveragePercentage = (
  percentageCalMap: IPercentageCalOption[]
) => {
  let overallScore: number[] = [];
  percentageCalMap?.forEach((value) => {
    const percentData: string = Object.values(value)[0];
    let [score, noOfOptions] = percentData.split("--");
    let optionWisePercentage = (+score / +noOfOptions) * 100;
    overallScore.push(optionWisePercentage);
  });
  return arrAvg(overallScore);
};

const arrAvg = (arr: number[]) => arr.reduce((a, b) => a + b, 0) / arr.length;

export const getScore = (
  criteriaItem: ICriterionOption,
  criteriaMap: ICriteriaOption
) => {
  return criteriaMap[criteriaItem.id] === null
    ? { mark: 0, total: criteriaItem.scores.length }
    : {
        mark: criteriaItem.scores.filter(
          (item) => item.id === criteriaMap[criteriaItem.id]
        )[0].weight,
        total: criteriaItem.scores.length,
      };
};

function createScoreLabel(criteria: ICriterion): string {
  return criteria.selectedOptionId
    ? criteria.scores.filter((item) => item.id === criteria.selectedOptionId)[0]
        .weight +
        "--" +
        criteria.scores.length
    : 0 + "--" + criteria.scores.length;
}

export function createUpdatedCriterias(
  criteriaMap: ICriteriaOption,
  percentageCalMap: IPercentageCalOption[],
  criteriaItem: ICriterionOption,
  optionDetails: IOption
) {
  let updatedCriteriaMap: ICriteriaOption = JSON.parse(
    JSON.stringify(criteriaMap)
  );
  let updatedPercentageCalMap: IPercentageCalOption[] = JSON.parse(
    JSON.stringify(percentageCalMap)
  );
  updatedCriteriaMap[criteriaItem.id] = optionDetails.value as number;
  updatedPercentageCalMap.forEach((value) => {
    if (Number(Object.keys(value)[0]) === criteriaItem.id) {
      value[criteriaItem.id] =
        criteriaItem.scores.filter((item) => item.id === optionDetails.value)[0]
          .weight +
        "--" +
        criteriaItem.scores.length;
    }
  });
  return { updatedCriteriaMap, updatedPercentageCalMap };
}
