import { useAppDispatch, useAppSelector } from "app/hooks";
import SelectInput from "components/SelectInput";
import { GradeCods, IExtendedOption } from "features/common/Interfaces";
import {
  GrGroupType,
  selectSelectedGradeGroup,
  setSelectedGrGroup,
} from "features/filters";
import { useTranslation } from "react-i18next";

const GrLevelGroups = () => {
  const disptach = useAppDispatch();
  const { t } = useTranslation();
  const selectedGradeGroup = useAppSelector(selectSelectedGradeGroup);

  const getSelectedGroup = (selectedOption: {
    currentTarget: IExtendedOption;
  }) => {
    disptach(
      setSelectedGrGroup(selectedOption.currentTarget.value as GrGroupType)
    );
  };

  const gradeOptions = [
    { value: "all", label: t("all levels") },
    { value: GradeCods.kg1, label: t(GradeCods.kg1) },
    { value: GradeCods.kg2, label: t(GradeCods.kg2) },
    { value: GradeCods.g1, label: t(GradeCods.g1) },
    { value: GradeCods.g2, label: t(GradeCods.g2) },
    { value: GradeCods.g3, label: t(GradeCods.g3) },
    { value: GradeCods.g4, label: t(GradeCods.g4) },
  ];
  return (
    // <>
    //   <label
    //     style={{
    //       whiteSpace: "nowrap",
    //       margin: "0 5px",
    //       fontWeight: "bold",
    //     }}
    //   >
    //     {t("filter by Grade")}
    //   </label>
    <SelectInput
      defaultValue={selectedGradeGroup ?? gradeOptions[0].value}
      valueList={gradeOptions}
      getOption={getSelectedGroup}
    />
    // </>
  );
};

export default GrLevelGroups;
