import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Button from "components/Button";
import DataNotFound from "components/DataNotFound";
import { ExamsIcon } from "components/SVGs/Exams";
import {
  IExam,
  IExamFilter,
  loadEaxms,
  loadFilters,
  selectedGradeSet,
  selectExams,
} from "features/exams";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetchExamsQuery } from "services/exams";
import ExamsTable from "./ExamsTable";
import ExamsSkeleton from "./ExamsSkeleton";
import CreateExam from "./CreateExam";
import { selectGrade } from "features/content";
import {
  ILevelFilter,
  selectExamsFilters,
  selectGrades,
} from "features/filters";
import { MasterDataContext } from "contexts/MasterDataContext";
const customStyles = {
  content: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "column",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "0 20px 10px",
    borderBottom: "1px solid rgb(241 242 246)",
  },
  createButton: { width: "100px", height: "30px", fontSize: "12px" },
  assessmentList: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "10px",
  },
};

const Exams = () => {
  const { logEvent } = useContext(MasterDataContext);

  const exams = useAppSelector(selectExams);
  const dispatch = useAppDispatch();
  const selectedGrade = useAppSelector(selectGrade);
  const examsFilters = useAppSelector(selectExamsFilters);
  const grades = useAppSelector(selectGrades);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { data: examsList, isLoading } = useFetchExamsQuery(
    selectedGrade?.id as number,
    { skip: !selectedGrade?.id }
  );

  useEffect(() => {
    if (examsList?._statusCode === 200) {
      const updateList: IExam[] = examsList._entity.map((exam) => ({
        id: exam._id,
        type: exam._assessmentType,
        name: exam._title,
        questions: exam._noOfQuestions,
        grade: exam._gradeCode,
      }));
      dispatch(loadEaxms(updateList));
    } else {
      dispatch(loadEaxms([]));
    }
  }, [examsList]);

  useEffect(() => {
    if (examsFilters) {
      const fetchedfilters: IExamFilter[] = [];
      Object.entries(examsFilters).forEach(
        ([gradeId, filters]: [string, ILevelFilter[]]) =>
          fetchedfilters.push({
            gradeId,
            greade: grades.filter((g) => g.id.toString() === gradeId)[0].code,
            filters: filters.map((f) => ({
              code: f.code,
              title: f.title,
              icon: f.code,
              subFilters: f.subGroups?.map((subf) => ({
                code: subf.code,
                title: subf.title,
              })),
            })),
          })
      );
      dispatch(loadFilters(fetchedfilters));
    }
  }, [examsFilters]);

  useEffect(() => {
    if (selectedGrade) dispatch(selectedGradeSet(selectedGrade));
  }, [selectedGrade]);

  return (
    <Typography component="div" sx={customStyles.content}>
      <Typography component="div" sx={customStyles.toolbar}>
        <Button
          label={t("create exam")}
          action={() => {
            logEvent("ClickOnCreateNewAB");
            setOpen(true);
          }}
          styles={customStyles.createButton}
        />
      </Typography>
      <Typography component="div" sx={customStyles.assessmentList}>
        {isLoading ? (
          <ExamsSkeleton />
        ) : exams.length ? (
          <ExamsTable
            list={exams.filter((item) => item.grade === selectedGrade?.code)}
          />
        ) : (
          <DataNotFound text={t("no exams")} icon={<ExamsIcon />} />
        )}
      </Typography>
      <CreateExam open={open} setOpen={setOpen} />
    </Typography>
  );
};

export default Exams;
