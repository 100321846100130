import { useContext, useEffect, useState } from "react";
import QRDialog from "./QRDialog";
import { Grid, Checkbox } from "@mui/material";
import { SendEmailIcon, PassCodeIcon } from "components/SVGs/index";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import QRSend from "./QRSend";
import DataTable from "components/DataTable";
import Pagination from "components/Pagination";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import {
  IStudentInfo,
  selectAllSet,
  selectedDataListSet,
  selectSelectAll,
  selectSelectedDataList,
  selectStudentList,
} from "features/students";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { IPagination, IStudent } from "features/common/Interfaces";
import { ITableHeadColumn } from "components/DataTable/EnhancedTableHead";

const customStyles = {
  checkBox: {
    width: "16px",
    height: "16px",
  },
};

const initPaginationState: IPagination = {
  currentPage: 1,
  totalPages: 1,
  pageSize: 30,
};

export interface IStudentQRcode {
  studentName: string;
  gradeName: string;
  className: string;
  qrCode: string;
  studentCode: string;
}
function StudentsList() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectAll = useAppSelector(selectSelectAll);
  const studentList = useAppSelector(selectStudentList);
  const selectedDataList = useAppSelector(selectSelectedDataList);
  const [openSendQRcode, setOpenSendQRcode] = useState(false);
  const [openShowQRcode, setOpenShowQRcode] = useState(false);
  const [studentQRcode, setStudentQRcode] = useState<
    IStudent | IStudentQRcode
  >();
  const [pagination, setPagination] =
    useState<IPagination>(initPaginationState);
  const [tableData, setTableBody] = useState<
    (string | number | JSX.Element)[][]
  >([]);
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    getViewDataList(pagination.currentPage);
  }, [studentList, pagination.currentPage, selectedDataList]);

  const viewHeaders = (): ITableHeadColumn[] => [
    { id: "checkAllBox", label: checkAllBox(selectAll), width: "5%" },
    { id: "id", label: t("Student Id"), width: "10%" },
    { id: "studentName", label: t("Student Name"), width: "25%" },
    { id: "grade", label: t("Grade"), width: "20%" },
    { id: "classes", label: t("Class"), width: "20%" },
    { id: "schoolCode", label: t("QRcode"), width: "10%" },
    { id: "actions", label: t("Email"), width: "10%" },
  ];

  const handleChangePage = (currentPage: number) => {
    setPagination({ ...pagination, currentPage });
  };

  const getViewDataList = (currentPage = 1) => {
    let students = JSON.parse(JSON.stringify(studentList)) as IStudentInfo[];
    let page = currentPage;
    let startIndex = (page - 1) * pagination.pageSize!;
    // check if current page is greater than student list length and do necessary pagination setup
    if (students.length <= startIndex) {
      startIndex = 0;
      page = 1;
    }
    const endIndex = page * pagination.pageSize!;
    const total = students.length;
    const totalPages =
      total === 0 ? 1 : Math.ceil(total / pagination.pageSize!);
    students = students.slice(startIndex, endIndex);
    let tbStudentList: (string | number | JSX.Element)[][] = [];
    if (students?.length > 0) {
      tbStudentList = students.map((item) => {
        const { _id, _name, _gradeName, _className } = item;
        return [
          renderCheckBox(_id),
          _id,
          _name,
          _gradeName,
          _className,
          renderQRBtn(item),
          renderSendEmailBtn(item),
        ];
      });
    }
    setTableBody(tbStudentList);
    setPagination({ ...initPaginationState, totalPages, currentPage: page });
  };

  const checkAllBox = (checked: boolean) => (
    <Checkbox
      key="checkAllBox"
      onChange={handleToggleAll}
      checked={checked}
      color="primary"
      icon={<CheckBoxOutlineBlankIcon sx={customStyles.checkBox} />}
      checkedIcon={<CheckBoxIcon sx={customStyles.checkBox} />}
    />
  );

  const handleToggle = (value: number) => {
    const currentIndex = selectedDataList.indexOf(value);
    const newChecked = JSON.parse(JSON.stringify(selectedDataList));
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    const newSelectAll = newChecked.length === studentList.length;
    dispatch(selectAllSet(newSelectAll));
    dispatch(selectedDataListSet(newChecked));
  };

  const handleToggleAll = () => {
    if (!selectAll) {
      const newChecked = JSON.parse(JSON.stringify(selectedDataList));
      studentList?.forEach((item) => {
        const currentIndex = selectedDataList.indexOf(item._id);
        if (currentIndex === -1) {
          newChecked.push(item._id);
        }
      });
      dispatch(selectAllSet(true));
      dispatch(selectedDataListSet(newChecked));
    } else {
      dispatch(selectAllSet(false));
      dispatch(selectedDataListSet([]));
    }
  };

  const handleSendStuEmail = (stu: IStudent) => {
    logEvent("QRStudentEmailClick");
    setOpenSendQRcode(true);
    setStudentQRcode(stu);
  };

  const renderCheckBox = (id: number) => {
    const checked = selectedDataList.indexOf(id) > -1;
    return (
      <Checkbox
        key={id}
        onChange={() => handleToggle(id)}
        checked={checked}
        color="primary"
        icon={<CheckBoxOutlineBlankIcon sx={customStyles.checkBox} />}
        checkedIcon={<CheckBoxIcon sx={customStyles.checkBox} />}
      />
    );
  };

  const QRDialogOpen = (studentData: IStudentInfo) => {
    logEvent("QRView");
    const qrData = {
      studentName: studentData._name,
      gradeName: studentData._gradeName,
      className: studentData._className,
      qrCode: studentData._deepLinkHome,
      studentCode: studentData._studentUUIDCode,
    };
    setOpenShowQRcode(true);
    setStudentQRcode(qrData);
  };

  return (
    <>
      <Grid container sx={{ "@media print": { display: "none" } }}>
        <Grid item xs={12}>
          <DataTable tableHead={viewHeaders()} tableData={tableData} />
          <Pagination
            total={pagination.totalPages}
            getPage={handleChangePage}
            defaultPage={pagination.currentPage}
            spreadStyle
          />
        </Grid>
      </Grid>
      <QRDialog
        open={openShowQRcode}
        onClose={() => {
          setOpenShowQRcode(false);
          setStudentQRcode(undefined);
        }}
        data={studentQRcode as IStudentQRcode}
      />
      <QRSend
        open={openSendQRcode}
        onClose={() => {
          setOpenSendQRcode(false);
          setStudentQRcode(undefined);
        }}
        data={studentQRcode as IStudent}
      />
    </>
  );

  function renderQRBtn(item: IStudentInfo) {
    return (
      <PassCodeIcon
        style={{ height: "22px", cursor: "pointer" }}
        onClick={() => QRDialogOpen(item)}
      />
    );
  }

  function renderSendEmailBtn(item: IStudentInfo) {
    return (
      <SendEmailIcon
        key={"email" + item._id}
        onClick={() => handleSendStuEmail({ id: item._id, name: item._name })}
        sx={{ cursor: "pointer", height: "16px" }}
      />
    );
  }
}

export default StudentsList;
