import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IGrade, IOption, LessonTypes } from "../common/Interfaces";
import { IFilter, ILevelFilter } from "../filters";

export interface ILo {
  file?: string;
  loId: number | string;
  loCode: number | string;
  moeLoCode?: string;
  loTitle: string;
  lessonContentId: string;
  isOfflineEvaluated: boolean;
  thumbUrl: string;
  loStatus?: string;
  hasEne?: boolean;
  type: LessonTypes;
  assignedClasses: string[];
  tags?: string[];
  unitId?: string;
  unitName?: string;
}

export interface IUnit {
  unitId: string;
  unitName: string;
  childLos: ILo[];
}

export interface ITag {
  checked?: boolean;
  code: string;
  id: number;
  name: string;
}

interface IContentState {
  grade?: IGrade;
  section: LessonTypes;
  levelOneFilter?: ILevelFilter;
  levelTwoFilter?: IFilter;
  moeView: boolean;
  losList: ILo[];
  unitList: IUnit[];
  page: number;
  totalPages: number;
  searchBy?: IOption;
  search?: string | ITag[];
}

const initialState: IContentState = {
  section: LessonTypes.LESSON,
  moeView: false,
  losList: [],
  unitList: [],
  page: 1,
  totalPages: 1,
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    gradeSet(state: IContentState, action: PayloadAction<IGrade>) {
      state.grade = action.payload;
    },
    sectionSet(state: IContentState, action: PayloadAction<LessonTypes>) {
      state.section = action.payload;
    },
    levelOneFilterSet(
      state: IContentState,
      action: PayloadAction<ILevelFilter>
    ) {
      state.levelOneFilter = action.payload;
    },
    levelTwoFilterSet(state: IContentState, action: PayloadAction<IFilter>) {
      state.levelTwoFilter = action.payload;
    },
    moeViewSet(state: IContentState, action: PayloadAction<boolean>) {
      state.moeView = action.payload;
    },
    losListLoaded(state: IContentState, action: PayloadAction<ILo[]>) {
      state.losList = action.payload;
    },
    unitListLoaded(state: IContentState, action: PayloadAction<IUnit[]>) {
      state.unitList = action.payload;
    },
    pageSet(state: IContentState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    totalPagesSet(state: IContentState, action: PayloadAction<number>) {
      state.totalPages = action.payload;
    },
    searchBySet(state: IContentState, action: PayloadAction<IOption>) {
      state.searchBy = action.payload;
    },
    searchSet(state: IContentState, action: PayloadAction<string | ITag[]>) {
      state.search = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.content.grade;
export const selectSection = (state: RootState) => state.content.section;
export const selectPage = (state: RootState) => state.content.page;
export const selectTotalPages = (state: RootState) => state.content.totalPages;
export const selectSearchBy = (state: RootState) => state.content.searchBy;
export const selectSearch = (state: RootState) => state.content.search;
export const selectMoeVeiw = (state: RootState) => state.content.moeView;
export const selectLoList = (state: RootState) => state.content.losList;
export const selectUnitList = (state: RootState) => state.content.unitList;
export const selectLevelOneFilter = (state: RootState) =>
  state.content.levelOneFilter;
export const selectLevelTwoFilter = (state: RootState) =>
  state.content.levelTwoFilter;

export const {
  gradeSet,
  sectionSet,
  levelOneFilterSet,
  levelTwoFilterSet,
  moeViewSet,
  losListLoaded,
  unitListLoaded,
  pageSet,
  totalPagesSet,
  searchBySet,
  searchSet,
} = contentSlice.actions;

export default contentSlice.reducer;
