import { Typography } from "@mui/material";
import { useEffect, useState } from "react";

const customStyles = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    "@keyframes fade": {
      "0%,100%": {
        opacity: 0,
      },
      "50% ": {
        opacity: 1,
      },
    },
    animation: "fade 3s alternate",
    opacity: 0,
    fontSize: "18px",
    fontWeight: "bold",
    color: "rgb(105 123 140)",
    padding: "0 15px",
  },
  dots: {
    position: "relative",
    width: "6px",
    height: "6px",
    borderRadius: "3px",
    backgroundColor: "rgb(60 180 229)",
    color: "rgb(60 180 229)",
    animation: "dotFlashing 1s infinite linear alternate",
    animationDelay: ".5s",
    "&:before,&:after": {
      content: "''",
      display: "inline-block",
      position: "absolute",
      top: 0,
    },
    "&:before": {
      left: "-9px",
      width: "6px",
      height: "6px",
      borderRadius: "3px",
      backgroundColor: "rgb(60 180 229)",
      color: "#9880ff",
      animation: "dotFlashing 1s infinite alternate",
      animationDelay: "0s",
    },
    "&:after": {
      left: "9px",
      width: "6px",
      height: "6px",
      borderRadius: "3px",
      backgroundColor: "rgb(60 180 229)",
      color: "rgb(60 180 229)",
      animation: "dotFlashing 1s infinite alternate",
      animationDelay: "1s",
    },
    "@keyframes dotFlashing": {
      "0%": {
        backgroundColor: "rgb(60 180 229)",
      },
      "50%,100%": {
        backgroundColor: "rgba(105 123 140/20%)",
      },
    },
  },
};
type ITextCarouselProps = {
  texts: string[];
};

function TextCarousel({ texts }: ITextCarouselProps) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const handle = setInterval(() => {
      const updateIndex = (a: number) => (a + 1) % texts.length;
      setIndex((prevState) => updateIndex(prevState));
    }, 3050);

    return () => {
      clearInterval(handle);
    };
  }, []);

  return (
    <div style={customStyles.root}>
      <Typography component="span" sx={customStyles.text} key={index}>
        {texts[index]}
      </Typography>
      {/* <Typography
        component="span"
        sx={customStyles.dots}
        key={"dots" + index}
      ></Typography> */}
    </div>
  );
}

export default TextCarousel;
