import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import StudentList from "./StudentList";
import DomainCard from "./DomainCard";
import DataNotFound from "components/DataNotFound";
import { IOption } from "features/common/Interfaces";
import { IDomainReport, IStudent } from "features/classPerformance";

const useStyles = makeStyles({
  root: {
    backgroundColor: "rgb(255, 255, 255, 255)",
    padding: "60px 0",
  },
  printableTitle: {
    display: "flex",
    fontWeight: "bold",
    justifyContent: "center",
    fontSize: "26px",
    marginBottom: "50px",
    marginTop: "15px",
    width: "100%",
  },
});

interface IPrintReportProps {
  reportData: IDomainReport[];
  studentsInfo: IStudent[];
  selectedClass: IOption;
}
function PrintReport({
  reportData,
  studentsInfo,
  selectedClass,
}: IPrintReportProps) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.root}>
      <h1 className={classes.printableTitle}>{selectedClass.label}</h1>
      {reportData?.length ? (
        <>
          <DomainCard reportData={reportData} />
          <StudentList reportData={reportData} studentsInfo={studentsInfo} />
        </>
      ) : (
        <DataNotFound />
      )}
    </Grid>
  );
}

export default PrintReport;
