import { API, IResponse } from "./API";

interface IParentDetails {
  _createdAt: string;
  _email: string;
  _id: number;
  _name: string;
  _status: number;
  _token: string;
  _type: "PARENT";
  _updatedAt: string;
}

export interface IStudentDetails {
  _avatarUrl: string | null;
  _classId: number;
  _className: string;
  _deepLinkHome: string;
  _dob: string;
  _gender: number;
  _gradeId: number;
  _gradeName: string;
  _id: number;
  _name: string;
  _parent: IParentDetails | null;
  _pin: string | null;
  _studentSchoolUid: string;
  _studentUUIDCode: string;
}

interface IFetchStudentsResponse extends IResponse {
  _entity: IStudentDetails[] | null;
}

const studentsQrCodeServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchTeacherStudents: build.query<IFetchStudentsResponse, string>({
        query(teacherId) {
          return `/ams/teacher/${teacherId}/student`;
        },
      }),
      fetchClassStudents: build.query<IFetchStudentsResponse, string>({
        query(classId) {
          return `/ams/class/${classId}/student`;
        },
      }),
    };
  },
});

export const { useFetchClassStudentsQuery, useFetchTeacherStudentsQuery } =
  studentsQrCodeServices;
