import { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { ArrowDropDownIcon } from "components/SVGs";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  List,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import { IClassStudents } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
    width: "100%",
    padding: "3px",
    "& label": {
      fontWeight: "bold",
    },
    "& *": {
      textAlign: "initial",
      fontSize: "16px",
    },
  },
  container: {
    overflow: "hidden",
    fontSize: "16px",
    borderRadius: "6px",
    alignItems: "center",
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    transition: "all 100ms",
    cursor: "pointer",
    padding: "5px",
    position: "relative",
    paddingLeft: "30px",
    height: "38px",
    zIndex: 6,
    border: "1px solid rgb(217,216,216)",
    "&:focus-visible, &:focus, &:hover": {
      borderColor: "rgb(98 195 238)",
      outline: "rgb(98 195 238)",
    },
    width: "100%",
  },
  dropdownIcon: {
    width: "25px",
    position: "absolute",
    top: 0,
    bottom: 0,

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dropDown: {
    border: "1px solid rgb(98 195 238)",
    background: "rgb(255 255 255)",
    borderRadius: "8px",
    position: "absolute",
    top: 0,
    height: "fit-content",
    width: "100%",
    padding: "45px 5px 5px",
    zIndex: 5,
  },
  list: {
    width: "100%",
    padding: "5px",
    maxHeight: "225px",
    overflow: "auto",
    "& > div": {
      borderRadius: "4px",
      padding: "4px 8px",
      margin: "2px 0",
    },
    "&> div:active": {
      background: "rgb(244 244 244)",
    },
  },
  optionItems: {
    right: 0,
    width: "100%",
    minWidth: "fit-content",
    whiteSpace: "nowrap",
    position: "absolute",
    top: 0,
    background: "white",
    borderRadius: "8px",
    border: "1px solid rgb(98 195 238)",
    maxHeight: "40vh",
    overflow: "auto",
  },
  listItemText: {
    color: "rgb(87 87 87)",
    "@media screen and (max-width: 1100px)": {
      "& span": {
        fontSize: "16px",
      },
    },
  },
});

const initPlaceHolder = "Select classes";
interface IMultiSelectProps {
  clickHandler(value: IClassStudents): void;
  data: IClassStudents[];
}
function MultiSelect({ clickHandler, data }: IMultiSelectProps) {
  const { t, i18n } = useTranslation();
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [placeHolder, setPlaceHolder] = useState(initPlaceHolder);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data?.length > 0) {
      const selectedClasses = data.reduce(
        (selectedNames, item) =>
          (selectedNames += item.checked ? `${item.label}, ` : ""),
        ""
      );
      setPlaceHolder(
        selectedClasses.length > 0
          ? selectedClasses.substr(0, selectedClasses.length - 2)
          : initPlaceHolder
      );
    } else {
      setPlaceHolder(initPlaceHolder);
    }
  }, [data]);

  const handleClickOutside = (event: MouseEvent) => {
    if (!wrapperRef.current?.contains(event.target as Node)) {
      setShowDropdownMenu(false);
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.root} ref={wrapperRef}>
      <div
        className={classes.container}
        onClick={() => setShowDropdownMenu(!showDropdownMenu)}
      >
        <span
          className={classes.dropdownIcon}
          style={{
            ...(i18n.dir() === "rtl"
              ? { left: "0.75rem" }
              : { right: "0.75rem" }),
          }}
        >
          <ArrowDropDownIcon
            style={{
              width: "10px",
              height: "13px",
              transform:
                showDropdownMenu && data.length > 0
                  ? "rotate(180deg)"
                  : undefined,
            }}
          />
        </span>
        <span
          style={{
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: "rgb(87 87 87)",
          }}
        >
          {t(placeHolder)}
        </span>
      </div>
      {showDropdownMenu && (
        <div className={classes.dropDown}>
          <List className={classes.list}>
            {data.map((item) => (
              <ListItemButton
                key={item.value}
                dense
                onClick={() => clickHandler(item)}
                sx={{
                  width: "100%",
                  padding: "5px",
                  maxHeight: "225px",
                  overflow: "auto",
                  "& > div": {
                    borderRadius: "4px",
                    // padding: "4px 8px",
                    // margin: "2px 0",
                  },
                  "&> div:active": {
                    background: "rgb(244 244 244)",
                  },
                }}
                disabled={item.disabled}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    paddingBottom: "3px",
                    "@media screen and (max-width: 1100px)": {
                      "& span": {
                        fontSize: "1.5rem",
                      },
                    },
                  }}
                >
                  <Checkbox
                    edge={i18n.dir() === "rtl" ? "end" : "start"}
                    checked={item.checked}
                    tabIndex={-1}
                    checkedIcon={
                      <CheckBoxIcon style={{ width: "20px", height: "20px" }} />
                    }
                    icon={
                      <CheckBoxOutlineBlankIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          fill: "rgba(204, 204, 204, 1)",
                        }}
                      />
                    }
                    color="primary"
                  />
                </ListItemIcon>
                <ListItemText
                  id={`${item.value}`}
                  primary={item.label}
                  classes={{ root: classes.listItemText }}
                />
              </ListItemButton>
            ))}
          </List>
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
