function useClientDevice() {
  const isChrome =
    navigator.userAgent.match(/CriOS/gi) ||
    navigator.userAgent.match(/Chrome/gi);

  const isiPadoriPadPro =
    navigator.userAgent.match(/iPad/gi) ||
    (navigator.userAgent.match(/Mac/gi) &&
      navigator.maxTouchPoints > 1 &&
      ((window.screen.width === 768 && window.screen.height === 1024) ||
        (window.screen.width === 1024 && window.screen.height === 1366)));

  const isAndroidTablet =
    navigator.userAgent.match(/Android/gi) ||
    navigator.userAgent.match(/Tablet/gi);

  const isTablet =
    isAndroidTablet ||
    isiPadoriPadPro ||
    (window.screen.width <= 1024 && navigator.maxTouchPoints > 1);

  return { isTablet, isiPadoriPadPro, isAndroidTablet, isChrome };
}

export default useClientDevice;
