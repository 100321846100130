import axios from "axios";
import { toastError } from "components/ToastError";
import i18n from "i18n";
import { resetLocalStorage } from "utils/HomeHelpers";
import { IResponse } from "./API";

let isRefreshRequested = false;

let API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Access-Control-Allow-Origin": process.env.REACT_APP_ACL_ORIGIN!,
    "Content-type": "application/json",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
  },
});

// Set the AUTH token for any request
API.interceptors.request.use(function (config) {
  const token = localStorage.getItem("abjadToken");
  if (config?.headers) config.headers.Authorization = token ? token : "";
  return config;
});

function redirectToLogin() {
  isRefreshRequested = false;
  resetLocalStorage();
  localStorage.setItem("expired", "true");
  toastError({
    label: i18n.t("error"),
    message: i18n.t("Session Expired"),
    toastId: "session-expired",
    rtl: i18n.dir() === "rtl",
    autoClose: 8000,
  });
  setTimeout(() => (window.location.pathname = "/login"), 2000);
}

const getNewAccessToken = async (_refreshToken: string) => {
  return API.post("/auth/refresh-token", {
    _refreshToken,
  })
    .then((response) => response.data)
    .catch((error) => error.response);
};

API.interceptors.response.use(
  async (response) => {
    const { data, config: issuedRequest } = response;
    const refreshToken = localStorage.getItem("refreshToken");
    if (data._statusCode === 403 && refreshToken && !isRefreshRequested) {
      isRefreshRequested = true;
      const res = (await getNewAccessToken(refreshToken)) as IResponse;
      if (res._statusCode === 200) {
        const { _accessToken } = res._entity as { _accessToken: string };
        localStorage.setItem("abjadToken", _accessToken);
        if (!issuedRequest.headers) issuedRequest.headers = {};
        issuedRequest.headers.Authorization = _accessToken;
        isRefreshRequested = false;
        return API(issuedRequest);
      }
    }

    if (data._statusCode === 401) {
      redirectToLogin();
    }
    return response;
  },
  (error) => {
    const errorStatus = error.response.status;
    const expectedError = errorStatus >= 400 && errorStatus < 500;
    if (!expectedError) {
      console.log(error);
      toastError({
        label: i18n.t("error"),
        message: i18n.t("Technical Error"),
        toastId: "technical-issue",
        rtl: i18n.dir() === "rtl",
      });
    }
    return Promise.reject(error);
  }
);

export const downloadReport = async (classId: number, type: string) => {
  let url = `/ams/class/${classId}/assessment/download?reportType=${type}`;
  return API.get(url, { responseType: "blob" });
};

export const sendQRcodesByEmail = async (data: FormData) => {
  return API.post("/cms/email/student-login-details-old", data);
};
