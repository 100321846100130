import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { toastError } from "../components/ToastError";
import i18n from "../i18n";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export interface IResponse {
  _statusCode: number;
  _message: string;
  _entity: unknown;
  _timeStamp: number;
  _logoLink: string | null;
}

export interface IEdgeCases {
  [key: number]: string;
}

let isRefreshRequested = false;

const baseQueryAPI = () => {
  const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders(headers) {
      headers.set(
        "Access-Control-Allow-Origin",
        process.env.REACT_APP_ACL_ORIGIN!
      );
      headers.set("Content-type", "application/json");
      headers.set("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE");
      const token = localStorage.getItem("abjadToken");
      headers.set("Authorization", token ?? "");
      return headers;
    },
  });
  const retryRequest = async (
    data: IResponse,
    _refreshToken: string | null,
    api: BaseQueryApi,
    extraOptions: {}
  ) => {
    if (data?._statusCode === 403 && _refreshToken && !isRefreshRequested) {
      isRefreshRequested = true;
      const refreshResult = await baseQuery(
        {
          url: "/auth/refresh-token",
          body: { _refreshToken },
          method: "POST",
        },
        api,
        extraOptions
      );
      const res = refreshResult.data as IResponse;
      if (res?._statusCode === 200) {
        const { _accessToken } = res._entity as { _accessToken: string };
        localStorage.setItem("abjadToken", _accessToken);
        isRefreshRequested = false;
        return true;
      }
      return false;
    }
  };
  const baseQueryExtended: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    const data = result.data as IResponse;
    const _refreshToken = localStorage.getItem("refreshToken");
    const requestBlocked =
      data?._statusCode === 403 && _refreshToken && !isRefreshRequested;
    if (requestBlocked) {
      const authRefreshed = await retryRequest(
        data,
        _refreshToken,
        api,
        extraOptions
      );

      if (authRefreshed) result = await baseQuery(args, api, extraOptions);
      else redirectToLogin();
    }
    if (
      data?._statusCode === 401 ||
      (!_refreshToken && data._statusCode === 403)
    )
      redirectToLogin();
    const errorStatus = result.error?.status as number;
    const expectedError = errorStatus >= 400 && errorStatus < 500;
    if (result.error && !expectedError) {
      console.log(result.error);
      toastError({
        label: i18n.t("error"),
        message: i18n.t("Technical Error"),
        toastId: "technical-issue",
        rtl: i18n.dir() === "rtl",
      });
    }
    return result;
  };
  return baseQueryExtended;
};

export const oneDayDelayProd = 3600;
export const oneDayDelay = 300;
export enum SlicesTags {
  Assessment = "Assessment",
  Notification = "Notification",
  Exams = "Exams",
  StudentsExam = "StudentsExam",
  ClassExamReport = "ClassExamReport",
}
export const API = createApi({
  reducerPath: "api",
  baseQuery: baseQueryAPI(),
  // refetchOnFocus: true,
  refetchOnReconnect: true,
  keepUnusedDataFor:
    process.env.REACT_APP_ENV !== "prod" ? oneDayDelay : oneDayDelayProd,
  tagTypes: Object.values(SlicesTags),
  endpoints: () => ({}),
});

function redirectToLogin() {
  isRefreshRequested = false;
  const dir = localStorage.getItem("dir");
  const restoreId = localStorage.getItem("restoreId");
  localStorage.clear();
  if (dir) localStorage.setItem("dir", dir);
  if (restoreId) localStorage.setItem("restoreId", restoreId);
  localStorage.setItem("expired", "true");
  toastError({
    label: i18n.t("error"),
    message: i18n.t("Session Expired"),
    toastId: "session-expired",
    rtl: i18n.dir() === "rtl",
    autoClose: 8000,
  });
  setTimeout(() => {
    window.location.pathname = "/login";
  }, 1000);
}
