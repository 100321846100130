import ForgotPassword from "views/Public/Auth/ForgotPassword";
import ResetPassword from "views/Public/Auth/ResetPassword";
import Login from "views/Public/Auth/Login";
import NotCompatible from "views/Public/NotCompatible";

const routes = [
  {
    path: "/device-not-compatible",
    name: "NotCompatible",
    component: NotCompatible,
  },
  {
    path: "/login",
    name: "Auth",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "Auth",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "Auth",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "Auth",
    component: Login,
  },
];

export default routes;
