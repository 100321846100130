import "./loading.css";

function Loading() {
  return (
    <div data-title=".dot-flashing">
      <div className="stage">
        <div className="dot-flashing"></div>
      </div>
    </div>
  );
}

export default Loading;
