import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Skeleton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { filtersLoaded, selectGrades } from "features/filters";
import {
  useFetchFiltersQuery,
  useFetchGrLevelsByGradeQuery,
} from "services/filters";
import { IGrade } from "features/common/Interfaces";
import { filterEntitiesData } from "utils/HomeHelpers";
import { useLocation } from "react-router-dom";
import CustomSwitch from "components/Switch";
const useStyles = makeStyles({
  homeTabs: {
    display: "flex",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    marginTop: "15px",
    background: "rgba(250, 250, 252, 1)",
    "& a:first-of-type div": {
      marginRight: 0,
    },
    "@media print": {
      display: "none",
    },
  },
  navLink: {
    border: "none",
    zIndex: "1",
    color: "initial",
    marginLeft: "-1px",
  },
  homeTab: {
    height: "48px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(151, 151, 151, 0.28)",
    borderTop: "none",
    cursor: "pointer",
    "& > h3": {
      fontSize: "1rem",
      fontWeight: "bold",
      color: "#838383",
      margin: 0,
      "@media screen and (max-width: 768px)": {
        fontSize: "0.8rem",
      },
    },
    "& svg": {
      width: 35,
      height: 35,
    },
    "@media screen and (max-width: 768px)": {
      minWidth: "100px",
    },
  },
  currentTab: {
    background: "rgb(255 255 255)",
    zIndex: 1,
    borderBottom: 0,
  },
});

function sortByOwned(): ((a: IGrade, b: IGrade) => number) | undefined {
  return (a, b) => {
    if ((a.owned && b.owned) || (!a.owned && !b.owned)) return 0;
    if (a.owned && !b.owned) return -1;
    else return 1;
  };
}
interface IGradeList {
  onGradeChange(grade: IGrade): void;
  selectedGrade?: IGrade;
  getIsGradeLoading?(value: boolean): void;
  withSwitch?: boolean;
  allGradesSwitch?: boolean;
  getAllGradesSwitch?(value: boolean): void;
  "data-test"?: string;
}

function GradesList({
  onGradeChange,
  selectedGrade,
  getIsGradeLoading,
  withSwitch = false,
  allGradesSwitch,
  getAllGradesSwitch,
  ...rest
}: IGradeList) {
  const { pathname: url } = useLocation();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const loadedGrades = useAppSelector(selectGrades);
  const grades = loadedGrades.filter((grade) => grade.owned);
  // const [selectedGrade, setCurrentGrade] = useState(selectedGrade);
  const { data: filtersData, isLoading } = useFetchFiltersQuery();
  const { data: grLevelGroups } = useFetchGrLevelsByGradeQuery();

  useEffect(() => {
    getIsGradeLoading?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (
      filtersData?._statusCode === 200 &&
      grLevelGroups?._statusCode === 200
    ) {
      const filterList = filtersData._entity;
      const grLevelGroupList = grLevelGroups._entity;
      dispatch(filtersLoaded(filterEntitiesData(filterList, grLevelGroupList)));
    }
  }, [filtersData, grLevelGroups]);

  useEffect(() => {
    if (loadedGrades.length > 0) {
      let initGrade = selectedGrade;

      // only for evaluation section app first check if there is an auto saved value for the grade
      if (url === "/evaluation") {
        const autoSavedGrade = JSON.parse(
          localStorage.getItem("grade-evaluation") ?? "{}"
        );
        initGrade =
          Object.keys(autoSavedGrade).length > 0
            ? autoSavedGrade
            : selectedGrade;
        if (initGrade) onGradeChange(initGrade);
      }
      // setCurrentGrade(initGrade ?? loadedGrades[0]);
      const ownedGrades = loadedGrades.filter((g) => g.owned);
      onGradeChange(initGrade ?? ownedGrades[0]);
    }
  }, [loadedGrades]);

  return (
    <div
      className={classes.homeTabs}
      style={{ direction: i18n.dir() }}
      {...rest}
    >
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            flexDirection: "row",
            height: "60px",
          }}
        >
          {Array(6)
            .fill(1)
            .map((_, index) => (
              <Skeleton
                key={"skeGrade" + index}
                variant="rectangular"
                sx={{ height: "50px", width: "10%", marginX: "5px" }}
              />
            ))}
        </div>
      ) : (
        <Typography
          component="div"
          sx={{
            display: "fex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {(JSON.parse(JSON.stringify(loadedGrades)) as IGrade[])
              .sort(sortByOwned())
              .map((grade, index) => (
                <div
                  key={grade.id}
                  className={classes.navLink}
                  style={{
                    display:
                      !grade.owned && !allGradesSwitch ? "none" : "block",
                  }}
                  onClick={() => {
                    // setCurrentGrade(grade);
                    onGradeChange(grade);
                  }}
                >
                  <div
                    className={classNames(
                      classes.homeTab,
                      selectedGrade?.id === grade.id ? classes.currentTab : null
                    )}
                    style={{
                      [i18n.dir() === "rtl"
                        ? "borderTopRightRadius"
                        : "borderTopLeftRadius"]: index === 0 ? "8px" : null,
                    }}
                  >
                    <h3
                      style={{
                        color:
                          selectedGrade?.id === grade.id
                            ? "rgb(60, 180, 229)"
                            : grade.owned
                            ? undefined
                            : "rgb(47 191 83)",
                      }}
                    >
                      {t(grade.code)}
                    </h3>
                  </div>
                </div>
              ))}
          </Typography>
          {withSwitch && getAllGradesSwitch && grades.length ? (
            <CustomSwitch
              checked={allGradesSwitch}
              setChecked={getAllGradesSwitch}
            />
          ) : null}
        </Typography>
      )}
    </div>
  );
}

export default GradesList;
