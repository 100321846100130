import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Avatar } from "@mui/material";
import defaultAvatar from "assets/img/ic_avatar_default.svg";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "app/hooks";
import {
  selectClass,
  // selectSelectedPlanMonth,
  selectSelectedStudent,
} from "features/pacing";
const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(98, 195, 238)",
    color: "rgb(255, 255, 255)",
    fontSize: "1rem",
    padding: "0 10px 10px",
    fontWeight: "bold",
    borderRadius: "17px 17px 0 0",
    height: "65px",
    marginBottom: "-15px",
    " & #title": { flexGrow: 1, textAlign: "center" },
    " & svg": {
      cursor: "pointer",
    },
  },
  studentInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});
interface IHeaderProps {
  onClose(): void;
}
const Header = ({ onClose }: IHeaderProps) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const student = useAppSelector(selectSelectedStudent);
  const classInfo = useAppSelector(selectClass);
  // const month = useAppSelector(selectSelectedPlanMonth);

  return (
    <div className={classes.header} style={{ direction: i18n.dir() }}>
      <div className={classes.studentInfo}>
        <Avatar
          sx={{ width: "32px", height: "32px", margin: "0 10px" }}
          src={student?.avatarUrl ? student.avatarUrl : defaultAvatar}
        />
        <span>{student?.name}</span>
      </div>
      <div id="title">
        {/* <span style={{ margin: "0 10px" }}>{month.label}</span> */}
        {/* <span>({classInfo.label})</span> */}
        <span>{classInfo?.label}</span>
      </div>
      <CancelRoundedIcon onClick={onClose} />
    </div>
  );
};

export default Header;
