import DomainFilter from "./DomainFilter";
import SearchBy from "./SearchBy";
import { makeStyles } from "@mui/styles";
import { InfoIcon } from "components/SVGs/HomeIcons";
import { useAppSelector } from "app/hooks";
import { selectLessonsFilters } from "features/filters";
import { selectGrade, selectMoeVeiw, selectSection } from "features/content";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import GroupOneFilters from "./GroupedFilters/GroupOneFilters";
import MoeFilters from "./GroupedFilters/MoeFilters";
import RegularFilters from "./GroupedFilters/RegularFilters";
import { GradeCods, LessonTypes } from "features/common/Interfaces";
import GrLevelGroups from "./GrLevelGroups";

interface IFiltersProps {
  isLoading: boolean;
}

function Filters({ isLoading = true }: IFiltersProps) {
  const { t, i18n } = useTranslation();
  const useStyles = makeStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      flexDirection: "column",
      width: "100%",
    },
    moeFilters: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginBottom: "10px",
      width: "100%",
    },
    info: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "rgba(236, 238, 241, 1)",
      padding: "3px 5px",
      borderRadius: "2px",
      position: "relative",
      width: "fit-content !important",
      whiteSpace: "nowrap",
      "& svg": {
        width: "20px",
        height: "20px",
      },
      "@media screen and (max-width: 768px)": {
        fontSize: "10px",
      },

      "&:before": {
        content: '""',
        position: "absolute",
        border: "5px solid transparent",
        borderTop: "7px solid rgba(236, 238, 241, 1)",
        top: "100%",
        ...(i18n.dir() === "rtl" ? { right: "10px" } : { left: "10px" }),
      },
    },

    groupFilters: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
      position: "relative",
      width: "100%",
      backgroundColor: "rgb(249 249 249)",
      borderRadius: "6px",
      padding: "20px 10px",
    },
    search: {
      position: "absolute",
      top: "30px",
      direction: "ltr",
      ...(i18n.dir() === "rtl" ? { left: "10px" } : { right: "10px" }),
    },
    filterGR: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      width: "200px",
      top: "10px",
      ...(i18n.dir() === "rtl" ? { right: "10px" } : { left: "10px" }),
    },
  });
  const classes = useStyles();
  const selectedGrade = useAppSelector(selectGrade);
  const selectedSection = useAppSelector(selectSection);
  const moeViewState = useAppSelector(selectMoeVeiw);
  const loadedlessonsFilters = useAppSelector(selectLessonsFilters);

  return isLoading ? (
    <Skeleton variant="rectangular" height={150} />
  ) : (
    <div className={classes.root} style={{ direction: i18n.dir() }}>
      {Object.keys(loadedlessonsFilters)?.length > 0 &&
      selectedGrade &&
      selectedSection === LessonTypes.LESSON &&
      loadedlessonsFilters?.[selectedGrade.id]?.moe?.length ? (
        <div className={classes.moeFilters}>
          <DomainFilter />
          {moeViewState ? (
            <div style={{ display: "flex", width: "100%", marginTop: "10px" }}>
              <div className={classes.info}>
                <InfoIcon />
                <span style={{ margin: "0 5px" }}>
                  {t("Abjadiyat similar moe")}
                </span>
              </div>
              <div style={{ flex: 1 }}></div>
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={classes.groupFilters}
        style={
          selectedSection === LessonTypes.GUIDED_READING
            ? { padding: "50px 10px 20px" }
            : {}
        }
      >
        <div style={{ flex: 1 }}>
          {(selectedGrade?.code === GradeCods.kg1 ||
            selectedGrade?.code === GradeCods.kg2 ||
            (selectedGrade?.code === GradeCods.g1 && !moeViewState)) &&
          selectedSection === LessonTypes.LESSON ? (
            <GroupOneFilters />
          ) : moeViewState ? (
            <MoeFilters />
          ) : (
            <RegularFilters />
          )}
        </div>
        <div className={classes.search}>
          <SearchBy selectedSection={selectedSection} />
        </div>
        {selectedSection === LessonTypes.GUIDED_READING ? (
          <div className={classes.filterGR}>
            <GrLevelGroups />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Filters;
