import { useState, useEffect, useRef } from "react";
import { NotificationsIcon } from "../../SVGs";
import Notification, { INotification } from "./Notification";
import { usePubNub } from "pubnub-react";
import { List, ListItem, Badge, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IFetchNotificationEntity,
  NotificationStatus,
  useFetchNotificationsQuery,
  useUpdateNotificationMutation,
} from "services/notifications";
import Pubnub from "pubnub";

const channels = ["usr-" + localStorage.getItem("id")];

interface INotificationCenterProps {
  "data-test"?: string;
}
const NotificationCenter = (props: INotificationCenterProps) => {
  const { i18n, t } = useTranslation();
  const align = i18n.dir() === "rtl" ? "left" : "right";
  const [visibility, setVisibility] = useState("hidden");
  const [notificationList, setNotificationList] = useState<INotification[]>([]);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const pubnub = usePubNub();
  const { data: notificationsData } = useFetchNotificationsQuery();
  const [updateNotification] = useUpdateNotificationMutation();

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutSideHandler);
    };
  }, []);

  const clickOutSideHandler = (event: MouseEvent) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setVisibility("hidden");
    }
  };

  const handleClick = () => {
    setVisibility(visibility === "hidden" ? "visible" : "hidden");
    setSeenStatus();
  };

  useEffect(() => {
    let isMounted = true;
    pubnub.addListener({
      message: (messageEvent: Pubnub.MessageEvent) => {
        if (isMounted) {
          const item = createNotificationInfo(messageEvent.message);
          setNotificationList([...notificationList, item]);
          let notificationCount = notificationList.filter(
            (notification) => notification.status === NotificationStatus.notSeen
          ).length;
          setNewNotificationCount(notificationCount);
        }
      },
    });
    pubnub.subscribe({ channels });
    return () => {
      isMounted = false;
    };
  }, [notificationList]);

  useEffect(() => {
    if (notificationsData?._statusCode === 200) {
      const data = notificationsData._entity?.map((item) =>
        createNotificationInfo(item)
      );
      setNotificationList(data);
      const notSeen = data.filter(
        (item) => item.status === NotificationStatus.notSeen
      );
      setNewNotificationCount(notSeen.length);
    }
  }, [notificationsData]);

  const setSeenStatus = async () => {
    try {
      const notSeenList = notificationList.filter(
        (item) => item.status === NotificationStatus.notSeen
      );
      if (notSeenList.length > 0) {
        const payload = notSeenList.map((item) => ({
          _id: item.id,
          _status: NotificationStatus.seen,
        }));
        const response = await updateNotification({
          _notifications: payload,
        }).unwrap();
        if (response._statusCode === 200) {
          setNewNotificationCount(0);
        } else throw response;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    root: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    menu: {
      visibility,
      top: "140%",
      ...(align === "left" ? { left: "-15%" } : { right: "-15%" }),
      color: "rgb(51 51 51)",
      position: "absolute",
      width: "max-content",
      backgroundColor: "rgb(255 255 255)",
      borderRadius: "0.75rem",
      boxShadow: " 0px 5px 10px 0px rgba(0, 0, 0, 0.06)",

      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "100%",
        ...(align === "left" ? { left: "0.65rem" } : { right: "0.65rem" }),
        borderWidth: "0.75rem",
        borderStyle: "solid",
        borderColor: "transparent transparent  rgb(60 180 229) transparent",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "100%",
        ...(align === "left" ? { left: "0.75rem" } : { right: "0.75rem" }),
        borderWidth: "0.65rem",
        borderStyle: "solid",
        borderColor: "transparent transparent  rgb(60 180 229) transparent",
      },
      " & *": {
        fontSize: "14px",
        ...(align === "left" ? { textAlign: "right" } : { textAlign: "left" }),
      },
    },

    menuList: {
      maxWidth: "300px",
      width: "fit-content",
      minWidth: "200px",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: "0.75rem",
      border: "1px solid rgb(218 218 218)",
      borderTop: "none",
      paddingBottom: "0.5rem",
      "& > span": {
        color: "rgb(255 255 255)",
        width: "calc(100% + 2px)",
        backgroundColor: "rgb(60 180 229)",
        borderTopLeftRadius: "0.75rem",
        borderTopRightRadius: "0.75rem",
        padding: "0.5rem 1rem",
        ...(align === "left" ? { textAlign: "right" } : { textAlign: "left" }),
      },
    },
    badge: {
      height: "100%",
      "& > span": {
        top: "5px",
      },
    },
    notificationIcon: {
      width: "33px",
      height: "33px",
      borderRadius: "50%",
      padding: "0.45rem",
      color: "rgb(169, 175, 187)",
      backgroundColor: "rgb(232 235 241)",
      cursor: "pointer",
      "&:hover": {
        borderRadius: "50%",
        border: "1px solid rgb(98 195 238)",
        color: "rgb(98 195 238)",
        background: "rgb(255 255 255)",
      },
    },

    activeIcon: {
      borderRadius: "50%",
      border: "1px solid rgb(98 195 238)",
      color: "rgb(98 195 238)",
      background: "rgb(255 255 255)",
    },
    list: {
      maxHeight: "400px",
      width: "100%",
      overflow: "auto",
      borderBottomLeftRadius: "0.75rem",
      borderBottomRightRadius: "0.75rem",
      padding: "0 3px",
    },
  };
  return (
    <Typography
      component="div"
      sx={customStyles.root}
      onClick={handleClick}
      {...props}
    >
      <Badge
        badgeContent={newNotificationCount}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: align === "left" ? "right" : "left",
        }}
      >
        <NotificationsIcon
          sx={{
            ...customStyles.notificationIcon,
            ...(visibility === "visible" ? customStyles.activeIcon : {}),
          }}
        />
      </Badge>
      <Typography component="div" sx={customStyles.menu} ref={menuRef}>
        <Typography component="div" sx={customStyles.menuList}>
          <span>{t("Notifications")}</span>
          <List component="nav" sx={customStyles.list} disablePadding>
            {notificationList && notificationList.length > 0 ? (
              notificationList.map((notification, index) => {
                return (
                  <ListItem
                    key={"key" + index}
                    disableGutters
                    dense
                    divider={notificationList.length !== index + 1}
                  >
                    <Notification info={notification} />
                  </ListItem>
                );
              })
            ) : (
              <ListItem>
                <span style={{ width: "150px" }}>{t("No notifications")}</span>
              </ListItem>
            )}
          </List>
        </Typography>
      </Typography>
    </Typography>
  );
};

export default NotificationCenter;

const createNotificationInfo = (
  notificationMessage: IFetchNotificationEntity
): INotification => ({
  id: notificationMessage._id,
  date: notificationMessage._date,
  type: notificationMessage._type,
  status: notificationMessage._status,
  studentCount: notificationMessage._payload._studentCount,
  className: notificationMessage._payload._className,
  classId: notificationMessage._payload._classId,
  gradeId: notificationMessage._payload._gradeId,
});
