import { useEffect } from "react";
import Filter from "./Filter";
import FiltersHoc, { IChildCompProps } from "./FiltersHoc";
import { useTranslation } from "react-i18next";
import { IFilter, ILevelMoeFilter } from "features/filters";

function MoeFilters({
  lessonsFilters,
  selectedGrade,
  dispatchLessonsCall,
  levelOneFilters,
  setLevelOneFilters,
  setLevelThreeFilters,
  levelTwoFilters,
  levelThreeFilters,
  setLevelTwoFilters,
  selectedLevelOneFilter,
  selectedLevelTwoFilter,
  setSelectedLevelTwoFilter,
  setSelectedLevelOneFilter,
  setSelectedLevelThreeFilter,
  selectedLevelThreeFilter,
}: IChildCompProps) {
  const { t } = useTranslation();
  useEffect(() => {
    if (Object.keys(lessonsFilters).length && selectedGrade) {
      let moeFilters = lessonsFilters[selectedGrade.id].moe;
      if (moeFilters) {
        setLevelOneFilters(moeFilters);
        setSelectedLevelOneFilter({ ...moeFilters[0] });
      }
    }
  }, [lessonsFilters, selectedGrade]);

  useEffect(() => {
    if (selectedLevelOneFilter) {
      let levelTwoFilters = levelOneFilters?.filter(
        (filter) => filter.code === selectedLevelOneFilter.code
      );
      const filtersOflevel2 = (levelTwoFilters as ILevelMoeFilter[])?.[0]
        ?.groups;
      setLevelTwoFilters(filtersOflevel2);
      setSelectedLevelTwoFilter(
        filtersOflevel2 ? { ...filtersOflevel2[0] } : undefined
      );
    }
  }, [selectedLevelOneFilter]);

  useEffect(() => {
    if (selectedLevelTwoFilter) {
      let levelThreeFilters = levelTwoFilters?.filter(
        (filter) => filter.code === selectedLevelTwoFilter.code
      );
      if (levelThreeFilters?.[0].subGroups) {
        setLevelThreeFilters(levelThreeFilters[0].subGroups);
        setSelectedLevelThreeFilter({ ...levelThreeFilters[0].subGroups[0] });
        dispatchLessonsCall({
          _termCode: selectedLevelOneFilter?.code,
          _groupCode:
            selectedLevelTwoFilter.code === "DEFAULT"
              ? ""
              : selectedLevelTwoFilter.code,
          _subGroupCode: levelThreeFilters[0].subGroups[0].code,
        });
      } else {
        setLevelThreeFilters([]);
        dispatchLessonsCall({
          _termCode: selectedLevelOneFilter?.code,
          _subGroupCode: selectedLevelTwoFilter.code,
        });
      }
    }
  }, [selectedLevelTwoFilter]);

  const setLevelThree = (group: IFilter) => {
    setSelectedLevelThreeFilter(group);
    dispatchLessonsCall({
      _termCode: selectedLevelOneFilter?.code,
      _groupCode:
        selectedLevelTwoFilter?.code === "DEFAULT"
          ? ""
          : selectedLevelTwoFilter?.code,
      _subGroupCode: group.code,
    });
  };

  const levelOneTitle = t("Term");
  const levelTwoTitle =
    levelThreeFilters && levelThreeFilters.length ? t("Unit") : t("Lesson");
  const levelThreeTitle = t("Unit parts");

  return (
    <>
      {levelOneFilters?.length ? (
        <Filter
          data={levelOneFilters}
          clickHandler={(group) => setSelectedLevelOneFilter(group)}
          selectedFilter={selectedLevelOneFilter!}
          title={levelOneTitle}
        />
      ) : null}
      {levelTwoFilters && levelTwoFilters.length > 1 ? (
        <div style={{ paddingTop: "15px" }}>
          <Filter
            data={levelTwoFilters}
            clickHandler={(group) => setSelectedLevelTwoFilter(group)}
            selectedFilter={selectedLevelTwoFilter!}
            title={levelTwoTitle}
          />
        </div>
      ) : null}
      {levelThreeFilters?.length ? (
        <div style={{ paddingTop: "15px" }}>
          <Filter
            data={(
              JSON.parse(JSON.stringify(levelThreeFilters)) as IFilter[]
            ).map((f) => {
              if (f.code === "ALL") f.title = t("All");
              return f;
            })}
            clickHandler={setLevelThree}
            selectedFilter={selectedLevelThreeFilter!}
            title={levelThreeTitle}
          />
        </div>
      ) : null}
    </>
  );
}

export default FiltersHoc(MoeFilters);
