import { makeStyles } from "@mui/styles";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { IEdgeCases } from "services/API";
import { useRequestResetPasswordMutation } from "services/auth";
import Button from "components/Button";
import { Done } from "components/SVGs/Done";
import { emailValue, resetPasswordRequested } from "features/auth";
import useToast from "hooks/useToast";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > :not(:last-child)": {
      marginBottom: "30px",
    },
  },
  heading: {
    textAlign: "center",
    "& p:nth-of-type(2)": {
      color: "rgba(98, 195, 238, 1)",
    },
    "& p:nth-of-type(3)": {
      color: "rgba(131, 131, 131, 1)",
    },
  },
  link: {
    width: "100%",
    margin: "10px 0",
    paddingBottom: "30px",
    textAlign: "center",
    "& span": {
      userSelect: "none",
      textDecoration: "underline",
      cursor: "pointer",
      color: "rgb(105 123 140)",
      fontSize: "16px",
      // "&:hover": {
      //   textShadow: "-2px -2px 3px rgb(227 239 248)",
      //   color: "rgb(98 195 238)",
      // },
    },
  },
});
type Props = {};
const edgeCases: IEdgeCases = {
  546: "No user found",
  630: "Not a teacher",
  500: "Bad Request",
};
const RequestConfirmed = (props: Props) => {
  const dispatch = useAppDispatch();
  const email = useAppSelector(emailValue);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const toast = useToast();

  const [requestRestPassword] = useRequestResetPasswordMutation();
  const resendHandler = useCallback(
    async (email: string): Promise<void> => {
      const toastId = "RequestResetPasswordToastMessage";
      try {
        const data = await requestRestPassword({
          _email: email,
          _lang: i18n.dir() === "rtl" ? "AR" : "EN",
        }).unwrap();
        if (data?._statusCode === 200) {
          toast({
            label: t("success"),
            message: t("resendLinksuccess"),
            rtl: i18n.dir() === "rtl",
            type: "success",
            toastId: "resendtoastMessage",
          });
        } else if (
          Object.keys(edgeCases).includes(data?._statusCode.toString())
        ) {
          toast({
            label: t("error"),
            message: t(edgeCases[data._statusCode]),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId,
          });
        } else throw data;
      } catch (error) {
        console.log({ error });
      }
    },
    [requestRestPassword]
  );

  const loginHandler = () => {
    dispatch(resetPasswordRequested("init"));
    history.push("/login");
  };

  return (
    <>
      <Done sx={{ fontSize: "50px" }} />
      <div className={classes.heading}>
        <p style={{ marginBottom: "40px" }}>{t("EmailSent")}</p>
        <p>{email}</p>
        <p>{t("EmailSentDescription")}</p>
      </div>
      <Button
        label={t("Resend")}
        styles={{ width: "300px" }}
        action={() => resendHandler(email)}
      />
      <div className={classes.link} onClick={loginHandler}>
        <span>{t("Login")}</span>
      </div>
    </>
  );
};

export default RequestConfirmed;
