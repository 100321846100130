import Joi from "joi";

// const emailLetters = new RegExp("^[a-zA-Z0-9._@]+$");

export const LoginSchema = {
  password: Joi.string().trim().required().messages({
    "string.empty": "Password is required",
  }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    // .pattern(emailLetters)
    .messages({
      "string.empty": "Email Id is required",
      "string.email": "EmailValidation",
      "string.pattern.base": "EmailLettersValidation",
    }),
};

export default LoginSchema;
