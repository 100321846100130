import { useLocation } from "react-router-dom";
import Performance from "./Performance";
import Pacing from "./Pacing";
import Breadcrumb from "components/Breadcrumb";

const renderTab = (tabToRender: string, childTab: string) => {
  switch (tabToRender) {
    case "performance":
      return <Performance tab={childTab} />;
    case "pacing":
      return <Pacing />;
    default:
      return null;
  }
};

function Report() {
  let { pathname: url } = useLocation();
  let newUrl = url.split("/");
  newUrl.shift();
  newUrl.shift();
  return (
    <>
      <Breadcrumb />
      {renderTab(newUrl[0], newUrl[1])}
    </>
  );
}

export default Report;
