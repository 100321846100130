import { Skeleton } from "@mui/material";

function TabelSkeleton() {
  return (
    <>
      <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
      <Skeleton variant="rectangular" height={220} style={{ margin: "10px" }} />
    </>
  );
}

export function ToolBarSkeleton() {
  return (
    <Skeleton variant="rectangular" height={40} style={{ margin: "10px" }} />
  );
}

export default TabelSkeleton;
