import { makeStyles } from "@mui/styles";
import {
  EneIcon,
  NotAssignedIcon,
  PendingAssignmentIcon,
} from "components/SVGs";
import {
  selectDomain,
  selectClassReport,
  selectReport,
} from "features/classPerformance";
import classNames from "classnames";
import { useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { Assessment } from "features/common/Interfaces";

const useStyles = makeStyles({
  tooltipBar: {
    userSelect: "none",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    position: "fixed",
    height: "3rem",
    bottom: "45px",
    width: "100%",
    background: "rgb(255 255 255)",
    boxShadow: " 0px -2px 4px 0px rgba(197, 197, 197, 0.5)",
    left: 0,
    color: "rgb(116 116 116)",
    "& svg": {
      fontSize: "1.5rem",
    },
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& span": {
        padding: "0 5px",
      },
    },
  },
  info: {
    position: "relative",
    top: 0,
    paddingRight: 30,
    "&:before": {
      borderRadius: 10,
      content: '" "',
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      height: 20,
      width: 20,
    },
  },
  completed: {
    "&:before": {
      backgroundColor: "rgb(134, 189, 48)",
    },
  },
  partiallyCompleted: {
    "&:before": {
      backgroundColor: "rgba(250, 150, 69, 1)",
    },
  },
  notCompleted: {
    "&:before": {
      backgroundColor: "rgba(212, 78, 78, 1)",
    },
  },
});

function TooltipBar() {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedDomain = useAppSelector(selectDomain);
  const classReport = useAppSelector(selectClassReport);
  const allDomains = selectedDomain?.value === -1;
  const havingData = classReport?.length > 0;
  const report = useAppSelector(selectReport);

  return (
    <>
      {!allDomains && havingData ? (
        report?.value === Assessment.FORMATIVE ? (
          <div className={classes.tooltipBar}>
            <div>
              <EneIcon style={{ color: "rgb(134 189 48)" }} />
              <span>{t("completedEnE")}</span>
            </div>
            <div>
              <EneIcon style={{ color: "rgb(249 168 37)" }} />
              <span>{t("pendingEnE")}</span>
            </div>
            <div>
              <NotAssignedIcon />
              <span>{t("notAssigned")}</span>
            </div>
            <div>
              <PendingAssignmentIcon />
              <span>{t("pending")}</span>
            </div>
          </div>
        ) : (
          <div className={classes.tooltipBar}>
            <div className={classNames(classes.info, classes.notCompleted)}>
              {t("notCompleted")}
            </div>
            <div
              className={classNames(classes.info, classes.partiallyCompleted)}
            >
              {t("partiallyCompleted")}
            </div>
            <div className={classNames(classes.info, classes.completed)}>
              {t("completed")}
            </div>
            <div>
              <PendingAssignmentIcon />
              <span>{t("pending")}</span>
            </div>
            <div>
              <NotAssignedIcon />
              <span>{t("notAssigned")}</span>
            </div>
          </div>
        )
      ) : null}
    </>
  );
}

export default TooltipBar;
