import { makeStyles } from "@mui/styles";
import StudentInfo from "../../shared/StudentInfo";
import { SortIcon } from "components/SVGs";
import classNames from "classnames";
import {
  selectStudents,
  selectSelectedSort,
  selectDomain,
  selectedSortSet,
  selectReport,
  selectExam,
} from "features/classPerformance";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { Assessment, Sort } from "features/common/Interfaces";
const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  namesList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "2rem",
    background: "rgb(255 255 255)",
    userSelect: "none",
    flexGrow: 1,
  },
  classAverage: {
    height: "170px",
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    color: "rgb(74 74 74)",
    "@media screen and (max-width: 768px)": {
      height: "140px",
    },
  },
  header: {
    height: "60px",
    width: "100%",
    userSelect: "none",
    padding: "0 0.25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "1px solid rgb(214 214 214)",
    boxShadow: "0px -1px 4px -4px rgba(0, 0, 0, 0.06)",
    backgroundColor: "rgba(200, 238, 255, 1)",
    "& svg": {
      cursor: "pointer",
      zIndex: 1,
    },
  },
  averagesList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(84 84 84)",
    fontWeight: "bold",
    position: "relative",
    border: "1px solid rgb(218 218 218)",
    borderTop: "none",
    borderBottom: "none",
    boxShadow: "0px -1px 4px 0px rgba(0, 0, 0, 0.06)",
  },
  cell: {
    height: "60px",
    borderTop: "1px solid rgba(218, 218, 218, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(107 106 106)",
    fontWeight: "bold",
    fontSize: "0.8rem",
    width: "100%",
    userSelect: "none",
  },
  disable: {
    pointerEvents: "none",
  },
  listPadding: {
    paddingTop: 76,
    "@media screen and (max-width: 768px)": {
      paddingTop: 67,
    },
  },
});

interface IStudentListProps {
  customStyle?: boolean;
}
function StudentsList({ customStyle = false }: IStudentListProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const studentsList = useAppSelector(selectStudents);
  const sortingOrder = useAppSelector(selectSelectedSort);
  const selectedDomain = useAppSelector(selectDomain);
  const selectedReportType = useAppSelector(selectReport);
  const selectedExam = useAppSelector(selectExam);
  const isExamData =
    selectedReportType.value === Assessment.EXAM && selectedExam?.value !== -1;
  const allDomains = selectedDomain?.value === -1;

  const sortHandler = () => {
    const order = sortingOrder === Sort.asc ? Sort.desc : Sort.asc;
    dispatch(selectedSortSet(order));
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classNames(
          classes.namesList,
          !allDomains && customStyle ? classes.listPadding : false
        )}
      >
        <div className={classes.header}></div>
        {allDomains && (
          <span className={classes.classAverage}>
            {t("classAverageInDomain")}
          </span>
        )}

        {studentsList?.map((info, index) => (
          <StudentInfo
            data={
              isExamData || !allDomains
                ? { ...info, assigned: undefined }
                : info
            }
            key={index}
          />
        ))}
      </div>
      {!allDomains && (
        <div
          className={classNames(
            classes.averagesList,
            !allDomains && customStyle ? classes.listPadding : false
          )}
        >
          <div className={classes.header}>
            <span style={{ padding: "0 10px 0 5px" }}>
              {t("studentAverage")}
            </span>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SortIcon
                onClick={sortHandler}
                style={{
                  transform:
                    sortingOrder === Sort.asc
                      ? "rotate(0deg)"
                      : "rotate(180deg)",
                  fontSize: "1.5rem",
                }}
              />
            </div>
          </div>
          {studentsList?.map(({ average }, index) => (
            <div key={index} className={classes.cell}>
              {average}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default StudentsList;
