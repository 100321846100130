import { API, IResponse } from "./API";

interface IStudentEvaluation {
  _pendingEvaluationCount?: number;
  _studentId: number;
  _studentName: string;
}
export interface IFetchClassesEntity {
  _classId: number;
  _className: string;
  _students: IStudentEvaluation[];
}
interface IFetchClassesResponse extends IResponse {
  _entity: IFetchClassesEntity[];
}

export interface IContentDetails {
  _adaptiveVideoUrl?: string;
  _contentName?: string;
  _id: string;
  _streamUrl?: string;
  _thumbUrl?: string;
  _url?: string;
  _contentCategory?: number;
  _contentType?: number | string;
  _eneLevelName?: string;
}
export interface IEneContent {
  _contentMetaDataResDtos: IContentDetails[];
  _eneLevelId: number;
  _eneLevelName: string;
}
export interface IEneContentEntity {
  _eneContentWithLevelCollection: IEneContent[];
}

interface IFetchLessonContentsResponse extends IResponse {
  _entity: IEneContentEntity | IContentDetails[];
}
interface IFetchLessonContentsRequest {
  contentId: string;
  playEne: boolean;
  grBook: boolean;
}

export interface IFetchedEntity {
  _id: number;
  _name: string;
}
interface IFetchLanguageDoaminsResponse extends IResponse {
  _entity: IFetchedEntity[];
}

interface IFetchClassesOfGradeResponse extends IResponse {
  _entity: IFetchedEntity[];
}
export interface IClassDetailsEntity {
  _color: string;
  _gradeAr: string;
  _gradeEn: string;
  _gradeId: number;
  _id: number;
  _name: string;
}
interface IFetchAllClassesResponse extends IResponse {
  _entity: IClassDetailsEntity[];
}

const commonServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchClasses: build.query<IFetchClassesResponse, number>({
        query(gradeId) {
          return `/ams/grade/${gradeId}/students`;
        },
      }),
      fetchAllGradesClasses: build.query<IFetchClassesResponse, void>({
        query() {
          return `ams/teacher/students`;
        },
      }),
      fetchLessonContents: build.query<
        IFetchLessonContentsResponse,
        IFetchLessonContentsRequest
      >({
        query(payload) {
          const CONTENT_URL = payload.playEne
            ? "/ams/lesson-ene"
            : payload.grBook
            ? "/ams/grbook"
            : "/ams/lesson";
          return `${CONTENT_URL}/${payload.contentId}/contents`;
        },
      }),
      fetchLanguageDomains: build.query<IFetchLanguageDoaminsResponse, void>({
        query() {
          return "ams/domains";
        },
      }),
      fetchClassesOfGrade: build.query<IFetchClassesOfGradeResponse, number>({
        query(gradeId) {
          return `/ams/grade/${gradeId}/class`;
        },
      }),
      fetchAllClasses: build.query<IFetchAllClassesResponse, void>({
        query() {
          return "/ams/teacher-classes";
        },
      }),
    };
  },
});

export const {
  useFetchClassesQuery,
  useFetchLessonContentsQuery,
  useFetchLanguageDomainsQuery,
  useFetchClassesOfGradeQuery,
  useFetchAllClassesQuery,
  useFetchAllGradesClassesQuery,
} = commonServices;
