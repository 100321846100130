import { renderSocial } from "../Elements";
import { useHistory } from "react-router";
import { BigLogoIcon } from "components/SVGs/MainMenuIcons";
import { Grid } from "@mui/material";
import ForgotPasswordHeader from "assets/img/ForgotPasswordHeader.svg";
import RequestConfirmed from "./RequestConfirmed";
import SendRequest from "./SendRequest";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  resetPasswordRequested,
  resetPasswordRequestState,
} from "features/auth";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const requestState = useAppSelector(resetPasswordRequestState);
  const history = useHistory();

  const loginHandler = () => {
    dispatch(resetPasswordRequested("init"));
    history.push("/login");
  };

  const renderStep = () => {
    switch (requestState) {
      case "started":
        return <SendRequest />;
      case "confirmed":
        return <RequestConfirmed />;
      default:
        loginHandler();
        break;
    }
  };
  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        background: "rgb(255 255 255)",
        width: "100%",
        height: "fit-content",
        minHeight: "100vh",
        "& > :not(:last-child)": {
          marginBottom: "30px",
        },
      }}
    >
      <Grid
        item
        sx={{
          background: `no-repeat url(${ForgotPasswordHeader})`,
          backgroundSize: "100% 100%",
          height: "250px",
          width: "100%",
          "@media only screen and (max-width: 1024px)": {
            height: "150px",
          },
        }}
      >
        {renderSocial(i18n.dir())}
        <BigLogoIcon
          sx={{
            position: "absolute",
            cursor: "pointer",
            [i18n.dir() === "rtl" ? "left" : "right"]: "40px",
            top: "5px",
            margin: 0,
            fontSize: "100px",
          }}
          onClick={loginHandler}
        />
        <div
          style={{
            background: "rgb(236 249 255)",
            width: "100%",
            height: "50px",
          }}
        ></div>
      </Grid>
      {renderStep()}
    </Grid>
  );
}

export default ForgotPassword;
