import { IDomainReport, IStudent } from "features/classPerformance";
import {
  Assessment,
  IOption,
  IPagination,
  Sort,
  StatusTypes,
} from "features/common/Interfaces";
import { IFetchedEntity } from "services/common";
import {
  IDomainDetailsEntity,
  ILoEntity,
  IPacingReportEntity,
  IStudentAssignmentEntity,
  IStudentDomainEntity,
  IStudentReportEntity,
} from "services/reports";

import { IExtendedOption } from "features/common/Interfaces";
import { IDomain } from "features/studentPerformance";
import { IFetchClassesEntity } from "services/common";
import {
  IDomainCriteriaStudentScore,
  IDomainLosStudentScore,
  IStudentScore,
} from "services/reports";
import { ILoStudentScore } from "views/Secure/Reports/Performance/StudentPerformance/Content";
import {
  IColorSchema,
  IPacingStudentDetails,
  IReportData,
} from "features/pacing";

export const toDomainOptions = (data: IFetchedEntity[]): IOption[] => {
  const domains = data
    .map(({ _id, _name }) => ({
      label: _name,
      value: _id,
    }))
    .sort((a, b) => a.value - b.value);
  domains.unshift({ label: "allDomains", value: -1 });
  return domains;
};

export const toClassOptions = (data: IFetchedEntity[]): IOption[] => {
  return data.map(({ _id, _name }) => ({
    label: _name,
    value: _id,
  }));
};

export const getStudentsInfo = (
  studentsInfo: IStudentDomainEntity[] | IStudentReportEntity[]
): IStudent[] => {
  return studentsInfo?.map((student) => {
    let stu: IStudent = {
      studentId: student?._id,
      name: student?._name,
      assigned: student?._assignedLos!,
      completed: student?._completedLos!,
      avatar: student?._avatarUrl,
    };
    if ("_averageScore" in student) {
      stu["average"] =
        student?._averageScore &&
        student._averageScore >= 0 &&
        student._averageScore <= 100
          ? `${student?._averageScore}%`
          : "-";
    }
    return stu;
  });
};

export const createReportRecords = (
  allDomains: boolean,
  domainsData: IDomainDetailsEntity[],
  studentsData: IStudentDomainEntity[],
  reportType: Assessment
): IDomainReport[] => {
  let reportData: IDomainReport[] = [];
  if (allDomains)
    reportData = domainsData.map((domain) => ({
      id: domain._domainId,
      name: domain._domainName,
      average: validateAverage(domain._score),
      scores: domain._studentAssessmentReports.map((stu) => stu._score),
    }));
  else {
    reportData = studentsData[0]?._los
      .map((lo) => ({
        id: lo._id,
        loTitle: lo._title,
        lessonTitle: lo._lessonTitle,
      }))
      .map((row: IDomainReport) => {
        let students = studentsData.map((stu) => {
          let res = stu._los
            ?.filter((lo) => lo._id === row.id)
            .map((item) =>
              reportType === Assessment.FORMATIVE
                ? createFormativeReportRecord(stu, item)
                : createBaselineReportRecord(stu, item)
            )[0];
          return res;
        });
        row.students = students;
        return row;
      });
  }
  reportData?.sort((a, b) => a.id - b.id);
  return reportData;
};

const validateAverage = (value: string) => {
  let average = Number(value) > 100 ? 100 : value;
  average = Number(average as string) >= 0 ? average : NaN;
  return average;
};

export const paginatedOptions = (pagination: IPagination) => {
  const pageNumber = pagination?.currentPage;
  const pageSize = pagination?.pageSize!;
  const startIndex = (pageNumber - 1) * pageSize;
  const endIndex = pageNumber * pageSize;

  return { startIndex, endIndex };
};

export const sortArray = (order: Sort) => {
  return function (a: IStudentDomainEntity, b: IStudentDomainEntity) {
    if (a._averageScore === b._averageScore) return 0;
    else if (a._averageScore === null) return 1;
    else if (b._averageScore === null) return -1;
    else if (a._averageScore! < 0) return 1;
    else if (b._averageScore! < 0) return -1;
    else if (order === Sort.asc)
      return a._averageScore! < b._averageScore! ? -1 : 1;
    else return a._averageScore! < b._averageScore! ? 1 : -1;
  };
};
const createBaselineReportRecord = (
  stu: IStudentDomainEntity,
  item: ILoEntity
) => ({
  studentId: stu._id,
  score: item._scoreState,
  studentName: stu._name,
});

const createFormativeReportRecord = (
  stu: IStudentDomainEntity,
  item: ILoEntity
) => ({
  studentId: stu._id,
  score: item._scoreState,
  offline: item._offlineEvaluation,
  ene: item._eneStatus ? item._eneStatus === StatusTypes.COMPLETED : null,
  questions: item._questionProgresses,
});

const validateScore = (score: number, offline: boolean = false) => {
  return score > 100
    ? 100
    : score == null || score < 0
    ? 0
    : score === 0 && offline
    ? 101
    : score;
};

export const modifyDomainsResponse = (studentDomainScore: IStudentScore[]) => {
  const domains: IDomain[] = [];

  if (studentDomainScore?.length > 0) {
    studentDomainScore
      .sort((a, b) => a._domainId - b._domainId)
      .forEach((item) => {
        if (item._studentScore != null) {
          domains.push({
            id: item._domainId,
            name: item._domainName,
            average: validateScore(item._studentScore),
          });
        }
      });
  }

  return domains;
};

export const modifyOnlineLosResponse = (
  studentDomainLoScores: IDomainLosStudentScore[]
) => {
  let data: ILoStudentScore[] = [];
  if (studentDomainLoScores && studentDomainLoScores.length > 0) {
    data = [
      ...studentDomainLoScores.map((item, index) => ({
        id: "lesson" + (index + 1),
        classScore: validateScore(item._classScore),
        classScoreColor:
          item._classScore === 0 ? "transparent" : "rgba(98, 195, 238, 1)",
        title: item._loTitle,
        studentScore: validateScore(item._studentScore),
        studentScoreColor:
          item._studentScore === 0 ? "transparent" : "rgba(117, 135, 152, 1)",
      })),
    ];
  }

  return data;
};

export const modifyOfflineLosResponse = (
  studentDomainOfflineScores: IDomainCriteriaStudentScore[]
) => {
  let offlineData: ILoStudentScore[] = [];
  if (studentDomainOfflineScores && studentDomainOfflineScores.length > 0) {
    offlineData = [
      ...studentDomainOfflineScores.map((item) => ({
        id: item._criteriaTitle,
        classScore: validateScore(item._classAverageScore, true),
        title: item._criteriaTitle,
        classScoreColor: "rgba(98, 195, 238, 1)",
        studentScore: validateScore(item._studentScore, true),
        studentScoreColor: "rgba(117, 135, 152, 1)",
      })),
    ];
  }

  return offlineData;
};

export const createSelectResponse = (
  data: IFetchClassesEntity[]
): IExtendedOption[] => {
  return data.map(({ _classId, _className, _students }) => {
    return {
      label: _className,
      value: _classId,
      students: _students.map(({ _studentId, _studentName }) => {
        return {
          label: _studentName,
          value: _studentId,
        };
      }),
    };
  });
};

export const getPacingReportData = (data: IPacingReportEntity) => {
  let students: IPacingStudentDetails[];
  let datareport: IReportData;
  if (data?._students?.length) {
    datareport = formatReportData(data);
    students = [
      ...data._students.map((item) => ({
        name: item._name,
        gender: item._gender,
        studentId: item._studentId,
        avatarUrl: item._avatarUrl,
        totalCount: item._totalCount,
        percentage: item._percentage,
        completedCount: item._completedCount,
      })),
    ];
    const stundentsList: [IPacingStudentDetails[], IPacingStudentDetails[]] = [
      [],
      [],
    ];
    const chunk = Math.ceil(students.length / 2);
    const rightGridData = students.slice(0, chunk);
    const leftGridData = students.slice(chunk, chunk + students.length);
    stundentsList[0] = rightGridData;
    stundentsList[1] = leftGridData;
    return { stundentsList, datareport };
  }
  return undefined;
};

const colors: {
  [key: string]: "rgba(248, 189, 136, 1)" | "rgba(255, 234, 211, 1)";
} = {
  classworkComplete: "rgba(248, 189, 136, 1)",
  classworkInComplete: "rgba(255, 234, 211, 1)",
};
const formatReportData = (response: IPacingReportEntity) => {
  const chartData: [IColorSchema, IColorSchema] = [
    {
      id: 1,
      value: response._percentage,
      color: colors.classworkComplete,
    },
    {
      id: 2,
      value: 100 - response._percentage,
      color: colors.classworkInComplete,
    },
  ];
  const datareport: IReportData = {
    label: "Assignments Progress",
    labelColor: colors.classworkComplete,
    value: response._percentage,
    chartData,
  };
  return datareport;
};

export interface IDeliverable {
  code: string;
  contentId: string;
  contentTitleAr: string;
  contentTitleEn: string;
  gamesAvailable: boolean;
  deliverableDate: string;
  isMergeable: boolean;
  learningObjectivePrefixArabic: string;
  learningObjectivePrefixEnglish: string;
  playCount: number;
  status?: string;
  thumbUrl: string;
  topic?: string;
  url: string;
}
interface IAssignmentDetails {
  name: string;
  gender: string;
  avatarUrl?: string;
  deliverables?: IDeliverable[];
}
export const mapAssignmentData = (response: IStudentAssignmentEntity) => {
  if (response) {
    const assignmentDetails: IAssignmentDetails = {
      name: response._name,
      gender: response._gender,
      avatarUrl: response._avatarUrl,
    };
    if (response._deliverables?.length) {
      assignmentDetails.deliverables = [
        ...response._deliverables.map((item) => ({
          code: item._code,
          contentId: item._contentId,
          contentTitleAr: item._contentTitleAr,
          contentTitleEn: item._contentTitleEn,
          gamesAvailable: item._gamesAvailable,
          deliverableDate: item._deliverableDate,
          isMergeable: item._isMergeable,
          learningObjectivePrefixArabic: item._learningObjectivePrefixArabic,
          learningObjectivePrefixEnglish: item._learningObjectivePrefixEnglish,
          playCount: item._playCount,
          status: item._status,
          thumbUrl: item._thumbUrl,
          topic: item._topic,
          url: item._url,
        })),
      ];
    }
    return assignmentDetails;
  }
  return undefined;
};
