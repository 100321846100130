import { SearchIcon, CloseIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { searchQuestionSet, selectSearchQuestion } from "features/exams";
import { useContext, useEffect, useState } from "react";
import usePrevious from "hooks/usePrevious";
import { MasterDataContext } from "contexts/MasterDataContext";

const customStyles = (dir: "ltr" | "rtl") => ({
  root: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: "50%",
    height: "38px",
    width: "fit-content",
    zIndex: 2,
    transform: "translateY(-50%)",
  },
  searchContainer: {
    width: "100%",
    height: "38px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(217 216 216)",
    borderRadius: "6px",
    fontSize: "16px",
    cursor: "pointer",
    "&:hover": {
      borderColor: "rgb(98 195 238)",
    },
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "250px",
    "& *": {
      border: "none",
    },
    "& input": {
      width: "100%",
      fontSize: "16px",
    },
  },
  closeIcon: {
    padding: "0 8px",
    alignItems: "center",
    display: "inline-flex",
    "& svg": {
      width: "10px",
      height: "10px",
      color: "rgb(169 175 187)",
    },
  },
  searchIcon: {
    width: "26px",
    height: "26px",
    ...(dir === "rtl" ? { marginRight: "16px" } : { marginLeft: "16px" }),
  },
});

function SearchBy() {
  const { logEvent } = useContext(MasterDataContext);
  const { t, i18n } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const prevClickedState = usePrevious(clicked);
  const dispatch = useAppDispatch();
  const searchQuestion = useAppSelector(selectSearchQuestion);
  const classes = customStyles(i18n.dir());
  const changeHandler = (event: { target: { value: string } }) => {
    const searchText = event.target.value;
    dispatch(searchQuestionSet(searchText));
  };

  const clickedHandler = () => {
    console.log({ prevClickedState, clicked });
    if (!prevClickedState && !clicked) {
      logEvent("ClickOnABSearch");
      setClicked(true);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(searchQuestionSet(""));
    };
  }, []);

  return (
    <Typography
      component="div"
      sx={classes.root}
      style={{
        direction: i18n.dir(),
        ...(i18n.dir() === "rtl" ? { left: "10px" } : { right: "10px" }),
      }}
    >
      <Typography component="div" sx={classes.searchContainer}>
        <Typography
          component="div"
          sx={classes.searchText}
          onClick={clickedHandler}
        >
          <SearchIcon sx={classes.searchIcon} />
          <input
            type="text"
            placeholder={t("Search")}
            value={searchQuestion}
            onChange={changeHandler}
          />
          {searchQuestion?.length ? (
            <Typography
              component="div"
              sx={classes.closeIcon}
              onClick={() => dispatch(searchQuestionSet(""))}
            >
              <CloseIcon />
            </Typography>
          ) : null}
        </Typography>
      </Typography>
    </Typography>
  );
}

export default SearchBy;
