import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { CSSProperties } from "@mui/styles";
import { ErrorIcon } from "../SVGs";
import { Typography } from "@mui/material";

const customStyles = {
  root: {
    display: "flex",
    flexDirection: "column" as "column",
    flexGrow: 1,
    color: "rgb(123 130 143)",
    fontSize: "16px",
    "& label": {
      fontWeight: "bold",
    },
    "& *": {
      textAlign: "initial",
      borderWidth: "1px !important",
    },
    " & legend": {
      width: 0,
    },
  },
  adornment: {
    "& p": {
      fontSize: "16px",
      borderLeft: "1px solid rgb(217 216 216)",
      paddingLeft: "8px",
    },
  },
  inputField: {
    ".MuiInputBase-root": {
      padding: "0",
      height: "38px",
      backgroundColor: "rgb(255 255 255)",
      fontSize: "16px",
      borderRadius: "8px",
      "& input": {
        padding: "7px 18px",
      },
      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: " 1px solid rgb(98 195 238)",
        },
      },
      "& fieldset": {
        border: "1px solid rgb(217 216 216)",
      },
    },
  },
  disabled: {
    ".MuiInputBase-root": {
      backgroundColor: "rgb(241 241 241)",
      border: "none",
      height: "38px",
      borderRadius: "8px",
      fontSize: "16px",
      "& input": {
        color: "rgb(220 220 220)",
      },
      "&:hover": {
        ".MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      },
      "& fieldset": {
        border: "none",
      },
    },
  },
  helperText: {
    position: "absolute",
    display: "flex",
    alignItems: "flex-start",
    top: "100%",
    fontSize: "12px",
    textAlign: "initial",
    margin: "0px",
    lineHeight: "20px",
  },
};

interface IInputProps {
  name: string;
  label?: string;
  placeholder: string;
  type?: string;
  errors: string;
  value?: string;
  autoFocus?: boolean;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  styles?: CSSProperties;
  onBlur?(): void;
  tabIndex?: number;
  inputRef?: React.Ref<any>;
  id?: string;
  defaultValue?: string;
  displayErrorIcon?: boolean;
  adornmentVal?: JSX.Element;
  style?: { root: CSSProperties };
  disabled?: boolean;
  "data-test"?: string;
}

function Input({
  placeholder,
  id,
  name,
  type = "text",
  value,
  defaultValue,
  label,
  errors,
  displayErrorIcon = false,
  adornmentVal,
  onChange,
  onBlur,
  inputRef,
  autoFocus = false,
  style = { root: {} },
  disabled = false,
  ...rest
}: IInputProps) {
  return (
    <Typography
      component="div"
      sx={{ ...customStyles.root, ...style.root }}
      {...rest}
    >
      {label?.length ? <label htmlFor={name}>{label} &nbsp;</label> : null}
      <TextField
        variant="outlined"
        autoFocus={autoFocus}
        inputRef={inputRef}
        disabled={disabled}
        type={type}
        id={id}
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        sx={{
          ...customStyles.inputField,
          ...(disabled ? customStyles.disabled : {}),
        }}
        helperText={
          displayErrorIcon && errors ? (
            <>
              <ErrorIcon style={{ fontSize: "1.2rem" }} />
              <span>{errors}</span>
            </>
          ) : (
            errors
          )
        }
        FormHelperTextProps={{ sx: customStyles.helperText }}
        error={errors ? true : false}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={customStyles.adornment}>
              {adornmentVal}
            </InputAdornment>
          ),
        }}
      />
    </Typography>
  );
}

export default Input;
