import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function Twitter(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 41">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4FBDEA"
          d="M19.859 1.091C8.91 1.091 0 9.785 0 20.467c0 10.686 8.91 19.378 19.859 19.378 10.953 0 19.864-8.692 19.864-19.378 0-10.682-8.911-19.376-19.864-19.376z"
        />
        <path
          fill="#FFF"
          d="M27.001 16.677c.006.155.01.313.01.472 0 4.803-3.632 10.342-10.275 10.342-2.04 0-3.938-.599-5.536-1.633a7.212 7.212 0 0 0 5.347-1.504 3.62 3.62 0 0 1-3.372-2.527 3.545 3.545 0 0 0 1.629-.062 3.63 3.63 0 0 1-2.898-3.563c0-.016 0-.03.003-.047a3.544 3.544 0 0 0 1.636.454 3.636 3.636 0 0 1-1.608-3.026c0-.665.18-1.29.488-1.827a10.23 10.23 0 0 0 7.442 3.8 3.678 3.678 0 0 1-.093-.83c0-2.007 1.617-3.635 3.611-3.635 1.04 0 1.977.442 2.635 1.146a7.108 7.108 0 0 0 2.296-.882 3.647 3.647 0 0 1-1.59 2.013 7.118 7.118 0 0 0 2.074-.57 7.375 7.375 0 0 1-1.799 1.879z"
        />
      </g>
    </SvgIcon>
  );
}

export function Facebook(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#4FBDEA"
          d="M39.892 19.727c0 10.895-8.93 19.727-19.946 19.727C8.931 39.454 0 30.622 0 19.727 0 8.833 8.93 0 19.946 0c11.016 0 19.946 8.833 19.946 19.727z"
        />
        <path
          fill="#FFF"
          d="M24.414 19.98h-2.848V30.4h-4.314V19.98H15.2v-3.662h2.052v-2.37c0-1.694.805-4.348 4.353-4.348l3.195.014v3.554h-2.318c-.381 0-.916.19-.916.998v2.156h3.226l-.378 3.659z"
        />
      </g>
    </SvgIcon>
  );
}
