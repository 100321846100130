import { Grid, Paper } from "@mui/material";
import { useState } from "react";
import Formative from "./formative";
import BaseLine from "./baseline";
import Toolbar from "./Toolbar";
import TooltipBar from "./TooltipBar";
import PrintReports from "./PrintReports";
import { selectReport } from "features/classPerformance";
import { useAppDispatch, useAppSelector } from "app/hooks";
import GradesList from "components/GradeList";
import { gradeSet, selectGrade } from "features/classPerformance";
import { useTranslation } from "react-i18next";
import { clearEvaluationVars } from "features/evaluation";
import { Assessment, IGrade } from "features/common/Interfaces";
import { selectGrades } from "features/filters";
import DataNotFound from "components/DataNotFound";
import Exam from "./Exam";
const customStyles = {
  container: {
    background: "rgba(255, 255, 255, 1)",
    borderRadius: " 0 0 18px 18px",
  },
  toolBar: {
    background: "rgba(247, 248, 250, 1)",
    margin: "30px 10px",
    padding: "0 10px",
    minHeight: "75px",
    height: "fit-content",
  },
  content: {
    borderRadius: "18px",
  },
};

export default function ClassPerformance() {
  const [printing, setPrinting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectedGrade = useAppSelector(selectGrade);
  const selectedReport = useAppSelector(selectReport);
  const ownedGrades = useAppSelector(selectGrades).filter(
    (grade) => grade.owned
  );

  const report = () => {
    switch (selectedReport.value) {
      case Assessment.EXAM:
        return <Exam />;
      case Assessment.FORMATIVE:
      default:
        return <Formative />;
    }
  };

  const clickHandler = (grade: IGrade) => {
    dispatch(gradeSet(grade));
  };
  clearEvaluationVars();
  return (
    <>
      <GradesList
        onGradeChange={clickHandler}
        selectedGrade={selectedGrade}
        getIsGradeLoading={setIsLoading}
      />
      {ownedGrades.length > 0 ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          sx={{ ...customStyles.container, direction: i18n.dir() }}
        >
          {printing ? (
            <PrintReports />
          ) : (
            <>
              <Grid item xs={12}>
                <Paper elevation={0} sx={customStyles.toolBar}>
                  <Toolbar setPrinting={setPrinting} />
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper elevation={0} sx={customStyles.content}>
                  {report()}
                </Paper>
                <div style={{ height: "50px" }}></div>
              </Grid>
            </>
          )}
        </Grid>
      ) : isLoading ? null : (
        <DataNotFound text={t("no classes")} />
      )}
      <TooltipBar />
    </>
  );
}
