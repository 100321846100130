import { makeStyles } from "@mui/styles";
import DataCard from "../../shared/DataCard";
import { selectReport, IDomainReport } from "features/classPerformance";
import { useAppSelector } from "app/hooks";
import { Assessment } from "features/common/Interfaces";

const useStyels = makeStyles({
  tooltipText: {
    fontSize: "1rem",
    textAlign: "center",
    display: "flex",
    direction: "rtl",
  },
});

interface ILoCardProps {
  data: IDomainReport;
  renderStudentMarksCell(student: any, loId: any): JSX.Element;
}
function LoCard({ data, renderStudentMarksCell }: ILoCardProps) {
  const report = useAppSelector(selectReport);
  const classes = useStyels();
  return (
    <DataCard
      id={data?.id}
      text={
        (report.value === Assessment.FORMATIVE ||
        report.value === Assessment.EXAM
          ? data?.lessonTitle
          : data?.loTitle)!
      }
      tooltip={
        <span className={classes.tooltipText}>
          {data?.loTitle ?? data.lessonTitle}
        </span>
      }
      students={data?.students!}
      renderStudentMarksCell={renderStudentMarksCell}
      showElipses={report.value === Assessment.FORMATIVE ? false : true}
    />
  );
}
export default LoCard;
