import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IGrade, IMonthOption, IOption } from "../common/Interfaces";

export interface IPacingStudentDetails {
  name: string;
  gender: string;
  studentId: number;
  avatarUrl?: string;
  totalCount: number;
  percentage: number;
  completedCount: number;
}
export interface IColorSchema {
  id: 1 | 2;
  value: number;
  color: "rgba(248, 189, 136, 1)" | "rgba(255, 234, 211, 1)";
}

export interface IReportData {
  label: string;
  labelColor: string;
  value: number;
  chartData: [IColorSchema, IColorSchema];
}

interface IPacingReportState {
  grade?: IGrade;
  studentList: [IPacingStudentDetails[], IPacingStudentDetails[]] | [];
  reportData?: IReportData;
  class?: IOption;
  selectedPlanMonth?: IMonthOption;
  selectedStudent?: IPacingStudentDetails;
}

const initialState: IPacingReportState = {
  studentList: [],
};

export const pacingReportSlice = createSlice({
  name: "pacing",
  initialState,
  reducers: {
    gradeSet(state, action: PayloadAction<IGrade>) {
      state.grade = action.payload;
    },
    studentListSet(
      state,
      action: PayloadAction<
        [IPacingStudentDetails[], IPacingStudentDetails[]] | []
      >
    ) {
      state.studentList = action.payload;
    },
    reportDataSet(state, action: PayloadAction<IReportData | undefined>) {
      state.reportData = action.payload;
    },
    classSet(state, action: PayloadAction<IOption>) {
      state.class = action.payload;
    },
    selectedPlanMonthSet(state, action: PayloadAction<IMonthOption>) {
      state.selectedPlanMonth = action.payload;
    },

    selectedStudentSet(
      state,
      action: PayloadAction<IPacingStudentDetails | undefined>
    ) {
      state.selectedStudent = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.pacing.grade;
export const selectStudentList = (state: RootState) => state.pacing.studentList;
export const selectReportData = (state: RootState) => state.pacing.reportData;
export const selectClass = (state: RootState) => state.pacing.class;
export const selectSelectedPlanMonth = (state: RootState) =>
  state.pacing.selectedPlanMonth;
export const selectSelectedStudent = (state: RootState) =>
  state.pacing.selectedStudent;

export const {
  gradeSet,
  studentListSet,
  reportDataSet,
  classSet,
  selectedPlanMonthSet,
  selectedStudentSet,
} = pacingReportSlice.actions;

export default pacingReportSlice.reducer;
