import { makeStyles } from "@mui/styles";
import { useAppSelector } from "app/hooks";
import LoThumb from "components/LoThumb";
import { selectAssignExam } from "features/exams";
import { LessonTypes } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    margin: "30px",
  },
  losList: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "15px",
  },
});

function ExamThumbList() {
  const classes = useStyles();
  const assignExam = useAppSelector(selectAssignExam);

  return (
    <div className={classes.losList}>
      <LoThumb
        lesson={{
          loTitle: assignExam.name!,
          type: LessonTypes.EXAMS,
        }}
        fixedWidth="170px"
      />
    </div>
  );
}

export default ExamThumbList;
