import { API, IResponse, SlicesTags } from "./API";

interface INotificationPayload {
  _classId: number;
  _gradeId: number;
  _className: string;
  _studentCount: number;
}

export enum NotificationStatus {
  notSeen = "NEW",
  seen = "SEEN_IN_FLYOUT",
  visited = "SEEN",
}
export enum NotificationTypes {
  FORMATIVE_OFFLINE_COMPLETION = "FORMATIVE_OFFLINE_COMPLETION",
  BASELINE_OFFLINE_COMPLETION = "BASELINE_OFFLINE_COMPLETION",
}
export interface IFetchNotificationEntity {
  _date: string;
  _entityId: null;
  _entityType: null;
  _expirationTime: string;
  _id: number;
  _message: string;
  _payload: INotificationPayload;
  _status: NotificationStatus;
  _type: NotificationTypes;
}

interface IFetchNotificationsResponse extends IResponse {
  _entity: IFetchNotificationEntity[];
}
interface IUpdateNotification {
  _id: number;
  _status: string;
}
interface IUpdateNotificationRequest {
  _notifications: IUpdateNotification[];
}
const notificationServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchNotifications: build.query<IFetchNotificationsResponse, void>({
        query() {
          return "ams/user/notifications";
        },
        providesTags: [SlicesTags.Notification],
      }),
      updateNotification: build.mutation<IResponse, IUpdateNotificationRequest>(
        {
          query(payload) {
            return {
              url: "/ams/notifications/update-status",
              method: "POST",
              body: payload,
            };
          },
          invalidatesTags: [SlicesTags.Notification],
        }
      ),
    };
  },
});

export const { useFetchNotificationsQuery, useUpdateNotificationMutation } =
  notificationServices;
