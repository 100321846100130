import { Divider, Typography } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { BreadcrumbLabels } from "./BreadcrumbLabels";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Direction } from "features/common/Interfaces";

const useStyles = makeStyles({
  subMenu: {
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: 0,
    background: "rgba(228, 230, 238, 1)",
    height: "60px",
    margin: "-25px -30px 0px",
    "& li": {
      position: "relative",
    },
    "@media print": {
      display: "none",
    },
  },
  tab: {
    position: "relative",
  },
  selectedTab: {
    display: "inline-block",
    boxShadow: "inset 0 -3px 0 rgba(111, 117, 138, 1)",
    fontWeight: "bold",
    height: "60px",
    padding: "0 5px",
    lineHeight: "60px",
    whiteSpace: "nowrap",
  },
  inActiveTab: {
    display: "inline-block",
    color: "rgba(111, 117, 138, 1)",
    fontSize: "0.9rem",
    cursor: "pointer",
    padding: "0 5px",
    whiteSpace: "nowrap",
    "&:hover": {
      fontWeight: "bold",
    },
  },
});
interface IBreadcrumbProps {
  "data-test"?: string;
}
export default function Breadcrumb(props: IBreadcrumbProps) {
  const [direction, setDirection] = useState<Direction>();
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  let { pathname: url } = useLocation();
  let newUrl = url.split("/");
  newUrl.shift();

  useEffect(() => {
    const storedDir = localStorage.getItem("dir") as Direction | null;
    setDirection(storedDir ?? i18n.dir());
  }, []);

  useEffect(() => {
    setDirection(i18n.dir());
  }, [i18n.dir()]);

  let label1 = BreadcrumbLabels[newUrl[0]]
    ? BreadcrumbLabels[newUrl[0]]["label"]
    : null;
  let label2 = BreadcrumbLabels[newUrl[1]]
    ? BreadcrumbLabels[newUrl[1]]["label"]
    : null;
  let label3 = newUrl[2]
    ? BreadcrumbLabels[newUrl[2]]
      ? BreadcrumbLabels[newUrl[2]]["label"]
      : null
    : null;
  const customStyles = {
    breadcrumb: {
      display: "block",
      position: "relative",
      height: "60px",
      width: "175px",
      padding: direction === "rtl" ? "0 30px 0 20px" : "0 20px 0 30px",
      lineHeight: "60px",
      color: "rgba(111, 117, 138, 1)",
      fontWeight: "bold",
      fontSize: "14px",
      textAlign: direction === "rtl" ? "left" : "right",
      whiteSpace: "nowrap",
      "&:after": {
        content: '""',
        position: "absolute",
        top: 6,
        ...(direction === "rtl" ? { left: "-22px" } : { right: "-22px" }),
        bottom: 0,
        background: "inherit",
        width: 48,
        height: 48,
        transform: direction === "rtl" ? "rotate(45deg)" : "rotate(225deg)",
        borderRadius: "10px",
        zIndex: 2,
        clipPath: "polygon(0% 0%, 100% 100%, 0% 100%)",
      },
    },

    link: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    inActive: {
      pointerEvents: "none",
    },
  };
  return label1 ? (
    <ul className={classes.subMenu} style={{ direction }} {...props}>
      <li>
        <Typography
          style={{ background: "rgba(209, 212, 225, 1)" }}
          sx={customStyles.breadcrumb}
        >
          {t(label1)}
        </Typography>
      </li>
      {label2 && (
        <li>
          <Typography
            style={{ background: "rgba(219, 220, 230, 1)" }}
            sx={customStyles.breadcrumb}
          >
            {t(label2)}
          </Typography>
        </li>
      )}
      {label3 &&
        Array.isArray(BreadcrumbLabels[newUrl[2]]["tab"]) &&
        BreadcrumbLabels[newUrl[2]]?.["tab"]?.map((item, index) => (
          <li key={index}>
            <span
              onClick={item.path ? () => history.push(item.path!) : undefined}
              className={
                item.label === label3
                  ? classes.selectedTab
                  : classes.inActiveTab
              }
              style={{
                ...(index === 0
                  ? direction === "rtl"
                    ? { marginRight: "50px" }
                    : { marginLeft: "50px" }
                  : null),
              }}
            >
              {t(item.label)}
            </span>
            {BreadcrumbLabels[newUrl[2]]["tab"]!.length - 1 !== index ? (
              <Divider
                sx={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  height: "25px",
                  margin: "auto 20px",
                  width: "1px",
                  backgroundColor: "rgba(188, 190, 201, 1)",
                }}
                orientation="vertical"
              />
            ) : null}
          </li>
        ))}
    </ul>
  ) : null;
}
