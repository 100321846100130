import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import useClientDevice from "hooks/useClientDevice";
import { SaSubmittedIcon } from "../../../SVGs";
import { useTranslation } from "react-i18next";
import useAssessmentAction from "./ActionsHooks/Assessment";
import {
  NotificationStatus,
  NotificationTypes,
  useUpdateNotificationMutation,
} from "services/notifications";
import { MasterDataContext } from "contexts/MasterDataContext";

const useStyles = makeStyles({
  new: {
    backgroundColor: "rgba(244, 244, 244, 1)",
    borderLeft: "5px solid rgba(98, 195, 238, 1)",
    "& *": {
      color: "rgba(86, 86, 86, 1) !important",
    },
  },
  notification: {
    width: "100%",
    display: "flex",
    padding: "5px 0 0",
    "& *": {
      color: "rgba(153, 153, 153, 1) !important",
      fontSize: "12px !important",
    },
    "& svg": {
      fontSize: "1.5rem !important",
      padding: "2px",
    },
  },
  message: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});

export interface INotification {
  id: number;
  date: string;
  type: NotificationTypes;
  studentCount: number;
  className: string;
  classId: number;
  gradeId: number;
  status: NotificationStatus;
}

const Notification = ({ info }: { info: INotification }) => {
  const { logEvent } = useContext(MasterDataContext);
  const { t } = useTranslation();
  const [visited, setVisited] = useState(false);
  const { isiPadoriPadPro } = useClientDevice();
  const evaluateAssessment = useAssessmentAction(info);
  const [updateNotification] = useUpdateNotificationMutation();

  useEffect(() => {
    setVisited(info.status === NotificationStatus.visited ? true : false);
  }, [info.status]);
  const notificationTypes = {
    [NotificationTypes.FORMATIVE_OFFLINE_COMPLETION]: {
      icon: <SaSubmittedIcon />,
      message: (data: INotification) =>
        t("FormativeOfflineCompletion", { data }),
      action: evaluateAssessment,
    },
    [NotificationTypes.BASELINE_OFFLINE_COMPLETION]: {
      icon: <SaSubmittedIcon />,
      message: (data: INotification) =>
        t("BaselineOfflineCompletion", { data }),
      action: evaluateAssessment,
    },
  };
  const visitHandler = async (info: INotification) => {
    try {
      setVisited(true);
      const payload = [
        {
          _id: info.id,
          _status: NotificationStatus.visited,
        },
      ];
      const response = await updateNotification({
        _notifications: payload,
      }).unwrap();
      if (response._statusCode === 200) {
        notificationTypes[info.type].action();
        logEvent("ClickOnNotification");
      } else throw response;
    } catch (error) {
      console.log(error);
    }
  };

  const classes = useStyles();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
  };
  const { type, date } = info;
  const locale = isiPadoriPadPro ? "ar-AE" : "ar";
  const arDate = new Date(date.replace(" ", "T")).toLocaleDateString(
    locale,
    options
  );
  return (
    <div
      id="notification"
      onClick={() => visitHandler(info)}
      className={classNames(classes.notification, !visited && classes.new)}
      // style={{ direction: i18n.dir() }}
    >
      {notificationTypes[type]?.icon}
      <div className={classes.message}>
        <span>{arDate}</span>
        <span style={{ fontWeight: "bold" }}>
          {notificationTypes[type]?.message(info)}
        </span>
      </div>
    </div>
  );
};

export default Notification;
