import { useEffect, useState } from "react";
import Filter from "./Filter";
import FiltersHoc, { IChildCompProps } from "./FiltersHoc";
import { makeStyles } from "@mui/styles";
import { Chip } from "@mui/material";
import { RemoveIcon } from "components/SVGs";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  levelOneFilterSet,
  levelTwoFilterSet,
  searchSet,
  selectLevelOneFilter,
  selectSearch,
} from "features/content";
import { FilterIcons } from "./Filter";
import { useTranslation } from "react-i18next";
import { ILevelFilter, selectSelectedGradeGroup } from "features/filters";
import { LessonTypes } from "features/common/Interfaces";

const customStyles = {
  chip: {
    borderRadius: "0.25rem",
    margin: "0.5rem 0.2rem",
    color: "rgba(105, 123, 140, 1)",
    width: "auto",
    padding: "0 10px 0 5px",
    flexDirection: "row-reverse",
    "& span": {
      direction: "rtl",
      paddingRight: 0,
    },
    "@media screen and (max-width: 768px)": {
      fontSize: "0.5rem",
      width: "5rem",
    },
  },
  removeIcon: {
    paddingLeft: "5px",
    margin: "0 !important",
    width: "12px",
  },
};
const useStyles = makeStyles({
  tags: {
    direction: "ltr",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    minHeight: "fit-content",
    flex: 1,
    "&>*": {
      fontSize: "0.75rem !important",
    },
  },
});

function RegularFilters({
  lessonsFilters,
  selectedGrade,
  dispatchLessonsCall,
  levelOneFilters,
  setLevelOneFilters,
  levelTwoFilters,
  setLevelTwoFilters,
  selectedLevelOneFilter,
  selectedLevelTwoFilter,
  setSelectedLevelTwoFilter,
  setSelectedLevelOneFilter,
  freePlayFilters,
  guidedReadingFilters,
  selectedSection,
}: IChildCompProps) {
  const { t, i18n } = useTranslation();
  const [levelOneTitle, setLevelOneTitle] = useState("");
  const [levelTwoTitle, setLevelTwoTitle] = useState("");
  const dispatch = useAppDispatch();

  const levelOneFitler = useAppSelector(selectLevelOneFilter);
  const selectedGrGroup = useAppSelector(selectSelectedGradeGroup);
  const search = useAppSelector(selectSearch);
  const classes = useStyles();

  useEffect(() => {
    if (
      selectedSection === LessonTypes.LESSON &&
      Object.keys(lessonsFilters).length > 0 &&
      selectedGrade
    ) {
      let domainFilters = lessonsFilters[selectedGrade.id].domains;
      const updatedWithIcons = (
        JSON.parse(JSON.stringify(domainFilters)) as ILevelFilter[]
      ).map((f) => {
        f.icon = f.code as FilterIcons;
        return f;
      });
      setLevelOneFilters(updatedWithIcons);
      setSelectedLevelOneFilter({ ...domainFilters[0] });
      setLevelOneTitle("Language Domains");
      setLevelTwoTitle("Sub Domains");
    } else if (
      selectedSection === LessonTypes.GUIDED_READING &&
      guidedReadingFilters.length
    ) {
      setLevelOneFilters(guidedReadingFilters);
      setSelectedLevelOneFilter({ ...guidedReadingFilters[0] });
      setLevelOneTitle("Levels");
    } else if (
      selectedSection === LessonTypes.FREEPLAY &&
      freePlayFilters.length
    ) {
      const updatedWithIcons = (
        JSON.parse(JSON.stringify(freePlayFilters)) as ILevelFilter[]
      ).map((f) => {
        f.icon = f.code as FilterIcons;
        f.title = t(f.code);
        return f;
      });
      setLevelOneFilters(updatedWithIcons);
      setSelectedLevelOneFilter({ ...freePlayFilters[0] });
      dispatch(levelOneFilterSet(freePlayFilters[0]));
      setLevelOneTitle("mainActivities");
      setLevelTwoTitle(
        freePlayFilters[0].code === "Character Activities" ? "Letters" : "Age"
      );
    }
  }, [lessonsFilters, selectedGrade, selectedSection]);

  useEffect(() => {
    if (
      selectedSection === LessonTypes.GUIDED_READING &&
      guidedReadingFilters.length
    ) {
      setLevelOneFilters(guidedReadingFilters);
      setSelectedLevelOneFilter({ ...guidedReadingFilters[0] });
      setLevelOneTitle("Levels");
    }
  }, [selectedGrGroup]);

  useEffect(() => {
    if (selectedSection === LessonTypes.FREEPLAY && freePlayFilters.length) {
      const updatedWithIcons = (
        JSON.parse(JSON.stringify(freePlayFilters)) as ILevelFilter[]
      ).map((f) => {
        f.icon = f.code as FilterIcons;
        f.title = t(f.code);
        return f;
      });
      setLevelOneFilters(updatedWithIcons);
    }
  }, [i18n.dir()]);

  useEffect(() => {
    if (selectedLevelOneFilter) {
      let levelTwoFilters = levelOneFilters?.filter(
        (filter) => filter.code === selectedLevelOneFilter.code
      );
      const filtersOflevel2 = (levelTwoFilters as ILevelFilter[])?.[0]
        ?.subGroups;
      if (filtersOflevel2?.length) {
        setLevelTwoFilters(filtersOflevel2);
        setSelectedLevelTwoFilter({ ...filtersOflevel2[0] });
        dispatch(levelTwoFilterSet({ ...filtersOflevel2[0] }));
        dispatchLessonsCall({
          _groupCode: selectedLevelOneFilter.code,
          _subGroupCode: filtersOflevel2[0].code,
        });
      } else {
        setLevelTwoFilters([]);
        dispatchLessonsCall({
          _groupCode: selectedLevelOneFilter.code,
        });
      }
    }
  }, [selectedLevelOneFilter]);

  const setLevelTwoFilter = (group: ILevelFilter) => {
    setSelectedLevelTwoFilter(group);
    dispatch(levelTwoFilterSet(group));
    dispatchLessonsCall({
      _groupCode: selectedLevelOneFilter?.code,
      _subGroupCode: group.code,
    });
  };

  const handleRemoveTag = (tagToRemove: any) => {
    const newSearchedTags = [...(search as any)].filter(
      (tag) => tag.id !== tagToRemove.id
    );
    dispatch(searchSet(newSearchedTags));
  };

  return (
    <>
      {levelOneFilters?.length ? (
        <Filter
          data={levelOneFilters}
          clickHandler={(group) => {
            setSelectedLevelOneFilter(group);
            setLevelTwoTitle(
              selectedSection === LessonTypes.FREEPLAY
                ? ["Printable Worksheets", "E-Books"].includes(group.code)
                  ? "Age"
                  : "Letters"
                : "Sub Domains"
            );
            dispatch(levelOneFilterSet(group));
          }}
          selectedFilter={selectedLevelOneFilter!}
          title={levelOneTitle}
        />
      ) : null}
      {levelTwoFilters?.length ? (
        <div style={{ paddingTop: "15px" }}>
          <Filter
            data={(
              JSON.parse(JSON.stringify(levelTwoFilters)) as ILevelFilter[]
            ).map((f) => {
              if (f.code === "ALL") f.title = t("All");
              return f;
            })}
            clickHandler={setLevelTwoFilter}
            selectedFilter={selectedLevelTwoFilter!}
            title={levelTwoTitle}
          />
        </div>
      ) : null}
      {(selectedSection === LessonTypes.GUIDED_READING ||
        (selectedSection === LessonTypes.FREEPLAY &&
          levelOneFitler?.code === "E-Books")) &&
      Array.isArray(search) &&
      search.length ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{ fontWeight: "bold", padding: "10px", fontSize: "16px" }}
          >
            {t("Tags")}
          </span>
          <div className={classes.tags}>
            {search.map((tag: any, index) => (
              <Chip
                key={index}
                label={tag.name}
                role={undefined}
                deleteIcon={<RemoveIcon sx={customStyles.removeIcon} />}
                onDelete={() => handleRemoveTag(tag)}
                sx={customStyles.chip}
              />
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

export default FiltersHoc(RegularFilters);
