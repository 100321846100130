import { ChangeEvent, CSSProperties, SyntheticEvent, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import { DownIcon, InProgress } from "../SVGs";
import finishedIcon from "assets/img/ic_medal_on.svg";
import DataNotFound from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { IClass } from "features/assignments";

const useStyles = makeStyles({
  students: {
    display: "grid",
    alignItems: "center",
    width: "100%",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    "@media screen and (max-width: 1400px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    "@media screen and (max-width: 1024px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& img": {
      height: "30px",
      width: "30px",
      marginLeft: "5px",
    },
  },
  stuName: {
    fontSize: "0.9rem",
    lineHeight: 1,
    padding: "9px 0px",
  },
});

const noDataStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "150px",
  justifyContent: "center",
  width: "100%",
  "& > span": {
    fontSize: "0.8rem",
    width: "30vw",
    textAlign: "center",
    color: "rgba(105, 123, 140, 1)",
    paddingTop: "1rem",
  },
  "& > svg": {
    fontSize: "4rem",
  },
};
interface IAccordionWithCheckboxProps {
  selectedClasses: IClass[];
  classCheckboxHandler(
    event: ChangeEvent<HTMLInputElement>,
    classId: number
  ): void;
  studentCheckboxHandler(
    event: ChangeEvent<HTMLInputElement>,
    studentId: number,
    classId: number
  ): void;
  status?: { In_Progress?: any; Completed?: any };
  completeEnable?: boolean;
  "data-test"?: string;
}
function AccordionWithCheckbox({
  selectedClasses,
  classCheckboxHandler,
  studentCheckboxHandler,
  status = {},
  completeEnable = false,
  ...rest
}: IAccordionWithCheckboxProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [expanded, setExpanded] = useState<number>();

  const handleChange =
    (panel: number) => (_: SyntheticEvent<Element>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : 0);
    };

  return (
    <Grid container {...rest}>
      {selectedClasses.map((data, index) => (
        <Grid
          style={{
            width: "100%",
            marginTop: index !== 0 ? "0.6rem" : undefined,
          }}
          key={data.classId}
        >
          <Accordion
            sx={{ boxShadow: "none" }}
            expanded={expanded === data.classId}
            onChange={handleChange(data.classId!)}
          >
            <AccordionSummary
              sx={{
                backgroundColor: "rgba(239, 241, 246, 1)",
                "& .MuiAccordionSummary-content": {
                  margin: "0 !important",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                },
              }}
              expandIcon={
                <>
                  <DownIcon
                    className="downIcon"
                    style={{ padding: "0.5rem" }}
                  />
                </>
              }
            >
              <FormControlLabel
                sx={{
                  fontSize: "1rem",
                  "& .MuiFormControlLabel-label": {
                    fontSize: "1rem",
                  },
                  "& svg": {
                    fontSize: "1.5rem",
                  },
                }}
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Checkbox
                    color="primary"
                    checked={data.checked}
                    disabled={data.students.length === 0 ? true : false}
                    onChange={(event) =>
                      classCheckboxHandler(event, data.classId!)
                    }
                  />
                }
                label={t("Select all students")}
              />
              <span style={{ fontSize: "1rem" }}>{data.className}</span>
            </AccordionSummary>
            <AccordionDetails>
              {data.students.length ? (
                <div className={classes.students}>
                  {data.students.map((student, idx) => (
                    <div className={classes.wrapper} key={idx}>
                      {student.status &&
                      Object.keys(status).length &&
                      student.status === status.In_Progress ? (
                        <InProgress
                          style={{ fontSize: "1.3rem", margin: "9px" }}
                        />
                      ) : student.status === status.Completed &&
                        completeEnable ? (
                        <img src={finishedIcon} alt="medal icon" />
                      ) : (
                        <Checkbox
                          sx={{
                            "& svg": {
                              fontSize: "1.2rem",
                            },
                          }}
                          color="primary"
                          checked={student.checked}
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            studentCheckboxHandler(
                              event,
                              student.studentId,
                              data.classId!
                            )
                          }
                        />
                      )}
                      <span className={classes.stuName}>
                        {student.studentName}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <DataNotFound
                  text={t("No students")}
                  style={noDataStyle as CSSProperties}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}

export default AccordionWithCheckbox;
