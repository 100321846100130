import { Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-around",
    width: "100%",
    paddingTop: "10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    background: "rgb(255 255 255)",
    borderRadius: " 0 0 18px 18px",
    paddingBottom: "20px",
  },
});

function ContentSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Skeleton variant="rectangular" height={300} width={"23%"} />
      <Skeleton variant="rectangular" height={300} width={"73%"} />
    </div>
  );
}

export function ToolbarSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      height={50}
      width={"98%"}
      sx={{ margin: "10px" }}
    />
  );
}

export function EvaluationSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {ToolbarSkeleton()}
      {ContentSkeleton()}
    </div>
  );
}

export default ContentSkeleton;
