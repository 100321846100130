import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Fragment, useState, useEffect, useRef } from "react";
import useActiveRoute from "hooks/useActiveRoute";
import { BigLogoIcon, MenuIcon } from "../../SVGs/MainMenuIcons";
import { useTranslation } from "react-i18next";
import { MenuItemTypes, MenuType } from "./MenuList";

const useStyles = makeStyles({
  root: {
    position: "relative",
    zIndex: "1",
    "@media only screen and (min-width: 993px)": {
      display: "none",
    },
  },
  sideMenu: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  drawer: {
    background: "rgba(15, 21, 32, 0.6)",
  },
  paper: {
    width: "40vw",
  },

  active: {
    color: "rgb(98 195 238) !important",
    fontWeight: "bold",

    "& *": {
      color: "rgb(98 195 238) !important",
      fontWeight: "bold",
    },
  },
});

interface IMobileMenuProps {
  sideList: MenuType[];
  "data-test"?: string;
}

function MobileMenu({ sideList, ...rest }: IMobileMenuProps) {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<{ [key: string]: boolean }>(
    {}
  );
  const handleClose = () => setOpen(!open);
  const activeRoute = useActiveRoute();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutSideHandler);
    };
  }, []);

  const clickOutSideHandler = (event: MouseEvent) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement>) => {
    const subMenuName = e.currentTarget.getAttribute("id")!;
    const MenuStates = JSON.parse(JSON.stringify(openSubMenu));
    MenuStates[subMenuName] = !MenuStates[subMenuName] ?? true;
    setOpenSubMenu(MenuStates);
  };

  const handleAction = (
    event: React.MouseEvent<HTMLLIElement>,
    action: (e: React.MouseEvent<HTMLLIElement>) => void
  ) => {
    action(event);
    handleClose();
  };

  const customStyles = {
    subMenu: {
      background: "rgb(247 247 247)",
      width: "100%",
      "& > div > div > div > li": {
        ...(i18n.dir() === "rtl"
          ? { paddingRight: "25%" }
          : { paddingLeft: "25%" }),
        cursor: "pointer",
      },
      "& span": {
        fontSize: "16px",
        color: "rgb(111 117 138)",

        ...(i18n.dir() === "rtl"
          ? { textAlign: "right" }
          : { textAlign: "left" }),
      },
      "& svg": {
        padding: "4px",
      },
    },
    listItem: {
      display: "flex",
      justifyContent: "center",
      fontSize: "16px",
      color: "rgb(105 123 140)",
      ...(i18n.dir() === "rtl"
        ? { textAlign: "right" }
        : { textAlign: "left" }),
      cursor: "pointer",
      borderBottom: "2px solid rgb(247 247 247)",
      "& *": {
        minWidth: "unset",
        padding: "0.25rem",
      },
    },
    subSubMenu: {
      background: "rgb(233 233 233)",
      width: "100%",
      "& > div >div>div": {
        ...(i18n.dir() === "rtl"
          ? { paddingRight: "35%" }
          : { paddingLeft: "35%" }),
        paddingTop: "10px",
        paddingBottom: "10px",
      },
      "& *": {
        color: "rgb(111 117 138)",
        ...(i18n.dir() === "rtl"
          ? { textAlign: "right" }
          : { textAlign: "left" }),
      },
      "& li": {
        ...(i18n.dir() === "rtl"
          ? { borderRight: "1px solid rgb(204 204 204)" }
          : { borderLeft: "1px solid rgb(204 204 204)" }),
        paddingTop: "0",
        paddingBottom: "0",
        cursor: "pointer",
      },
    },
  };
  const menuList = sideList.map((menu, index) => (
    <Fragment key={"mainMobileMenu" + index}>
      <ListItem
        onClick={
          menu.menuType === "leaf"
            ? (event) => handleAction(event, menu.action!)
            : (event) => handleClick(event)
        }
        sx={customStyles.listItem}
        id={menu.name}
      >
        <ListItemIcon>
          {menu?.Icon?.({
            stroke: activeRoute(menu.name) ? "rgb(98 195 238)" : "currentColor",
            sx: { fontSize: "33px" },
          })}
        </ListItemIcon>
        <ListItemText
          primary={t(menu.label)}
          className={activeRoute(menu.name) ? classes.active : undefined}
          disableTypography={true}
        />
        {renderArrow(menu)}
      </ListItem>
      {menu.menuType !== "leaf" ? (
        <Collapse
          key={"mainCollapseMenu" + index}
          in={openSubMenu[menu.name] ?? false}
          sx={customStyles.subMenu}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {menu.menuItems.map((subItem, index) => (
              <Fragment key={"subItem" + index}>
                <ListItem
                  onClick={
                    subItem.menuType === "leaf"
                      ? (event) => handleAction(event, subItem.action!)
                      : handleClick
                  }
                  id={subItem.name}
                >
                  <ListItemText
                    primary={t(subItem.label)}
                    className={
                      activeRoute(subItem.name) ? classes.active : undefined
                    }
                  />
                  {renderArrow(subItem)}
                </ListItem>
                {subItem.menuType !== "leaf" && (
                  <Collapse
                    key={"subItemCollapse" + index}
                    in={openSubMenu[subItem.name] ?? false}
                    sx={customStyles.subSubMenu}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {subItem.menuItems.map((subSubItem, index) => (
                        <ListItem
                          key={"subSubItem" + index}
                          onClick={(event) =>
                            handleAction(event, subSubItem.action!)
                          }
                        >
                          <ListItemText
                            primary={t(subSubItem.label)}
                            className={
                              activeRoute(subSubItem.name)
                                ? classes.active
                                : undefined
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                )}
              </Fragment>
            ))}
          </List>
        </Collapse>
      ) : null}
    </Fragment>
  ));

  return (
    <div className={classes.root} {...rest}>
      <MenuIcon
        onClick={handleClose}
        style={{
          fontSize: "33px",
          color: "rgb(60 180 229)",
          cursor: "pointer",
        }}
      />
      <Drawer
        anchor={i18n.dir() === "rtl" ? "right" : "left"}
        open={open}
        classes={{ root: classes.drawer, paper: classes.paper }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px 20px 20px",
            flexDirection: i18n.dir() === "rtl" ? "row-reverse" : "row",
          }}
        >
          <BigLogoIcon sx={{ fontSize: "48px", width: "unset" }} />
          <CloseRoundedIcon
            style={{
              color: "rgb(98 195 238)",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
        </div>
        <div tabIndex={0} role="button" ref={menuRef}>
          <List className={classes.sideMenu} sx={{ direction: i18n.dir() }}>
            {menuList}
          </List>
        </div>
      </Drawer>
    </div>
  );

  function renderArrow(menu: MenuType) {
    const subMenuArrow = (opened: boolean) =>
      opened ? (
        <ExpandMoreIcon
          style={{ color: "rgb(98 195 238)" }}
          sx={{ fontSize: "25px", padding: "0 !important" }}
        />
      ) : (
        <ArrowBackIosIcon
          style={{ fontSize: "16px", padding: 0, color: "rgb(194 194 194)" }}
          sx={{ transform: i18n.dir() === "rtl" ? null : "rotate(180deg)" }}
        />
      );

    return menu.menuType !== MenuItemTypes.leaf
      ? subMenuArrow(openSubMenu[menu.name] ?? false)
      : null;
  }
}

export default MobileMenu;
