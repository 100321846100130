import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import Input from "components/Input";
import {
  createExamTitelSet,
  selectCreateExam,
  selectExams,
} from "features/exams";
import Joi from "joi";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Filters from "./Filters";
import LosList from "./LosList";

interface IExamFormProps {}
const customStyles = (dir: "rtl" | "ltr") => ({
  form: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    padding: "0 30px",
    direction: dir,
  },
  name: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    flexDirection: "row",
    paddingBottom: "30px",
    width: "350px",
  },
  filters: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  losList: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
    paddingBottom: "60px",
  },
});
const ExamForm = ({}: IExamFormProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const examDetails = useAppSelector(selectCreateExam);
  const exams = useAppSelector(selectExams);
  const titlesList = exams.map((item) => item.name);
  const [title, setTitle] = useState("");
  const [errors, setErrors] = useState("");
  const classes = customStyles(i18n.dir());
  const titleSchema = Joi.object({
    name: Joi.string()
      .trim()
      .required()
      .min(1)
      .max(150)
      .invalid(...titlesList)
      .messages({
        "any.invalid": "invalid exam title",
        "any.required": "exam title required",
        "string.empty": "exam title required",
        "string.max": "too long title",
        "string.min": "exam title required",
      }),
  });

  useEffect(() => {
    if (examDetails.title) setTitle(examDetails.title);
  }, [examDetails]);
  const handleChange = (event: { target: { value: string } }) => {
    const options = { abortEarly: false };
    const { error } = titleSchema.validate(
      { name: event.target.value },
      options
    );
    if (error) setErrors(error.details[0].message);
    else setErrors("");
    setTitle(event.target.value);
    dispatch(createExamTitelSet(error ? "" : event.target.value));
  };

  return (
    <Typography component="div" sx={classes.form}>
      <Typography component="div" sx={classes.name}>
        <Input
          name="examName"
          label={t("exam name")}
          onChange={handleChange}
          value={title}
          placeholder=""
          displayErrorIcon
          errors={errors ? t(errors) : ""}
          style={{
            root: {
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              "& > div": {
                flexGrow: 1,
              },
            },
          }}
        />
      </Typography>
      <Typography component="div" sx={classes.filters}>
        <Filters />
      </Typography>
      <Typography component="div" sx={classes.losList}>
        <LosList />
      </Typography>
    </Typography>
  );
};

export default ExamForm;
