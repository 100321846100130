import { makeStyles } from "@mui/styles";
import { utils, writeFile } from "xlsx";
import { SpreadSheetErrorsIcon } from "components/SVGs/index";
import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";
import { IFile } from "./useFileBuilder";

const useStyles = makeStyles({
  errorIcon: {
    margin: "0 2px",
    borderRadius: "50%",
    cursor: "pointer",
    fontSize: "2.6rem",
    "&:hover": {
      boxShadow: "0 0 6px rgb(245 79 100)",
    },
  },
});
interface IResultErrorsProps {
  errors: number;
  uploadedFile: IFile;
}
function ResultErrors({ errors, uploadedFile }: IResultErrorsProps) {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const handleDownloadErrors = () => {
    const { invalidData, fileName } = uploadedFile;
    if (!invalidData || invalidData.length === 0) {
      toast({
        label: t("Note"),
        message: t("No errors found, file not uploaded yet"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "invalid-value",
      });
      return;
    }
    const studentDetailsSheet = utils.json_to_sheet(invalidData);
    const dwonloadWorkbook = utils.book_new();
    utils.book_append_sheet(
      dwonloadWorkbook,
      studentDetailsSheet,
      "student-details"
    );
    const errorFileName = "Errors-" + fileName;
    writeFile(dwonloadWorkbook, errorFileName);
  };
  const classes = useStyles();
  return (
    <Badge
      badgeContent={errors}
      color="error"
      anchorOrigin={{
        horizontal: i18n.dir() === "rtl" ? "left" : "right",
        vertical: "top",
      }}
      sx={{
        margin: "0 5px",
        visibility: errors > 0 ? "visible" : "hidden",
      }}
    >
      <SpreadSheetErrorsIcon
        className={classes.errorIcon}
        onClick={handleDownloadErrors}
      />
    </Badge>
  );
}

export default ResultErrors;
