import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DomainCard from "./DomainCard";
import DataNotFound from "components/DataNotFound";
import DomainSkeleton from "../StudentSkeleton/DomainSkeleton";
import { IDomain, domainSet } from "features/studentPerformance";
import { useAppDispatch } from "app/hooks";

const useStyles = makeStyles({
  root: {
    color: "rgb(98 195 238)",
    fontWeight: "bold",
    padding: "1rem",
    position: "relative",
  },
  LosProgress: {
    paddingTop: "15px",
    "& > :nth-of-type(2n)": {
      background: "rgb(248 248 248)",
    },
    "& > :nth-of-type(2n+1)": {
      background: "rgb(255 255 255)",
    },
  },
});

const colors = [
  "rgb(68 183 253)",
  "rgb(90 107 199)",
  "rgb(134 83 203)",
  "rgb(201 97 206)",
];

interface IProgressedLOsProps {
  isLoading: boolean;
  domains: IDomain[];
}
const ProgressedLOs = ({ isLoading, domains }: IProgressedLOsProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);

  useEffect(() => {
    dispatch(domainSet(domains[0]));
  }, [domains]);

  // to prevent showing noData early
  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!isLoading || !show) {
      id = setTimeout(() => setShow(true), 500);
    }
    if (isLoading) setShow(false);

    return () => clearTimeout(id);
  }, [isLoading, show]);

  return (
    <Grid
      container
      className={classes.root}
      direction="column"
      justifyContent="space-between"
    >
      <Grid item xs={12}>
        <Grid container className={classes.LosProgress} direction="column">
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              {isLoading || !show ? (
                <DomainSkeleton />
              ) : domains?.length > 0 ? (
                <>
                  {domains.map((domainData, index) => (
                    <Grid
                      xs={3}
                      item
                      key={index}
                      style={{ width: "13%", position: "relative" }}
                    >
                      <DomainCard data={domainData} color={colors[index]} />
                    </Grid>
                  ))}
                </>
              ) : (
                <DataNotFound />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProgressedLOs;
