import { useEffect, useState } from "react";
import { Switch, useHistory, Route } from "react-router-dom";
import Header from "components/Header/Secure";
import secureRoutes from "routes/secure";
import { makeStyles } from "@mui/styles";
import useCheckCompatibility from "hooks/useCheckCompatibility";
import { useTranslation } from "react-i18next";
import { Direction } from "features/common/Interfaces";
import { Theme } from "@mui/system";
import LiveChat from "components/LiveChat";
import Footer from "components/Footer";

const drawerMiniWidth = 80;

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "',
    },
    "@media print": {
      height: "100% !important",
    },
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    overflow: "auto",
    position: "relative",
    float: "left",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    height: "100%",
    width: "100%",
    "@media print": {
      overflow: "hidden !important",
    },
  },
  content: {
    padding: "35px 10px",
    top: "-10px",
    position: "relative",
    "@media print": {
      marginTop: "0px",
      padding: "0px",
    },
  },
  container: {
    paddingRight: "0px",
    paddingLeft: "0px",
    marginRight: "auto",
    marginLeft: "auto",
    "&:before,&:after": {
      display: "table",
      content: '" "',
    },
    "&:after": {
      clear: "both",
    },
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  footer: {
    background: "rgba(237, 241, 247, 1)",
    boxShadow: "inset 0px 0px 4px 0px rgb(0 0 0 / 10%)",
  },
}));

function Layouts() {
  const [direction, setDirection] = useState<Direction>();
  const { i18n } = useTranslation();
  const isNotCompatible = useCheckCompatibility();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const authToken = localStorage.getItem("abjadToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (isNotCompatible) history.push("/device-not-compatible");
    else if (!authToken || !refreshToken) history.push("/login");
    const storedDir = (localStorage.getItem("dir") ?? i18n.dir()) as Direction;
    setDirection(storedDir);
  }, []);

  useEffect(() => {
    setDirection(i18n.dir());
  }, [i18n.dir()]);

  const switchRoutes = (
    <Switch>
      {secureRoutes.map((prop, key) => (
        <Route path={prop.path} component={prop.component} key={key} />
      ))}
    </Switch>
  );

  return (
    <div className={classes.wrapper} style={{ direction }}>
      <div className={classes.mainPanel} id="mainpanel">
        <Header />
        <LiveChat />
        <div className={classes.content}>
          <div className={classes.container}>{switchRoutes}</div>
        </div>
      </div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default Layouts;
