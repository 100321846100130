import { useEffect, useState } from "react";
import PieChart from "./PieChart";
import SummaryChart from "./SummaryChart";
import { makeStyles } from "@mui/styles";
import AccordianTable from "./AccordianTable";
import DataNotFound from "components/DataNotFound";
import { GuidedReadingIcon } from "components/SVGs/MainMenuIcons";
import { Skeleton } from "@mui/material";
import {
  BookStatus,
  grLevels,
  useFetchGrBooksStatisticsQuery,
  useFetchGrStudentProgressReportQuery,
} from "services/reports";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  isSortingSet,
  selectClass,
  selectSelectedPlanMonth,
  selectSelectedSort,
} from "features/grStudentsProgress";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  statistics: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "220px",
    padding: "2rem",
    borderBottom: "1px solid rgb(217 217 217)",
    width: "98%",
  },
});

const renderStatisticsSkeleton = (
  <>
    <Skeleton variant="circular" width={180} height={180} />
    <Skeleton
      width="80%"
      variant="text"
      style={{
        margin: "0 1.5rem",
        height: "250px",
      }}
    />
  </>
);

const renderStudentsSkeleton = (
  <Skeleton
    width="90%"
    variant="text"
    style={{
      height: "250px",
    }}
  />
);

const renderNoDataFound = (
  <DataNotFound icon={<GuidedReadingIcon />} text="No added books" />
);
const colorSchema = {
  أ: "rgb(226 41 0)",
  ب: "rgb(254 217 0)",
  ج: "rgb(57 205 85)",
  د: "rgb(46 192 219)",
  هـ: "rgb(171 38 255)",
  و: "rgb(117 86 80)",
  ز: "rgb(65 106 255)",
  ح: "rgb(96 123 134)",
  ط: "rgb(213 223 41)",
  ي: "rgb(122 97 255)",
  ك: "rgb(84 204 255)",
  ل: "rgb(189 234 0)",
  م: "rgb(251 167 137)",
  ن: "rgb(184 150 255)",
  س: "rgb(250 140 180)",
  pending: "rgb(234 234 234)",
};

export interface IStudentProgress {
  studentId: number;
  studentName: string;
  bookLevels: string[];
  totalSpentTime: string;
  totalAssingedBooks: number;
  totalCompletedBooks: number;
  books: {
    bookTitle: string;
    bookLevelName: grLevels;
    status: BookStatus;
    spentTime: string;
  }[];
}

export interface ILevelStatistics {
  id: grLevels;
  color: string;
  value: number;
}

export interface ISummaryStatistics {
  percentage: string;
  color: string;
  label: grLevels;
}

function Content() {
  const dispatch = useAppDispatch();
  const [levelsStatistics, setLevelsStatistics] =
    useState<ILevelStatistics[]>();
  const [summaryStatistics, setSummaryStatistics] =
    useState<ISummaryStatistics[]>();
  const [totalAssignedBooks, setTotalAssignedBooks] = useState<number>();
  const [totalCompletedBooks, setTotalCompletedBooks] = useState<number>();
  const sort = useAppSelector(selectSelectedSort);
  const [students, setStudents] = useState<IStudentProgress[]>();
  const classes = useStyles();
  const selectedClass = useAppSelector(selectClass);
  const selectedPlanMonth = useAppSelector(selectSelectedPlanMonth);
  const {
    data: grBooksStatistics,
    isLoading,
    isUninitialized,
  } = useFetchGrBooksStatisticsQuery(
    {
      classId: selectedClass?.value as number,
      ...(typeof selectedPlanMonth?.value === "object"
        ? {
            year: selectedPlanMonth?.value?.year,
            monthName: selectedPlanMonth?.value?.month,
          }
        : {}),
    },
    {
      skip: !selectedClass?.value,
    }
  );
  const {
    data: studentsProgressData,
    isLoading: isLoadingStudents,
    isUninitialized: isUninitializedStudents,
  } = useFetchGrStudentProgressReportQuery(
    {
      classId: selectedClass?.value as number,
      sort,
      ...(typeof selectedPlanMonth?.value === "object"
        ? {
            monthName: selectedPlanMonth?.value?.month,
          }
        : {}),
    },
    {
      skip: !selectedClass?.value,
    }
  );

  useEffect(() => {
    dispatch(isSortingSet(isLoadingStudents));
  }, [isLoadingStudents]);

  useEffect(() => {
    if (studentsProgressData?._statusCode === 200) {
      const studentProgress = [
        ...studentsProgressData._entity?.map((item) => ({
          studentId: item._studentId,
          studentName: item._studentName,
          bookLevels: Array.isArray(item._levelNames)
            ? item._levelNames
            : ["- -"],
          totalSpentTime: item._totalTimeSpent ?? "- -",
          totalAssingedBooks: item._totalAssignedBooks,
          totalCompletedBooks: item._totalCompletedBooks,
          books: item._books
            ? item._books.map((book) => ({
                bookTitle: book._name,
                bookLevelName: book._level,
                status: book._assignmentStatus,
                spentTime: book._timeSpent ?? "00:00:00",
              }))
            : [],
        })),
      ];
      setStudents(studentProgress);
    }
  }, [studentsProgressData]);

  useEffect(() => {
    if (grBooksStatistics?._statusCode === 200) {
      const {
        _totalNumberOfAssignedBooks: total,
        _totalNumberOfCompletedBooks: completed,
        _levels,
      } = grBooksStatistics._entity;
      let levelsStatistics: ILevelStatistics[] = [];
      let summaryStatistics: ISummaryStatistics[] = [];
      if (_levels?.length) {
        levelsStatistics = [
          ..._levels.map((item) => ({
            id: item._name,
            color: colorSchema[item._name],
            value: item._totalNumberOfCompletedBooks,
          })),
        ];

        summaryStatistics = levelsStatistics.map((level) => ({
          percentage:
            level.value === 0
              ? "--"
              : ((level.value / total) * 100 <= 1
                  ? 1
                  : Math.ceil((level.value / total) * 100)) + "%",
          color: level.color,
          label: level.id,
        }));

        levelsStatistics.push({
          id: "pending",
          color: "rgb(234 234 234)",
          value: total - completed,
        });
      }
      setSummaryStatistics(summaryStatistics);
      setLevelsStatistics(levelsStatistics);
      setTotalCompletedBooks(completed);
      setTotalAssignedBooks(total);
    }
  }, [grBooksStatistics]);

  return (
    <div className={classes.root}>
      {isLoading || isUninitialized ? (
        <div className={classes.statistics}>{renderStatisticsSkeleton}</div>
      ) : totalAssignedBooks &&
        levelsStatistics &&
        summaryStatistics &&
        levelsStatistics?.length > 0 &&
        summaryStatistics?.length > 0 ? (
        <div className={classes.statistics}>
          <PieChart
            levels={levelsStatistics}
            total={totalAssignedBooks}
            completed={totalCompletedBooks}
          />
          <SummaryChart statistics={summaryStatistics} />
        </div>
      ) : null}
      {isLoadingStudents || isUninitializedStudents ? (
        renderStudentsSkeleton
      ) : totalAssignedBooks && students && students?.length > 0 ? (
        <AccordianTable records={students} />
      ) : null}
      {!isLoading &&
      !isLoadingStudents &&
      !(
        totalAssignedBooks &&
        levelsStatistics &&
        summaryStatistics &&
        levelsStatistics?.length > 0 &&
        summaryStatistics?.length > 0
      )
        ? renderNoDataFound
        : null}
    </div>
  );
}

export default Content;
