import { API, IResponse } from "./API";
import { FilterTypes } from "./filters";

export interface IFetchLosRequest {
  _filterType: FilterTypes;
  _gradeId: number;
  _groupCode: string;
  _subGroupCode: string;
  _termCode: string;
}
export interface IFetchedLo {
  _assignedClasses: string[];
  _hasEne: boolean;
  _isOfflineEvaluated: boolean;
  _lessonContentId: string;
  _loCode: string;
  _loId: number;
  _loStatus: string;
  _loTitle: string;
  _moeLoCode: string;
  _thumbUrl: string;
}
export interface IFetchedUnit {
  _unitId: string;
  _unitTitle: string;
  _loList: IFetchedLo[];
}
export interface ILessonLosEntity {
  _loList: IFetchedLo[];
  _unitList: IFetchedUnit[];
}
interface IFreePlayLo {
  _contentId: string;
  _file: string;
  _tags: string[];
  _thumbUrl: string;
  _title: string;
}
export interface IFreePlayLosEntity {
  _freeplays: IFreePlayLo[];
}
export interface IGuidedReadingLo {
  _ageGroup: string;
  _bookId: number;
  _code: string;
  _tags: string[];
  _thumbUrl: string;
  _title: string;
}
export interface IGuidedReadingLosEntity {
  _grBooks: IGuidedReadingLo[];
}
export interface IFetchLosResponse extends IResponse {
  _entity: ILessonLosEntity | IFreePlayLosEntity | IGuidedReadingLosEntity;
}
interface IGrBookTagEntity {
  _code: string;
  _id: number;
  _textAr: string;
}
interface IFetchGrBookTagsResponse extends IResponse {
  _entity: IGrBookTagEntity[];
}
const contentServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchLos: build.mutation<IFetchLosResponse, IFetchLosRequest>({
        query(payload) {
          let type;
          switch (payload._filterType) {
            case FilterTypes.MOE:
            case FilterTypes.LESSON:
              type = "lesson";
              break;
            case FilterTypes.GUIDED_READING:
              type = "grbook";
              break;
            default:
              type = "freeplay";
          }
          return {
            url: `ams/dashboard/${type}`,
            method: "POST",
            body: payload,
          };
        },
      }),
      fetchGrBookTags: build.query<IFetchGrBookTagsResponse, void>({
        query() {
          return "ams/grbook/tags";
        },
      }),
    };
  },
});

export const { useFetchLosMutation, useFetchGrBookTagsQuery } = contentServices;
