import { makeStyles } from "@mui/styles";
import { ISummaryStatistics } from "./Content";

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(155px,1fr))",
    gridAutoRows: "minmax(40px,auto)",
    padding: "15px",
    flexGrow: 1,
    userSelect: "none",
    "@media screen and (max-width: 1024px)": {
      gridTemplateColumns: "repeat(auto-fill, minmax(100px,1fr))",
    },
  },
  widget: {
    display: "flex",
    width: "150px",
    alignItems: "center",
    color: "rgb(127 139 163)",
    fontWeight: "bold",
    "& span": {
      padding: "0 10px",
    },
    "& div": {
      textAlign: "left",
    },
  },
  thumb: { borderRadius: "10px", width: "40px", height: "25px" },
});
interface ISummaryChartProps {
  statistics: ISummaryStatistics[];
}
function SummaryChart({ statistics }: ISummaryChartProps) {
  const classes = useStyles();

  function LevelStatistics({ data }: { data: ISummaryStatistics }) {
    const { percentage, color, label } = data;

    return (
      <div className={classes.widget}>
        <div
          className={classes.thumb}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <span>{label}</span>
        <div style={{ textAlign: "right" }}>{percentage}</div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {statistics.map((level, index) => (
        <LevelStatistics data={level} key={"key" + index} />
      ))}
    </div>
  );
}

export default SummaryChart;
