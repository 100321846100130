import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import ProgressedLOs from "./ProgressedLOs";
import LosTable from "./LosTable";
import {
  selectStudent,
  selectDomain,
  selectSelectedSortForOnline,
  selectSelectedSortForOffline,
  selectedSortForOfflineSet,
  selectedSortForOnlineSet,
  IDomain,
} from "features/studentPerformance";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  useFetchOfflineStudentReportQuery,
  useFetchOnlineStudentReportQuery,
  useFetchStudentScoreByDomainQuery,
} from "services/reports";
import { Sort } from "features/common/Interfaces";
import {
  modifyDomainsResponse,
  modifyOfflineLosResponse,
  modifyOnlineLosResponse,
} from "utils/ReportsHelpers";

const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: "0.75rem 5px",
    height: "fit-content",
    display: "flex",
  },
  categoryGrid: {
    width: "100%",
    background: "rgb(255 255 255)",
    "& > div": {
      background: "rgb(255 255 255)",
    },
  },
});

const onlineLosHeader = ["average", "StudentPerformance"];
const offlineLosHeader = ["average", "EvaluationCriteria"];

export interface ILoStudentScore {
  id: string;
  classScore: number;
  classScoreColor: string;
  title: string;
  studentScore: number;
  studentScoreColor: string;
}

function Content() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [onlineLos, setOnlineLos] = useState<ILoStudentScore[]>([]);
  const [offlineLos, setOfflineLos] = useState<ILoStudentScore[]>([]);
  const selectedStudent = useAppSelector(selectStudent);
  const selectedDomain = useAppSelector(selectDomain);
  const sortingOrderForOffline = useAppSelector(selectSelectedSortForOffline);
  const sortingOrderForOnline = useAppSelector(selectSelectedSortForOnline);
  const {
    data: studentScorebyDomainData,
    isLoading: isDomainsLoading,
    isUninitialized: isDomainsUninitialized,
  } = useFetchStudentScoreByDomainQuery(selectedStudent?.value as number, {
    skip: !selectedStudent?.value,
  });
  const { data: studentOnlineReportData } = useFetchOnlineStudentReportQuery(
    {
      studentId: selectedStudent?.value as number,
      domainId: selectedDomain?.id as number,
    },
    { skip: !selectedStudent?.value || !selectedDomain?.id }
  );
  const { data: studentOfflineReportData } = useFetchOfflineStudentReportQuery(
    {
      studentId: selectedStudent?.value as number,
      domainId: selectedDomain?.id as number,
    },
    { skip: !selectedStudent?.value || !selectedDomain?.id }
  );
  const sortHandlerForOffline = () => {
    const order = sortingOrderForOffline === Sort.asc ? Sort.desc : Sort.asc;
    dispatch(selectedSortForOfflineSet(order));
  };

  const sortHandlerForOnline = () => {
    const order = sortingOrderForOnline === Sort.asc ? Sort.desc : Sort.asc;
    dispatch(selectedSortForOnlineSet(order));
  };

  useEffect(() => {
    if (studentScorebyDomainData?._statusCode === 200) {
      const studentDomainScore =
        studentScorebyDomainData._entity._studentDomainScores;
      const domains = modifyDomainsResponse(
        JSON.parse(JSON.stringify(studentDomainScore))
      );
      setDomains(domains);
    } else {
      setDomains([]);
    }
  }, [studentScorebyDomainData]);

  useEffect(() => {
    if (!domains.length) {
      setOnlineLos([]);
      setOfflineLos([]);
    }
  }, [domains]);

  useEffect(() => {
    if (studentOnlineReportData?._statusCode === 200) {
      const onlineReport =
        studentOnlineReportData._entity._studentDomainLoScores;
      const onlineData = modifyOnlineLosResponse(
        JSON.parse(JSON.stringify(onlineReport))
      );
      setOnlineLos(onlineData);
    } else {
      setOnlineLos([]);
    }
  }, [studentOnlineReportData]);
  useEffect(() => {
    if (studentOfflineReportData?._statusCode === 200) {
      const offlineReport =
        studentOfflineReportData._entity._studentDomainOfflineScores;
      const offlineData = modifyOfflineLosResponse(
        JSON.parse(JSON.stringify(offlineReport))
      );
      setOfflineLos(offlineData);
    } else {
      setOfflineLos([]);
    }
  }, [studentOfflineReportData]);

  return (
    <div className={classes.root}>
      <div className={classes.categoryGrid}>
        <ProgressedLOs
          isLoading={isDomainsLoading || isDomainsUninitialized}
          domains={domains}
        />
        {onlineLos?.length > 0 ? (
          <LosTable
            header={onlineLosHeader}
            data={onlineLos}
            sortHandler={sortHandlerForOnline}
            sortingOrder={sortingOrderForOnline}
          />
        ) : null}
        {offlineLos?.length > 0 ? (
          <LosTable
            header={offlineLosHeader}
            data={offlineLos}
            sortHandler={sortHandlerForOffline}
            sortingOrder={sortingOrderForOffline}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Content;
