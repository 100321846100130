import { ResponsivePie } from "@nivo/pie";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { ILevelStatistics } from "./Content";
import { grLevels } from "services/reports";
import { ICSS } from "features/common/Interfaces";

const useStyles = makeStyles({
  tooltip: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(218, 218, 218, 1)",
    borderRadius: "4px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    padding: "10px",
    maxWidth: "150px",
    "&:after": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(255, 255, 255, 1)",
      bottom: "-19px",
      right: "48px",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(218, 218, 218, 1)",
      bottom: "-20px",
      right: "48px",
    },
  },
  progress: { fontSize: "20px", fontWeight: "bold", userSelect: "none" },
});

interface IPieChartProps {
  levels: ILevelStatistics[];
  total: number;
  completed?: number;
}
function PieChart({ levels, total, completed }: IPieChartProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const progressRatio = completed + (i18n.dir() === "ltr" ? "/" : "\\") + total;

  const tooltip = (e: { datum: { data: { id: grLevels; value: number } } }) => {
    const { data } = e.datum;
    const { id, value } = data;
    return id !== "pending" ? (
      <div className={classes.tooltip}>
        <div>{`${t("grLevel")} ${id}`}</div>
        <div>:&nbsp;</div>
        <span>{value + " " + (value === 1 ? t("Book") : t("Books"))}</span>
      </div>
    ) : null;
  };

  const commonProperties = {
    enableArcLinkLabels: false,
    enableArcLabels: false,
    data: levels,
    animate: true,
    innerRadius: 0.8,
    tooltip,
  };
  const styles: ICSS = {
    root: {
      textAlign: "center",
      position: "relative",
      width: "170px",
      height: "170px",
    },
    overlay: {
      position: "absolute",
      top: 0,
      right: "20px",
      bottom: 0,
      left: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "18px",
      userSelect: "none",
      fontWeight: "bold",
      // This is important to preserve the chart interactivity
      pointerEvents: "none",
    },
  };

  return (
    <div style={styles.root}>
      <ResponsivePie colors={{ datum: "data.color" }} {...commonProperties} />
      <div style={styles.overlay}>
        <span> {t("progress")}</span>
        <span>{progressRatio}</span>
      </div>
    </div>
  );
}

export default PieChart;
