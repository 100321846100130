import { makeStyles } from "@mui/styles";
import {
  selectSelectedLo,
  selectPendingAssessments,
  selectCompletedAssessments,
  selectedLoSet,
  IEvaluationTab,
  IAssessment,
} from "features/evaluation";
import DataNotFound from "components/DataNotFound";
import LoThumb from "./LoThumb";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { StatusTypes } from "features/common/Interfaces";

const useStyles = makeStyles({
  root: {
    color: "rgb(98 195 238)",
    fontWeight: "bold",
    position: "relative",
    flexGrow: 1,
    padding: "5px 3px",
    height: "100%",
    overflowY: "auto",
    overflowX: "hidden",
  },
});

interface ILOsGridProps {
  selectedTab: IEvaluationTab;
}

function LOsGrid({ selectedTab }: ILOsGridProps) {
  const { t } = useTranslation();
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const completedAssessments = useAppSelector(selectCompletedAssessments);
  const selectedLo = useAppSelector(selectSelectedLo);
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const selectLoHandler = (lo: IAssessment, status: StatusTypes) => {
    if (selectedLo?.loId !== lo?.loId) {
      dispatch(
        selectedLoSet({
          ...lo,
          status,
        })
      );
    }
  };

  return (
    <div className={classes.root}>
      {selectedTab?.code === StatusTypes.PENDING ? (
        pendingAssessments?.length > 0 ? (
          pendingAssessments.map((lo, index) => (
            <div
              key={"incomplete" + index}
              style={{
                backgroundColor:
                  selectedLo?.loId === lo?.loId ? "rgba(245, 245, 245, 1)" : "",
                border:
                  selectedLo?.loId === lo?.loId ? "1px solid #979797" : "",
                borderRadius: "5px",
                margin: "5px 0",
              }}
              onClick={() => selectLoHandler(lo, StatusTypes.PENDING)}
            >
              <LoThumb data={lo} key={"incomplete" + index} />
            </div>
          ))
        ) : (
          <DataNotFound
            text={t("No assessments")}
            style={{ height: "200px" }}
          />
        )
      ) : completedAssessments?.length > 0 ? (
        completedAssessments.map((lo, index) => (
          <div
            key={"complete" + index}
            style={{
              backgroundColor:
                selectedLo?.loId === lo?.loId ? "rgba(245, 245, 245, 1)" : "",
              border: selectedLo?.loId === lo?.loId ? "1px solid #979797" : "",
              borderRadius: "5px",
            }}
            onClick={() => selectLoHandler(lo, StatusTypes.COMPLETED)}
          >
            <LoThumb data={lo} isCompleted />
          </div>
        ))
      ) : (
        <DataNotFound text={t("No assessments")} />
      )}
    </div>
  );
}

export default LOsGrid;
