import { useEffect } from "react";
import Filter from "./Filter";
import FiltersHoc, { IChildCompProps } from "./FiltersHoc";
import { useTranslation } from "react-i18next";
import { IFilter } from "features/filters";

function GroupOneFilters({
  lessonsFilters,
  selectedGrade,
  dispatchLessonsCall,
  levelOneFilters,
  setLevelOneFilters,
  levelTwoFilters,
  setLevelTwoFilters,
  selectedLevelOneFilter,
  selectedLevelTwoFilter,
  setSelectedLevelTwoFilter,
  setSelectedLevelOneFilter,
}: IChildCompProps) {
  const { t } = useTranslation();
  useEffect(() => {
    if (Object.keys(lessonsFilters).length && selectedGrade) {
      let domainFilters = lessonsFilters[selectedGrade.id].domains;
      setLevelOneFilters(domainFilters[0].subGroups!);
      setSelectedLevelOneFilter({ ...domainFilters[0]?.subGroups?.[0]! });
      setLevelTwoFilters(domainFilters[1].subGroups!);
      setSelectedLevelTwoFilter();
      dispatchLessonsCall({
        _subGroupCode: domainFilters[0]?.subGroups?.[0].code,
      });
    }
  }, [lessonsFilters, selectedGrade]);

  const setLevelOne = (group: IFilter) => {
    dispatchLessonsCall({
      _subGroupCode: group.code,
    });
    setSelectedLevelTwoFilter();
    setSelectedLevelOneFilter(group);
  };

  const setLevelTwo = (group: IFilter) => {
    dispatchLessonsCall({
      _subGroupCode: group.code,
    });
    setSelectedLevelOneFilter();
    setSelectedLevelTwoFilter(group);
  };
  const levelOneTitle = t("Letters");
  const levelTwoTitle = t("Language Domains");

  return (
    <>
      {levelOneFilters?.length ? (
        <Filter
          data={levelOneFilters}
          clickHandler={setLevelOne}
          selectedFilter={selectedLevelOneFilter!}
          title={levelOneTitle}
        />
      ) : null}
      {levelTwoFilters?.length ? (
        <div style={{ paddingTop: "15px" }}>
          <Filter
            data={levelTwoFilters}
            clickHandler={setLevelTwo}
            selectedFilter={selectedLevelTwoFilter!}
            title={levelTwoTitle}
          />
        </div>
      ) : null}
    </>
  );
}

export default FiltersHoc(GroupOneFilters);
