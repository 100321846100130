import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { Call, InstagramIcon, Mail, YouTubeIcon } from "components/SVGs/Footer";
import { MasterDataContext } from "contexts/MasterDataContext";

const useStyles = makeStyles({
  root: {
    position: "fixed",
    zIndex: 999,
    width: "100%",
    bottom: 0,
    marginTop: "auto",
    background: "rgba(237, 241, 247, 1)",
    boxShadow: "inset 0px 0px 4px 0px rgb(0 0 0 / 10%)",
    padding: "0 20px",
    fontSize: "12px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "rgba(60, 61, 66, 1)",
    "& svg": {
      fontSize: "1.5rem",
    },
    "@media print": {
      display: "none",
    },
  },
  aboutDetails: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    "& a": {
      color: "rgba(60 180 229)",
      textDecoration: "underline",
    },
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  contactUs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": { margin: "0 5px" },
    "@media (max-width:1024px)": {
      "& .tabletHide": {
        display: "none",
      },
    },
  },
});
interface IFooterProps {
  "data-test"?: string;
}
function Footer(props: IFooterProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { logEvent } = useContext(MasterDataContext);

  return (
    <div className={classes.root} {...props}>
      <div className={classes.aboutDetails}>
        <p>
          <span>{`${t("ReadPolicy")} `}</span>
          <a
            onClick={() => logEvent("ClickOnPrivacy")}
            href={
              i18n.language === "ar"
                ? "https://legal.abjadiyat.com/Abjadiyat_Privacy_Notice_ar.pdf"
                : "https://legal.abjadiyat.com/Abjadiyat_Privacy_Notice_en.pdf"
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("Policy")}
          </a>
        </p>
      </div>
      <div className={classes.contactUs}>
        <span>{t("Cotactus")}</span>
        <a
          href={`mailto:helpdesk@alefeducation.com `}
          rel="noopener noreferrer"
        >
          <Mail
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          />
        </a>
        <span className="tabletHide">helpdesk@alefeducation.com </span>
        <span>{t("or by phone")}</span>
        <a href={`tel:+97122040101`} rel="noopener noreferrer">
          <Call sx={{ display: "flex", alignItems: "center" }} />
        </a>
        <span style={{ direction: "ltr" }} className="tabletHide">
          +97122040101
        </span>
      </div>
      <div className={classes.socialIcons}>
        <span>{t("Follow us")}</span>
        <a
          onClick={() => logEvent("ClickOnYoutube")}
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0 15px",
          }}
          href="https://www.youtube.com/c/AbjadiyatApp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <YouTubeIcon />
        </a>
        <a
          onClick={() => logEvent("ClickOnInstagram")}
          style={{ display: "inline-flex", alignItems: "center" }}
          href="https://www.instagram.com/abjadiyatapp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
        {/* <span>{t("Copyright")}</span> */}
      </div>
    </div>
  );
}

export default Footer;
