import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import CriteriaSelect from "./CriteriaSelect";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  criteriaMapSet,
  ICriterionOption,
  percentageCalMapSet,
  selectCriteriaMap,
  selectCriterion,
  selectPercentageCalMap,
} from "features/assessment";
import { createUpdatedCriterias, getScore } from "utils/EvaluationHelpers";
import { IOption } from "features/common/Interfaces";

const useStyles = makeStyles({
  criteriaNameClass: {
    "& > span": {
      fontWeight: "bold",
      fontSize: "14px",
      "@media screen and (max-width: 1024px)": {
        fontSize: "12px",
      },
    },
  },
  criteriaWeightClass: {
    padding: "0 10px",
    fontWeight: "bold",
    fontSize: "15px",
  },
});

const CriteriaList = () => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const criteriaMap = useAppSelector(selectCriteriaMap);
  // to be abl to sort and assign values
  const criterion = JSON.parse(
    JSON.stringify(useAppSelector(selectCriterion))
  ) as ICriterionOption[];
  const percentageCalMap = useAppSelector(selectPercentageCalMap);

  const onChangeOption = (
    optionDetails: IOption,
    criteriaItem: ICriterionOption
  ) => {
    let { updatedCriteriaMap, updatedPercentageCalMap } =
      createUpdatedCriterias(
        criteriaMap,
        percentageCalMap,
        criteriaItem,
        optionDetails
      );
    dispatch(criteriaMapSet(updatedCriteriaMap));
    dispatch(percentageCalMapSet(updatedPercentageCalMap));
  };
  return (
    <Grid container>
      {criterion
        ?.sort((a, b) => a.id - b.id)
        .map((criteriaItem, index) => {
          return (
            <Grid
              container
              key={"cirteer" + index}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingTop: "10px",
                width: "100%",
              }}
            >
              <Grid item sm={6} className={classes.criteriaNameClass}>
                <span>{criteriaItem.textAr}</span>
              </Grid>
              <Grid item sm={5}>
                <CriteriaSelect
                  criteriaItem={criteriaItem}
                  criteriaMap={criteriaMap}
                  onChange={onChangeOption}
                  style={{ container: { zIndex: 50 - index } }}
                />
              </Grid>
              <Grid
                item
                sm={1}
                className={classes.criteriaWeightClass}
                sx={{ textAlign: i18n.dir() === "rtl" ? "left" : "right" }}
              >
                {(() => {
                  const { mark, total } = getScore(criteriaItem, criteriaMap);
                  return mark + (i18n.dir() === "rtl" ? "\\" : "/") + total;
                })()}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CriteriaList;
