import { CSSProperties } from "react";

interface IAudioPlayerProps {
  url: string;
  style: CSSProperties;
  "data-test"?: string;
}
function AudioPlayer({ url, style, ...rest }: IAudioPlayerProps) {
  return <audio {...rest} src={url} style={style} controls></audio>;
}

export default AudioPlayer;
