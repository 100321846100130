import { Grid } from "@mui/material";
import LoThumb from "./LoThumb";
import { useTranslation } from "react-i18next";
import { IDeliverable } from "utils/ReportsHelpers";


const chunkData = (data: IDeliverable[], chunkSize: number) => {
  let index = 0;
  let contentData = [];
  let myChunk = [];
  for (index = 0; index < data.length; index += chunkSize) {
    myChunk = data.slice(index, index + chunkSize);
    contentData.push(myChunk);
  }
  return contentData;
};

interface ILoGridProps {
  data: IDeliverable[];
  reportType: string;
}
function LoGrid({ data, reportType }: ILoGridProps) {
  const { i18n } = useTranslation();
  const content = chunkData(data, 2);
  return data?.length > 0 ? (
    <Grid
      container
      sx={{
        direction: i18n.dir(),
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}
      spacing={2}
    >
      {content.map((assignment, idx) =>
        assignment.map((deliverable, index) => (
          <LoThumb
            data={deliverable}
            key={idx + index}
            reportType={reportType}
          />
        ))
      )}
    </Grid>
  ) : null;
}

export default LoGrid;
