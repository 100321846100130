import { configureStore } from "@reduxjs/toolkit";
import filtersReducer from "features/filters";
import contentReducer from "features/content";
import assignmentReducer from "features/assignments";
import studentPerformanceReducer from "features/studentPerformance";
import classPerformanceReducer from "features/classPerformance";
import pacingReducer from "features/pacing";
import grBooksPrgressReducer from "features/grBooksProgress";
import grStudentsPrgressReducer from "features/grStudentsProgress";
import evaluationReducer from "features/evaluation";
import assessmentReducer from "features/assessment";
import examsReducer from "features/exams";
import uiReducer from "features/ui";
import studentsRducer from "features/students";
import { API } from "services/API";
import authReducer from "features/auth";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

export const store = configureStore({
  reducer: {
    [API.reducerPath]: API.reducer,
    filters: filtersReducer,
    content: contentReducer,
    assignment: assignmentReducer,
    studentPerformance: studentPerformanceReducer,
    classPerformance: classPerformanceReducer,
    pacing: pacingReducer,
    grBooksPrgress: grBooksPrgressReducer,
    grStudentsProgress: grStudentsPrgressReducer,
    evaluation: evaluationReducer,
    assessment: assessmentReducer,
    students: studentsRducer,
    auth: authReducer,
    exams: examsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(API.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
