import { MutableRefObject } from "react";

declare var google: IGoogleIdentity;
interface IGoogleIdentity {
  accounts: {
    id: {
      initialize: ({
        client_id,
        callback,
      }: {
        client_id: string;
        callback: Function;
      }) => void;
      renderButton: Function;
    };
  };
}
type Props = {
  credentialHandler: Function;
  nodeRef: MutableRefObject<null>;
};
const src = "https://accounts.google.com/gsi/client";
const client_id =
  "410291431871-spre092o8eiunrhlqnihichhrbk4go1f.apps.googleusercontent.com";
const defaultOption = {
  theme: "outline",
  width: 270,
  longtitle: true,
  height: 50,
};
const onload = (
  nodeRef: MutableRefObject<null>,
  callback: Function,
  option = defaultOption
) => {
  google.accounts.id.initialize({
    client_id,
    callback,
  });
  google.accounts.id.renderButton(nodeRef.current, option);
};
const loadscript = (onload: Function, onerror: Function) => {
  if (document.querySelector(`script[src="${src}"]`)) return;
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = () => onload();
  script.onerror = () => onerror();
  const head = document.getElementsByTagName("head")[0];
  head.appendChild(script);
};

const googleIdentity = (props: Props) => {
  loadscript(() => onload(props.nodeRef, props.credentialHandler), console.log);

  return () => {
    const scriptElement = document.querySelector(`script[src="${src}"]`)!;
    const head = document.getElementsByTagName("head")[0];
    head.removeChild(scriptElement);
  };
};

export default googleIdentity;
