declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}
class TagManger {
  gtmId: string | undefined;
  config: {
    w: Window & typeof globalThis;
    d: Document;
    s: string;
    l: string;
    i?: string;
  };
  constructor() {
    this.gtmId = process.env.REACT_APP_GTM_ID;
    this.config = {
      w: window,
      d: document,
      s: "script",
      l: "dataLayer",
      i: this.gtmId,
    };
  }

  init = () => {
    const { w, d, s, l, i } = this.config;
    w.dataLayer = w.dataLayer || [];
    w.dataLayer.push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s) as HTMLScriptElement,
      dl = l !== "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f?.parentNode?.insertBefore(j, f);
  };
}

export default new TagManger();
