import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Fragment,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import useActiveRoute from "hooks/useActiveRoute";
import { useTranslation } from "react-i18next";
import {
  ILeafMenuItem,
  ISubMenuItem,
  MenuType,
} from "components/Header/Secure/MenuList";

const customStyles = {
  splitBtn: {
    color: "rgb(200 207 210)",
    fontSize: "18px",
    cursor: "pointer",
  },
  active: {
    color: "rgb(98 195 238)",
  },
  subMenu: {
    visibility: "hidden",
    position: "absolute",
    right: "100%",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "max-content",
    padding: "0.25rem 0.35rem",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(218 218 218)",
    borderRadius: "0.25rem",
    boxShadow: " 0px 5px 10px 0px rgba(0, 0, 0, 0.06)",
    "& > div:hover": {
      borderRadius: "4px",
      backgroundColor: "rgb(241 242 246)",
    },
  },
};

interface IMenuProps {
  menuItems: MenuType[];
  visible: "visible" | "hidden";
  align: "right" | "left";
  active?: boolean;
  "data-test"?: string;
}

function Menu({
  menuItems,
  visible,
  align,
  active = false,
  ...rest
}: IMenuProps) {
  const { t, i18n } = useTranslation();
  const [visibility, setVisibility] = useState(visible);
  const activeRoute = useActiveRoute();
  const [showSubMenu, setShowSubMenu] = useState(true);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSide);
    return () => {
      document.removeEventListener("mousedown", clickOutSide);
    };
  }, []);

  useEffect(() => {
    setVisibility(visible);
  }, [visible]);

  const clickOutSide = (event: MouseEvent) => {
    if (!menuRef.current?.contains(event.target as Node)) {
      setVisibility("hidden");
      setShowSubMenu(true);
    }
  };

  const clickHandler = (
    event: ReactMouseEvent<HTMLDivElement>,
    subItem: ILeafMenuItem
  ) => {
    event.stopPropagation();
    setShowSubMenu(false);
    setVisibility("hidden");
    subItem.action?.();
  };

  const IconMenu = visibility === "hidden" ? ExpandMoreIcon : ExpandLessIcon;

  const menuStyle = {
    menu: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      top: "130%",
      ...(align === "left" ? { left: "20%" } : { right: "20%" }),
      color: "rgba(107 106 106)",
      position: "absolute",
      width: "max-content",
      padding: "0.25rem 0.35rem",
      backgroundColor: "rgb(255 255 255)",
      border: "1px solid rgb(218 218 218)",
      borderRadius: "0.25rem",
      boxShadow: " 0px 5px 10px 0px rgba(0, 0, 0, 0.06)",
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: "100%",
        ...(align === "left" ? { left: "0.65rem" } : { right: "0.65rem" }),
        borderWidth: "0.75rem",
        borderStyle: "solid",
        borderColor: "transparent transparent  rgb(218 218 218) transparent",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        bottom: "100%",
        ...(align === "left" ? { left: "0.75rem" } : { right: "0.75rem" }),
        borderWidth: "0.65rem",
        borderStyle: "solid",
        borderColor: "transparent transparent  rgb(255 255 255) transparent",
      },
      "& *": {
        fontSize: "1rem !important",
        textAlign: "center",
        margin: "0.15rem 0 !important",
        padding: "0",
      },
      "& > div:hover": {
        borderRadius: "4px",
        backgroundColor: "rgb(241 242 246)",
        "& nav": {
          visibility: "visible",
        },
      },
      "& span": {
        padding: "0 15px",
      },
      "& hr": {
        width: "100%",
      },
      "& hr:last-child": {
        display: "none",
      },
    },
  };

  return (
    <>
      <IconMenu
        sx={{
          ...customStyles.splitBtn,
          ...(active ? customStyles.active : {}),
        }}
      />
      <List
        component="nav"
        sx={menuStyle.menu}
        style={{ visibility }}
        ref={menuRef}
        {...rest}
      >
        {menuItems.map((item, index) => (
          <Fragment key={"dropdownItem" + index}>
            <ListItemButton
              onClick={(event) => item.action?.(event)}
              sx={{
                ...(activeRoute(item.name) ? customStyles.active : {}),
                padding: "0 10px",
                width: "100%",
                ...(item.action || (item as ISubMenuItem).menuItems
                  ? { pointerEvents: "initial" }
                  : { cursor: "inherit", pointerEvents: "none" }),
              }}
            >
              <ListItemText primary={t(item.label)} />
              {item.menuType === "sub" ? (
                <>
                  <ArrowBackIosIcon
                    sx={{
                      transform:
                        i18n.dir() === "rtl" ? undefined : "rotate(180deg)",
                    }}
                  />
                  <List
                    component="nav"
                    sx={customStyles.subMenu}
                    style={{
                      visibility: !showSubMenu ? "hidden" : undefined,
                      ...(i18n.dir() === "rtl"
                        ? { right: "100%" }
                        : { left: "100%" }),
                    }}
                    ref={menuRef}
                  >
                    {item.menuItems.map((subItem, index) => (
                      <Fragment key={"subDropdownItem" + index}>
                        <ListItemButton
                          onClick={(event) => clickHandler(event, subItem)}
                          style={{
                            padding: "0 10px",
                            width: "100%",
                            color: activeRoute(subItem.name)
                              ? "rgb(98 195 238)"
                              : "rgba(107 106 106)",
                          }}
                          key={"subItem" + index}
                        >
                          <ListItemText primary={t(subItem.label)} />
                        </ListItemButton>
                        <Divider />
                      </Fragment>
                    ))}
                  </List>
                </>
              ) : null}
            </ListItemButton>
            <Divider />
          </Fragment>
        ))}
      </List>
    </>
  );
}

export default Menu;
