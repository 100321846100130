import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IGrade, IMonthOption, IOption, Sort } from "../common/Interfaces";

interface IGrStudentsProgressState {
  grade?: IGrade;
  class?: IOption;
  selectedPlanMonth?: IMonthOption;
  selectedSort: Sort;
  isSorting: boolean;
}

const initialState: IGrStudentsProgressState = {
  selectedSort: Sort.asc,
  isSorting: false,
};

export const grStudentsProgressSlice = createSlice({
  name: "grStudentsProgress",
  initialState,
  reducers: {
    gradeSet(state, action: PayloadAction<IGrade>) {
      state.grade = action.payload;
    },
    classSet(state, action: PayloadAction<IOption>) {
      state.class = action.payload;
    },
    selectedPlanMonthSet(state, action: PayloadAction<IMonthOption>) {
      state.selectedPlanMonth = action.payload;
    },
    selectedSortSet(state, action: PayloadAction<Sort>) {
      state.selectedSort = action.payload;
    },
    isSortingSet(state, action: PayloadAction<boolean>) {
      state.isSorting = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.grStudentsProgress.grade;
export const selectClass = (state: RootState) => state.grStudentsProgress.class;
export const selectSelectedPlanMonth = (state: RootState) =>
  state.grStudentsProgress.selectedPlanMonth;
export const selectSelectedSort = (state: RootState) =>
  state.grStudentsProgress.selectedSort;
export const selectIsSorting = (state: RootState) =>
  state.grStudentsProgress.isSorting;

export const {
  gradeSet,
  classSet,
  selectedPlanMonthSet,
  selectedSortSet,
  isSortingSet,
} = grStudentsProgressSlice.actions;

export default grStudentsProgressSlice.reducer;
