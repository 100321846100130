import { TableCell, TableRow, TableHead } from "@mui/material";

const customStyles = {
  tableRow: {
    height: "50px",
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: "bold",
    padding: 0,
    color: "rgba(62, 77, 91, 1)",
    "@media screen and (max-width: 1100px)": {
      fontSize: "13px",
    },
  },
  inActive: {
    ".MuiTableSortLabel-icon": {
      display: "none",
    },
  },
  onHover: {
    "&:hover svg": {
      display: "inline-block",
    },
  },
};

export interface ITableHeadColumn {
  id: string;
  label: string | JSX.Element;
  width: string;
}

function EnhancedTableHead({ tableHead }: { tableHead: ITableHeadColumn[] }) {
  return (
    <TableHead>
      <TableRow sx={customStyles.tableRow}>
        {tableHead.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sx={customStyles.tableCell}
            width={headCell.width}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
