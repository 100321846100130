import { Skeleton } from "@mui/material";

function AssessmentSkeleton() {
  return (
    <div
      style={{
        width: "100%",
        padding: "0 15px 20px",
      }}
    >
      <Skeleton variant="rectangular" height={225} />
      <Skeleton variant="text" sx={{ marginBottom: "20px" }} />
      <Skeleton variant="rectangular" height={275} sx={{ margin: "10px 0" }} />
      <Skeleton variant="rectangular" height={150} sx={{ margin: "10px 0" }} />
      <Skeleton
        variant="rectangular"
        height={40}
        sx={{ margin: "10px auto" }}
        width={200}
      />
    </div>
  );
}

export default AssessmentSkeleton;
