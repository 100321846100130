import { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Filters from "./Filters";
import SelectedClasses from "./SelectedClasses";
import Header from "./Header";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import ExamThumbList from "./ExamThumbList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  examReset,
  selectAssignExam,
  selectSelectedClasses,
} from "features/exams";
import { IAssignExamRequest, useAssignExamMutation } from "services/exams";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";

const btnStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  paddingTop: "20px",
  marginTop: "20px",
  borderTop: "1px solid rgba(224, 224, 224, 1)",
  boxShadow: "0px -3px 0px -2px rgba(224, 224, 224, 1)",
};

interface IAssignmentProps {
  open: boolean;
  setOpen(open: boolean): void;
}
function ExamAssignment({ open, setOpen }: IAssignmentProps) {
  const { logEvent } = useContext(MasterDataContext);

  const { t, i18n } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const [isInvalid, setIsInvalid] = useState(false);
  const selectedClasses = useAppSelector(selectSelectedClasses);
  const assignedClasses = selectedClasses.filter(
    (c) => c.students.filter((stu) => stu.assignable).length > 0
  );
  const examData = useAppSelector(selectAssignExam);
  const [assignExam, { isLoading }] = useAssignExamMutation();

  const submitHandler = async () => {
    try {
      const payload: IAssignExamRequest = {
        _assessmentId: examData.examId!,
        _startDate: examData.startDate,
        _classes: assignedClasses.map((c) => ({
          _classId: c.classId,
          _students: c.students
            .filter((stu) => stu.checked && stu.assignable)
            .map((stu) => stu.studentId),
        })),
      };
      const response = await assignExam(payload).unwrap();
      if (response._statusCode === 200) {
        closeAssignmentHandler();
        toast({
          label: t("success"),
          message: t("Assignments Done Successfully"),
          rtl: i18n.dir() === "rtl",
          type: "success",
        });
        const generateAssignmentWeek = (date: string) => {
          const formatDate = (date: Date) => {
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
          };
          const today = new Date();
          const firstDay = formatDate(
            new Date(today.setDate(today.getDate() - today.getDay()))
          );
          const lastDay = formatDate(
            new Date(today.setDate(today.getDate() - today.getDay() + 6))
          );
          return firstDay + " - " + lastDay;
        };
        logEvent("AssignAssessment", {
          ContentId: examData.examId,
          StudentsCount: assignedClasses.reduce(
            (count, assignedClass) =>
              (count += assignedClass.students.filter(
                (stu) => stu.checked && stu.assignable
              ).length),
            0
          ),
          AssignmetDate: generateAssignmentWeek(examData.startDate),
        });
      } else if (response._statusCode === 619) {
        toast({
          label: t("error"),
          message: t("Can't Assign!, acadameic year finished"),
          rtl: i18n.dir() === "rtl",
          type: "error",
          toastId: "InvalidDate",
        });
      } else throw response;
    } catch (error) {
      console.log({ error });
    }
  };

  const closeAssignmentHandler = () => {
    dispatch(examReset());
    setOpen(false);
  };

  return (
    <>
      <Loader open={isLoading} />
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={closeAssignmentHandler}
        sx={{
          "& .MuiDrawer-paper": {
            height: "90%",
          },
        }}
      >
        <div style={{ margin: "15px", direction: i18n.dir() }}>
          <Header setOpen={setOpen} />
          <ExamThumbList />
          <Filters
            resetSelectedDate={open}
            invalidDate={isInvalid}
            resetValid={setIsInvalid}
          />
          <SelectedClasses />
          <div style={btnStyle}>
            <Button
              label={t("Assign")}
              styles={{ width: "260px" }}
              action={submitHandler}
              disable={assignedClasses.length === 0 || isLoading}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ExamAssignment;
