import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { SortIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";
import { ILoStudentScore } from ".";
import { Sort } from "features/common/Interfaces";

const customStyles = {
  rightColumn: {
    width: 130,
    borderLeft: "1px solid  rgba(214, 214, 214, 1)",
    borderRight: "1px solid  rgba(214, 214, 214, 1)",
    borderBottom: "none",
    backgroundColor: "none",
    textAlign: "center",
  },
  leftColumn: {
    padding: "0 20px",
  },
};
const useStyles = makeStyles({
  container: {
    border: "1px solid rgba(214, 214, 214, 1)",
    margin: "30px 20px",
    borderRadius: "4px",
  },
});

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));
interface ILosTable {
  header: string[];
  data: ILoStudentScore[];
  sortHandler(): void;
  sortingOrder: Sort;
}
const LosTable = ({ header, data, sortHandler, sortingOrder }: ILosTable) => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const [response, setResponse] = useState<ILoStudentScore[]>([]);

  useEffect(() => {
    const sortedData = (
      JSON.parse(JSON.stringify(data)) as ILoStudentScore[]
    ).sort((a, b) =>
      sortingOrder === Sort.asc
        ? Number(a.studentScore) - Number(b.studentScore)
        : Number(b.studentScore) - Number(a.studentScore)
    );
    setResponse(sortedData);
  }, [data, sortingOrder]);

  return (
    <>
      <div className={classes.container}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={customStyles.rightColumn}>
                  {t(header[0])}
                  <SortIcon
                    onClick={sortHandler}
                    style={{
                      transform:
                        sortingOrder === Sort.asc
                          ? "rotate(0deg)"
                          : "rotate(180deg)",
                      fontSize: "1.5rem",
                    }}
                  />
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    ...customStyles.leftColumn,
                    textAlign: i18n.dir() === "rtl" ? "right" : "left",
                  }}
                >
                  {t(header[1])}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {response?.map((d, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell sx={customStyles.rightColumn}>
                    {d.studentScore}%
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      ...customStyles.leftColumn,
                      textAlign: i18n.dir() === "rtl" ? "right" : "left",
                    }}
                  >
                    {d.title}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default LosTable;
