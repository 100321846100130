import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import SelectInput from "components/SelectInput";
import {
  classSet,
  studentSet,
  selectClass,
  selectStudent,
  selectGrade,
} from "features/studentPerformance";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFetchClassesQuery } from "services/common";
import { IExtendedOption } from "features/common/Interfaces";
import { createSelectResponse } from "utils/ReportsHelpers";

function Toolbar() {
  const [classesList, setClassesList] = useState<IExtendedOption[]>([]);
  const selectedClass = useAppSelector(selectClass);
  const selectedStudent = useAppSelector(selectStudent);
  const selectedGrade = useAppSelector(selectGrade);
  const dispatch = useAppDispatch();
  const { data: classesData } = useFetchClassesQuery(selectedGrade?.id!, {
    skip: !selectedGrade?.id,
  });

  useEffect(() => {
    if (classesData?._statusCode === 200) {
      const classes = createSelectResponse(classesData._entity);
      setClassesList(classes);
      dispatch(classSet(classes[0]));
      if (classes[0]?.students?.length)
        dispatch(studentSet(classes[0]?.students[0]));
    }
  }, [classesData]);

  return (
    <>
      {classesList.length > 0 ? (
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          sx={{ padding: "0 0.5rem 0.5rem" }}
        >
          <Grid item xs={3} md={3} xl={2}>
            <SelectInput
              defaultValue={selectedClass ? selectedClass.value : undefined}
              valueList={classesList}
              getOption={({
                currentTarget,
              }: {
                currentTarget: IExtendedOption;
              }) => {
                dispatch(classSet(currentTarget));
                dispatch(studentSet(currentTarget.students![0]));
              }}
            />
          </Grid>
          {selectedClass?.students?.length ? (
            <Grid item xs={3} md={3} xl={2}>
              <SelectInput
                defaultValue={
                  selectedStudent ? selectedStudent.value : undefined
                }
                valueList={selectedClass?.students!}
                getOption={({ currentTarget }) => {
                  dispatch(studentSet(currentTarget));
                }}
                disabled={selectedClass?.students?.length === 0}
              />
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </>
  );
}

export default Toolbar;
