import { makeStyles } from "@mui/styles";
import { Modal } from "@mui/material";
import Header from "../Popup/Header";

const useStyles = makeStyles({
  modal: {
    width: "80%",
    position: "absolute",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    outline: "none",
  },
  bigImage: {
    border: "2px solid rgba(98, 195, 238, 1)",
    borderRadius: "10px",
    width: "100%",
    aspectRatio: "auto",
  },
});

interface IModalImageProps {
  open: boolean;
  setOpen(value: boolean): void;
  selectedImage: string;
  "data-test"?: string;
}

function ModalImage({
  open,
  setOpen,
  selectedImage,
  ...rest
}: IModalImageProps) {
  const classes = useStyles();
  const closeHandler = () => setOpen(false);
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <div className={classes.modal} {...rest}>
        <Header onClose={closeHandler} />
        <img
          alt="assessment"
          className={classes.bigImage}
          src={selectedImage}
        />
      </div>
    </Modal>
  );
}

export default ModalImage;
