import { useState, useEffect, useContext } from "react";
import { IDateRange, MasterDataContext } from "contexts/MasterDataContext";
import SelectInput from "components/SelectInput/index";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IMonthOption, IOption } from "features/common/Interfaces";
import { ReportPathes } from "views/Secure/Reports/GuidedReading";

const monthArNames = [
  "يناير",
  "فبراير",
  "مارس",
  "أبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];
const monthEnNames = [
  "JANUARY",
  "FEBRUARY",
  "MARCH",
  "APRIL",
  "MAY",
  "JUNE",
  "JULY",
  "AUGUST",
  "SEPTEMBER",
  "OCTOBER",
  "NOVEMBER",
  "DECEMBER",
];

interface IMonthSelectProps {
  getMonth(option: IMonthOption): void;
  selectedClass?: IOption;
}

export default function MonthSelect({
  getMonth,
  selectedClass,
}: IMonthSelectProps) {
  const { t, i18n } = useTranslation();
  const { state, logEvent } = useContext(MasterDataContext);
  const [monthsList, setMonthsList] = useState<IMonthOption[]>([]);
  const [selectedPlanMonth, setSelectedPlanMonth] = useState<IMonthOption>();
  const { pathname: url } = useLocation();

  useEffect(() => {
    setMonthsList(
      state.academicYear ? generateMonthsPerYear(state.academicYear!) : []
    );
  }, [state.academicYear, i18n.dir()]);

  useEffect(() => {
    if (monthsList.length) {
      const d = new Date();
      const currentMonth = monthsList.filter(
        (data) =>
          typeof data.value === "object" &&
          data.value.month === monthEnNames[d.getMonth()] &&
          data.value.year === d.getFullYear()
      );
      setSelectedPlanMonth(currentMonth[0] ?? monthsList[0]);
      getMonth(currentMonth[0]);
    }
  }, [monthsList]);

  useEffect(() => {
    if (selectedPlanMonth) {
      getMonth(selectedPlanMonth);
    }
  }, [selectedPlanMonth]);

  const generateMonthsPerYear = (dateRange: IDateRange) => {
    const monthList = [];
    const startDate = new Date(dateRange.start);
    const endDate = new Date(dateRange.end);
    if (startDate.getTime() <= endDate.getTime()) {
      monthList.push({
        label: t("All Peroid"),
        value: "ALL",
      });
      if (startDate.getDate() > endDate.getDate())
        startDate.setDate(endDate.getDate());
      while (startDate.getTime() <= endDate.getTime()) {
        const currentMonth = startDate.getMonth();
        const currentYear = startDate.getFullYear();
        const currentOption = {
          label:
            currentYear +
            " - " +
            (i18n.dir() === "rtl"
              ? monthArNames[currentMonth]
              : monthEnNames[currentMonth].charAt(0).toLocaleUpperCase() +
                monthEnNames[currentMonth].slice(1).toLowerCase()),
          value: { month: monthEnNames[currentMonth], year: currentYear },
        };
        monthList.push(currentOption);
        startDate.setMonth(currentMonth + 1);
      }
    }
    return monthList;
  };

  const onChange = ({ currentTarget }: { currentTarget: IMonthOption }) => {
    logAnalyticsEvent(currentTarget);
    setSelectedPlanMonth(currentTarget);
  };

  const logAnalyticsEvent = (selectedPlanMonth: IMonthOption) => {
    const currentPath = url.split("/").pop();
    let eventName = "";
    if (currentPath === ReportPathes.students)
      eventName = "GuidedReadingClassReportPeriodFilter";
    else if (currentPath === ReportPathes.books)
      eventName = "GuidedReadingBooksReportPeriodFilter";
    if (eventName)
      logEvent(eventName, {
        ClassId: selectedClass?.value,
        SelectedMonth: selectedPlanMonth.label,
      });
  };

  return (
    <SelectInput
      defaultValue={selectedPlanMonth?.value}
      valueList={monthsList}
      getOption={onChange}
    />
  );
}
