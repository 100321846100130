import { API, IResponse, SlicesTags } from "./API";

interface IClassReportRequest {
  classId: number;
  domainId: number;
  examId?: number;
}
interface IStudentReportRequest {
  studentId: number;
  domainId: number;
}
export interface IStudentReportEntity {
  _assignedLos?: number;
  _avatarUrl?: string;
  _completedLos?: number;
  _id: number;
  _name: string;
  _score: number;
}
export interface IDomainDetailsEntity {
  _domainId: number;
  _domainName: string;
  _score: string;
  _studentAssessmentReports: IStudentReportEntity[];
}

export interface IDomainsReportEntity {
  _domainAssessmentReportRes: IDomainDetailsEntity[];
}
export interface ILoEntity {
  _eneStatus?: string;
  _id: number;
  _lessonTitle?: string;
  _offlineEvaluation: boolean;
  _questionProgresses?: boolean[];
  _scoreState: string;
  _title: string;
}
export interface IStudentDomainEntity {
  _name: string;
  _assignedLos?: number;
  _avatarUrl: string;
  _averageScore?: number;
  _completedLos?: number;
  _id: number;
  _los: ILoEntity[];
}
export interface IDomainReportEntity {
  _studentDomainDetails: IStudentDomainEntity[];
}
interface IFetchClassReportResponse extends IResponse {
  _entity: IDomainsReportEntity | IDomainReportEntity;
}
export interface IStudentScore {
  _domainId: number;
  _domainName: string;
  _studentScore?: number;
}

interface IStudentScores {
  _studentDomainScores: IStudentScore[];
}

interface IFetchStudentScoreByDomainResponse extends IResponse {
  _entity: IStudentScores;
}

interface IReportRequest {
  classId: number;
  monthName?: string;
  year?: number;
  sort?: string;
}

interface IStudentAssignmentRequest {
  classId: number;
  studentId: number;
}

interface IFetchGrBooksRequest {
  classId: number;
  monthName?: string;
  pageNumber?: number;
  year?: number;
}

export type grLevels =
  | "pending"
  | "أ"
  | "ب"
  | "ج"
  | "د"
  | "هـ"
  | "و"
  | "ز"
  | "ح"
  | "ط"
  | "ي"
  | "ك"
  | "ل"
  | "م"
  | "ن"
  | "س";
export type BookStatus = "FINISHED" | "STARTED";
interface IGrBookProgress {
  _assignmentStatus: BookStatus;
  _level: grLevels;
  _name: string;
  _timeSpent: string;
}
interface IGrStudentReportEntity {
  _books: IGrBookProgress[];
  _levelNames: grLevels[];
  _studentId: number;
  _studentName: string;
  _totalAssignedBooks: number;
  _totalCompletedBooks: number;
  _totalTimeSpent: string;
}
interface IGrStudentReportResponse extends IResponse {
  _entity: IGrStudentReportEntity[];
}

interface ILevelStatistics {
  _id: number;
  _name: grLevels;
  _totalNumberOfCompletedBooks: number;
}
interface IGrBooksStatisticsEntity {
  _levels: ILevelStatistics[];
  _totalNumberOfAssignedBooks: number;
  _totalNumberOfCompletedBooks: number;
}
interface IGrBooksStatisticsResponse extends IResponse {
  _entity: IGrBooksStatisticsEntity;
}

interface IGrBookEntity {
  _contentId: string;
  _id: number;
  _levelName: grLevels;
  _progressPercentage: number;
  _thumbUrl: string;
  _title: string;
  _totalNumberOfStudentAssigned: number;
  _totalNumberOfStudentCompleted: number;
}
interface IGrBooksReportEntity {
  _books: IGrBookEntity[];
  _currentPage: number;
  _totalPage: number;
}
interface IGrBooksReportResponse extends IResponse {
  _entity: IGrBooksReportEntity;
}

export interface IDomainLosStudentScore {
  _classScore: number;
  _loId: number;
  _loTitle: string;
  _studentScore: number;
}
interface IOnlineStudentReportEntity {
  _studentDomainLoScores: IDomainLosStudentScore[];
}
interface IOnlineStudentReportResponse extends IResponse {
  _entity: IOnlineStudentReportEntity;
}
export interface IDomainCriteriaStudentScore {
  _classAverageScore: number;
  _criteriaId: number;
  _criteriaTitle: string;
  _studentScore: number;
}
interface IOfflineStudentReportEntity {
  _studentDomainOfflineScores: IDomainCriteriaStudentScore[];
}
interface IOfflineStudentReportResponse extends IResponse {
  _entity: IOfflineStudentReportEntity;
}

export interface IStudentPacing {
  _avatarUrl?: string;
  _completedCount: number;
  _gender: string;
  _name: string;
  _percentage: number;
  _studentId: number;
  _totalCount: number;
}
export interface IPacingReportEntity {
  _percentage: number;
  _students: IStudentPacing[];
}
interface IPacingReportResponse extends IResponse {
  _entity: IPacingReportEntity;
}

export interface IDeliverableResponse {
  _code: string;
  _contentId: string;
  _contentTitleAr: string;
  _contentTitleEn: string;
  _deliverableDate: string;
  _gamesAvailable: boolean;
  _isMergeable: boolean;
  _learningObjectiveId: number;
  _learningObjectivePrefixArabic: string;
  _learningObjectivePrefixEnglish: string;
  _playCount: number;
  _status?: string;
  _thumbUrl: string;
  _topic?: string;
  _url: string;
}
export interface IStudentAssignmentEntity {
  _avatarUrl?: string;
  _deliverables: IDeliverableResponse[];
  _gender: string;
  _name: string;
  _studentId: number;
}
interface IStudentAssignmentResponse extends IResponse {
  _entity: IStudentAssignmentEntity;
}
const reportsServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchBaselineReport: build.query<
        IFetchClassReportResponse,
        IClassReportRequest
      >({
        query: (payload) => {
          let url = `/ams/class/${payload.classId}/la/1`;
          const domainUrl = `${url}/baseline-report/domain/${payload.domainId}`;
          url =
            payload.domainId !== -1 ? `${domainUrl}` : `${url}/baseline-report`;
          return url;
        },
      }),
      fetchFormativeReport: build.query<
        IFetchClassReportResponse,
        IClassReportRequest
      >({
        query: (payload) => {
          const url =
            payload.domainId !== -1 ? `/domain/${payload.domainId}` : "-report";
          return `/ams/class/${payload.classId}/la/1/assessment${url}`;
        },
      }),
      fetchExamReport: build.query<
        IFetchClassReportResponse,
        IClassReportRequest
      >({
        query: ({ classId, domainId, examId }) => {
          const domain = domainId !== -1 ? `&domainId=${domainId}` : "";
          const exam = examId !== -1 ? `&assessmentId=${examId}` : "";
          return (
            `/ams/assessment-bank/report?classId=${classId}` + domain + exam
          );
        },
        providesTags: (result, error, { examId, domainId, classId }) => {
          return [
            { type: SlicesTags.ClassExamReport, examId, domainId, classId },
          ];
        },
      }),
      fetchStudentScoreByDomain: build.query<
        IFetchStudentScoreByDomainResponse,
        number
      >({
        query: (studentId) => {
          return `/ams/student/${studentId}/assessment/domains`;
        },
      }),
      fetchOnlineStudentReport: build.query<
        IOnlineStudentReportResponse,
        IStudentReportRequest
      >({
        query: (payload) => {
          return `/ams/student/${payload.studentId}/domain/${payload.domainId}`;
        },
      }),
      fetchOfflineStudentReport: build.query<
        IOfflineStudentReportResponse,
        IStudentReportRequest
      >({
        query: (payload) => {
          return `/ams/student/${payload.studentId}/domain/${payload.domainId}/offline`;
        },
      }),
      fetchPacingReport: build.query<IPacingReportResponse, IReportRequest>({
        query: (payload) => {
          return payload.monthName
            ? `/ams/pacing/class/${payload.classId}?month=${payload.monthName}&year=${payload.year}`
            : `/ams/pacing/class/${payload.classId}`;
        },
      }),
      fetchStudentAssignmentDetails: build.query<
        IStudentAssignmentResponse,
        IStudentAssignmentRequest
      >({
        query: (payload) => {
          return `ams/pacing/class/${payload.classId}/student/${payload.studentId}`;
        },
      }),
      fetchGrBooksReport: build.query<
        IGrBooksReportResponse,
        IFetchGrBooksRequest
      >({
        query: (payload) => {
          let url = `ams/grbook/report/class/${payload.classId}?pageNumber=${payload.pageNumber}`;
          if (payload.monthName)
            url += `&month=${payload.monthName}&year=${payload.year}`;
          return url;
        },
      }),
      fetchStudentsGrbookProgress: build.query<IResponse, number>({
        query: (bookId) => {
          return `ams/grbook/student/book/${bookId}`;
        },
      }),
      fetchGrBooksStatistics: build.query<
        IGrBooksStatisticsResponse,
        IReportRequest
      >({
        query: (payload) => {
          let url = `ams/grbook/statistics/class/${payload.classId}`;
          if (payload.monthName)
            url += `?month=${payload.monthName}&year=${payload.year}`;
          return url;
        },
      }),
      fetchGrStudentProgressReport: build.query<
        IGrStudentReportResponse,
        IReportRequest
      >({
        query: (payload) => {
          let url = `ams/grbook/student-report/class/${payload.classId}?sortSpentTime=${payload.sort}`;
          if (payload.monthName) url += `&month=${payload.monthName}`;
          return url;
        },
      }),
    };
  },
});

export const {
  useFetchBaselineReportQuery,
  useFetchStudentScoreByDomainQuery,
  useFetchOnlineStudentReportQuery,
  useFetchOfflineStudentReportQuery,
  useFetchFormativeReportQuery,
  useFetchPacingReportQuery,
  useFetchStudentAssignmentDetailsQuery,
  useFetchGrBooksReportQuery,
  useFetchStudentsGrbookProgressQuery,
  useFetchGrBooksStatisticsQuery,
  useFetchGrStudentProgressReportQuery,
  useFetchExamReportQuery,
} = reportsServices;
