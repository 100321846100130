import { Grid } from "@mui/material";
import QRBulkSend from "./QRBulkSend";
import PrintQR from "./PrintQR";
import StudentsList from "./StudentsList";
import { selectStudentList } from "features/students";
import { useAppSelector } from "app/hooks";
import DataNotFound from "components/DataNotFound";
import { useTranslation } from "react-i18next";

const customStyles = {
  contentHeader: {
    boxShadow: "0px 2px 0px -2px rgba(224, 224, 224, 1)",
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    paddingBottom: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};
interface IContentProps {
  listCount: number;
  getIsPrinting(printing: boolean): void;
}
function Content({ listCount, getIsPrinting }: IContentProps) {
  const { t } = useTranslation();
  const studentList = useAppSelector(selectStudentList);

  return (
    <Grid container>
      {studentList?.length > 0 ? (
        <>
          <Grid item xs={12} sx={customStyles.contentHeader}>
            <PrintQR listCount={listCount} getIsPrinting={getIsPrinting} />
            <QRBulkSend />
          </Grid>
          <Grid item xs={12}>
            <StudentsList />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <DataNotFound text={t("No students")} />
        </Grid>
      )}
    </Grid>
  );
}

export default Content;
