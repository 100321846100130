import { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import {
  LessonIcon,
  FreeplayIcon,
  GuidedReadingIcon,
} from "components/SVGs/HomeIcons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import {
  ITag,
  moeViewSet,
  searchBySet,
  searchSet,
  sectionSet,
  selectSearchBy,
  selectSection,
} from "features/content";
import SectionsSkeleton from "./SectionsSkeleton";
import { LessonTypes } from "features/common/Interfaces";
import { MasterDataContext } from "contexts/MasterDataContext";
import { ExamsSectionIcon } from "components/SVGs/Exams";
import ConfirmMessage from "components/ConfirmMessage";
import { examRemoved, selectAssignExam } from "features/exams";
import {
  assignedLessonsSet,
  selectAssignedLessons,
} from "features/assignments";

const useStyles = makeStyles({
  subMenu: {
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    listStyle: "none",
    padding: "0 25px",
    background: "rgba(255 255 255)",
    margin: 0,
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  },
  tab: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(111, 117, 138, 1)",
    fontSize: "0.9rem",
    padding: "0 25px",
    cursor: "pointer",
    lineHeight: "60px",
    "&:hover": {
      fontWeight: "bold",
    },
    "& svg": {
      margin: "0 10px",
      color: "rgba(165, 170, 182, 1)",
    },
  },
  selectedTab: {
    boxShadow: "inset 0 -2px 0 rgba(111, 117, 138, 1)",
    fontWeight: "bold",
    padding: "0 25px",
    lineHeight: "60px",
    cursor: "default",
    "& svg": {
      color: "rgba(106, 112, 129, 1)",
    },
  },
});

const icons = {
  LESSON: <LessonIcon sx={{ fontSize: "1.5rem" }} />,
  FREEPLAY: <FreeplayIcon />,
  GUIDED_READING: <GuidedReadingIcon />,
  EXAMS: <ExamsSectionIcon />,
};
interface ISectionsProps {
  isLoading?: boolean;
}
function Sections({ isLoading = true }: ISectionsProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const currentSection = useAppSelector(selectSection);
  const assignExam = useAppSelector(selectAssignExam);
  const { assignedLosCount } = useAppSelector(selectAssignedLessons);
  const [section, setSection] = useState<LessonTypes>();
  const [mounted, setMounted] = useState(false);
  const searchBy = useAppSelector(selectSearchBy);
  const dispatch = useAppDispatch();
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  const selectSectionHandler = (section: LessonTypes) => {
    dispatch(sectionSet(section));
    dispatch(moeViewSet(false));
    dispatch(searchSet(searchBy?.value === "name" ? "" : ([] as ITag[])));
    dispatch(searchBySet({ label: t("Name"), value: "name" }));
  };

  const classes = useStyles();

  const clickOnSection = (section: LessonTypes) => {
    logEvent(`ClickOn${section}Section`);
    selectSectionHandler(section);
    dispatch(examRemoved());
    if (assignedLosCount && section === LessonTypes.EXAMS) {
      dispatch(moeViewSet(false));

      dispatch(
        assignedLessonsSet({
          losList: [],
          unitList: [],
          assignedLosCount: 0,
        })
      );
      dispatch(searchSet(""));
      dispatch(searchBySet({ label: t("Name"), value: "name" }));
    }
  };

  const changeSectionHandler = (section: LessonTypes) => {
    if (
      (assignExam.examId ||
        (assignedLosCount && section === LessonTypes.EXAMS)) &&
      mounted
    ) {
      setModalOpen(true);
      setSection(section);
    } else clickOnSection(section);
  };

  const deleteHandler = (answer: "yes" | "no") => {
    if (answer === "yes") {
      clickOnSection(section!);
    }
    setModalOpen(false);
  };
  return (
    <>
      <ul className={classes.subMenu} style={{ direction: i18n.dir() }}>
        {isLoading ? (
          <SectionsSkeleton />
        ) : (
          (Object.keys(LessonTypes) as LessonTypes[]).map((section) => (
            <li
              key={section}
              className={classNames(
                classes.tab,
                currentSection === section ? classes.selectedTab : null
              )}
              onClick={() => changeSectionHandler(section)}
            >
              {icons[section]}
              <span>{t(section)}</span>
            </li>
          ))
        )}
      </ul>
      <ConfirmMessage
        open={modalOpen}
        onClose={deleteHandler}
        description={t("Data will be lost on section change")}
        title={t("Warning")}
      />
    </>
  );
}

export default Sections;
