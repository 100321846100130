import PrintReport from "../../shared/PrintReport";
import {
  selectClass,
  selectClassReport,
  selectBaseLineReport,
  selectStudents,
  selectReport,
} from "features/classPerformance";
import { useAppSelector } from "app/hooks";
import { Assessment } from "features/common/Interfaces";

function PrintReports() {
  const selectedClass = useAppSelector(selectClass);
  const classReport = useAppSelector(selectClassReport);
  const students = useAppSelector(selectStudents);
  const baselineReport = useAppSelector(selectBaseLineReport);
  const report = useAppSelector(selectReport);

  return (
    <PrintReport
      studentsInfo={students}
      reportData={
        report.value === Assessment.FORMATIVE ? classReport : baselineReport
      }
      selectedClass={selectedClass!}
    />
  );
}

export default PrintReports;
