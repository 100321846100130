import { useEffect } from "react";
import LockIcon from "@mui/icons-material/Lock";
import { makeStyles } from "@mui/styles";
import useCheckCompatibility from "hooks/useCheckCompatibility";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    padding: "0.5rem",
    color: "rgba(111, 117, 138, 1)",
    fontSize: "1rem",
    lineHeight: "2rem",
    fontWeight: "bold",
  },
});

interface INotCompatibleProps {
  "data-test"?: string;
}
function NotCompatible(props: INotCompatibleProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dir = localStorage.getItem("dir");
  const isNotCompatible = useCheckCompatibility();
  useEffect(() => {
    const authToken = localStorage.getItem("abjadToken");
    const refreshToken = localStorage.getItem("refreshToken");
    if (isNotCompatible) history.push("/device-not-compatible");
    else if (authToken && refreshToken) history.push("/home/dashboard");
    else history.push("/login");
  }, []);
  const NotCompatibleAr =
    "عذراً، يمكنك تصفح الموقع من خلال أجهزة الحاسوب أو الأجهزة اللوحية فقط.";
  const NotCompatibleEn =
    "Sorry, you can only browse the site on computers or tablets.";

  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      <LockIcon style={{ fontSize: "6rem", padding: "1rem" }} />
      <span>
        {dir === "rtl" || dir === "ltr"
          ? t("Not Compatible")
          : navigator.language.includes("ar")
          ? NotCompatibleAr
          : NotCompatibleEn}
      </span>
    </div>
  );
}

export default NotCompatible;
