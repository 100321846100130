import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  header: {
    padding: "0 10px 10px",
    height: 65,
    marginBottom: "-15px",
    backgroundColor: "rgba(98, 195, 238, 1)",
    borderTopLeftRadius: 17,
    borderTopRightRadius: 17,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(255, 255, 255)",
    fontSize: "1.1rem",
    " & span": { flexGrow: 1, textAlign: "center" },
    " & svg": {
      cursor: "pointer",
    },
  },
});
interface IHeader {
  onClose(): void;
}
const Header = ({ onClose }: IHeader) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <span>{t("QRcode")}</span>
      <CancelRoundedIcon onClick={onClose} />
    </div>
  );
};
export default Header;
