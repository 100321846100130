import { useContext, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "components/Button";
import AssetType from "./AssetType";
import CriteriaList from "./CriteriaList";
import DataNotFound from "components/DataNotFound";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectClass,
  selectGrade,
  selectSelectedLo,
  selectStudent,
  isNeedRefetchingSet,
  containerHeightSet,
} from "features/evaluation";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import StudentSlider from "./StudentSlider";
import {
  IFetchAssessmentDetailsResponse,
  useEvaluateAssessmentMutation,
  useFetchAssessmentDetailsQuery,
} from "services/evaluation";
import {
  allOptionsNotSelected,
  calculateAveragePercentage,
  ICriterion,
  mapStudentScore,
} from "utils/EvaluationHelpers";
import useToast from "hooks/useToast";
import {
  assetsSet,
  changeDescSet,
  criteriaMapSet,
  criterionSet,
  currentStudentSet,
  descriptionSet,
  originalMapSet,
  overAllPercentageSet,
  paginationSet,
  percentageCalMapSet,
  reset,
  selectAssets,
  selectChangeDesc,
  selectCriteriaMap,
  selectCurrentStudent,
  selectDescription,
  selectOriginalMap,
  selectOverAllPercentage,
  selectPagination,
  selectPercentageCalMap,
  selectStudentInfo,
  studentInfoSet,
} from "features/assessment";
import AssessmentSkeleton from "./AssessmentSkeleton";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { StatusTypes } from "features/common/Interfaces";

const customStyles = {
  overAllPercentage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10px",
    backgroundColor: "rgb(228, 228, 228)",
    width: "100%",
    fontSize: "15px",
    paddingTop: "10px",
    borderRadius: "8px",
  },
  assignFooter: {
    display: "flex",
    justifyContent: "center",
    padding: "10px 15px",
    borderRadius: "0 0 20px 20px",
  },
  percentageText: {
    color: "rgb(98, 195, 238)",
  },
};

const maxDescriptionLength = 250;

function AssessmentContent() {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const selectedStudent = useAppSelector(selectStudent);
  const selectedClass = useAppSelector(selectClass);
  const selectedLo = useAppSelector(selectSelectedLo);
  const selectedGrade = useAppSelector(selectGrade);
  const currentStudent = useAppSelector(selectCurrentStudent);
  const criteriaMap = useAppSelector(selectCriteriaMap);
  const originalMap = useAppSelector(selectOriginalMap);
  const percentageCalMap = useAppSelector(selectPercentageCalMap);
  const overAllPercentage = useAppSelector(selectOverAllPercentage);
  const assets = useAppSelector(selectAssets);
  const description = useAppSelector(selectDescription);
  const changeDesc = useAppSelector(selectChangeDesc);
  const pagination = useAppSelector(selectPagination);
  const studentInfo = useAppSelector(selectStudentInfo);

  const { logEvent } = useContext(MasterDataContext);
  const assessmentContainerRef = useRef<HTMLDivElement>(null);

  const studentId =
    currentStudent === "all" ? selectedLo?.studentsIds?.[0] : currentStudent;
  const loId = selectedLo?.loCode;
  const type = selectedLo?.assessmentType;
  const {
    data: fetchAssessmentData,
    isLoading,
    isFetching,
  } = useFetchAssessmentDetailsQuery(
    studentId && loId && type ? { studentId, loId, type } : skipToken
  );

  const [evaluateAssessment, { isLoading: isSubmitting }] =
    useEvaluateAssessmentMutation();

  useEffect(() => {
    if (!selectedLo) {
      dispatch(reset());
    } else {
      //
      if (selectedStudent?.value !== "all") {
        dispatch(
          studentInfoSet(
            selectedLo?.studentDetails?.find(
              (student) => student.id === selectedStudent?.value
            )!
          )
        );
        const updatePagination = {
          totalPages: 1,
          currentPage: 1,
        };
        dispatch(paginationSet(updatePagination));
        dispatch(currentStudentSet(selectedStudent?.value! as number));
      } else {
        const updatePagination = {
          totalPages: selectedLo?.studentsIds?.length ?? 1,
          currentPage: 1,
        };
        dispatch(paginationSet(updatePagination));
        dispatch(studentInfoSet(selectedLo?.studentDetails?.[0]));
        dispatch(currentStudentSet(selectedLo?.studentsIds?.[0] as number));
      }
    }
  }, [selectedLo]);

  useEffect(() => {
    if (percentageCalMap)
      dispatch(
        overAllPercentageSet(calculateAveragePercentage(percentageCalMap))
      );
  }, [JSON.stringify(percentageCalMap)]);

  useEffect(() => {
    if (studentId && fetchAssessmentData && selectedLo)
      createAllStateValues(fetchAssessmentData);
  }, [fetchAssessmentData, studentId]);

  useEffect(() => {
    dispatch(currentStudentSet(selectedStudent?.value as number | "all"));
    if (selectedStudent?.value !== "all") {
      dispatch(
        studentInfoSet(
          selectedLo?.studentDetails?.find(
            (student) => student.id === selectedStudent?.value
          )!
        )
      );
    }
  }, [selectedStudent?.value]);

  useEffect(() => {
    if (assessmentContainerRef?.current?.getBoundingClientRect()?.height)
      dispatch(
        containerHeightSet(
          assessmentContainerRef?.current?.getBoundingClientRect()?.height
        )
      );
  }, [assessmentContainerRef?.current?.getBoundingClientRect()?.height]);

  const updateDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (event.target.value.length < maxDescriptionLength) {
    dispatch(changeDescSet(event.target.value));
    // }
  };

  useEffect(() => {
    dispatch(changeDescSet(description));
  }, [description]);

  const submitHandler = async () => {
    try {
      let _criteriaAndOptionDto = [];
      for (const key in criteriaMap) {
        let selectedData: { _criteriaId: string; _optionId: number | null } = {
          _criteriaId: key,
          _optionId: criteriaMap[key],
        };

        _criteriaAndOptionDto.push(selectedData);
      }
      let dataForPostReq = {
        _criteriaAndOptionDto,
        _loCode: selectedLo?.loCode,
        _studentId: studentId,
        _description: changeDesc.trim(),
        _assessmentType: selectedLo?.assessmentType,
      };

      const data = await evaluateAssessment(dataForPostReq).unwrap();
      if (data._statusCode === 200) {
        if (pagination.currentPage < pagination.totalPages) {
          dispatch(
            paginationSet({
              ...pagination,
              currentPage: pagination.currentPage + 1,
            })
          );
          dispatch(
            studentInfoSet(
              selectedLo?.studentDetails?.[pagination.currentPage]!
            )
          );
          dispatch(
            currentStudentSet(
              selectedLo?.studentDetails?.[pagination.currentPage].id!
            )
          );
        }
        toast({
          label: t("success"),
          message: t("Evaluated Successfully"),
          rtl: i18n.dir() === "rtl",
          type: "success",
          toastId: "EvaluationMessage",
        });
        if (selectedLo?.status === StatusTypes.PENDING)
          dispatch(isNeedRefetchingSet(true));
      } else throw data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = () => {
    if (pagination.currentPage < pagination.totalPages) {
      logEvent("ClickOnNextStudentToEvaluate", {
        AssessmentID: selectedLo?.loCode,
        AssessmentType: selectedLo?.assessmentType,
        Grade: selectedGrade?.code,
        Class: selectedClass?.label,
      });
      dispatch(
        paginationSet({
          ...pagination,
          currentPage: pagination.currentPage + 1,
        })
      );
      dispatch(
        studentInfoSet(selectedLo?.studentDetails?.[pagination.currentPage]!)
      );
      dispatch(
        currentStudentSet(
          selectedLo?.studentDetails?.[pagination.currentPage].id!
        )
      );
    }
  };

  const studentChangeHandler = (currentPage: number) => {
    if (currentPage !== pagination.currentPage) {
      dispatch(paginationSet({ ...pagination, currentPage }));
      dispatch(studentInfoSet(selectedLo?.studentDetails?.[currentPage - 1]!));
      dispatch(
        currentStudentSet(selectedLo?.studentDetails?.[currentPage - 1].id!)
      );
    }
  };

  const isNotValid = changeDesc.trim().length > maxDescriptionLength;
  return (
    <Grid container ref={assessmentContainerRef} sx={{ direction: i18n.dir() }}>
      <Grid item xs={12}>
        <StudentSlider
          onChangeStudent={studentChangeHandler}
          studentInfo={studentInfo}
          pagination={pagination}
        />
      </Grid>
      {isLoading || isFetching ? (
        <AssessmentSkeleton />
      ) : assets?.length > 0 ? (
        <>
          <Grid item xs={12} sx={{ height: "fit-content" }}>
            <AssetType />
          </Grid>
          <Grid item xs={12}>
            <CriteriaList />
          </Grid>
          <Grid
            item
            xs
            sx={{
              width: "100%",
              paddingTop: "10px",
            }}
          >
            <div style={customStyles.overAllPercentage}>
              <span style={{ padding: "0 30px" }}>{t("Total sum")}</span>
              <span style={customStyles.percentageText}>
                {isNaN(overAllPercentage) ? 0 : Math.ceil(overAllPercentage)}%
              </span>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="note-details"
              value={changeDesc}
              onChange={updateDescription}
              placeholder={t("write your note")}
              multiline
              rows={5}
              helperText={t("Only 250 characters are allowed.")}
              error={isNotValid}
              sx={{
                width: "100%",
                margin: "10px 0px",
                borderRadius: "8px",
                direction: i18n.dir(),
                "& > p": {
                  textAlign: i18n.dir() === "ltr" ? "left" : "right",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} style={customStyles.assignFooter}>
            <Button
              label={t("save")}
              styles={{ width: "150px" }}
              action={submitHandler}
              disable={
                allOptionsNotSelected(
                  criteriaMap,
                  originalMap,
                  changeDesc,
                  description
                ) || isSubmitting
              }
            />
            {selectedStudent?.value === "all" &&
            selectedLo?.studentDetails &&
            selectedLo.studentDetails.length > 1 ? (
              <Button
                label={t("skip")}
                white
                styles={{
                  width: "150px",
                  margin: "0 10px",
                  ...(pagination.currentPage === pagination.totalPages
                    ? {
                        background: "rgb(223,223,223)",
                        color: "rgb(255 255 255)",
                      }
                    : {}),
                }}
                action={handleNext}
                disable={pagination.currentPage === pagination.totalPages}
              />
            ) : null}
          </Grid>
        </>
      ) : (
        <DataNotFound />
      )}
    </Grid>
  );

  function createAllStateValues(
    fetchAssessmentData: IFetchAssessmentDetailsResponse
  ) {
    if (fetchAssessmentData?._statusCode === 200) {
      const {
        _criterion,
        _assets: assets,
        _description: description,
      } = fetchAssessmentData?._entity;
      if (_criterion?.length) {
        const criterion: ICriterion[] = [
          ..._criterion.map((item) => ({
            id: item._id,
            selectedOptionId: item._selectedOptionId,
            textAr: item._textAr,
            scores: item._scores.map((score) => ({
              id: score._id,
              textAr: score._textAr,
              weight: score._weight,
            })),
          })),
        ];
        const { criteriaCellMapping, percentageArray } =
          mapStudentScore(criterion);
        dispatch(criteriaMapSet(criteriaCellMapping));
        dispatch(originalMapSet(criteriaCellMapping));
        dispatch(percentageCalMapSet(percentageArray));
        dispatch(criterionSet(criterion));
        dispatch(assetsSet(assets));
        dispatch(descriptionSet(description));
      }
    } else {
      dispatch(criteriaMapSet({}));
      dispatch(originalMapSet({}));
      dispatch(percentageCalMapSet([]));
      dispatch(criterionSet([]));
      dispatch(assetsSet([]));
      dispatch(descriptionSet(""));
    }
  }
}

export default AssessmentContent;
