import {
  createContext,
  useReducer,
  useEffect,
  Dispatch,
  ReactNode,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useCheckCompatibility from "hooks/useCheckCompatibility";
import { useHistory } from "react-router-dom";
import { useUserInfoQuery } from "services/auth";
import { resetLocalStorage } from "utils/HomeHelpers";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent as googleLogEvent } from "firebase/analytics";
import ConfirmMessage from "components/ConfirmMessage";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  assignedLessonsSet,
  selectAssignedLessons,
} from "features/assignments";
import { allGradesSwitchSet, selectAllGradesSwitch } from "features/filters";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_GOOGLEAPP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  env: process.env.REACT_APP_ENV,
};

const analytics = () => {
  const app = initializeApp(config);
  return (eventName: string, loggingData?: any) => {
    googleLogEvent(getAnalytics(app), eventName, loggingData);
  };
};

type LogEvent = (eventName: string, loggingData?: any) => void;

const configLogEvent = (state: any, url: string, lang: "AR" | "EN") => {
  const logAnalytics = analytics();
  return (eventName: string, additionalData?: any) => {
    let loggingData = {
      TeacherId: state.userInfo.id,
      SchoolId: state.userInfo.school,
      Source: url,
      SelectedLanguage: lang,
      ...additionalData,
    };

    logAnalytics(eventName, loggingData);
  };
};
export interface IDateRange {
  start: string;
  end: string;
}

export enum ActionType {
  academicYear = "academicYear",
  userInfo = "userInfo",
  schoolLogo = "schoolLogo",
}
export type IAction = {
  type: ActionType;
  payload: any;
};

interface IMasterDataState {
  academicYear?: IDateRange;
  schoolLogoUrl?: string;
  userInfo: {
    school?: string;
    schoolName?: string;
    id?: string;
    name?: string;
    email?: string;
  };
}

const initialState: IMasterDataState = {
  userInfo: {
    school: localStorage.getItem("school") ?? undefined,
    id: localStorage.getItem("id") ?? undefined,
    name: localStorage.getItem("name") ?? undefined,
    schoolName: localStorage.getItem("schoolName") ?? undefined,
    email: localStorage.getItem("email") ?? undefined,
  },
};

const reducer = (state: IMasterDataState, action: IAction) => {
  switch (action.type) {
    case ActionType.userInfo:
      return { ...state, userInfo: action.payload };
    case ActionType.schoolLogo:
      return { ...state, schoolLogo: action.payload };
    case ActionType.academicYear:
      return { ...state, academicYear: action.payload };
    default:
      return { ...state };
  }
};
const locations = ["/forgot-password", "/reset-password", "/login"];

interface IMasterDataContext {
  state: IMasterDataState;
  dispatch: Dispatch<IAction>;
  logEvent: LogEvent;
}
export const MasterDataContext = createContext<IMasterDataContext>({
  state: initialState,
  dispatch: () => {},
  logEvent: (eventName, additionalData) => {},
});

const MasterDataContextProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { t, i18n } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const { assignedLosCount } = useAppSelector(selectAssignedLessons);
  const dispatchStore = useAppDispatch();
  const allGradesSwitch = useAppSelector(selectAllGradesSwitch);
  const { pathname: url } = useLocation();
  const isNotCompatible = useCheckCompatibility();
  const { data } = useUserInfoQuery(state.userInfo?.school, {
    skip: !state.userInfo?.school,
  });
  const history = useHistory();
  const confirmCartCleanup = (answer: "yes" | "no") => {
    if (answer === "yes") {
      dispatchStore(
        assignedLessonsSet({
          losList: [],
          unitList: [],
          assignedLosCount: 0,
        })
      );
      dispatchStore(allGradesSwitchSet(false));
    } else {
      history.push("/dashboard");
    }
    setModalOpen(false);
  };
  useEffect(() => {
    if (url !== "/dashboard" && assignedLosCount) {
      setModalOpen(true);
    }
    if (allGradesSwitch && !assignedLosCount) {
      dispatchStore(allGradesSwitchSet(false));
    }
  }, [url]);
  useEffect(() => {
    if (!isNotCompatible) {
      if (
        Object.keys(state.userInfo).length < 3 ||
        isNaN(+state.userInfo.id) ||
        !state.userInfo.id ||
        isNaN(+state.userInfo.school) ||
        !state.userInfo.school ||
        state.userInfo.name.length < 3
      ) {
        if (!locations.includes(url)) {
          resetLocalStorage();
          history.push("/login");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (data?._statusCode === 200) {
      const {
        _entity: {
          _educationalYearStartDate,
          _educationalYearEndDate,
          _schoolLogoUrl,
        },
      } = data;
      dispatch({
        type: ActionType.academicYear,
        payload: {
          start: _educationalYearStartDate,
          end: _educationalYearEndDate,
        },
      });
      dispatch({
        type: ActionType.schoolLogo,
        payload: _schoolLogoUrl,
      });
    }
  }, [data]);

  const logEvent = configLogEvent(
    state,
    url,
    i18n.dir() === "rtl" ? "AR" : "EN"
  );

  return (
    <MasterDataContext.Provider value={{ state, dispatch, logEvent }}>
      <>
        <ConfirmMessage
          open={modalOpen}
          onClose={confirmCartCleanup}
          description={t("Data will be lost on change section")}
          title={t("Warning")}
        />
        {props.children}
      </>
    </MasterDataContext.Provider>
  );
};

export default MasterDataContextProvider;
