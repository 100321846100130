import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Pagination from "components/Pagination";
import Progress from "./Progress";
import DataNotFound from "components/DataNotFound";
import GrBooksSkeleton from "./GrBooksSkeleton";
// import StudentsProgress from "./StudentsProgress";
import { GuidedReadingIcon } from "components/SVGs/MainMenuIcons";
import { MasterDataContext } from "contexts/MasterDataContext";
import { grLevels, useFetchGrBooksReportQuery } from "services/reports";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  currentPageSet,
  selectClass,
  selectCurrentPage,
  // selectedBookSet,
  selectSelectedPlanMonth,
  selectTotalPages,
  totalePagesSet,
} from "features/grBooksProgress";
import LoThumb from "components/LoThumb";
import { useTranslation } from "react-i18next";
import { LessonTypes } from "features/common/Interfaces";
import { ILo } from "features/content";

const useStyles = makeStyles({
  root: {
    position: "relative",
    padding: "1.5rem 0.75rem",
  },
  content: {
    display: "grid",
    gridColumnGap: "60px",
    gridRowGap: "20px",
    gridTemplateColumns: "repeat(auto-fill, minmax(250px,1fr))",
  },
  container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  bookData: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "166px",
    minWidth: "100px",
    flex: 1,
    "& span": {
      color: "rgba(105, 123, 140, 1)",
      marginBottom: "10px",
    },
  },
  progress: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    "& span": {
      paddingTop: "10px",
      textAlign: "left",
      color: "rgba(105, 123, 140, 1)",
    },
  },
  links: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
    "& > a": {
      color: "rgb(98 195 238)",
      fontSize: "14px",
      borderBottom: "1px solid",
    },
  },
});

interface IGrBook {
  bookId: number;
  bookTitle: string;
  thumbUrl: string;
  bookLevelName: grLevels;
  progress: number;
  bookContentId: string;
  totalAssignedStudents: number;
  totalCompletedStudents: number;
}

function Content() {
  const classes = useStyles();
  const [books, setBooks] = useState<IGrBook[]>([]);
  const { t, i18n } = useTranslation();
  // const [openStudentsProgressPopup, setOpenStudentsProgressPopup] =
  //   useState(false);
  const dispatch = useAppDispatch();
  const selectedClass = useAppSelector(selectClass);
  const selectedMonth = useAppSelector(selectSelectedPlanMonth);
  const pageNumber = useAppSelector(selectCurrentPage);
  const totalPages = useAppSelector(selectTotalPages);
  const { logEvent } = useContext(MasterDataContext);

  const {
    data: grBooksReportData,
    isLoading,
    isUninitialized,
  } = useFetchGrBooksReportQuery(
    {
      classId: selectedClass?.value as number,
      ...(typeof selectedMonth?.value === "object"
        ? {
            year: selectedMonth?.value?.year,
            monthName: selectedMonth?.value?.month,
          }
        : {}),
      pageNumber,
    },
    { skip: !selectedClass?.value }
  );

  useEffect(() => {
    if (grBooksReportData?._statusCode === 200) {
      let books: IGrBook[] = [];
      if (grBooksReportData._entity?._books?.length) {
        books = [
          ...grBooksReportData._entity._books.map((item) => ({
            bookId: item._id,
            bookTitle: item._title,
            thumbUrl: item._thumbUrl,
            bookLevelName: item._levelName,
            progress: item._progressPercentage,
            bookContentId: item._contentId,
            totalAssignedStudents: item._totalNumberOfStudentAssigned,
            totalCompletedStudents: item._totalNumberOfStudentCompleted,
          })),
        ];
      }
      setBooks(books);
      dispatch(totalePagesSet(grBooksReportData._entity._totalPage ?? 1));
    }
  }, [grBooksReportData]);

  const getPage = (value: number) => {
    logEvent("GuidedReadingBooksReportPageNavigate", {
      ClassId: selectedClass?.value,
    });
    dispatch(currentPageSet(value));
  };

  const compToRender = (data: IGrBook[]) => (
    <div className={classes.content}>
      {data.map((book) => (
        <div
          className={classes.container}
          key={book.bookId}
          // onClick={() => {
          //   dispatch(selectedBookSet(book));
          //   setOpenStudentsProgressPopup(true);
          // }}
        >
          <LoThumb
            lesson={
              {
                type: LessonTypes.GUIDED_READING,
                loTitle: book.bookTitle,
                thumbUrl: book.thumbUrl,
              } as ILo
            }
          />
          <div className={classes.bookData}>
            <span>{book.bookTitle}</span>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              {book.bookLevelName}
            </span>
            <div className={classes.progress}>
              <Progress progress={book.progress} />
              <span>{book.progress ? book.progress + "%" : "- -"}</span>
            </div>
            <div className={classes.links}>
              <span style={{ marginLeft: "10px" }}>
                {`${t("progress")} ${
                  book.totalCompletedStudents +
                  (i18n.dir() === "ltr" ? "/" : "\\") +
                  book.totalAssignedStudents
                }`}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
  // const closeHandler = () => {
  //   dispatch(selectedBookSet(undefined));
  //   setOpenStudentsProgressPopup(false);
  // };
  return (
    <>
      <div className={classes.root}>
        {isLoading || isUninitialized ? (
          <GrBooksSkeleton />
        ) : books.length ? (
          compToRender(books)
        ) : (
          <DataNotFound icon={<GuidedReadingIcon />} text="No added books" />
        )}
        {/* <StudentsProgress
          onClose={closeHandler}
          open={openStudentsProgressPopup}
        /> */}
      </div>
      {books.length ? (
        <Pagination
          total={totalPages}
          getPage={getPage}
          defaultPage={pageNumber}
          spreadStyle
        />
      ) : null}
    </>
  );
}

export default Content;
