import { makeStyles } from "@mui/styles";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(98, 195, 238)",
    color: "rgb(255, 255, 255)",
    fontSize: "1rem",
    padding: "0 10px 10px",
    fontWeight: "bold",
    borderRadius: "17px 17px 0 0",
    height: "65px",
    marginBottom: "-15px",
    " & span": { flexGrow: 1, textAlign: "center" },
    " & svg": {
      cursor: "pointer",
    },
  },
});
interface IHeaderProps {
  title?: string;
  onClose(): void;
  "data-test"?: string;
}
const Header = ({ title, onClose, ...rest }: IHeaderProps) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.root} style={{ direction: i18n.dir() }} {...rest}>
      <span>{title}</span>
      <CancelRoundedIcon onClick={() => onClose()} />
    </div>
  );
};

export default Header;
