import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ActivityLoIcon from "assets/img/ic_step_activity.svg";
import EneLoIcon from "assets/img/ic_step_arrow.svg";
import VideoLoIcon from "assets/img/ic_step_video.svg";
import AssessmentIcon from "assets/img/ic_step_test.svg";
import GameLoIcon from "assets/img/ic_step_gamepad.svg";
import EBook from "assets/img/ic_step_book.svg";
import DataNotFound from "../DataNotFound";
import { useTranslation } from "react-i18next";
import { IContentDetails, IEneContent } from "services/common";
import { IExamQuestionsEntity } from "services/exams";

const useStyles = makeStyles(() => ({
  root: {
    padding: "50px 0",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "17px",
    maxHeight: "500px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  lessonItem: {
    display: "flex",
    alignItems: "center",
    "&:first-of-type": {
      "& > div:last-of-type": {
        visibility: "hidden",
      },
    },
  },
  dotsThumb: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    color: "rgba(88, 88, 88, 1)",
    "& >*": {
      height: "100%",
      width: "100%",
      textAlign: "center",
      margin: "0.5rem 0",
    },
  },
  threeDots: {
    display: "flex",
    "& > span": {
      width: "0.5rem",
      height: "0.5rem",
      margin: "0 0.1rem",
      borderRadius: "0.5rem",
      backgroundColor: "rgb(98 195 238)",
    },
  },
}));

type TitlesKeys = keyof typeof titles;
const titles = {
  2: "Recognize",
  48: "Recognize",
  33: "Assessment",
  46: "Let's paly",
  52: "Reactive book",
  57: "Reactive book",
  ENH1: "ENH1",
  ENR1: "ENR1",
  ENR2: "ENR2",
};

interface IContentProps {
  lessonData: IContentDetails[] | IEneContent[] | IExamQuestionsEntity[];
  playEne: boolean;
  playExam: boolean;
  setSelectedId(value: string): void;
}

const Content = ({
  lessonData,
  playEne = false,
  playExam = false,
  setSelectedId,
}: IContentProps) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const playContent = async (contentId: string) => setSelectedId(contentId);

  const renderIcon = (
    id: string,
    type: number | string,
    playEne: boolean = false
  ) => {
    const icons = {
      true: EneLoIcon,
      2: VideoLoIcon,
      48: VideoLoIcon,
      33: AssessmentIcon,
      46: GameLoIcon,
      52: EBook,
      57: EBook,
    };
    const Icon =
      (playEne ? icons[`${playEne}`] : icons[type as keyof typeof icons]) ??
      ActivityLoIcon;
    return (
      <img
        onClick={() => playContent(id)}
        className="play-ext"
        src={Icon}
        alt={id}
      />
    );
  };
  let lessonContent: IContentDetails[] = [];
  if (lessonData?.length > 0) {
    if (playEne) {
      (lessonData as IEneContent[])
        .sort((item1, item2) => {
          return item1._eneLevelId - item2._eneLevelId;
        })
        .forEach((item) => {
          item._contentMetaDataResDtos.forEach((data) => {
            const updatedData = { ...data, _eneLevelName: item._eneLevelName };
            lessonContent.push(updatedData);
          });
        });
    } else if (playExam) {
      (lessonData as IExamQuestionsEntity[]).forEach((question) => {
        lessonContent.push({
          _id: question._questionBankContentId,
          _contentType: question._questionBankType,
        });
      });
    } else {
      lessonContent = JSON.parse(
        JSON.stringify(lessonData as IContentDetails[])
      );
    }
  }
  const size = Math.floor(100 / lessonContent.length) - 1;

  const renderContent = (lessonContent: IContentDetails[]) => {
    let counter = 1;
    const items = lessonContent.map((item, index) => {
      const { _contentType, _eneLevelName, _id } = item;
      const defaultTitle = () => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            direction: i18n.dir(),
          }}
        >
          <span>{counter++}</span>
          <span>{t("Practice")}</span>
        </div>
      );
      const indexOfTitel = (_eneLevelName ?? _contentType!) as TitlesKeys;
      const title = titles[indexOfTitel]
        ? t(titles[indexOfTitel])
        : defaultTitle();
      return (
        <Grid
          item
          key={index}
          className={classes.lessonItem}
          style={{ width: size + "%", direction: "rtl" }}
        >
          <div className={classes.dotsThumb}>
            {renderIcon(_id, _contentType!, playEne)}
            <Typography variant="caption" component="span">
              {title}
            </Typography>
          </div>
          <div className={classes.threeDots}>
            <span />
            <span />
            <span />
          </div>
        </Grid>
      );
    });
    return i18n.dir() === "rtl" ? items.reverse() : items;
  };

  return (
    <>
      <div
        className={classes.root}
        style={{
          paddingRight: "30px",
        }}
      >
        {lessonContent.length > 0 ? (
          <Grid
            container
            justifyContent="center"
            alignContent="center"
            style={{
              flexWrap: "wrap",
              direction: i18n.dir(),
              flexDirection: i18n.dir() === "ltr" ? "row" : "row-reverse",
            }}
          >
            {renderContent(lessonContent)}
          </Grid>
        ) : (
          <DataNotFound
            text={t("No students or Classes exists")}
            style={{ height: "150px", width: "450px" }}
          />
        )}
      </div>
    </>
  );
};

export default Content;
