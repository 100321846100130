import Filters from "./Filters";
import LosList from "./LosList";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "app/hooks";
import { selectAllGradesSwitch } from "features/filters";
import { selectSection } from "features/content";
import { LessonTypes } from "features/common/Interfaces";
import Exams from "../Exams";

const useStyles = makeStyles({
  card: {
    position: "relative",
    background: "rgba(255 255 255)",
    padding: "30px 0 100px",
  },
});

const AllGradesDivider = () => {
  const { t, i18n } = useTranslation();
  const selectedSection = useAppSelector(selectSection);
  return (
    <Typography
      component="div"
      sx={{
        position: "absolute",
        borderTop: "3px solid rgb(47 191 83)",
        width: "100%",
        top: "10px",
      }}
    >
      <Typography
        component="div"
        sx={{
          height: "35px",
          borderRadius: "0 0 11px 11px",
          backgroundColor: "rgb(47 191 83)",
          color: "rgb(255 255 255)",
          position: "absolute",
          width: "200px",
          top: 0,
          [i18n.dir() === "rtl" ? "right" : "left"]:
            selectedSection === LessonTypes.EXAMS ? "18%" : "3%",
          padding: "5px",
          textAlign: "center",
          fontWeight: "bold",
          zIndex: 30,
        }}
      >
        {t("Los of All Grades")}
      </Typography>
    </Typography>
  );
};
interface IContentProps {
  isLoading?: boolean;
}
function Content({ isLoading = true }: IContentProps) {
  const allGradesSwitch = useAppSelector(selectAllGradesSwitch);
  const selectedSection = useAppSelector(selectSection);
  const classes = useStyles();
  return (
    <div className={classes.card}>
      {allGradesSwitch ? <AllGradesDivider /> : null}
      {selectedSection === LessonTypes.EXAMS ? (
        <Exams />
      ) : (
        <>
          <Filters isLoading={isLoading} />
          <LosList isLoading={isLoading} />
        </>
      )}
    </div>
  );
}

export default Content;
