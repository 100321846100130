import Header from "./Header";
import Content from "./Content";
import { makeStyles } from "@mui/styles";
import { IStudentProgress } from "../Content";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontWeight: "bold",
    color: "rgb(62 77 91)",
    fontSize: "1rem",
    padding: "0.5rem",
  },
});

interface IAccordianTablePorps {
  records: IStudentProgress[];
}
function AccordianTable({ records }: IAccordianTablePorps) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <Content records={records} />
    </div>
  );
}

export default AccordianTable;
