import { useTranslation } from "react-i18next";
import { write, utils } from "xlsx";
import useToast from "hooks/useToast";
import { binaryToOct } from "utils/StudentListHelpers";
import { sendQRcodesByEmail } from "services/fileServices";
import { IUploadedRow } from "./useFileBuilder";

const useSendQRcodes = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const successMessage = () =>
    toast({
      label: t("success"),
      message: t("Qr codes sent successfully"),
      rtl: i18n.dir() === "rtl",
      type: "success",
      toastId: "Sent-Qr-codes-successfully",
    });
  const failedMessage = () =>
    toast({
      label: t("error"),
      message:
        t("Sent with some errors") +
        "\n" +
        t("Click on red icon to see errors"),
      rtl: i18n.dir() === "rtl",
      type: "error",
      toastId: "Some-error-with-sent",
    });
  const sendData = async (
    validData: IUploadedRow[],
    invalidData: IUploadedRow[] = []
  ) => {
    try {
      const studentDetailsSheet = utils.json_to_sheet(validData);
      const sendWorkbook = utils.book_new();
      utils.book_append_sheet(
        sendWorkbook,
        studentDetailsSheet,
        "student-details"
      );
      const binaryFile = write(sendWorkbook, {
        bookType: "xlsx",
        type: "binary",
      });
      const octFile = binaryToOct(binaryFile);
      const file = new Blob([octFile], {
        type: "application/octet-stream",
      });
      const form = new FormData();
      form.append("file", file);
      const response = await sendQRcodesByEmail(form);
      if (response) {
        if (response.data._statusCode === 200) {
          invalidData.length === 0 ? successMessage() : failedMessage();
        } else throw response;
      }
    } catch (error) {
      console.log(error);
      //todo
    }
  };
  return sendData;
};

export default useSendQRcodes;
