import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { SortIcon } from "components/SVGs";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectClass,
  selectedSortSet,
  selectIsSorting,
  selectSelectedSort,
} from "features/grStudentsProgress";
import { Sort } from "features/common/Interfaces";

const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "1rem 16px",
    fontSize: "14px",
    "@media screen and (max-width: 1024px)": {
      fontSize: "12px",
    },
  },
  sort: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      cursor: "pointer",
    },
  },

  studentName: {
    width: "275px",
    fontSize: "14px",
    padding: "0 0.5rem",
    "@media screen and (max-width: 1024px)": {
      width: "200px",
      fontSize: "12px",
    },
  },
  levels: { flexGrow: 1 },
  spentTime: {
    width: "285px",
    display: "flex",
    "@media screen and (max-width: 1024px)": {
      width: "190px",
    },
  },
  progress: {
    width: "90px",
    "@media screen and (max-width: 1024px)": {
      width: "70px",
    },
  },
});

function Header() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sort = useAppSelector(selectSelectedSort);
  const isSorting = useAppSelector(selectIsSorting);
  const selectedClass = useAppSelector(selectClass);
  const classes = useStyles();
  const { logEvent } = useContext(MasterDataContext);

  const sortHandler = () => {
    const order = sort === Sort.asc ? Sort.desc : Sort.asc;
    logEvent("GuidedReadingClassReportSortByTimeSpent", {
      ClassId: selectedClass?.value,
      Order: order,
    });
    dispatch(selectedSortSet(order));
  };

  return (
    <div className={classes.header}>
      <span className={classes.studentName}>{t("Student Name")}</span>
      <span className={classes.levels}>{t("Levels")}</span>
      <div className={classes.spentTime}>
        <span>{t("Time spent")}</span>
        <div className={classes.sort}>
          <SortIcon
            onClick={sortHandler}
            style={{
              transform: sort === Sort.asc ? "rotate(180deg)" : "rotate(0deg)",
              fontSize: "1.5rem",
              pointerEvents: isSorting ? "none" : "auto",
            }}
          />
          {isSorting && (
            <CircularProgress size={27} style={{ position: "absolute" }} />
          )}
        </div>
      </div>
      <span className={classes.progress}>{t("progress")}</span>
    </div>
  );
}

export default Header;
