import { CSSProperties } from "@mui/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import logo from "assets/img/logo.svg";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { Property } from "csstype";
import { Direction } from "features/common/Interfaces";
import { CloseIcon } from "components/SVGs";
import "./chat.css";
import Tooltip from "components/Tooltip";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectShowHelp, toggleShowHelp } from "features/ui";
import { Typography } from "@mui/material";
import { MasterDataContext } from "contexts/MasterDataContext";
// import { Tooltip } from "@mui/material";
const chatBoxId = "Freshchat-js-sdk";
const token = "dbe736eb-2b32-4217-90cc-3c77b357b985";

interface IChatConfig {
  config: {
    cssNames?: CSSProperties;
    headerProperty: {
      hideChatButton: boolean;
      direction?: Direction;
      backgroundColor?: string;
    };
  };
  token: string;
  host: string;
  // locale: string;
  externalId?: string;
  restoreId?: string;
  firstName?: string;
  email?: string;
  tags: string[];
  faqTags: {
    tags: string[];
    filterType: string;
  };
  onInit?(): void;
}
declare global {
  interface Window {
    fcSettings: IChatConfig;
    fcWidget: {
      init(params: IChatConfig): void;
      destroy(): void;
      open(): void;
      on(event: string, handler: Function): void;
      user: {
        setFirstName(firstName: string): void;
        setLastName(lastName: string): void;
        setEmail(email: string): void;
        get(
          callback: (resp: {
            status: number;
            data: { restoreId: string };
          }) => void
        ): void;
        setProperties({
          journey,
          school,
          school_name,
        }: {
          journey: string;
          school?: string;
          school_name?: string;
        }): void;
      };
      setExternalId(ids: string): void;
    };
  }
}
const customStyles = {
  root: {
    position: "fixed",
    bottom: "120px",
    zIndex: 999,
    "@media print": {
      display: "none",
    },
  },
  button: {
    cursor: "pointer",
    padding: "10px",
    width: "55px",
    height: "55px",
    boxShadow: "0 5px 4px 0 rgb(0 0 0 / 26%)",
    backgroundColor: "rgb(98 195 238)",
    // border: "1px solid rgb(255, 255, 255)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon: {
    color: "rgb(98 195 238)",
    background: "rgb(255, 255, 255)",
    border: "1px solid rgb(98 195 238)",
    borderRadius: "50%",
    fontSize: "20px",
  },
};
type Props = {};

function LiveChat({}: Props) {
  const dispatch = useAppDispatch();
  const showHelpState = useAppSelector(selectShowHelp);
  const { i18n, t } = useTranslation();
  const [direction, setDirection] = useState<Direction>();
  const [widget, setWidget] = useState<Direction>();
  const [loaded, setLoaded] = useState(false);
  const [destroyed, setDestroyed] = useState(false);
  const [visibility, setVisibility] = useState("visible");
  const {
    state: { userInfo },
  } = useContext(MasterDataContext);

  const loadChat = (load: boolean = true) => {
    let chatElement = document.getElementById(chatBoxId) as HTMLScriptElement;
    // if (chatElement) document.body.removeChild(chatElement);
    if (load) {
      // Load script
      chatElement = document.createElement("script");
      chatElement.id = chatBoxId;
      chatElement.async = true;
      chatElement.defer = true;
      chatElement.src = "https://wchat.freshchat.com/js/widget.js";
      chatElement.onload = initChat;
      document.head.appendChild(chatElement);
    }
  };

  const initChat = () => {
    const cssNames = { widget };
    let restoreId;
    const storedSessions = localStorage.getItem("restoreId") ?? undefined;
    if (storedSessions)
      restoreId = JSON.parse(storedSessions)[`${userInfo.id}`];
    const chatConfig: IChatConfig = {
      config: {
        cssNames,
        headerProperty: {
          hideChatButton: true,
          backgroundColor: "rgba(98 195 238)",
        },
      },
      token,
      host: "https://wchat.freshchat.com",
      // locale: i18n.dir() === "rtl" ? "ar" : "en",
      externalId: userInfo.id,
      restoreId,
      firstName: userInfo.name,
      email: userInfo.email,
      // tags: ["abjadiyat", "أبجديات"],
      tags: ["abjadiyat"],
      faqTags: {
        tags: ["abjadiyat"],
        filterType: "category",
      },
    };
    window.fcWidget.init(chatConfig);
    window.fcWidget.user.get(function (resp: {
      status: number;
      data: { restoreId: string };
    }) {
      var status = resp && resp.status;
      if (status !== 200) {
        window.fcWidget.user.setProperties({
          journey: "Teachers Web app",
          school: userInfo.school,
          school_name: userInfo.schoolName,
        });
        window.fcWidget.on(
          "user:created",
          function (resp: { status: number; data: { restoreId: string } }) {
            var status = resp && resp.status,
              data = resp && resp.data;
            if (status === 200) {
              if (data.restoreId) {
                if (storedSessions) {
                  const updateStoredSessions = JSON.parse(storedSessions);
                  updateStoredSessions[`${userInfo.id}`] = data.restoreId;
                  localStorage.setItem(
                    "restoreId",
                    JSON.stringify(updateStoredSessions)
                  );
                } else
                  localStorage.setItem(
                    "restoreId",
                    JSON.stringify({ [`${userInfo.id}`]: data.restoreId })
                  );
              }
            }
          }
        );
      }
    });

    window.fcWidget.on("widget:closed", () => {
      setVisibility("visible");
    });
    window.fcWidget.on("widget:opened", () => {
      setVisibility("hidden");
    });
    window.fcWidget.on("widget:loaded", () => {
      setLoaded(true);
    });
  };

  useEffect(() => {
    if (widget) setVisibility(showHelpState ? "visible" : "hidden");
  }, [showHelpState]);

  useEffect(() => {
    const dir = (localStorage.getItem("dir") ?? i18n.dir()) as Direction;
    setDirection(dir);
    return () => {
      loadChat(false);
    };
  }, []);

  useEffect(() => {
    setDirection(i18n.dir());
  }, [i18n.dir()]);

  useEffect(() => {
    setWidget(direction);
  }, [direction]);

  useEffect(() => {
    if (loaded) {
      window.fcWidget.destroy();
      setLoaded(false);
      setDestroyed(true);
    } else if (widget && !loaded) {
      loadChat();
    }
  }, [widget]);

  useEffect(() => {
    if (destroyed) {
      setTimeout(() => {
        initChat();
        setDestroyed(false);
      }, 100);
    }
  }, [destroyed]);

  const openWidget = function () {
    window.fcWidget.open();
  };

  const closeHandler = () => {
    setVisibility("hidden");
    dispatch(toggleShowHelp());
  };

  return (
    <Typography
      component="div"
      sx={{
        ...customStyles.root,
        [direction === "rtl" ? "left" : "right"]: "20px",
        display: loaded && visibility === "visible" ? "block" : "none",
      }}
    >
      <Tooltip title={t("Hide Help")}>
        <Typography
          component="div"
          sx={{
            position: "absolute",
            cursor: "pointer",
            top: "-4px",
            visibility: visibility as Property.Visibility,
            [direction === "rtl" ? "right" : "left"]: "-15px",
          }}
          onClick={closeHandler}
        >
          <CloseIcon sx={customStyles.closeIcon} />
        </Typography>
      </Tooltip>
      <div
        id="open_fc_widget"
        onClick={openWidget}
        style={{
          ...customStyles.button,
          borderRadius:
            direction === "rtl" ? "8px 55px 55px 55px" : "55px 8px 55px 55px",
          visibility: visibility as Property.Visibility,
        }}
      >
        <ChatOutlinedIcon
          id="imageicon"
          sx={{
            fontSize: "32px",
            color: "rgb(255, 255, 255)",
          }}
        />
      </div>
    </Typography>
  );
}

export default LiveChat;
