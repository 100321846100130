import { useEffect, useRef, useState } from "react";
import { CSSProperties, makeStyles } from "@mui/styles";
import { List, ListItemButton, ListItemText } from "@mui/material";
import classNames from "classnames";
import { ArrowDropDownIcon } from "../SVGs";
import { IExtendedOption } from "features/common/Interfaces";

const customStyles = {
  list: {
    width: "100%",
    padding: "5px",
    maxHeight: "225px",
    overflow: "auto",
    "&> div:active": {
      background: "rgb(244 244 244)",
    },
  },
  listItem: {
    borderRadius: "4px",
    padding: "4px 8px",
    margin: "2px 0",
  },
  plainList: {
    padding: "0 2px",
    "& > div": {
      borderRadius: "6px",
    },
  },
  arrowIcon: {
    width: "10px",
    height: "13px",
    top: "30%",
    margin: "0 5px",
  },
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    cursor: "pointer",
    width: "100%",
    "& *": {
      textAlign: "initial",
      fontSize: "16px !important",
      color: "rgb(87 87 87)",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
  },
  dropDown: {
    border: "1px solid rgb(98 195 238)",
    background: "rgb(255 255 255)",
    borderRadius: "8px",
    position: "absolute",
    top: 0,
    height: "fit-content",
    width: "100%",
    padding: "45px 5px 5px",
    zIndex: 5,
  },
  input: {
    zIndex: 9,
    background: "rgb(255 255 255)",
    display: "flex",
    position: "relative",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgb(217,216,216)",
    borderRadius: "8px",
    height: "38px",
    cursor: "pointer",
    width: "100%",
    padding: "0 10px",
    "&:hover": {
      borderColor: "rgb(98 195 238)",
    },
  },
  total: {
    height: "24px",
    width: "24px",
    backgroundColor: "rgba(252, 163, 68, 1)",
    borderRadius: "50%",
    fontWeight: "bold",
    color: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px !important",
  },
  tick: {
    height: "24px",
    width: "24px",
    backgroundColor: "#6DC679",
    borderRadius: "50%",
    color: "rgba(255, 255, 255, 1)",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  extra: {
    height: "24px",
    width: "24px",
    borderRadius: "50%",
  },
  inputText: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-start",
    userSelect: "none",
    padding: "0 5px",
    overflow: "hidden",
    "& > span": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "calc(100% - 1px)",
    },
  },
  searchInput: {
    width: "100%",
    height: "35px",
    padding: "5px",
    border: "1px solid rgb(98 195 238)",
    borderRadius: "8px",
    outline: "rgb(98 195 238)",
    "&:focus-visible, &:focus": {
      border: "1px solid rgb(98 195 238)",
      borderRadius: "8px",
      outline: "rgb(98 195 238)",
    },
  },
  selected: {
    background: "rgb(98 195 238) !important",
    " & *": {
      color: "rgb(255 255 255)",
      fill: "rgb(255 255 255)",
    },
  },
  button: {
    "&:hover": {
      background: "rgb(244 244 244)",
    },
  },
  plainMenu: {
    marginTop: "37px",
    border: "1px solid rgb(218 218 218)",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    padding: 0,
    "& > div": {
      padding: "2px",
      margin: "2px 0",
    },
  },
  plainInput: {
    border: "none",
    height: "100%",
  },
  disabled: {
    backgroundColor: "rgb(241 241 241)",
    border: "none",
    "& *": {
      color: "rgb(220 220 220)",
    },
    cursor: "default",
  },
  disabledPlain: {
    backgroundColor: "rgb(255 255 255)",
    "& *": {
      color: "rgb(181 181 181)",
    },
  },
});

interface ISelectInputProps {
  name?: string;
  label?: string;
  defaultValue?: string | number | { year: number; month: string };
  valueList: IExtendedOption[];
  getOption(option: { currentTarget: IExtendedOption }): void;
  searchable?: boolean;
  placeholder?: string;
  style?: { container: CSSProperties };
  plain?: boolean;
  disabled?: boolean;
  "data-test"?: string;
}

function SelectInput({
  name,
  label,
  defaultValue,
  valueList,
  getOption,
  searchable = false,
  placeholder = "",
  style = { container: {} },
  plain,
  disabled,
  ...rest
}: ISelectInputProps) {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IExtendedOption>();
  const [search, setSearch] = useState("");
  const [optionList, setOptionList] = useState<IExtendedOption[]>([]);
  const [searchedOptionList, setSearchedOptionList] = useState<
    IExtendedOption[]
  >([]);
  const selectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", clickOutSideHandler);
    return () => {
      document.removeEventListener("mousedown", clickOutSideHandler);
    };
  }, []);

  useEffect(() => {
    initOptions();
  }, [valueList, defaultValue]);

  useEffect(() => {
    if (search.length) {
      const filteredOptions = optionList.filter((opt) =>
        opt.label.toLowerCase().includes(search.toLowerCase())
      );
      if (filteredOptions.length) setSearchedOptionList(filteredOptions);
      else setSearchedOptionList([{ label: "No value", value: undefined }]);
    } else {
      setSearchedOptionList(optionList);
    }
  }, [search]);

  useEffect(() => {
    setSearchedOptionList(optionList);
  }, [optionList]);

  const clickHandler = () => {
    if (!open) {
      setSearch("");
    }

    setOpen(disabled ? !disabled : !open);
  };

  const getBadge = (content: IExtendedOption) => {
    const { pending, completed } = content?.extra!;
    if (pending) return <span className={classes.total}>{pending}</span>;
    if (completed) return <span className={classes.tick}>&#10003;</span>;
    return <span className={classes.extra}></span>;
  };

  const clickOutSideHandler = (event: MouseEvent) => {
    if (!selectRef.current?.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  const selectHandler = (item: IExtendedOption) => {
    if (item.value) {
      getOption({ currentTarget: { ...item } });
      setSelectedOption(item);
      clickHandler();
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value ?? "");
  };

  const list = searchable ? searchedOptionList : optionList;

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      onClick={clickHandler}
      ref={selectRef}
      {...rest}
    >
      <label
        style={{
          display: label && label.length > 0 ? "inline-block" : "none",
          color: "rgb(123 130 143)",
        }}
      >
        {label}
      </label>
      <div
        className={classes.inputContainer}
        style={{
          padding: !plain ? "3px 3px 0 " : "3px 0",
          ...style.container,
        }}
      >
        <div
          className={classNames(
            classes.input,
            plain && classes.plainInput,
            disabled && classes.disabled,
            disabled && plain && classes.disabledPlain
          )}
        >
          <div className={classes.inputText}>
            <span>
              {selectedOption && Object.keys(selectedOption).length
                ? selectedOption.label
                : placeholder}
            </span>
          </div>
          {selectedOption?.extra?.pending &&
          selectedOption?.extra?.pending > 0 ? (
            <span className={classes.total}>
              {selectedOption?.extra?.pending}
            </span>
          ) : null}
          <ArrowDropDownIcon
            sx={customStyles.arrowIcon}
            style={{
              transform:
                open && optionList.length > 0 ? "rotate(180deg)" : undefined,
            }}
          />
        </div>
        <div
          className={classNames(classes.dropDown, plain && classes.plainMenu)}
          style={{ display: open && optionList.length > 0 ? "block" : "none" }}
        >
          {searchable ? (
            <input
              id={name}
              name={name}
              type="text"
              value={search}
              onChange={handleSearch}
              onClick={(event) => event.stopPropagation()}
              className={classes.searchInput}
              placeholder={placeholder}
              autoComplete="no"
            />
          ) : null}
          <List
            sx={{
              ...customStyles.list,
              ...(plain ? customStyles.plainList : {}),
            }}
          >
            {list.map((valueItem, index) => (
              <ListItemButton
                key={"itemList" + index}
                onClick={
                  valueItem.value ? () => selectHandler(valueItem) : undefined
                }
                sx={customStyles.listItem}
                selected={
                  selectedOption && valueItem.value === selectedOption.value
                }
                style={{ cursor: valueItem.value ? "pointer" : "default" }}
                classes={{ selected: classes.selected, root: classes.button }}
              >
                {valueItem?.extra && getBadge(valueItem)}
                &nbsp;&nbsp;
                <ListItemText primary={valueItem.label} />
              </ListItemButton>
            ))}
          </List>
        </div>
      </div>
    </div>
  );

  function initOptions() {
    if (Array.isArray(valueList) && valueList.length > 0) {
      let selectedValue;
      if (defaultValue)
        selectedValue = valueList.find((item) => item.value === defaultValue);
      setSelectedOption(selectedValue);
      setOptionList(valueList);
      setSearchedOptionList(valueList);
    } else {
      setOptionList([]);
      setSearchedOptionList([]);
    }
  }
}

export default SelectInput;
