import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

export default function ClearIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
      <g fill="none" fillRule="evenodd">
        <rect width="14" height="14" fill="#A9AFBB" rx="6.84" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M9.864 9.033a.588.588 0 1 1-.832.831l-2.08-2.08-2.078 2.08a.589.589 0 0 1-.832-.832l2.079-2.08-2.08-2.08a.588.588 0 1 1 .832-.83l2.08 2.079 2.08-2.08a.588.588 0 1 1 .831.832l-2.08 2.08 2.08 2.08z"
        />
      </g>
    </SvgIcon>
  );
}
