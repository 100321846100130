import { Skeleton, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

function AssignmentSkeleton() {
  const { i18n } = useTranslation();
  return (
    <>
      {Array.from(new Array(2)).map((data, index) => (
        <Grid
          container
          key={index}
          sx={{ paddingTop: "15px", direction: i18n.dir() }}
        >
          <Grid item sm={6}>
            <Skeleton variant="text" />
          </Grid>
          <Grid item sm={12} style={{ paddingTop: "10px" }}>
            <Skeleton variant="rectangular" height={150} />
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default AssignmentSkeleton;
