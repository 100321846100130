import { useEffect, useState } from "react";
import emptyThumb from "assets/img/emptyThumb.png";

interface IThumb {
  url: any;
  style: string;
  "data-test"?: string;
}
function Thumb({ url = emptyThumb, style, ...rest }: IThumb) {
  const [src, setSrc] = useState(url);
  const handleImageLoadError = () => {
    setSrc(emptyThumb);
  };
  useEffect(() => {
    setSrc(url);
  }, [url]);
  return (
    <img
      alt=""
      className={style}
      src={src}
      onError={handleImageLoadError}
      {...rest}
    />
  );
}

export default Thumb;
