import { makeStyles } from "@mui/styles";
import CircleChart from "../../../shared/CircleChart";
import { domainSet, selectDomain, IDomain } from "features/studentPerformance";
import { useAppDispatch, useAppSelector } from "app/hooks";

const useStyels = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  notActive: {
    position: "absolute",
    top: 0,
    height: "100%",
    width: "100%",
    zIndex: 1,
    background: "rgba(255 255 255 /40%)",
    cursor: "pointer",
  },
  chart: {
    display: "flex",
    width: "170px",
    height: "170px",
    "@media screen and (max-width: 768px)": {
      width: "140px",
      height: "140px",
    },
  },
  title: {
    color: "rgb(74 74 74)",
    fontSize: "0.8rem",
    fontWeight: "bold",
    background: "rgb(255 255 255)",
  },
});
interface IDomainCardPorps {
  data: IDomain;
  color: string;
}
function DomainCard({ data, color }: IDomainCardPorps) {
  const dispatch = useAppDispatch();
  const selectedDomain = useAppSelector(selectDomain);
  const classes = useStyels();
  const { average } = data;

  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <CircleChart value={average} forground={color} key={"key" + average} />
      </div>
      <div className={classes.title}>{data.name}</div>
      {selectedDomain?.id !== data.id && (
        <div
          className={classes.notActive}
          onClick={() => dispatch(domainSet(data))}
        ></div>
      )}
    </div>
  );
}

export default DomainCard;
