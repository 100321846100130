import { Skeleton, Typography } from "@mui/material";

function ExamsSkeleton() {
  return (
    <Typography
      component="div"
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "10px",
      }}
    >
      {Array(3)
        .fill(1)
        .map((_, index) => (
          <Skeleton
            key={"skeSection" + index}
            variant="rectangular"
            sx={{
              height: "50px",
              width: "90%",
              marginX: "5px",
              margin: "5px 0",
            }}
          />
        ))}
    </Typography>
  );
}

export default ExamsSkeleton;
