import { SvgIcon, SvgIconProps } from "@mui/material";

export default function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 28">
      <g fill="none" fillRule="evenodd" transform="translate(0 .817)">
        <path d="M0 0h26.4v26.4H0z" />
        <circle
          cx="11"
          cy="11"
          r="7.7"
          stroke="#62C3EE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
        />
        <path
          stroke="#62C3EE"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.75"
          d="M23.1 23.1l-6.6-6.6"
        />
      </g>
    </SvgIcon>
  );
}
