import { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import PercentageChart from "./PercentageChart";
import StudentData from "./StudentData";
import { makeStyles } from "@mui/styles";
import { IPacingStudentDetails } from "features/pacing";

const useStyles = makeStyles({
  studentList: {
    marginTop: "45px",
  },
});
interface IStudentListProps {
  data: IPacingStudentDetails[];
  openAssignmentDetails(): void;
}
function StudentList({ data, openAssignmentDetails }: IStudentListProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerHeight, setHeight] = useState(100);
  const classes = useStyles();

  useEffect(() => {
    if (containerRef.current) setHeight(containerRef?.current?.clientHeight);
  }, [containerRef.current]);

  return data.length > 0 ? (
    <Grid
      item
      xs={6}
      ref={containerRef}
      sx={{
        position: "relative",
      }}
    >
      <PercentageChart containerHeight={containerHeight} />
      <div className={classes.studentList}>
        {data.map((studentData, index) => {
          return (
            <StudentData
              data={studentData}
              key={index}
              openAssignmentDetails={openAssignmentDetails}
            />
          );
        })}
      </div>
    </Grid>
  ) : null;
}

export default StudentList;
