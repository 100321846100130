import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles } from "@mui/styles";
import { useAppSelector } from "app/hooks";
import { selectAssignedLessons } from "features/assignments";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  header: {
    userSelect: "none",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    color: "rgb(116 116 116)",
    padding: "0 20px",
    marginBottom: "20px",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& span": {
      margin: "0 15px",
    },
  },
  count: {
    textAlign: "center",
    width: "20px",
    height: "20px",
    background: "rgba(251, 170, 144, 1)",
    borderRadius: "100%",
    color: "rgba(255 255 255)",
  },
  addToCart: {
    textAlign: "center",
    borderRadius: "6px",
    border: "1px solid rgba(98, 195, 238, 1)",
    width: "40px",
    height: "35px",
    "& svg": {
      fontSize: "2rem",
      color: "rgba(98, 195, 238, 1)",
    },
    cursor: "pointer",
  },
});
interface IHeaderProps {
  setOpen(value: boolean): void;
}
function Header({ setOpen }: IHeaderProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { assignedLosCount } = useAppSelector(selectAssignedLessons);

  return (
    <div className={classes.header}>
      <div className={classes.wrapper}>
        <div className={classes.count}>{assignedLosCount}</div>
        <span>{t("Learning Objective")}</span>
      </div>
      <div className={classes.wrapper}>
        <span>{t("Preview & Assign")}</span>
        <div className={classes.addToCart} onClick={() => setOpen(false)}>
          <ArrowDropDownIcon />
        </div>
      </div>
    </div>
  );
}

export default Header;
