import {
  IClassStudents,
  ISelectedClassStudents,
} from "features/common/Interfaces";
import {
  IExamStudentEvaluation,
  IFetchedExamClassesResponse,
  IFetchExamClassesEntity,
} from "services/exams";

export const handleFetchedClasses = (response: IFetchedExamClassesResponse) => {
  let allClasses: IClassStudents[] = [];
  let selectedClasses: ISelectedClassStudents[] = [];
  try {
    if (response?._statusCode === 200) {
      const { _entity: data } = response;
      allClasses = transformAllClassesData(data);
      selectedClasses = transformData(data);
    } else throw response;
  } catch (err) {
    console.log(err);
  }
  return { allClasses, selectedClasses };
};

const transformData = (response: IFetchExamClassesEntity[]) => {
  return [
    ...response.map((item) => ({
      classId: item._classId,
      className: item._className,
      checked: false,
      students: transformStudentData(item._students),
    })),
  ];
};

const transformAllClassesData = (response: IFetchExamClassesEntity[]) => {
  return [
    ...response.map((item) => ({
      value: item._classId,
      label: item._className,
      checked: false,
      students: transformStudentData(item._students),
    })),
  ];
};

const transformStudentData = (students: IExamStudentEvaluation[]) => {
  return [
    ...students.map((student) => ({
      studentId: student._studentId,
      studentName: student._studentName,
      checked: true,
      assignable: student._assignable,
    })),
  ];
};
