import { makeStyles } from "@mui/styles";
import StudentAvatar from "../StudentAvatar";
import { Badge } from "@mui/material";
import { IDomainReport, IStudent } from "features/classPerformance";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    "&:nth-of-type(even)": {
      backgroundColor: "#F5F5F5",
    },
    "@media print": {
      "break-inside": "avoid",
      "color-adjust": "exact",
      margin: "15px 0",
      padding: "15px 0",
    },
  },
  printablePercent: {
    width: "17.5%",
    fontWeight: "bold",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    padding: "0.5rem",
  },
  student: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "60px",
    width: "100%",
    whiteSpace: "nowrap",
    color: "rgb(0 0 0)",
    fontWeight: "bold",
    textAlign: "right",
    padding: "0.5rem",
  },
  printableStudent: {
    width: "30%",
  },
  badge: { height: "100%", "& > span": { top: "5px", right: "10px" } },
});

interface IStudentInfo {
  student: IStudent;
  index: number;
  reportData: IDomainReport[];
}

function StudentInfo({ student, index, reportData }: IStudentInfo) {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} key={index}>
      <div className={classes.printableStudent}>
        <div className={classes.student}>
          <Badge color="primary" className={classes.badge}>
            <StudentAvatar url={student.avatar} alt={student.name} />
          </Badge>
          <div style={{ padding: "0 0.35rem" }}>
            <span>{student.name}</span>
          </div>
        </div>
      </div>
      {reportData.map((item, ikey) => (
        <div
          key={"key" + ikey}
          className={classes.printablePercent}
          style={{
            color:
              item.scores?.[index]! < 50
                ? "rgb(214, 79, 79)"
                : "rgb(107, 106, 106)",
          }}
        >
          {item.scores?.[index]! >= 0 ? item.scores?.[index] + "%" : "-"}
        </div>
      ))}
    </div>
  );
}

export default StudentInfo;
