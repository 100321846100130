import { useContext, useEffect, useState } from "react";
import { Grid, Paper } from "@mui/material";
import StudentsPrint from "./StudentsPrint";
import Content from "./Content";
import Toolbar from "./ToolBar";
import Breadcrumb from "components/Breadcrumb";
import { useTranslation } from "react-i18next";
import {
  IStudentDetails,
  useFetchTeacherStudentsQuery,
} from "services/studentsQrCode";
import TabelSkeleton, { ToolBarSkeleton } from "./StudentsSkeleton";
import { IClassDetailsEntity, useFetchAllClassesQuery } from "services/common";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";
import {
  allStudentsSet,
  classListSet,
  defaultClassOption,
  selectAllSet,
  selectedDataListSet,
  selectSelectedClass,
  selectSelectedDataList,
  studentListSet,
} from "features/students";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DataNotFound from "components/DataNotFound";

const customStyles = {
  toolBar: {
    background: "rgba(98, 195, 238, 1)",
    borderRadius: "18px 18px 0 0",
    marginTop: "1rem",
    padding: "0.5rem",
    height: "75px",
  },
  content: {
    padding: "1rem",
    borderRadius: "18px",
    zIndex: 1,
    marginTop: "-15px",
  },
};
const getClassList = (data: IClassDetailsEntity[]) => {
  let students = [
    ...data.map((item) => ({
      label: item._name,
      value: item._id,
    })),
  ].sort((a, b) => a.label.localeCompare(b.label));
  students.unshift(defaultClassOption);
  return students;
};

const getStudentList = (data: IStudentDetails[]) => {
  return data.map((item) => ({
    _id: item._id,
    _name: item._name,
    _gradeName: item._gradeName,
    _className: item._className,
    _deepLinkHome: item._deepLinkHome,
    _studentUUIDCode: item._studentUUIDCode,
  }));
};

function Students() {
  const dispatch = useAppDispatch();
  const selectedClass = useAppSelector(selectSelectedClass);
  const [noClasses, setNoClasses] = useState(false);
  const selectedDataList = useAppSelector(selectSelectedDataList);
  const { i18n, t } = useTranslation();
  const toast = useToast();
  const {
    state: { userInfo },
  } = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const {
    data: allClassesData,
    isLoading: isLoadingClasses,
    isFetching: isFetchingClasses,
  } = useFetchAllClassesQuery();

  const {
    data: teacherStudentsData,
    isLoading: isLoadingTeacherStudents,
    isFetching: isFectingTeacherStudents,
  } = useFetchTeacherStudentsQuery(userInfo?.id!, { skip: !userInfo?.id });

  useEffect(() => {
    dispatch(selectedDataListSet([]));
    dispatch(selectAllSet(false));
    const response = teacherStudentsData;
    if (response?._statusCode === 200) {
      const studentList = response._entity
        ? getStudentList(response._entity)
        : [];
      dispatch(studentListSet(studentList));
      if (selectedClass.value === defaultClassOption.value)
        dispatch(allStudentsSet(studentList));
    }
  }, [teacherStudentsData]);

  useEffect(() => {
    setIsLoading(
      isLoadingClasses ||
        isFetchingClasses ||
        isLoadingTeacherStudents ||
        isFectingTeacherStudents
    );
  }, [isLoadingTeacherStudents]);

  useEffect(() => {
    if (allClassesData?._statusCode === 200) {
      if (allClassesData?._entity) {
        setNoClasses(false);
        const classList = getClassList(allClassesData?._entity);
        dispatch(classListSet(classList));
      } else {
        setNoClasses(true);
      }
    } else if (allClassesData?._statusCode === 400) {
      toast({
        label: t("error"),
        message: t("No students or Classes exists"),
        rtl: i18n.dir() === "rtl",
        type: "error",
        toastId: "NoStudentsOrClasses",
      });
      dispatch(classListSet([]));
    }
  }, [allClassesData]);

  return isPrinting ? (
    <StudentsPrint />
  ) : (
    <>
      <Breadcrumb />
      {noClasses ? (
        <DataNotFound text={t("no classes")} />
      ) : (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          sx={{ direction: i18n.dir() }}
        >
          <Grid item xs={12}>
            <Paper elevation={0} sx={customStyles.toolBar}>
              {isLoadingClasses ? <ToolBarSkeleton /> : <Toolbar />}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0} sx={customStyles.content}>
              {isLoading ? (
                <TabelSkeleton />
              ) : (
                <Content
                  getIsPrinting={setIsPrinting}
                  listCount={selectedDataList?.length}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Students;
