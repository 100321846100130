import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { FilterIcons } from "views/Secure/Home/Content/Filters/GroupedFilters/Filter";
import { GradeCods, IGrade } from "../common/Interfaces";

export interface IFilter {
  icon?: FilterIcons;
  code: string;
  title: string;
}
export interface ILevelFilter extends IFilter {
  subGroups?: IFilter[];
}

export interface ILevelMoeFilter extends IFilter {
  groups?: ILevelFilter[];
}

interface IGradeLessonsFilters {
  moe?: ILevelMoeFilter[];
  domains: ILevelFilter[];
}

export interface ILessonsFilters {
  [key: number]: IGradeLessonsFilters;
}

export interface IExamsFilters {
  [key: number]: ILevelFilter[];
}
export type GrGroupType = GradeCods | "all";

export type IGrGroups = {
  [key in GrGroupType]?: number[];
};

export interface IFiltersState {
  grades: IGrade[];
  lessonsFilters: ILessonsFilters;
  freePlayFilters: ILevelFilter[];
  guidedReadingFilters: ILevelFilter[];
  examsFilters: IExamsFilters;
  allGradesSwitch?: boolean;
  grGroups?: IGrGroups;
  selectedGrGroup: GrGroupType;
}

const initialState: IFiltersState = {
  grades: [],
  lessonsFilters: {},
  freePlayFilters: [],
  guidedReadingFilters: [],
  examsFilters: {},
  selectedGrGroup: "all",
};
export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    filtersLoaded(state: IFiltersState, action: PayloadAction<IFiltersState>) {
      state.grades = action.payload.grades;
      state.lessonsFilters = action.payload.lessonsFilters;
      state.freePlayFilters = action.payload.freePlayFilters;
      state.guidedReadingFilters = action.payload.guidedReadingFilters;
      state.examsFilters = action.payload.examsFilters;
      state.grGroups = action.payload.grGroups;
      state.selectedGrGroup = action.payload.selectedGrGroup;
    },
    allGradesSwitchSet(state: IFiltersState, action: PayloadAction<boolean>) {
      state.allGradesSwitch = action.payload;
    },
    setSelectedGrGroup(
      state: IFiltersState,
      action: PayloadAction<GradeCods | "all">
    ) {
      state.selectedGrGroup = action.payload;
    },
  },
});

export const selectGrades = (state: RootState) => state.filters.grades;
export const selectAllGradesSwitch = (state: RootState) =>
  state.filters.allGradesSwitch;
export const selectLessonsFilters = (state: RootState) =>
  state.filters.lessonsFilters;
export const selectFreePlayFilters = (state: RootState) =>
  state.filters.freePlayFilters;
export const selectGuidedReadingFilters = (state: RootState) =>
  state.filters.guidedReadingFilters.filter((level) => {
    const result = state.filters.grGroups?.[
      state.filters.selectedGrGroup
    ]?.includes(parseInt(level.code));
    return result ?? true;
  });
export const selectExamsFilters = (state: RootState) =>
  state.filters.examsFilters;
export const selectSelectedGradeGroup = (state: RootState) =>
  state.filters.selectedGrGroup;

export const { filtersLoaded, allGradesSwitchSet, setSelectedGrGroup } =
  filtersSlice.actions;

export default filtersSlice.reducer;
