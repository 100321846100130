import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IExtendedOption, IGrade, IOption, Sort } from "../common/Interfaces";

export interface IDomain {
  id: number;
  name: string;
  average: number;
}
interface IStudentPerformanceState {
  grade?: IGrade;
  class?: IExtendedOption;
  student?: IOption;
  domain?: IDomain;
  sortForOnline: Sort;
  sortForOffline: Sort;
}

const initialState: IStudentPerformanceState = {
  sortForOffline: Sort.asc,
  sortForOnline: Sort.asc,
};
export const studentPerformanceSlice = createSlice({
  name: "studentPerformance",
  initialState,
  reducers: {
    gradeSet: (state, action: PayloadAction<IGrade>) => {
      state.grade = action.payload;
    },
    classSet: (state, action: PayloadAction<IExtendedOption>) => {
      state.class = action.payload;
    },
    domainSet: (state, action: PayloadAction<IDomain>) => {
      state.domain = action.payload;
    },
    studentSet: (state, action: PayloadAction<IOption>) => {
      state.student = action.payload;
    },
    selectedSortForOnlineSet: (state, action: PayloadAction<Sort>) => {
      state.sortForOnline = action.payload;
    },
    selectedSortForOfflineSet: (state, action: PayloadAction<Sort>) => {
      state.sortForOffline = action.payload;
    },
  },
});

export const selectGrade = (state: RootState) => state.studentPerformance.grade;
export const selectClass = (state: RootState) => state.studentPerformance.class;
export const selectDomain = (state: RootState) =>
  state.studentPerformance.domain;
export const selectStudent = (state: RootState) =>
  state.studentPerformance.student;
export const selectSelectedSortForOnline = (state: RootState) =>
  state.studentPerformance.sortForOnline;
export const selectSelectedSortForOffline = (state: RootState) =>
  state.studentPerformance.sortForOffline;

export const {
  gradeSet,
  classSet,
  domainSet,
  studentSet,
  selectedSortForOnlineSet,
  selectedSortForOfflineSet,
} = studentPerformanceSlice.actions;

export default studentPerformanceSlice.reducer;
