import { makeStyles } from "@mui/styles";
import React, { CSSProperties, ForwardedRef } from "react";
interface IProperties {
  completeBackgroungColor: string;
  incompleteBackgroundColor: string;
  barWidth: number;
}
interface IProgressBarProps {
  completecolor: string;
  incompletecolor: string;
  width: number;
  style: CSSProperties;
  onClick(): void;
}

const useStyles = makeStyles({
  completeSection: (props: IProperties) => ({
    width: props.barWidth + "%",
    backgroundColor: props.completeBackgroungColor,
    height: "30px",
  }),

  incompleteSection: (props) => ({
    width: 100 - props.barWidth + "%",
    backgroundColor: props.incompleteBackgroundColor,
    height: "30px",
  }),
});

const ProgressBar = React.forwardRef<HTMLDivElement, IProgressBarProps>(
  (props, ref) => {
    const { completecolor, incompletecolor, width, style } = props;
    const properties = {
      completeBackgroungColor: completecolor,
      incompleteBackgroundColor: incompletecolor,
      barWidth: width,
    };
    const classes = useStyles(properties);
    return (
      <>
        <div
          {...props}
          style={style}
          ref={ref}
          className={classes.completeSection}
        ></div>
        <div {...props} ref={ref} className={classes.incompleteSection}></div>
      </>
    );
  }
);

export default ProgressBar;
