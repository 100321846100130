import type * as CSS from "csstype";

export const enum GradeCods {
  "kg1" = "Kindergarten 1",
  "kg2" = "Kindergarten 2",
  "g1" = "Grade 1",
  "g2" = "Grade 2",
  "g3" = "Grade 3",
  "g4" = "Grade 4",
}
export interface IGrade {
  id: number;
  code: string;
  name: string;
  owned: boolean;
}

export interface IOption {
  label: string;
  value?: string | number | { year: number; month: string };
  avatar?: string;
}

export interface IExtendedOption extends IOption {
  extra?: {
    pending: number | undefined;
    completed: number | undefined;
  };
  students?: IOption[];
}

export interface IOptionWithList {
  [key: string]: IOption[] | string | number;
  label: string;
  value: number;
}

export interface ITab {
  id: number;
  code: string;
  name: string;
}

export interface IStudent {
  id: number;
  name: string;
  avatar?: string;
}

export interface IValidationErrors {
  [key: string]: string;
}

export interface IPagination {
  currentPage: number;
  totalPages: number;
  pageSize?: number;
}

export interface IMonthOption {
  label: string;
  value:
    | string
    | {
        month: string;
        year: number;
      };
}

export interface IFilterOption {
  label: string;
  value: string;
}

export type Direction = "rtl" | "ltr";
export enum Sort {
  "asc" = "asc",
  "desc" = "desc",
}

export interface ICSS {
  [key: string]: CSS.Properties;
}

export enum Assessment {
  "BASELINE" = "BASELINE",
  "FORMATIVE" = "FORMATIVE",
  "EXAM" = "EXAM",
}

export enum StatusTypes {
  "PENDING" = "PENDING",
  "COMPLETED" = "COMPLETED",
}

export enum LessonTypes {
  "LESSON" = "LESSON",
  "FREEPLAY" = "FREEPLAY",
  "GUIDED_READING" = "GUIDED_READING",
  "EXAMS" = "EXAMS",
}

export interface IStudentAssignExam {
  studentId: number;
  studentName: string;
  checked: boolean;
  assignable: boolean;
}

export interface IClassStudents {
  value: number;
  label: string;
  checked: boolean;
  disabled?: boolean;
  students: IStudentAssignExam[];
}

export interface ISelectedClassStudents {
  classId: number;
  className: string;
  checked: boolean;
  students: IStudentAssignExam[];
}
