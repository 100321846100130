import { GradeCods } from "features/common/Interfaces";
import { API, IResponse, SlicesTags } from "./API";

interface IExamEntity {
  _id: string;
  _assessmentType: "BASELINE" | "ASSESSMENT_BANK";
  _title: string;
  _noOfQuestions: number;
  _gradeCode: GradeCods;
}
interface IFetchedExamsResponse extends IResponse {
  _entity: IExamEntity[];
}

interface IFetchedFilterData {
  _code: string;
  _title: string;
}
interface IFetchedExamFilterType {
  _code: string;
  _title: string;
  _subGroups: IFetchedFilterData[];
}
export interface IGradeFilterEntity {
  _id: number;
  _code: string;
  _filters: IFetchedExamFilterType[];
}

export interface IExamStudentEvaluation {
  _studentId: number;
  _studentName: string;
  _assignable: boolean;
}
export interface IFetchExamClassesEntity {
  _classId: number;
  _className: string;
  _students: IExamStudentEvaluation[];
}
export interface IFetchedExamClassesResponse extends IResponse {
  _entity: IFetchExamClassesEntity[];
}

export interface IAssignExamRequest {
  _assessmentId: string;
  _startDate: string;
  _classes: {
    _classId: number;
    _students: number[];
  }[];
}

export interface IExamQuestionsEntity {
  _sequence: number;
  _assessmentBankId: string;
  _questionBankId: string;
  _questionBankContentId: string;
  _questionBankType: string;
}

export interface IFetchExamQuestionsResponse extends IResponse {
  _entity: { _questions: IExamQuestionsEntity[] };
}

export interface IFilteredQuestion {
  _contentName: string;
  _contentTypeId: number;
  _questionLevelEn: string;
  _questionLevelAr: string;
  _questionBankContentId: string;
  _questionBankId: string;
  _sequence: number;
}
export interface IFilteredLoEntity {
  _loId: number;
  _loCode: string;
  _loTitleAr: string;
  _loTitleEn: string;
  _questions: IFilteredQuestion[];
}
export interface IFilteredLosResponse extends IResponse {
  _entity: IFilteredLoEntity[];
}
export interface IFilterLosRequest {
  _gradeId: number;
  _groupCode?: string;
  _subGroupCode?: string;
}

export interface ICreateExamRequest {
  _title: string;
  _gradeId: number;
  _questions: string[];
}

const examsServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchExams: build.query<IFetchedExamsResponse, number>({
        query(gradeId) {
          return `/ams/assessment-bank?gradeId=${gradeId}`;
        },
        providesTags: [SlicesTags.Exams],
      }),
      fetchExamClasses: build.query<
        IFetchedExamClassesResponse,
        { examId: string; gradeId?: number }
      >({
        query({ examId, gradeId }) {
          const url =
            `/ams/assessment-bank/students?assessmentId=${examId}` +
            (gradeId ? `&gradeId=${gradeId}` : "");
          return url;
        },
        providesTags: (result, error, { examId, gradeId }) => {
          return [{ type: SlicesTags.StudentsExam, examId, gradeId }];
        },
      }),
      assignExam: build.mutation<IResponse, IAssignExamRequest>({
        query(payload) {
          return {
            url: "/ams/assessment-bank/assign",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: (result, error, { _assessmentId, _classes }) => [
          { type: SlicesTags.StudentsExam, examId: _assessmentId },
          ..._classes.map((cl) => ({
            type: SlicesTags.ClassExamReport,
            examId: _assessmentId,
            classId: cl._classId,
          })),
          SlicesTags.Exams,
        ],
      }),
      fetchExamQuestions: build.query<IFetchExamQuestionsResponse, string>({
        query(examId) {
          return `/ams/assessment-bank/${examId}/questions`;
        },
      }),
      filterLos: build.mutation<IFilteredLosResponse, IFilterLosRequest>({
        query(payload) {
          return {
            url: "/ams/assessment-bank/dashboard",
            method: "POST",
            body: payload,
          };
        },
      }),
      createExam: build.mutation<IResponse, ICreateExamRequest>({
        query(payload) {
          return {
            url: "/ams/assessment-bank",
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: [SlicesTags.Exams],
      }),
    };
  },
});

export const {
  useFetchExamsQuery,
  useFetchExamClassesQuery,
  useAssignExamMutation,
  useFetchExamQuestionsQuery,
  useFilterLosMutation,
  useCreateExamMutation,
} = examsServices;
