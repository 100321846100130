import { useState } from "react";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";
import { read, utils } from "xlsx";

export interface IUploadedRow {
  [key: string]: string | number;
}
export interface IFile {
  data: IUploadedRow[];
  fileName?: string;
  invalidData?: IUploadedRow[];
}
const useFileBuilder = () => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const [file, setFile] = useState<IFile>({
    data: [],
    fileName: "Upload students file",
  });

  const handleFileChange = async (event: Event) => {
    try {
      const currentTarget = event.target as HTMLInputElement;
      if (currentTarget?.files?.length === 0) {
        toast({
          label: t("Note"),
          message: t("No file selected"),
          rtl: i18n.dir() === "rtl",
          type: "info",
          toastId: "file-not-selected",
        });
        return;
      }
      const recivedFile = currentTarget.files?.[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e?.target?.result;
        const workbook = read(data, { type: "binary" });
        const studentDetailsSheet = workbook.Sheets["student-details"];
        if (!studentDetailsSheet) {
          toast({
            label: t("error"),
            message: t("No student sheet exists"),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId: "Sheets-is-not-valid",
          });
          return;
        }
        const XL_row_object =
          utils.sheet_to_json<IUploadedRow>(studentDetailsSheet);
        if (XL_row_object.length === 0) {
          toast({
            label: t("error"),
            message: t("Empty file"),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId: "Sheets-is-empty",
          });
          return;
        }
        const uploadedFile = {
          data: XL_row_object,
          fileName: recivedFile?.name,
        };
        setFile(uploadedFile);
      };
      reader.onerror = (ex) => {
        setFile({ data: [], fileName: undefined });
        console.log(ex);
      };
      reader.readAsBinaryString(recivedFile!);
    } catch (error) {
      console.log(error);
    }
  };

  const buildFileSelector = () => {
    const fileSelector = document.createElement("input");
    fileSelector.setAttribute("type", "file");
    fileSelector.setAttribute("accept", ".xlsx");
    fileSelector.onchange = handleFileChange;
    fileSelector.onclick = function () {
      (this as HTMLInputElement).value = "";
    };
    return fileSelector;
  };

  const builder = {
    file,
    buildFileSelector,
    setFile,
  };
  return builder;
};

export default useFileBuilder;
