import { useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import { ProfileIcon } from "../../SVGs";
import Menu from "../../Menu";
import { MasterDataContext } from "contexts/MasterDataContext";
import useActiveRoute from "hooks/useActiveRoute";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { resetLocalStorage } from "utils/HomeHelpers";
import { MenuItemTypes, MenuType } from "./MenuList";

const customStyles = {
  profile: {
    width: "33px",
    height: "33px",
    // fontSize: "2.75rem",
    borderRadius: "50%",
    padding: "0.35rem",
    backgroundColor: "rgb(232 235 241)",
    cursor: "pointer",
  },
  activeProfile: {
    borderRadius: "50%",
    border: "1px solid rgb(98 195 238)",
    zIndex: 2,
    color: "rgb(98 195 238)",
    background: "rgb(255 255 255)",
  },
};

const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    color: "rgb(169, 175, 187)",
    "&:hover > svg ": {
      color: "rgb(98 195 238)",
      background: "rgb(255 255 255)",
    },
    "&:hover > svg:first-of-type": {
      borderRadius: "50%",
      border: "1px solid rgb(98 195 238)",
      zIndex: 2,
    },
  },
  active: {
    "& > svg": {
      color: "rgb(98 195 238)",
    },
  },
});
interface IAvatarMenuProps {
  "data-test"?: string;
}
const AvatarMenu = (props: IAvatarMenuProps) => {
  const { i18n, t } = useTranslation();
  const {
    state: { userInfo },
  } = useContext(MasterDataContext);
  const [visibility, setVisibility] = useState<"visible" | "hidden">("hidden");
  const history = useHistory();
  const classes = useStyles();
  const activeRoute = useActiveRoute();

  const handleClick = () => {
    setVisibility(visibility === "hidden" ? "visible" : "hidden");
  };

  const greetingMessage = `${t("Greetings")} ${userInfo.name}`;

  const avatarMenuItems: MenuType[] = [
    { name: "profile", label: greetingMessage, menuType: MenuItemTypes.leaf },
    {
      name: "/students-list",
      action: () => {
        history.push("/students-list");
      },
      label: "Students List",
      menuType: MenuItemTypes.leaf,
    },
    {
      name: "profile",
      action: () => {
        resetLocalStorage();
        window.location = "/login" as (string | Location) & Location;
      },
      label: "Logout",
      menuType: MenuItemTypes.leaf,
    },
  ];

  return (
    <div
      className={classNames(
        classes.root,
        activeRoute("/students-list") && classes.active
      )}
      onClick={handleClick}
      {...props}
    >
      <ProfileIcon
        sx={{
          ...customStyles.profile,
          ...(activeRoute("/students-list") ? customStyles.activeProfile : {}),
        }}
      />
      <Menu
        menuItems={avatarMenuItems}
        visible={visibility}
        align={i18n.dir() === "rtl" ? "left" : "right"}
      />
    </div>
  );
};

export default AvatarMenu;
