import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import LoGrid from "./LoGrid";
import AssignmentSkeleton from "./AssignmentSkeleton";
import DataNotFound from "components/DataNotFound";
import Tabs from "./Tabs";
import { useTranslation } from "react-i18next";
import { useFetchStudentAssignmentDetailsQuery } from "services/reports";
import { useAppSelector } from "app/hooks";
import { selectClass, selectSelectedStudent } from "features/pacing";
import { IDeliverable, mapAssignmentData } from "utils/ReportsHelpers";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "500px",
    padding: "20px",
    color: "rgb(105 123 140)",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "17px",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});
interface IData {
  completed: IDeliverable[];
  incompleted: IDeliverable[];
}
function Content() {
  const { t } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState<IData>();
  const selectedClass = useAppSelector(selectClass);
  const selectedStudent = useAppSelector(selectSelectedStudent);

  const [selectedTab, setSelectedTab] = useState({
    id: 1,
    code: "incompleted",
    name: t("Uncompleted Assignments"),
  });

  const {
    data: studentAssignmentDetailsData,
    isLoading,
    isUninitialized,
    isFetching,
  } = useFetchStudentAssignmentDetailsQuery(
    {
      classId: selectedClass?.value as number,
      studentId: selectedStudent?.studentId as number,
    },
    { skip: !selectedClass?.value || !selectedStudent?.studentId }
  );
  useEffect(() => {
    if (studentAssignmentDetailsData?._statusCode === 200) {
      const assignment = mapAssignmentData(
        JSON.parse(JSON.stringify(studentAssignmentDetailsData._entity))
      );
      if (assignment) {
        if (assignment.deliverables) {
          const completedDeliverables = assignment.deliverables.filter(
            (item) => item.playCount > 0
          );
          const incompletedDeliverables = assignment.deliverables.filter(
            (item) => item.playCount === 0
          );

          setData({
            completed: completedDeliverables,
            incompleted: incompletedDeliverables,
          });
        }
      }
    } else {
      setData({
        completed: [],
        incompleted: [],
      });
    }
  }, [studentAssignmentDetailsData]);

  return (
    <div className={classes.root}>
      {isLoading || isUninitialized || isFetching || !data ? (
        <AssignmentSkeleton />
      ) : (
        <>
          <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
          <div style={{ marginBottom: "15px" }}></div>
          {data[selectedTab.code as keyof IData]?.length > 0 ? (
            <LoGrid
              key={selectedTab.id}
              data={data[selectedTab.code as keyof IData]}
              reportType={selectedTab.code}
            />
          ) : (
            <DataNotFound style={{ height: "250px" }} />
          )}
        </>
      )}
    </div>
  );
}

export default Content;
