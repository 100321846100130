import { useEffect, useContext } from "react";
import ClassPerformance from "./ClassPerformance";
import StudentPerformance from "./StudentPerformance";
import { MasterDataContext } from "contexts/MasterDataContext";

const tabs = [{ name: "class" }, { name: "student" }];

const renderTab = {
  [tabs[0].name]: <ClassPerformance />,
  [tabs[1].name]: <StudentPerformance />,
};

function Performance({ tab }: { tab: string }) {
  const { logEvent } = useContext(MasterDataContext);

  useEffect(() => {
    let eventName = "ClassPerformanceVisit";
    if (tab === tabs[1].name) eventName = "StudentPerformanceVisit";
    logEvent(eventName);
  }, []);

  return renderTab[tab];
}

export default Performance;
