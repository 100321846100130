import QrCode from "qrcode.react";
import logo from "assets/img/logo.svg";
import infoIcon from "assets/img/help.svg";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface IStudentCard {
  name: string;
  grade: string;
  className: string;
  QRcode: string;
  studentCode: string;
}
const StudentCard = ({
  name,
  grade,
  className,
  QRcode,
  studentCode,
}: IStudentCard) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
        padding: "20px auto",
        border: "2px dashed #c6c6c6",
        height: "18cm",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          width: "100%",
          padding: "0 20px",
        }}
      >
        <Typography
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            "& p": {
              marginBottom: "10px",
              fontSize: "30px",
              lineHeight: "40px",
            },
            "& h4": {
              marginBottom: "15px",
              fontWeight: "bold",
              fontSize: "35px",
            },
          }}
        >
          <img
            src={logo}
            alt="Abjadiyat logo"
            style={{ width: "50%", marginBottom: "30px" }}
          />
          <h4>{name}</h4>
          <p>
            <span>{t("Grade field")}</span> {grade}
          </p>
          <p>
            <span>{t("Class field")}</span> {className}
          </p>
        </Typography>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "40px",
            fontSize: "30px",
          }}
        >
          <QrCode
            renderAs="svg"
            value={QRcode}
            style={{ width: "320px", height: "320px", overflow: "visible" }}
          />
          <p style={{ padding: "10px 0" }}>{t("QRcode")}</p>
          <p
            style={{
              backgroundColor: "rgb(214 242 255)",
              color: "rgb(73 172 216)",
              borderRadius: "10px",
              fontWeight: "bold",
              padding: "10px 20px 0",
            }}
          >
            {studentCode}
          </p>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          padding: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontSize: "16px",
          color: "rgb(141 141 141)",
        }}
      >
        <img
          src={infoIcon}
          style={{ maxWidth: "25px", margin: "0 10px" }}
          alt="help icon"
        />
        <span
          style={{
            margin: 0,
            fontSize: "30px",
            color: "rgb(141 141 141)",
            textAlign: "center",
          }}
        >
          {t("Instrcutions to login")}
        </span>
      </div>
    </div>
  );
};
export default StudentCard;
