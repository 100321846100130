import Drawer from "@mui/material/Drawer";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Loader from "components/Loader";
import { Typography } from "@mui/material";
import { CloseIcon } from "components/SVGs";
import ExamForm from "./ExamForm";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { examReset, selectCreateExam, selectedGrade } from "features/exams";
import { ICreateExamRequest, useCreateExamMutation } from "services/exams";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useContext } from "react";

const customStyles = (dir: "rtl" | "ltr") => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    flexDirection: "row",
    padding: "20px",
    direction: dir,
  },
  footer: {
    border: "1px solid rgb(214 214 214)",
    height: "60px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    padding: "20px",
    width: "100%",
    backgroundColor: "rgb(255, 255, 255)",
    position: "fixed",
    bottom: 0,
    right: 0,
    direction: dir === "rtl" ? "ltr" : "rtl",
  },
});

interface ICreateExamProps {
  open: boolean;
  setOpen(open: boolean): void;
}
function CreateExam({ open, setOpen }: ICreateExamProps) {
  const { logEvent } = useContext(MasterDataContext);

  const dispatch = useAppDispatch();
  const examDetails = useAppSelector(selectCreateExam);
  const { t, i18n } = useTranslation();
  const classes = customStyles(i18n.dir());
  const grade = useAppSelector(selectedGrade);
  const toast = useToast();
  const [createExam, { isLoading }] = useCreateExamMutation();

  const submitHandler = async () => {
    try {
      if (grade && examDetails) {
        const payload: ICreateExamRequest = {
          _gradeId: grade?.id,
          _title: examDetails.title,
          _questions: examDetails.questionList,
        };
        const response = await createExam(payload).unwrap();
        if (response._statusCode === 200) {
          toast({
            label: t("success"),
            message: t("assessment created successfully"),
            rtl: i18n.dir() === "rtl",
            type: "success",
            toastId: "assessment-create-success",
          });
          logEvent("ClickOnCreateAssessment", {
            Questions_Num: examDetails.questionList.length,
          });
          closeHandler();
        } else {
          toast({
            label: t("error"),
            message: t("create exam error"),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId: "create-exam-error",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeHandler = () => {
    setOpen(false);
    dispatch(examReset());
  };

  return (
    <>
      <Loader open={isLoading} />
      <Drawer
        anchor={"bottom"}
        open={open}
        onClose={closeHandler}
        sx={{
          display: "flex",
          flexDirection: "column",
          "& .MuiDrawer-paper": {
            height: "90%",
          },
        }}
      >
        <Typography component="div" sx={classes.header}>
          <Typography
            component="div"
            sx={{
              flex: 1,
              padding: "0 20px",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {t("create new exam")}
          </Typography>
          <CloseIcon
            sx={{
              fontSize: "35px",
              color: "rgb(81 196 246)",
              cursor: "pointer",
            }}
            onClick={closeHandler}
          />
        </Typography>
        <Typography component="div" sx={{ flex: 1 }}>
          <ExamForm />
        </Typography>

        <Typography component="div" sx={classes.footer}>
          <Button
            label={t("create exam")}
            styles={{
              width: "150px",
              height: "30px",
              fontSize: "12px",
              margin: "0 20px",
            }}
            action={submitHandler}
            disable={
              examDetails.questionList.length === 0 ||
              examDetails.title.length === 0
            }
          />
          <Typography component="div">
            {t("questionNo", { count: examDetails.questionList.length })}
          </Typography>
        </Typography>
      </Drawer>
    </>
  );
}

export default CreateExam;
