import { makeStyles } from "@mui/styles";
import { AddIcon, EneIcon, RemoveIcon } from "components/SVGs/HomeIcons";
import LoThumb from "components/LoThumb";
import showView from "assets/img/showView.png";
import { PrintWorkSheetIcon } from "components/SVGs";
import DataNotFound from "components/DataNotFound";
import WorkSheet from "./WorkSheet";
import useClientDevice from "hooks/useClientDevice";
import useToast from "hooks/useToast";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ILo, IUnit, selectLevelOneFilter } from "features/content";
import { IAssignedUnit, selectAssignedLessons } from "features/assignments";
import { useTranslation } from "react-i18next";
import { IContentPlayerProps } from "components/ContentPlayer";
import { LessonTypes } from "features/common/Interfaces";
import { contentIdsSet, showPlayerSet } from "features/ui";

const isiPadoriPadPro =
  navigator.userAgent.match(/iPad/gi) ||
  (navigator.userAgent.match(/Mac/gi) &&
    navigator.maxTouchPoints > 1 &&
    ((window.screen.width === 768 && window.screen.height === 1024) ||
      (window.screen.width === 1024 && window.screen.height === 1366)));
const isAndroid =
  navigator.userAgent.match(/Android/gi) ||
  navigator.userAgent.match(/Tablet/gi);

const useStyles = makeStyles({
  root: {
    marginTop: "20px",
    display: "grid",
    gridGap: "20px",
    gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
    "@media screen and (max-width: 1400px)": {
      gridTemplateColumns: "1fr 1fr 1fr 1fr",
    },
    "@media screen and (max-width: 1024px)": {
      gridTemplateColumns: "1fr 1fr 1fr",
    },
  },
});

interface IListViewProps {
  lessons: (ILo | IUnit)[];
  assignHandler(lo: IAssignedUnit, type: string): void;
  getContentToPlay(payload: IContentPlayerProps): void;
  selectedSection: LessonTypes;
}
function GridView({
  lessons,
  getContentToPlay,
  selectedSection,
  assignHandler,
}: IListViewProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const toast = useToast();

  const { isTablet } = useClientDevice();
  const assignedLessons = useAppSelector(selectAssignedLessons);
  const levelOneFitler = useAppSelector(selectLevelOneFilter);
  const dispatch = useAppDispatch();

  const checkIfLessonAssigned = (lo: ILo) => {
    if (lo.unitId) {
      let unitFound = assignedLessons.unitList.filter(
        (item) => item.unitId === lo.unitId
      );
      if (unitFound.length) {
        let loFound = unitFound[0]?.childLos?.filter(
          (lesson) => lesson.loId === lo.loId
        );
        if (loFound?.length) return true;
      }
    } else {
      let loFound = assignedLessons.losList.filter(
        (lesson) => lesson.loId === lo.loId
      );
      if (loFound.length) return true;
    }
    return false;
  };

  const playFreePlayContent = (id: string) => {
    if (isTablet) {
      notCompatibleWithTablets();
    } else {
      dispatch(contentIdsSet([{ _contentId: id }]));
      dispatch(showPlayerSet(true));
    }
  };
  function notCompatibleWithTablets() {
    toast({
      label: t("Info"),
      message: t("Only Desktop supported"),
      rtl: i18n.dir() === "rtl",
      type: "info",
      toastId: "ContentPlayNotSupoorted",
    });
  }

  const playLessonContent = (id: string, title: string, ene = false) => {
    if (isTablet) notCompatibleWithTablets();
    else
      getContentToPlay({
        contentId: id,
        contentTitle: title,
        playEne: ene,
        grBook: selectedSection === "GUIDED_READING",
      });
  };

  const handlePrint = (id: string, file: string) => {
    try {
      const iframe = document.getElementById(id) as HTMLIFrameElement;
      const printFramewindow =
        iframe?.contentDocument || iframe?.contentWindow?.document;
      const isTablet = isAndroid || isiPadoriPadPro;
      const isChrome =
        navigator.userAgent.match(/CriOS/gi) ||
        navigator.userAgent.match(/Chrome/gi);
      if (printFramewindow?.readyState === "complete") {
        if (isChrome && isTablet) {
          const url = isAndroid ? file : iframe.src;
          window.open(url);
        } else {
          iframe?.contentWindow?.print();
        }
      } else {
        setTimeout(() => handlePrint(id, file), 1000);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const playContentHandler = (id: string, title: string) =>
    selectedSection === LessonTypes.FREEPLAY
      ? () => playFreePlayContent(id)
      : () => playLessonContent(id, title);

  const actions = (lo: ILo) => {
    const {
      lessonContentId: contentId,
      loTitle: contentTitle,
      hasEne,
      file,
    } = lo;
    const worksheets = {
      icon: <PrintWorkSheetIcon sx={{ fontSize: "1.2rem" }} />,
      actionName: t("Print"),
      actionHandler: () => handlePrint(contentId, file!),
    };
    const view = {
      icon: (
        <img
          src={showView}
          style={{ width: "24px", height: "24px" }}
          {...(selectedSection === LessonTypes.FREEPLAY
            ? { alt: contentId, className: "play-ext", id: contentId }
            : { alt: "" })}
        />
      ),
      actionName: t("View"),
      actionHandler: playContentHandler(contentId, contentTitle),
    };

    const actlist = [
      levelOneFitler?.code === "Printable Worksheets" &&
      selectedSection === LessonTypes.FREEPLAY
        ? worksheets
        : view,
    ];
    if (selectedSection === LessonTypes.LESSON && hasEne)
      actlist.push({
        icon: <EneIcon sx={{ fontSize: "1.2rem" }} />,
        actionName: t("ENE"),
        actionHandler: () => playLessonContent(contentId, contentTitle, true),
      });

    return actlist;
  };

  const renderLoThumbComp = (lo: ILo) => (
    <div key={"GridList" + lo.loId + Math.random()}>
      <LoThumb
        key={`${lo.loId}${Math.random()}`}
        lesson={lo}
        actionHandler={
          checkIfLessonAssigned(lo)
            ? () => assignHandler(lo as IAssignedUnit, "remove")
            : () => assignHandler(lo as IAssignedUnit, "add")
        }
        thumbActionIcon={
          checkIfLessonAssigned(lo) ? (
            <RemoveIcon sx={{ fontSize: "1rem" }} />
          ) : (
            <AddIcon sx={{ fontSize: "1rem" }} />
          )
        }
        actions={actions(lo)}
        actionName={checkIfLessonAssigned(lo) ? "remove" : "add"}
      />
      {levelOneFitler?.code === "Printable Worksheets" ? (
        <WorkSheet
          data={{ file: lo.file!, id: lo.loId as string, title: lo.loTitle }}
        />
      ) : null}
    </div>
  );

  return lessons.length ? (
    <div className={classes.root} style={{ direction: i18n.dir() }}>
      {lessons.map((lo) => renderLoThumbComp(lo as ILo))}
    </div>
  ) : (
    <DataNotFound />
  );
}

export default GridView;
