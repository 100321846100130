import { CSSProperties } from "@mui/styles";
import { useTranslation } from "react-i18next";
import SelectInput from "components/SelectInput/index";
import {
  ICriterionOption,
  ICriteriaOption,
  IScoreOption,
} from "features/assessment";
import { IOption } from "features/common/Interfaces";

const createOptionDropDown = (scoreData: IScoreOption[]) => {
  const options = scoreData
    ?.sort((a, b) => a.id - b.id)
    .map((scoreItem) => {
      return {
        label: scoreItem.textAr,
        value: scoreItem.id,
      };
    });
  return options;
};
interface ICriteriaSelectProps {
  onChange(target: IOption, criteriaItem: ICriterionOption): void;
  criteriaItem: ICriterionOption;
  criteriaMap: ICriteriaOption;
  style: { container: CSSProperties };
}
export default function CriteriaSelect({
  onChange,
  criteriaItem,
  criteriaMap,
  style,
}: ICriteriaSelectProps) {
  const { t } = useTranslation();

  const getSelectedValue = (criteriaItem: ICriterionOption) => {
    return {
      label: criteriaItem.scores.filter(
        (item) => item.id === criteriaMap[criteriaItem.id]
      )[0]
        ? criteriaItem.scores.filter(
            (item) => item.id === criteriaMap[criteriaItem.id]
          )[0].textAr
        : t("select student level"),
      value: criteriaItem.scores.filter(
        (item) => item.id === criteriaMap[criteriaItem.id]
      )[0]
        ? criteriaMap[criteriaItem.id]
        : null,
    };
  };
  const list = createOptionDropDown(criteriaItem.scores);
  const onChangeOption = ({ currentTarget }: { currentTarget: IOption }) => {
    onChange(currentTarget, criteriaItem);
  };
  return (
    <SelectInput
      defaultValue={getSelectedValue(criteriaItem).value ?? undefined}
      valueList={list}
      getOption={onChangeOption}
      placeholder={t("select student level")}
      style={style}
    />
  );
}
