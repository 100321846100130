import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface IPercentageChartProps {
  containerHeight: number;
}
function PercentageChart({ containerHeight }: IPercentageChartProps) {
  const { i18n } = useTranslation();

  const useStyles = makeStyles({
    percentageContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "40px",
      position: "absolute",
      width: "calc(80% - 15px);",
      ...(i18n.dir() === "rtl" ? { left: 0 } : { right: 0 }),
      "&:before": {
        content: '""',
        borderBottom: "1px solid rgb(221, 221, 221)",
        height: "1px",
        width: "calc(100% + 24px)",
        top: "22px",
        position: "absolute",
        ...(i18n.dir() === "rtl" ? { left: "-7px" } : { right: "-7px" }),
      },
    },
    text: {
      position: "absolute",
      color: "rgba(88, 88, 88, 1)",
      fontWeight: "bold",
      fontSize: "0.9rem",
    },
    oneDigit: (height) => ({
      "&:before": {
        content: '""',
        height: height + "px",
        width: "1px",
        position: "absolute",
        top: "26px",
        ...(i18n.dir() === "rtl"
          ? { right: 0, borderLeft: "1px dotted rgb(221, 221, 221)" }
          : { left: 0, borderRight: "1px dotted rgb(221, 221, 221)" }),
      },
    }),
    twoDigit: (height) => ({
      "&:before": {
        content: '""',
        height: height + "px",
        width: "1px",
        position: "absolute",
        top: "26px",
        ...(i18n.dir() === "rtl"
          ? { right: "24px", borderLeft: "1px dotted rgb(221, 221, 221)" }
          : { left: "24px", borderRight: "1px dotted rgb(221, 221, 221)" }),
      },
    }),
    threeDigit: (height) => ({
      "&:before": {
        content: '""',
        height: height + "px",
        width: "1px",
        position: "absolute",
        top: "26px",
        ...(i18n.dir() === "rtl"
          ? { right: "25px", borderLeft: "1px dotted rgb(221, 221, 221)" }
          : { left: "25px", borderRight: "1px dotted rgb(221, 221, 221)" }),
      },
    }),
  });
  const classes = useStyles(containerHeight);
  const cssProp = i18n.dir() === "rtl" ? "left" : "right";
  return (
    <div className={classes.percentageContainer}>
      <div
        className={classNames(classes.text, classes.oneDigit)}
        style={{ [cssProp]: "100%" }}
      >
        0
      </div>
      <div
        className={classNames(classes.text, classes.twoDigit)}
        style={{ [cssProp]: "80%" }}
      >
        20%
      </div>
      <div
        className={classNames(classes.text, classes.twoDigit)}
        style={{ [cssProp]: "60%" }}
      >
        40%
      </div>
      <div
        className={classNames(classes.text, classes.twoDigit)}
        style={{ [cssProp]: "40%" }}
      >
        60%
      </div>
      <div
        className={classNames(classes.text, classes.twoDigit)}
        style={{ [cssProp]: "20%" }}
      >
        80%
      </div>
      <div
        className={classNames(classes.text, classes.threeDigit)}
        style={{ [cssProp]: 0 }}
      >
        100%
      </div>
    </div>
  );
}

export default PercentageChart;
