import { MouseEvent, useState } from "react";
import {
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import Input from "components/Input";
import { VisibilityOff, Visibility } from "components/SVGs/PasswordVisibility";
import { Twitter, Facebook } from "components/SVGs/Social";
import { CSSProperties } from "@mui/styles";

export interface IChangedTarget {
  currentTarget: { name: string; value: string };
}

interface IRenderInputProps {
  name: string;
  placeholder: string;
  label?: string;
  type?: string;
  errors: string;
  value: string;
  autoFocus?: boolean;
  changeHandler(event: IChangedTarget): void;
  styles: CSSProperties;
  onBlur?(): void;
  tabIndex?: number;
  inputRef?: React.Ref<any>;
  "data-test"?: string;
}
export const RenderInput = ({
  name,
  placeholder,
  label,
  type = "text",
  errors,
  value,
  autoFocus,
  changeHandler,
  styles,
  onBlur,
  tabIndex,
  inputRef,
  ...rest
}: IRenderInputProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent): void => {
    event.preventDefault();
  };

  const endAdornment = (
    <IconButton
      aria-label="toggle password visibility"
      onClick={handleClickShowPassword}
      onMouseDown={handleMouseDownPassword}
      sx={{ padding: "0 4px" }}
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  );

  return (
    <Input
      name={name}
      id={name}
      label={label}
      placeholder={placeholder}
      errors={errors}
      inputRef={inputRef}
      displayErrorIcon={true}
      style={{ root: styles }}
      autoFocus={autoFocus}
      onBlur={onBlur}
      adornmentVal={type === "password" ? endAdornment : undefined}
      type={showPassword ? "text" : type}
      value={value}
      onChange={changeHandler}
      tabIndex={tabIndex}
      {...rest}
    />
  );
};

export const renderHeadLine = (text: string) => {
  return (
    <Typography
      component="p"
      style={{
        color: "rgba(80, 80, 80, 1)",
        fontSize: "1rem",
        userSelect: "none",
        fontWeight: "bold",
      }}
    >
      {text}
    </Typography>
  );
};

interface IRenderCheckboxProps {
  name: string;
  value: boolean;
  label: string;
  changeHandler(): void;
  labelStyle: string;
  checkboxStyle: string;
  "data-test"?: string;
}
export const renderCheckbox = ({
  name,
  value,
  label,
  changeHandler,
  labelStyle,
  checkboxStyle,
  ...rest
}: IRenderCheckboxProps) => (
  <FormControlLabel
    classes={{ root: labelStyle }}
    control={
      <Checkbox
        classes={{ root: checkboxStyle }}
        checked={value}
        onChange={changeHandler}
        name={name}
        color="primary"
      />
    }
    label={label}
    {...rest}
  />
);

export const renderSocial = (direction: "rtl" | "ltr") => (
  <Box
    sx={{
      position: "absolute",
      [direction === "rtl" ? "right" : "left"]: "78px",
      top: "30px",
    }}
  >
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://mobile.twitter.com/abjadiyatapp"
    >
      <Twitter sx={{ fontSize: "33px" }} />
    </a>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://www.facebook.com/abjadiyatapp"
    >
      <Facebook sx={{ margin: "0 15px", fontSize: "33px" }} />
    </a>
  </Box>
);
