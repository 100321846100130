import {
  AppBar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState, useContext, MouseEvent } from "react";
import { useHistory } from "react-router-dom";
import { MasterDataContext } from "contexts/MasterDataContext";
// import { TutorialContext } from "contexts/tutorialContext";
import useActiveRoute from "hooks/useActiveRoute";
import Menu from "../../Menu";
import { HelpIcon } from "../../SVGs";
import AvatarMenu from "./AvatarMenu";
import ChromeLink from "./ChromeLink";
import MobileMenu from "./MobileMenu";
import NotificationCenter from "./NotificationCenter";
import { BigLogoIcon } from "../../SVGs/MainMenuIcons";
import menuConstructor, { MenuItemTypes } from "./MenuList";
import LanguageSwitch from "./LanguageSwitch";
import { useTranslation } from "react-i18next";
import { selectShowHelp, toggleShowHelp } from "features/ui";
import { useAppDispatch, useAppSelector } from "app/hooks";

const customStyles = {
  appBar: {
    height: "80px",
    position: "relative",
    backgroundColor: "rgb(255 255 255)",
    transition: "all 150ms ease 0s",
    "@media print": {
      display: "none",
    },
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.1)",
    "@media screen and (max-width: 768px)": {
      height: "65px",
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerLogo: {
    fontSize: "48px",
    width: "unset",
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width:992px)": {
      display: "none",
    },
  },
  listItem: {
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    fontWeight: "bold",
    color: "rgb(131 131 131)",
    textAlign: "right",
    cursor: "pointer",
    whiteSpace: "nowrap",
    "& *": {
      minWidth: "unset !important",
      padding: "0.15rem",
    },
    "& > div > svg": {
      width: "32px",
      height: "32px",
      color: "rgb(169 175 187)",
    },
    "& .active, &:hover, &:hover >div > svg,&:hover > svg ": {
      color: "rgb(98 195 238)",
    },
  },
  help: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    padding: "3px",
    backgroundColor: "rgb(232 235 241)",
    color: "rgb(169, 175, 187)",
    cursor: "pointer",
    "&:hover": {
      borderRadius: "50%",
      border: "1px solid rgb(98 195 238)",
      color: "rgb(98 195 238)",
      background: "rgb(255 255 255)",
    },
  },
  activeHelp: {
    borderRadius: "50%",
    border: "1px solid rgb(98 195 238)",
    color: "rgb(98 195 238)",
    background: "rgb(255 255 255)",
  },
};

const useStyles = makeStyles({
  actionIcons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    " & > *": {
      margin: "0 0.2rem",
    },
  },
});

const Header = () => {
  const [openedMenu, setOpenedMenu] = useState<string>("");
  const dispatch = useAppDispatch();
  const { i18n, t } = useTranslation();
  const showHelpState = useAppSelector(selectShowHelp);
  const history = useHistory();
  const classes = useStyles();
  const activeRoute = useActiveRoute();

  const clickHandler = (e: MouseEvent<HTMLLIElement>) => {
    const clickedMenu = e.currentTarget.getAttribute("id")!;
    setOpenedMenu(clickedMenu === openedMenu ? "" : clickedMenu);
  };
  const { logEvent } = useContext(MasterDataContext);

  const menuList = menuConstructor({ history, clickHandler, logEvent });

  // const { open, setOpen, videoList } = useContext(TutorialContext);

  const menuItems = menuList.map((menu, index) => (
    <ListItem
      sx={customStyles.listItem}
      key={"mainMenu" + index}
      onClick={(event) => menu.action?.(event)}
      id={menu.name}
    >
      <ListItemIcon>
        {menu?.Icon?.({
          stroke: activeRoute(menu.name) ? "rgb(98 195 238)" : "currentColor",
        })}
      </ListItemIcon>
      <ListItemText
        primary={t(menu.label)}
        className={activeRoute(menu.name) ? "active" : undefined}
        disableTypography={true}
      />
      {menu.menuType !== MenuItemTypes.leaf ? (
        <Menu
          key={"dropDown" + index}
          active={activeRoute(menu.name)}
          menuItems={menu.menuItems}
          visible={menu.name === openedMenu ? "visible" : "hidden"}
          align={i18n.dir() === "rtl" ? "right" : "left"}
        />
      ) : null}
    </ListItem>
  ));

  const helpHandler = () => {
    if (!showHelpState) {
      logEvent("HelpHit");
    }
    dispatch(toggleShowHelp());
  };

  return (
    <AppBar sx={customStyles.appBar} id="appBar">
      <Toolbar sx={customStyles.container} style={{ direction: i18n.dir() }}>
        <MobileMenu sideList={menuList} />
        <BigLogoIcon sx={customStyles.headerLogo} />
        <List sx={customStyles.list}>{menuItems}</List>
        <div className={classes.actionIcons}>
          <NotificationCenter />
          {/* {videoList.length > 0 && ( */}
          <HelpIcon
            sx={{
              ...customStyles.help,
              ...(showHelpState ? customStyles.activeHelp : {}),
            }}
            onClick={helpHandler}
          />
          {/* )} */}
          {/* <ChromeLink /> */}
          <AvatarMenu />
          <LanguageSwitch />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
