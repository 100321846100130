import { Grid } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import SelectInput from "components/SelectInput";
import { createSelectResponse } from "utils/EvaluationHelpers";
import {
  classSet,
  studentSet,
  selectGrade,
  totalPendingAssessmentsCount,
  totalCompletedAssessmentsCount,
  studentsPendingAssessmentsCount,
  studentsCompletedAssessmentsCount,
  assessmentsSet,
  selectAssessments,
  defaultStudentOption,
  isLoadingAssessmentsSet,
  selectIsNeedRefetching,
  selectSelectedLo,
  isNeedRefetchingSet,
} from "features/evaluation";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useFetchClassesQuery } from "services/common";
import { useFetchClassAssessmentsQuery } from "services/evaluation";
import { useTranslation } from "react-i18next";
import { ToolbarSkeleton } from "./EvaluationSkeleton";
import {
  IExtendedOption,
  IOption,
  IOptionWithList,
} from "features/common/Interfaces";
interface ISelectClassHandler {
  currentTarget: IOptionWithList;
}
interface ISelectStudentHandler {
  currentTarget: IOption;
}
function Toolbar() {
  const { t } = useTranslation();
  const [classesList, setClassesList] = useState<IOptionWithList[]>([]);
  const [studentsList, setStudentsList] = useState<IExtendedOption[]>([]);
  const [selectedClass, setSelectedClass] = useState<IOptionWithList>();
  const [selectedStudent, setSelectedStudent] =
    useState<IOption>(defaultStudentOption);
  const dispatch = useAppDispatch();
  const assessmentsList = useAppSelector(selectAssessments);
  const selectedGrade = useAppSelector(selectGrade);
  const totalPending = useAppSelector(totalPendingAssessmentsCount);
  const totalCompleted = useAppSelector(totalCompletedAssessmentsCount);
  const studentsPendingCounts = useAppSelector(studentsPendingAssessmentsCount);
  const selectedLo = useAppSelector(selectSelectedLo);
  const isNeedRefetching = useAppSelector(selectIsNeedRefetching);
  const studentsCompletedCounts = useAppSelector(
    studentsCompletedAssessmentsCount
  );
  const { logEvent } = useContext(MasterDataContext);
  const { data: classesData, isLoading: isClassesLoading } =
    useFetchClassesQuery(selectedGrade?.id!, {
      skip: !selectedGrade?.id,
    });

  const createStudentList = (
    students: IOption[],
    totalPending: number | undefined,
    totalCompleted: number | undefined,
    studentsPendingCounts: { [key: number]: number },
    studentsCompletedCounts: { [key: number]: number }
  ): IExtendedOption[] => [
    {
      ...defaultStudentOption,
      extra: {
        pending: totalPending,
        completed: totalCompleted,
      },
    },
    ...students.map((student) => ({
      ...student,
      extra: {
        pending: studentsPendingCounts[+student.value!],
        completed: studentsCompletedCounts[+student.value!],
      },
    })),
  ];

  const {
    data: classAssessmentData,
    isLoading,
    isUninitialized,
    isFetching,
    refetch,
  } = useFetchClassAssessmentsQuery(selectedClass?.value!, {
    skip: !selectedClass?.value,
  });
  useEffect(() => {
    dispatch(
      isLoadingAssessmentsSet(isLoading || isUninitialized || isFetching)
    );
  }, [isLoading, isUninitialized, isFetching]);

  useEffect(() => {
    if (isNeedRefetching) {
      refetch();
      dispatch(isNeedRefetchingSet(false));
    }
  }, [selectedLo]);

  useEffect(() => {
    if (classAssessmentData?._statusCode === 200) {
      const data = classAssessmentData._entity?._classOfflineAssessments;
      const assessments = data.map((assessment) => ({
        loId: assessment._loId,
        loTitle: assessment._loTitle,
        lessonTitle: assessment._lessonTitle ?? assessment._loTitle ?? "",
        loCode: assessment._loCode,
        thumb: assessment._loThumbURL,
        domainName: assessment._domainName,
        assessmentType: assessment._assessmentType,
        studentDetails: assessment._studentDetails.map((item) => ({
          name: item._name,
          id: item._id,
          evaluationStatus: item._evaluationStatus,
          avatar: item._avatar,
        })),
      }));
      dispatch(assessmentsSet(assessments));
    }
  }, [classAssessmentData]);

  useEffect(() => {
    try {
      if (classesData?._statusCode === 200) {
        const classes = createSelectResponse(classesData._entity);
        setClassesList(classes);
        const autoSavedClass = JSON.parse(
          localStorage.getItem("class-evaluation") ?? "{}"
        ) as IOption;
        let initClass;
        if (Object.keys(autoSavedClass).length > 0)
          initClass = classes.find((c) => c.value === +autoSavedClass.value!);

        setSelectedClass(initClass ?? classes[0]);
      }
    } catch (error) {
      console.log(error);
    }
  }, [classesData]);

  useEffect(() => {
    let updatedStudentList = (selectedClass?.students ??
      []) as IExtendedOption[];
    if (assessmentsList?.length) {
      updatedStudentList = createStudentList(
        updatedStudentList,
        totalPending,
        totalCompleted,
        studentsPendingCounts,
        studentsCompletedCounts
      );
    } else {
      updatedStudentList = [defaultStudentOption, ...updatedStudentList];
    }
    setStudentsList(updatedStudentList);
    const autoSavedStudent = localStorage.getItem("student-evaluation");
    let autoSelectedStudent;
    if (autoSavedStudent) {
      autoSelectedStudent = updatedStudentList.find(
        (student: IExtendedOption) => student.value === +autoSavedStudent
      );
    }
    setSelectedStudent(autoSelectedStudent ?? updatedStudentList?.[0]);
  }, [assessmentsList]);

  useEffect(() => {
    if (selectedClass) dispatch(classSet(selectedClass));
  }, [selectedClass]);

  useEffect(() => {
    if (selectedStudent) dispatch(studentSet(selectedStudent));
  }, [selectedStudent]);

  const classSelectHandler = ({ currentTarget }: ISelectClassHandler) => {
    logEvent("ClickOnClassFilter", {
      GradeId: selectedGrade!.code,
      ClassId: selectedClass!.value,
    });
    setSelectedClass(currentTarget);
  };

  const studentSelectHandler = ({ currentTarget }: ISelectStudentHandler) => {
    logEvent("ClickOnStudentsFilter", {
      Grade: selectedGrade!.code,
      Class: selectedClass!.label,
      StudentsId:
        currentTarget.value === "all" ? "All Student" : currentTarget.value,
    });
    setSelectedStudent(currentTarget);
  };

  return isClassesLoading ? (
    <ToolbarSkeleton />
  ) : (
    <Grid
      container
      alignItems="stretch"
      spacing={2}
      sx={{ padding: "0 10px 2px" }}
    >
      <Grid item xs={3} md={3} xl={2}>
        <SelectInput
          defaultValue={selectedClass?.value ?? classesList?.[0]?.value}
          valueList={classesList}
          getOption={classSelectHandler}
          disabled={classesList.length === 0}
        />
      </Grid>
      <Grid item xs={3} md={3} xl={2}>
        <SelectInput
          defaultValue={selectedStudent?.value}
          valueList={[
            { ...studentsList[0], label: t("All Students") },
            ...studentsList.slice(1),
          ]}
          getOption={studentSelectHandler}
          disabled={studentsList.slice(1).length === 0}
        />
      </Grid>
    </Grid>
  );
}

export default Toolbar;
