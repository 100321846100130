import {
  Checkbox,
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { RemoveIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";
import useToast from "hooks/useToast";
import { ITag } from "features/content";

const useStyles = makeStyles({
  list: {
    flexGrow: "1",
    overflowY: "auto",
    marginBottom: "0.75rem",
    "@media screen and (max-width: 768px)": {
      "& span": {
        fontSize: "16px",
      },
    },
  },
  expanded: {
    width: "100%",
    zIndex: 2,
    background: "rgba(255,255,255,1)",
    alignItems: "stretch",
    userSelect: "none",
    display: "flex",
    padding: "0.25rem 0.75rem",
    justifyContent: "space-between",
    top: "100%",
    left: 0,
    right: 0,
  },
  tags: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    minHeight: "fit-content",
    marginTop: "10px",
    borderBottom: "1px solid rgb(216, 216, 216)",
    paddingBottom: "10px",
    "&>*": {
      fontSize: "0.75rem",
    },
  },

  actions: {
    display: "flex",
    minHeight: "fit-content",
    borderTop: "1px solid rgba(216, 216, 216, 1)",
    paddingTop: "10px",
    marginTop: "10px",
    "@media screen and (max-width: 768px)": {
      "& span": {
        fontSize: "0.75rem",
      },
    },
  },
  addTags: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "center",
    height: "2.5rem",
    marginBottom: "0.5rem",
    "&>*": {
      margin: "0 5px",
    },
  },
  addBtn: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid rgba(98,195,238,1)",
    borderRadius: "0.5rem",
    userSelect: "none",
    cursor: "pointer",
    padding: "1.25rem 1.75rem",
    color: "rgba(255,255,255,1)",
    background: "rgba(98, 195, 238, 1)",
    "& > span": {
      fontSize: "1rem",
      margin: "0 5px",
    },
  },
  disabled: {
    cursor: "default",
    background: "rgb(98 195 238 / 50%)",
    border: "none",
  },
  disableClearAll: {
    pointerEvents: "none",
    color: "rgb(0 0 0 / 30%)",
    border: "1px solid rgb(98 195 238 / 75%)",
  },

  clearBtn: {
    display: "flex",
    alignItems: "center",
    border: "1px solid rgba(98,195,238,1)",
    borderRadius: "0.5rem",
    userSelect: "none",
    cursor: "pointer",
    padding: "0.25rem 0.75rem",
    margin: "0 10px",
    "&:hover": {
      color: "rgba(255,255,255,1)",
      background: "rgba(98, 195, 238, 1)",
    },
    "& > span": {
      fontSize: "1rem",
      marginRight: "0.5rem",
    },
  },
});

const maxSelectedTags = 8;
interface ITagsListProps {
  searchText: string;
  showDropdownList: boolean;
  selectedTags: ITag[];
  setSelectedTags(tag: ITag[]): void;
  handleSubmit(value: string): void;
  tagList: ITag[];
}
function TagsList({
  searchText,
  showDropdownList,
  selectedTags,
  setSelectedTags,
  handleSubmit,
  tagList,
}: ITagsListProps) {
  const classes = useStyles();
  const toast = useToast();

  const { t, i18n } = useTranslation();
  const [allTags, setAllTags] = useState<ITag[]>([]);
  const [searchTags, setSearchTags] = useState<ITag[]>([]);
  const [disableCheckbox, setDisableCheckbox] = useState(false);

  useEffect(() => {
    setSearchTags([]);
    if (tagList.length && showDropdownList) {
      setAllTags([
        ...tagList.map((tag) => ({
          id: tag.id,
          name: tag.name,
          code: tag.code,
          checked: selectedTags.filter(
            (selectedTag) => selectedTag.id === tag.id
          ).length
            ? true
            : false,
        })),
      ]);
    }
  }, [tagList, showDropdownList]);

  useEffect(() => {
    const newSelectedTags = allTags.filter((tag) => tag.checked);
    setSearchTags(
      searchText.trim().length && searchTags.length
        ? updateSearchTags(newSelectedTags)
        : allTags
    );
    setSelectedTags(newSelectedTags);
  }, [allTags]);

  const updateSearchTags = (selectedTags: ITag[]) => {
    return [
      ...searchTags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        code: tag.code,
        checked: selectedTags.filter((selectedTag) => selectedTag.id === tag.id)
          .length
          ? true
          : false,
      })),
    ];
  };

  useEffect(() => {
    if (selectedTags.length >= maxSelectedTags) setDisableCheckbox(true);
    else setDisableCheckbox(false);
  }, [selectedTags]);

  useEffect(() => {
    if (allTags.length) {
      const searchStr = searchText.trim();
      let newSearchTags = [...allTags];
      if (searchStr.length) {
        newSearchTags = newSearchTags.filter((item) => {
          const res = item.name
            .toString()
            .toLowerCase()
            .includes(searchStr.toLowerCase());
          return res;
        });
      }
      setSearchTags(newSearchTags);
    }
  }, [searchText]);

  const handleToggle = (tag: ITag) => {
    if (disableCheckbox && !tag.checked) {
      toast({
        label: t("Info"),
        message: t("Only 8 tags per one search"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "tagsError",
      });

      return;
    }
    let changedTags = (JSON.parse(JSON.stringify(allTags)) as ITag[]).map(
      (t) => {
        if (t.id === tag.id) {
          t.checked = !t.checked;
        }
        return t;
      }
    );
    setAllTags(changedTags);
  };

  const handleRemoveTag = (tagToRemove: ITag) => {
    let changedTags = (JSON.parse(JSON.stringify(allTags)) as ITag[]).map(
      (t) => {
        if (t.id === tagToRemove.id) {
          t.checked = !t.checked;
        }
        return t;
      }
    );
    setAllTags(changedTags);
  };

  const handleClearTags = () => {
    if (selectedTags.length) {
      setSearchTags([]);
      setAllTags([
        ...tagList.map((tag) => ({
          id: tag.id,
          name: tag.name,
          code: tag.code,
          checked: false,
        })),
      ]);
    }
  };

  const customStyles = {
    chip: {
      fontSize: "16px",
      borderRadius: "2px",
      width: "auto",
      color: "rgb(105 123 140)",
      flexDirection: "row",
      height: "27px",
      margin: "3px",
      background: "rgba(233, 236, 242, 1)",
      "& span": {
        padding: "0 5px",
      },
    },
    removeIcon: {
      width: "6px",
      height: "6px",

      ...(i18n.dir() === "rtl"
        ? { margin: "0 0 0 5px !important" }
        : { margin: "0 5px 0 0 !important" }),
      color: "rgb(169 175 187) !important",
    },
    listItem: {
      textAlign: i18n.dir() === "rtl" ? "right" : "left",
      color: "rgb(87 87 87)",
      "&>span": {
        fontSize: "16px",
        "@media screen and (max-width: 768px)": {
          fontSize: "16px",
        },
      },
    },
  };

  return showDropdownList ? (
    <>
      {selectedTags.length ? (
        <div className={classes.tags} style={{ direction: i18n.dir() }}>
          {selectedTags.map((tag, index) => (
            <Chip
              key={index}
              label={tag.name}
              role={undefined}
              deleteIcon={<RemoveIcon sx={customStyles.removeIcon} />}
              onDelete={() => handleRemoveTag(tag)}
              sx={customStyles.chip}
            />
          ))}
        </div>
      ) : null}

      <div
        className={classNames(classes.list, classes.expanded)}
        style={{ marginTop: selectedTags.length ? "5px" : 0 }}
      >
        <List style={{ width: "100%", maxHeight: "200px" }}>
          {searchTags.map((tag) => (
            <ListItemButton
              key={tag.id}
              dense
              onClick={() => handleToggle(tag)}
              sx={{
                "&>*": { fontSize: "0.5rem" },
                padding: 0,
              }}
            >
              <ListItemIcon sx={{ minWidth: "40px" }}>
                <Checkbox
                  edge={i18n.dir() === "rtl" ? "start" : "end"}
                  disabled={tag.checked ? false : disableCheckbox}
                  checked={tag.checked}
                  tabIndex={-1}
                  checkedIcon={
                    <CheckBoxIcon style={{ width: "20px", height: "20px" }} />
                  }
                  icon={
                    <CheckBoxOutlineBlankIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  }
                  inputProps={{ "aria-labelledby": `tag${tag.id}` }}
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText
                id={`tag${tag.id}`}
                primary={tag.name}
                sx={customStyles.listItem}
              />
            </ListItemButton>
          ))}
        </List>
      </div>
      <div className={classes.actions} style={{ direction: i18n.dir() }}>
        <div
          className={classNames(classes.addTags)}
          onClick={() => handleSubmit(searchText)}
        >
          <div
            className={classNames(
              classes.addBtn,
              selectedTags.length === 0 && classes.disabled
            )}
          >
            <Typography component="span">{t("Search")}</Typography>
          </div>
        </div>
        <div
          className={classNames(
            classes.addTags,
            classes.clearBtn,
            selectedTags.length === 0 && classes.disableClearAll
          )}
          onClick={handleClearTags}
        >
          <Typography component="span">{t("Clear all")}</Typography>
        </div>
      </div>
    </>
  ) : null;
}

export default TagsList;
