import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import defaultAvatar from "assets/img/ic_avatar_default.svg";
import ProgressBar from "../ProgressBar";
import Tooltip from "components/Tooltip";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import {
  IPacingStudentDetails,
  selectClass,
  selectedStudentSet,
} from "features/pacing";
import { useAppDispatch, useAppSelector } from "app/hooks";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  studName: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 1);",
    marginTop: "15px",
  },
  percentage: {
    display: "flex",
    marginBottom: "10px",
    position: "relative",
  },
  percentageCalculation: {
    position: "absolute",
    top: "-22px",
    display: "flex",
    alignItems: "center",
    width: "aut0",
    fontSize: "0.9rem",
    color: "rgba(106, 106, 106, 1)",
    justifyContent: "center",
  },
  studentContainer: {
    width: "20%",
    textAlign: "center",
    paddingRight: "10px",
    "& > img": {
      margin: "0px",
      width: "45px",
      height: "45px",
    },
  },
  studentData: {
    display: "flex",
    flexDirection: "column",
    width: "calc(80% - 10px)",
  },
  activityType: {
    textAlign: "center",
    fontWeight: "bold",
  },
  percentRatio: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

const barsColor = {
  complete: "rgba(248, 189, 136, 1)",
  incomplete: "rgba(255, 234, 211, 1)",
};

interface IStudentDataProps {
  data: IPacingStudentDetails;
  openAssignmentDetails(): void;
}
function StudentData({ data, openAssignmentDetails }: IStudentDataProps) {
  const { t, i18n } = useTranslation();
  const selectedClass = useAppSelector(selectClass);
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const customStyles = {
    cursor: "pointer",
  };

  const { logEvent } = useContext(MasterDataContext);

  const handleAssignmentHandler = (
    studentData: IPacingStudentDetails,
    count: number
  ) => {
    if (count) {
      logEvent("PacingReportClickInDetalis", {
        ClassId: selectedClass?.value,
        StudentId: studentData.studentId,
      });
      dispatch(selectedStudentSet(studentData));
      openAssignmentDetails();
    }
  };

  const tooltip = (data: IPacingStudentDetails) => {
    const uncompleted = data.totalCount > 0 ? 100 - data.percentage : 0;
    const customStyles = {
      ...(i18n.dir() === "rtl"
        ? { marginLeft: "15px" }
        : { marginRight: "15px" }),
    };
    return (
      <div style={{ width: "100px", direction: i18n.dir() }}>
        <p className={classes.activityType}>{t("Assignments Progress")}</p>
        <p className={classes.percentRatio}>
          <span style={customStyles}>{`${t("Completed")}: `}</span>
          <span>{data.percentage}%</span>
        </p>
        <p className={classes.percentRatio}>
          <span style={customStyles}>{`${t("Uncompleted")}: `}</span>
          <span>{uncompleted}%</span>
        </p>
      </div>
    );
  };

  return (
    <div
      className={classes.root}
      style={{
        ...(i18n.dir() === "rtl"
          ? { paddingLeft: "10px" }
          : { paddingRight: "10px" }),
      }}
    >
      <div className={classes.studentContainer}>
        <img
          src={data.avatarUrl ? data.avatarUrl : defaultAvatar}
          alt="student avatar"
        />
        <div className={classes.studName}>{data.name}</div>
      </div>
      <div className={classes.studentData}>
        <div className={classes.percentage}>
          <div
            className={classes.percentageCalculation}
            style={{
              ...(i18n.dir() === "rtl" ? { right: "5px" } : { left: "5px" }),
            }}
          >
            {data.completedCount +
              (i18n.dir() === "rtl" ? "\\" : "/") +
              data.totalCount}
          </div>
          <Tooltip title={tooltip(data)}>
            <ProgressBar
              completecolor={barsColor.complete}
              incompletecolor={barsColor.incomplete}
              width={data.percentage}
              style={data.totalCount ? customStyles : {}}
              onClick={() => handleAssignmentHandler(data, data.totalCount)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default StudentData;
