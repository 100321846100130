import { CSSProperties, MouseEventHandler, ReactNode, useContext } from "react";
import {
  AssignIcon,
  EneWithCircleIcon,
  CompletedIcon,
  PartiallyCompletedIcon,
} from "components/SVGs/HomeIcons";
import evaluation from "assets/img/evaluation.png";
import { makeStyles } from "@mui/styles";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import showView from "assets/img/showView.png";
import Tooltip from "components/Tooltip";
import DataNotFound from "components/DataNotFound";
import { PrintWorkSheetIcon } from "components/SVGs";
import WorkSheet, { IWorkSheetProps } from "./WorkSheet";
import useClientDevice from "hooks/useClientDevice";
import useToast from "hooks/useToast";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { ILo, IUnit, selectLevelOneFilter } from "features/content";
import { IAssignedUnit, selectAssignedLessons } from "features/assignments";
import { useTranslation } from "react-i18next";
import { IContentPlayerProps } from "components/ContentPlayer";
import { LessonTypes } from "features/common/Interfaces";
import { contentIdsSet, showPlayerSet } from "features/ui";

const isiPadoriPadPro =
  navigator.userAgent.match(/iPad/gi) ||
  (navigator.userAgent.match(/Mac/gi) &&
    navigator.maxTouchPoints > 1 &&
    ((window.screen.width === 768 && window.screen.height === 1024) ||
      (window.screen.width === 1024 && window.screen.height === 1366)));
const isAndroid =
  navigator.userAgent.match(/Android/gi) ||
  navigator.userAgent.match(/Tablet/gi);

const useStyles = makeStyles({
  root: {
    margin: "15px 0",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
    padding: "10px",
    "&:nth-of-type(odd)": {
      background: "rgba(249, 249, 249, 1)",
    },
  },
  left: {
    display: "flex",
    "& svg": {
      fontSize: "1.5rem",
    },
  },
  right: {
    display: "flex",
    alignItems: "center",
    flex: 1,
    overflow: "hidden",
    paddingLeft: "20px",
    "& svg": { fontSize: "1.5rem" },
  },
  parentLo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0 10px 20px 10px",
    "& svg": {
      cursor: "pointer",
    },
  },
  childLos: {
    width: "100%",
    paddingRight: "20px",
    "& .heading": {
      "&:nth-of-type(odd)": {
        background: "transparent",
      },
    },
  },
  loTitle: {
    padding: "0 15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: 0,
  },
});

const renderListElement = (
  item: ReactNode,
  action: MouseEventHandler<HTMLDivElement>,
  style: CSSProperties = {},
  data?: IWorkSheetProps
) => {
  return (
    <>
      <div
        onClick={action}
        style={{
          ...style,
          minWidth: "50px",
          textAlign: "center",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        {item}
      </div>
      {data ? <WorkSheet data={data} /> : null}
    </>
  );
};

interface IListViewProps {
  lessons: (ILo | IUnit)[];
  count: number;
  assignHandler(lo: IAssignedUnit, type: string): void;
  getContentToPlay(payload: IContentPlayerProps): void;
  selectedSection: LessonTypes;
}

function ListView({
  lessons,
  count,
  assignHandler,
  getContentToPlay,
  selectedSection,
}: IListViewProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { isTablet } = useClientDevice();
  const toast = useToast();
  const assignedLessons = useAppSelector(selectAssignedLessons);
  const dispatch = useAppDispatch();

  const renderClassesList = (assignedClasses: string[]) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <span style={{ fontWeight: "bold" }}>{t("Assigned to a class")}</span>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        {assignedClasses?.map((className) => (
          <p key={className}>{className}</p>
        ))}
      </div>
    </div>
  );

  const renderLoStatusIcon = (lo: ILo) => (
    <>
      {lo.isOfflineEvaluated ? (
        <Tooltip title={t("Manual Eavluation")}>
          <img
            src={evaluation}
            style={{ width: "20px", height: "20px", marginLeft: "10px" }}
            alt="offline evaluation"
          />
        </Tooltip>
      ) : null}
      {lo.loStatus === "ASSIGNED" ? (
        <Tooltip title={t("Assigned to all")}>
          <div style={{ display: "flex" }}>
            <CompletedIcon style={{ fontSize: "1.2rem" }} />
          </div>
        </Tooltip>
      ) : lo.loStatus === "PARTIALLY_ASSIGNED" ? (
        <Tooltip title={renderClassesList(lo.assignedClasses) ?? ""}>
          <div style={{ display: "flex" }}>
            <PartiallyCompletedIcon />
          </div>
        </Tooltip>
      ) : null}
    </>
  );
  const levelOneFitler = useAppSelector(selectLevelOneFilter);

  const { logEvent } = useContext(MasterDataContext);

  const playFreePlayContent = (id: string) => {
    if (isTablet) {
      notCompatibleWithTablets();
    } else {
      dispatch(contentIdsSet([{ _contentId: id }]));
      dispatch(showPlayerSet(true));
    }
  };
  function notCompatibleWithTablets() {
    toast({
      label: t("Info"),
      message: t("Only Desktop supported"),
      rtl: i18n.dir() === "rtl",
      type: "info",
      toastId: "ContentPlayNotSupoorted",
    });
  }

  const playLessonContent = (
    id: string,
    title: string,
    ene: boolean = false
  ) => {
    if (isTablet) notCompatibleWithTablets();
    else
      getContentToPlay({
        contentId: id,
        contentTitle: title,
        playEne: ene,
        grBook: selectedSection === LessonTypes.GUIDED_READING,
      });
  };

  const playContentHandler = (id: string, title: string) =>
    selectedSection === LessonTypes.FREEPLAY
      ? () => playFreePlayContent(id)
      : () => playLessonContent(id, title);

  const checkIfLessonAssigned = (lo: ILo, unit?: IUnit) => {
    if (unit) {
      let unitFound = assignedLessons.unitList.filter(
        (item) => item.unitId === unit.unitId
      );
      if (unitFound.length) {
        let loFound = unitFound[0]?.childLos?.filter(
          (lesson) => lesson.loId === lo.loId
        );
        if (loFound?.length) return true;
      }
    } else {
      let loFound = assignedLessons.losList.filter(
        (lesson) => lesson.loId === lo.loId
      );
      if (loFound.length) return true;
    }
    return false;
  };

  const checkIfUnitAssigned = (unit: IUnit) => {
    let unitFound = assignedLessons.unitList.filter(
      (item) => item.unitId === unit.unitId
    );
    if (
      unitFound.length &&
      unitFound[0]?.childLos?.length === unit.childLos.length
    )
      return true;
    return false;
  };

  const handlePrint = (id: string, file: string) => {
    try {
      const iframe = document.getElementById(id) as HTMLIFrameElement;
      const printFramewindow =
        iframe?.contentDocument || iframe?.contentWindow?.document;
      const isTablet = isAndroid || isiPadoriPadPro;
      const isChrome =
        navigator.userAgent.match(/CriOS/gi) ||
        navigator.userAgent.match(/Chrome/gi);
      if (printFramewindow?.readyState === "complete") {
        if (isChrome && isTablet) {
          const url = isAndroid ? file : iframe?.src;
          window.open(url);
        } else {
          iframe?.contentWindow?.print();
        }
      } else {
        setTimeout(() => handlePrint(id, file), 1000);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const renderLo = (lo: ILo, unit?: IUnit) => {
    const isLoAssigned = checkIfLessonAssigned(lo, unit);
    return (
      <div
        className={classes.listItem + " heading"}
        key={`${lo.loId}${Math.random()}`}
        style={{ margin: unit ? 0 : undefined }}
      >
        <div className={classes.left}>
          {lo.hasEne
            ? renderListElement(
                <EneWithCircleIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    logEvent("ClickOnBrowseEnE", {
                      ContentId: lo.lessonContentId,
                    })
                  }
                />,
                () => playLessonContent(lo.lessonContentId, lo.loTitle, true),
                {
                  marginLeft: "15px",
                }
              )
            : null}
          {levelOneFitler?.code === "Printable Worksheets" &&
          selectedSection === LessonTypes.FREEPLAY
            ? renderListElement(
                <PrintWorkSheetIcon sx={{ fontSize: "1.2rem" }} />,
                () => handlePrint(lo.lessonContentId, lo.file!),
                {},
                {
                  file: lo.file!,
                  id: lo.lessonContentId,
                  title: lo.loTitle,
                }
              )
            : renderListElement(
                <img
                  src={showView}
                  style={{ width: "24px", height: "24px" }}
                  {...(selectedSection === LessonTypes.FREEPLAY
                    ? {
                        alt: lo.lessonContentId,
                        className: "play-ext",
                        id: lo.lessonContentId,
                      }
                    : { alt: "" })}
                  onClick={() =>
                    logEvent("ClickOnBrowseContent", {
                      ContentId: lo.lessonContentId,
                    })
                  }
                />,
                playContentHandler(lo.lessonContentId, lo.loTitle)
              )}
        </div>
        <div className={classes.right}>
          {isLoAssigned
            ? renderRemoveIcon(lo as IAssignedUnit)
            : renderAddIcon(lo as IAssignedUnit)}
          <p className={classes.loTitle}>{lo.loTitle}</p>
          {renderLoStatusIcon(lo)}
        </div>
      </div>
    );
  };

  const renderUnit = (unit: IUnit) => {
    const isUnitAssigned = checkIfUnitAssigned(unit);
    return (
      <div
        className={classes.listItem}
        key={unit.unitId + Math.random() + new Date()}
        style={{ padding: "10px 0" }}
      >
        <div
          className={classes.right}
          style={{ flexDirection: "column", paddingLeft: 0 }}
        >
          <div className={classes.parentLo}>
            {isUnitAssigned
              ? renderRemoveIcon(unit as IAssignedUnit)
              : renderAddIcon(unit as IAssignedUnit)}
            <p className={classes.loTitle}>{unit.unitName}</p>
          </div>
          <div className={classes.childLos}>
            {unit.childLos.map((childLo) => renderLo(childLo, unit))}
          </div>
        </div>
      </div>
    );
  };

  const renderAddIcon = (lo: IAssignedUnit) => (
    <AssignIcon
      onClick={() => {
        assignHandler(lo, "add");
        logEvent("AddContentToAssigingCart", {
          ContentId: lo.lessonContentId,
          ContentType: "Lessons",
        });
      }}
      sx={{ cursor: "pointer" }}
    />
  );

  const renderRemoveIcon = (lo: IAssignedUnit) => (
    <RemoveCircleIcon
      sx={{ color: "rgba(105, 123, 140, 1)", cursor: "pointer" }}
      onClick={() => assignHandler(lo, "remove")}
    />
  );

  return (
    <div style={{ minHeight: "150px", direction: i18n.dir() }}>
      {lessons.length ? (
        <div className={classes.root}>
          <div className={classes.listItem} style={{ background: "none" }}>
            <div className={classes.left}>
              {selectedSection === LessonTypes.LESSON ? (
                <div
                  style={{
                    minWidth: "50px",
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "0 10px",
                  }}
                >
                  {t("ENE")}
                </div>
              ) : null}
              <div
                style={{
                  minWidth: "50px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                {t("View")}
              </div>
            </div>
            <div
              className={classes.right}
              style={{
                paddingRight: "40px",
                fontWeight: "bold",
                justifyContent: "flex-start",
              }}
            >
              {t("Learning Objective count", { count })}
            </div>
          </div>
        </div>
      ) : null}

      <div className={classes.root}>
        {lessons.length ? (
          lessons.map((lo) =>
            "childLos" in lo ? renderUnit(lo as IUnit) : renderLo(lo as ILo)
          )
        ) : (
          <DataNotFound />
        )}
      </div>
    </div>
  );
}

export default ListView;
