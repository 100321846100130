import { Assessment, StatusTypes } from "features/common/Interfaces";
import { AssetTypes } from "features/assessment";
import { API, IResponse, SlicesTags } from "./API";

interface IFetchAssessmentDetailsRequest {
  studentId: number;
  loId: string;
  type: string;
}

interface IFetchedStudentDetails {
  _avatar?: string;
  _evaluationStatus: StatusTypes;
  _id: number;
  _name: string;
}
interface IAssessmentEntity {
  _assessmentType: Assessment;
  _domainName: string;
  _lessonTitle: string;
  _loCode: string;
  _loId: number;
  _loThumbURL: string;
  _loTitle: string;
  _studentDetails: IFetchedStudentDetails[];
}
interface IFetchClassAssessmentsResponse extends IResponse {
  _entity: {
    _classOfflineAssessments: IAssessmentEntity[];
  };
}

export interface IAssessmentAssets {
  _type: AssetTypes;
  _url: string;
}
interface IAssessmentScore {
  _id: number;
  _textAr: string;
  _weight: number;
}
interface IAssessmentCriterion {
  _id: number;
  _scores: IAssessmentScore[];
  _selectedOptionId: number;
  _textAr: string;
}
export interface IFetchAssessmentDetailsResponse extends IResponse {
  _entity: {
    _assets: IAssessmentAssets[];
    _criterion: IAssessmentCriterion[];
    _description: string;
  };
}

const evaluationServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchClassAssessments: build.query<
        IFetchClassAssessmentsResponse,
        number
      >({
        query: (classId) => {
          return `/ams/offline-assessments?classId=${classId}`;
        },
      }),
      fetchAssessmentDetails: build.query<
        IFetchAssessmentDetailsResponse,
        IFetchAssessmentDetailsRequest
      >({
        query: (payload) => {
          return `/ams/offline-assessment/student/evaluation?studentId=${payload.studentId}&loCode=${payload.loId}&assessmentType=${payload.type}`;
        },
        providesTags: (result, error, arg) =>
          arg
            ? [
                {
                  type: SlicesTags.Assessment,
                  studentId: arg.studentId,
                  loId: arg.loId,
                },
                SlicesTags.Assessment,
              ]
            : [SlicesTags.Assessment],
      }),
      evaluateAssessment: build.mutation<IResponse, any>({
        query: (payload) => {
          return {
            url: `/ams/offline-assessment/student/evaluation`,
            method: "POST",
            body: payload,
          };
        },
        invalidatesTags: (result, error, arg) => [
          {
            type: SlicesTags.Assessment,
            studentId: arg._studentId,
            loId: arg._loCode,
          },
        ],
      }),
    };
  },
});

export const {
  useFetchClassAssessmentsQuery,
  useFetchAssessmentDetailsQuery,
  useEvaluateAssessmentMutation,
} = evaluationServices;
