import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { IDeliverable } from "utils/ReportsHelpers";

const useStyles = makeStyles({
  contentGridItem: {
    borderBottom: "1px solid #c6c6c6",
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  summaryContentThumb: {
    borderRadius: "8px",
    border: "1px solid #979797",
  },

  summaryContentTitle: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "rgb(88 88 88)",
    margin: "0.5rem 0",
  },
  date: {
    fontSize: "12px",
    color: "rgba(131, 131, 131, 1)",
  },
});

interface ILoThumbProps {
  data: IDeliverable;
  reportType: string;
}

function LoThumb({ data, reportType }: ILoThumbProps) {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid key={JSON.stringify(data)} item xs={6}>
      <div
        style={{
          borderBottom: "1px solid #c6c6c6",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <div
          style={{
            borderRadius: "8px",
            width: "168px",
            margin: "3px",
            background:
              data?.contentId?.includes("WRK") ||
              data?.contentId?.includes("INTEBOOK") ||
              data?.contentId?.includes("GRB")
                ? `url(${data.thumbUrl})`
                : undefined,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <div
            style={{
              backdropFilter: "blur(8px)",
              background: "rgba(255,255,255,0.15)",
              width: "100%",
              borderRadius: "10px",
              height: "100%",
              border: "1px solid #979797",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              aspectRatio: "auto",
            }}
          >
            <img
              className={classes.summaryContentThumb}
              style={{
                width: data?.contentId?.includes("WRK")
                  ? "74px"
                  : data?.contentId?.includes("INTEBOOK") ||
                    data?.contentId?.includes("GRB")
                  ? "102px"
                  : "168px",
              }}
              src={data.thumbUrl}
              alt={
                i18n.dir() === "rtl" ? data.contentTitleAr : data.contentTitleEn
              }
            />
          </div>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.summaryContentTitle}>
            {i18n.dir() === "rtl" ? data.contentTitleAr : data.contentTitleEn}
          </div>
          <div className={classes.date}>
            {reportType === "completed"
              ? t("completion date")
              : t("assigning date")}
            {`: ${moment(data.deliverableDate).format("DD MMM YYYY")}`}
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default LoThumb;
