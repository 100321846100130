import { GradeCods } from "features/common/Interfaces";
import { API, IResponse } from "./API";

export enum FilterTypes {
  LESSON = "LESSON",
  MOE = "MOE",
  FREEPLAY = "FREEPLAY",
  GUIDED_READING = "GUIDED_READING",
  EXAMS = "EXAMS",
}
interface IFilter {
  _code: string;
  _title: string;
}
interface ILevelFilter extends IFilter {
  _subGroups?: IFilter[];
  _groups?: IFilter[];
}
interface IMOELevelFilter extends IFilter {
  _groups?: ILevelFilter[];
}
export interface IFecthedFilter {
  _filterType: FilterTypes;
  _groups?: ILevelFilter[];
  _terms?: IMOELevelFilter[];
}
export interface IFetchFiltersEntity {
  _id: number;
  _code: string;
  _nameAr: string;
  _nameEn: string;
  _owned: boolean;
  _filters: IFecthedFilter[];
}

interface IFetchFiltersResponse extends IResponse {
  _entity: IFetchFiltersEntity[];
}

export type IFetchGrLevelsByGradeEntity = {
  [key in GradeCods]: number[];
};
interface IFetchGrLevelsByGradeResponse extends IResponse {
  _entity: IFetchGrLevelsByGradeEntity;
}

const contentServices = API.injectEndpoints({
  endpoints(build) {
    return {
      fetchFilters: build.query<IFetchFiltersResponse, void>({
        query() {
          return `ams/dashboard/filters/all-grades`;
        },
      }),
      fetchGrLevelsByGrade: build.query<IFetchGrLevelsByGradeResponse, void>({
        query() {
          return `ams/grbook/levels/grades`;
        },
      }),
    };
  },
});

export const { useFetchFiltersQuery, useFetchGrLevelsByGradeQuery } =
  contentServices;
