import { makeStyles } from "@mui/styles";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  completedEvaluationTabOption,
  IEvaluationTab,
  pendingEvaluationTabOption,
} from "features/evaluation";

const useStyles = makeStyles({
  homeTabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(250, 250, 252, 1)",
    border: "1px solid rgba(151, 151, 151, 0.24)",
    padding: "5px",
    width: "100%",
    borderRadius: "8px",
    "@media screen and (max-width: 1024px)": {
      padding: "2px",
    },
  },
  navLink: {
    border: "none",
    zIndex: "1",
    color: "initial",
    width: "100%",
  },
  homeTab: {
    height: "48px",
    minWidth: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& > span": {
      textAlign: "center",
      fontSize: "13px",
      fontWeight: "bold",
      color: "#838383",
      margin: 0,
      "@media screen and (max-width: 1024px)": {
        fontSize: "10px",
      },
    },
    "& svg": {
      width: 35,
      height: 35,
    },
    "@media screen and (max-width: 1024px)": {
      minWidth: "100px",
    },
  },
  currentTab: {
    borderRadius: "8px",
    backgroundColor: "rgba(98, 195, 238, 1)",
    zIndex: 1,
    borderBottom: 0,
    "& > span": {
      color: "#ffffff !important",
    },
  },
});
const tabs = [pendingEvaluationTabOption, completedEvaluationTabOption];
interface ITabsProps {
  selectedTab: IEvaluationTab;
  selectTab(tab: IEvaluationTab): void;
  "data-test"?: string;
}
function Tabs({ selectedTab, selectTab, ...rest }: ITabsProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.homeTabs} {...rest}>
      {tabs?.map((tab, index) => (
        <div
          key={tab.id}
          className={classes.navLink}
          onClick={() => selectTab(tab)}
        >
          <div
            className={classNames(
              classes.homeTab,
              selectedTab?.id === tab.id ? classes.currentTab : null
            )}
            style={{ borderTopRightRadius: index === 0 ? "8px" : undefined }}
          >
            <span
              style={{
                color:
                  selectedTab?.id === tab.id ? "rgb(60, 180, 229)" : undefined,
              }}
            >
              {t(tab.name)}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Tabs;
