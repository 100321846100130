import { useEffect, useState } from "react";

export interface IWorkSheetProps {
  file: string;
  id: string;
  title: string;
}

function WorkSheet({ data }: { data: IWorkSheetProps }) {
  const [file, setFile] = useState<string>();

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(file!);
    };
  }, []);

  const getBinaryData = async () => {
    const isAndroid =
      navigator.userAgent.match(/Android/gi) ||
      navigator.userAgent.match(/Tablet/gi);
    const option = isAndroid ? { mode: "no-cors" as RequestMode } : {};
    fetch(data.file, option)
      .then(function (response) {
        return response.blob();
      })
      .then(function (myBlob) {
        const objectURL = URL.createObjectURL(myBlob);
        setFile(objectURL);
      });
  };

  useEffect(() => {
    if (data?.file) getBinaryData();
  }, [data]);

  return (
    <iframe
      src={file}
      id={data.id}
      style={{ display: "none" }}
      title={data.title}
    />
  );
}

export default WorkSheet;
