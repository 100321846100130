import { useContext, useState, useEffect } from "react";
import Content from "./Content";
import Header from "./Header";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import useClientDevice from "hooks/useClientDevice";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useTranslation } from "react-i18next";
import {
  IContentDetails,
  IEneContentEntity,
  IEneContent,
  useFetchLessonContentsQuery,
} from "services/common";
import useToast from "hooks/useToast";
import { useAppDispatch } from "app/hooks";
import { contentIdsSet, showPlayerSet } from "features/ui";
import {
  IExamQuestionsEntity,
  useFetchExamQuestionsQuery,
} from "services/exams";

const useStyles = makeStyles({
  root: {
    userSelect: "none",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    maxWidth: "90vw",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
    margin: "auto",
  },
  modal: {
    width: "auto",
    display: "inline-flex",
    flexDirection: "column",
  },
});
interface IPlayableContent {
  open: boolean;
  lessonContentList: IContentDetails[] | IEneContent[] | IExamQuestionsEntity[];
  selectedContentName?: string;
}
export interface IContentPlayerProps {
  contentId: string;
  contentTitle: string;
  playEne?: boolean;
  contentFinished?(value: boolean): void;
  grBook?: boolean;
  exam?: boolean;
  "data-test"?: string;
}
function ContentPlayer({
  contentId,
  contentTitle,
  playEne = false,
  contentFinished,
  grBook = false,
  exam = false,
  ...rest
}: IContentPlayerProps) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const classes = useStyles();
  const { data: examContent } = useFetchExamQuestionsQuery(contentId, {
    skip: !contentId && !exam,
  });
  const { data: contentData } = useFetchLessonContentsQuery(
    { playEne, grBook, contentId },
    {
      skip: !contentId && exam,
    }
  );
  const [content, setContent] = useState<IPlayableContent>({
    open: false,
    lessonContentList: [],
    selectedContentName: contentTitle,
  });
  const { logEvent } = useContext(MasterDataContext);
  const { isTablet } = useClientDevice();

  useEffect(() => {
    if (contentTitle?.length && contentId)
      try {
        if (exam && examContent?._statusCode === 200) {
          const questionsList = examContent._entity._questions;
          if (Array.isArray(questionsList))
            setContent({
              open: true,
              lessonContentList: JSON.parse(
                JSON.stringify(questionsList)
              ).reverse() as IExamQuestionsEntity[],
              selectedContentName: contentTitle,
            });
        } else if (contentData?._statusCode === 200) {
          const contentList = playEne
            ? (contentData._entity as IEneContentEntity)
                ?._eneContentWithLevelCollection
            : contentData._entity;
          if (Array.isArray(contentList)) {
            setContent({
              open: true,
              lessonContentList: JSON.parse(
                JSON.stringify(contentList)
              ).reverse(),
              selectedContentName: contentTitle,
            });
            const eventName = playEne
              ? "EnEContentPlay"
              : grBook
              ? "GuidedReadingContentPlay"
              : "ContentPlay";
            logEvent(eventName);
          }
        } else throw contentData;
      } catch (error) {
        setContent({
          open: false,
          lessonContentList: [],
          selectedContentName: undefined,
        });
        console.log(error);
      }
  }, [contentData, contentTitle, contentId, examContent]);

  const closeHandler = () => {
    setContent({
      open: false,
      lessonContentList: [],
      selectedContentName: undefined,
    });
    contentFinished?.(true);
  };

  const selectedIdHandler = (value?: string) => {
    if (value) {
      dispatch(contentIdsSet([{ _contentId: value }]));
      dispatch(showPlayerSet(true));
    } else {
      dispatch(contentIdsSet([]));
      dispatch(showPlayerSet(false));
    }
  };

  return isTablet ? (
    notCompatibleWithTablets()
  ) : (
    <Modal open={content.open} onClose={closeHandler}>
      <div className={classes.root} style={{ direction: i18n.dir() }} {...rest}>
        <div className={classes.modal}>
          <Header title={content.selectedContentName!} onClose={closeHandler} />
          <Content
            lessonData={content.lessonContentList}
            playEne={playEne}
            setSelectedId={selectedIdHandler}
            playExam={exam}
          />
        </div>
      </div>
    </Modal>
  );

  function notCompatibleWithTablets() {
    if (content.open) {
      toast({
        label: t("Info"),
        message: t("Only Desktop supported"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "ContentPlayNotSupoorted",
      });
      setContent({ ...content, open: false });
    }
    return null;
  }
}

export default ContentPlayer;
