import { keyframes, Modal, Typography } from "@mui/material";

const spin = keyframes`
0% { transform: rotate(0deg) },
100%  { transform: rotate(360deg) },
`;
const loaderStyle = {
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    border: "0.75rem solid rgb(98 195 238 /20%)",
    borderRadius: " 50%",
    borderTop: "0.75rem solid rgb(98 195 238)",
    width: "6rem",
    height: "6rem",
    animation: `${spin} 1.1s linear infinite`,
  },
};
interface ILoaderProps {
  open: boolean;
  "data-test"?: string;
}
const Loader = ({ open, ...rest }: ILoaderProps) => {
  return (
    <Modal open={open} {...rest}>
      <Typography component="div" sx={loaderStyle.loader}></Typography>
    </Modal>
  );
};

export default Loader;
