import { SuccessIcon, CloseIcon, AlertIcon } from "components/SVGs";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { toast } from "react-toastify";
import { ICSS } from "features/common/Interfaces";

const customStyles: ICSS = {
  root: {
    display: "flex",
    flexDirection: "row",
  },
  successIcon: {
    fontSize: "1.75rem",
  },
  closeIcon: {
    fontSize: "1.75rem",
  },
  alertIcon: { fontSize: "1.5rem" },
  infoIcon: {
    fontSize: "1.3rem",
    color: "rgb(98 195 238)",
    margin: "5px 10px 0",
  },
  messageBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexGrow: 1,
    padding: "5px 0",
  },
  title: { color: "rgb(0 0 0)", fontSize: "1rem" },
  content: {
    fontWeight: "bold",
    fontSize: "0.75rem",
    color: "rgb(81 81 81)",
  },
  toastMessage: {
    border: "1px solid rgb(194 219 241)",
    borderRadius: "8px",
    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.19)",
    padding: 0,
    margin: "15px 10px 0",
  },
};

export enum MessageType {
  "success" = "success",
  "error" = "error",
  "info" = "info",
}

export interface IToast {
  label: string;
  message: string;
  rtl?: boolean;
  type?: string;
  toastId?: string;
  positionBottom?: boolean;
}
const useToast =
  () =>
  ({
    label,
    message,
    rtl = true,
    type = MessageType.success,
    toastId,
    positionBottom = false,
  }: IToast) =>
    toast(
      <div style={customStyles.root}>
        {type === MessageType.success && (
          <SuccessIcon style={customStyles.successIcon} />
        )}
        {type === MessageType.error && (
          <AlertIcon style={customStyles.alertIcon} />
        )}
        {type === MessageType.info && (
          <InfoOutlinedIcon style={customStyles.infoIcon} />
        )}
        <div
          style={{
            ...customStyles.messageBody,
            alignItems: rtl ? "right" : "left",
          }}
        >
          <span style={customStyles.title}>{label}</span>
          <span style={customStyles.content}>{message}</span>
        </div>
        <CloseIcon style={customStyles.closeIcon} />
      </div>,
      {
        bodyStyle: { textAlign: rtl ? "right" : "left", padding: 0, margin: 0 },
        style: {
          ...customStyles.toastMessage,
          direction: rtl ? "rtl" : "ltr",
        },
        toastId,
        position: positionBottom
          ? toast.POSITION.BOTTOM_RIGHT
          : toast.POSITION.TOP_CENTER,
      }
    );

export default useToast;
