import ExpiredTokenStateIcon from "assets/img/ExpiredToken.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "rgb(80 80 80)",
    minHeight: "300px",
    "& svg": {
      fontSize: "4rem",
    },
    "& p": {
      margin: 0,
      paddingBottom: "10px",
    },
  },
  resendlink: {
    fontWeight: "bold",
    textDecoration: "underline",
    cursor: "pointer",
    padding: "0 5px",
  },
  link: {
    width: "100%",
    textAlign: "center",
    "& span": {
      userSelect: "none",
      textDecoration: "underline",
      cursor: "pointer",
      color: "rgb(60 180 229)",
      fontSize: "16px",
      // "&:hover": {
      //   textShadow: "-2px -2px 3px rgb(227 239 248)",
      //   color: "rgb(98 195 238)",
      // },
    },
  },
});

function ExpiredLink() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const loginHandler = () => {
    history.push("/login");
  };
  return (
    <div className={classes.root}>
      {/* <ExpiredTokenStateIcon /> */}
      <img
        src={ExpiredTokenStateIcon}
        style={{ width: "70px", height: "70px" }}
        alt="Request Expired"
      />
      <p style={{ fontSize: "20px", margin: "10px 0 40px" }}>
        {t("LinkExpired")}
      </p>
      <div className={classes.link} onClick={loginHandler}>
        <span>{t("Login")}</span>
      </div>
      {/* <p>{t("LinkExpiredDescription")}</p> */}
      {/* <p style={{ color: "rgb(108 206 245)" }}>{t("LinkExpiredNote")}</p> */}
      {/* <p>
        <span>{t("YouCan")}</span>
        <span
          onClick={() => history.push("/forgot-password")}
          className={classes.resendlink}
        >
          {t("RequestNewLink")}
        </span>
      </p> */}
    </div>
  );
}

export default ExpiredLink;
