import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import NotificationsContextProvider from "./contexts/NotificationsContext";
import MasterDataContextProvider from "./contexts/MasterDataContext";
import TagManger from "./components/GoogleTagManager";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import indexRoutes from "./routes";
import { store } from "./app/store";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css?v=2.0.0";
import "./i18n";
import WebGLPlayer from "components/WebGLPlayer";

TagManger.init();

const theme = createTheme({
  typography: {
    fontFamily: "dubai-regular",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    secondary: {
      main: "rgb(255 255 255)",
    },
    primary: {
      main: "rgb(98 195 238)",
      contrastText: "rgb(255 255 255)",
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <WebGLPlayer />
      <ThemeProvider theme={theme}>
        <ToastContainer position="top-center" closeButton={false} />
        <MasterDataContextProvider>
          <NotificationsContextProvider>
            <Switch>
              {indexRoutes.map((prop, key) => {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              })}
            </Switch>
          </NotificationsContextProvider>
        </MasterDataContextProvider>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
