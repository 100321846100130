import { makeStyles } from "@mui/styles";
import StudentAvatar from "./StudentAvatar";
import { Badge } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IStudent } from "features/classPerformance";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "60px",
    width: "100%",
    // whiteSpace: "nowrap",
    color: "rgb(0 0 0)",
    fontWeight: "bold",
    fontSize: "0.8rem",
    textAlign: "right",
    padding: "0.5rem",
    borderTop: "1px solid rgba(218, 218, 218, 1)",
    "@media screen and (max-width: 1024px)": {
      fontSize: "0.65rem",
    },
  },
  badge: { height: "100%", "& > span": { top: "5px", right: "10px" } },
});

interface IStudentInfoProps {
  data: IStudent;
  badge?: number;
}

function StudentInfo({ data, badge }: IStudentInfoProps) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { name, assigned, completed, avatar } = data;
  return (
    <div className={classes.root}>
      <Badge badgeContent={badge} color="primary" className={classes.badge}>
        <StudentAvatar url={avatar} alt={name} />
      </Badge>
      <div
        style={{
          padding: "0 0.35rem",
          textAlign: i18n.dir() === "rtl" ? "right" : "left",
        }}
      >
        <span>{name}</span>
        {assigned ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span>{completed}</span>
            <span>{i18n.dir() === "rtl" ? "\\" : "/"}</span>
            <span style={{ color: "rgb(175 175 175)" }}>{assigned}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default StudentInfo;
