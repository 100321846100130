import { makeStyles } from "@mui/styles";
import CircleChart from "./CircleChart";
import Tooltip from "components/Tooltip";
import { NotAssignedIcon, PendingAssignmentIcon } from "components/SVGs";
import { useTranslation } from "react-i18next";
import { IDomainReport } from "features/classPerformance";

const useStyels = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "&:first-of-type": {
      // width: "calc(100% + 1px)",
    },
  },
  chart: {
    display: "flex",
    width: "170px",
    height: "170px",
    "@media screen and (max-width: 768px)": {
      width: "140px",
      height: "140px",
    },
  },
  cell: {
    height: "60px",
    borderTop: "1px solid rgba(218, 218, 218, 1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(107 106 106)",
    fontWeight: "bold",
    fontSize: "0.8rem",
    width: "100%",
    userSelect: "none",
    "&  svg": {
      cursor: "help",
    },
  },
  header: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    color: "rgb(74 74 74)",
    fontSize: "1rem",
    fontWeight: "bold",
    background: "rgb(255 255 255)",
    borderBottom: "1px solid rgb(214 214 214)",
    boxShadow: "0px -1px 4px -4px rgba(0, 0, 0, 0.06)",
    backgroundColor: "rgba(200, 238, 255, 1)",
  },
});
interface IDomainCardPorps {
  data: IDomainReport;
  color: string;
  renderParentCell?(value: number): string | JSX.Element;
}
function DomainCard({ data, color, renderParentCell }: IDomainCardPorps) {
  const { t } = useTranslation();
  const classes = useStyels();

  const renderCell = (value: number | string) => {
    let cell;
    switch (value) {
      case -1:
      case "PENDING":
        cell = (
          <Tooltip title={t("pending")}>
            <div>
              <PendingAssignmentIcon />
            </div>
          </Tooltip>
        );
        break;
      case "NOT_ASSIGNED":
      case null:
        cell = (
          <Tooltip title={t("notAssigned")}>
            <div>
              <NotAssignedIcon />
            </div>
          </Tooltip>
        );
        break;

      default:
        cell = `${value}%`;
        break;
    }
    return cell;
  };
  const { average, scores } = data;
  return (
    <div className={classes.root}>
      <div className={classes.header}>{data.name}</div>
      <div className={classes.chart}>
        <CircleChart
          value={Number(average as string)}
          forground={color}
          disable={isNaN(average as number)}
          key={average}
        />
      </div>
      {scores &&
        scores.map((score, index) => (
          <div key={index} className={classes.cell}>
            {renderParentCell ? renderParentCell(score!) : renderCell(score!)}
          </div>
        ))}
    </div>
  );
}

export default DomainCard;
