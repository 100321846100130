import { makeStyles } from "@mui/styles";
import { BookStatus, grLevels } from "services/reports";
import { CompletedIcon, PendingIcon } from "components/SVGs/index";

const useStyles = makeStyles({
  card: {
    color: "rgb(105 123 140)",
    fontSize: "16px",
    height: "75px",
    width: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "5px 0",
    backgroundColor: "rgb(255 255 255)",
    border: "1px solid rgb(204 204 204)",
    borderRadius: "10px",
    userSelect: "none",
    "& > div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexGrow: 1,
    },
  },
});

interface IBookCardProps {
  bookTitle: string;
  bookLevelName: grLevels;
  status: BookStatus;
  spentTime: string;
}

function BookCard({
  bookTitle,
  bookLevelName,
  status,
  spentTime,
}: IBookCardProps) {
  const Icon = ["FINISHED", "ARCHIVED"].includes(status)
    ? CompletedIcon
    : PendingIcon;
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div>
        <span
          style={{
            color: "rgb(98 195 238)",
            borderBottom: "1px solid rgb(204 204 204)",
            width: "28px",
            textAlign: "center",
          }}
        >
          {bookLevelName}
        </span>
        <span style={{ flexGrow: 1, textAlign: "left", fontWeight: "normal" }}>
          {spentTime}
        </span>
        <Icon style={{ fontSize: "1.6rem", margin: "0 5px 0 10px" }} />
      </div>
      <span style={{ padding: "0 10px 5px", fontSize: "12px" }}>
        {bookTitle}
      </span>
    </div>
  );
}

export default BookCard;
