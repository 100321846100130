import { makeStyles } from "@mui/styles";
import QrCode from "qrcode.react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { CopyIcon } from "components/SVGs";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";
import { IStudentQRcode } from "../StudentsList";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    background: "rgb(255 255 255)",
    borderRadius: "17px",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "10px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  studentInfo: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "rgb(76 76 76)",
    padding: "0 2rem",
    "@media screen and (max-width: 990px)": {
      fontSize: "14px",
    },
  },
  studName: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "rgb(76 76 76)",
    "@media screen and (max-width: 990px)": {
      fontSize: "16px",
    },
  },
  qrContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem",
    width: "80%",
    "& > svg": {
      width: "70%",
      height: "70%",
    },
  },
  studentCode: {
    backgroundColor: "rgb(214 242 255)",
    color: "rgb(73 172 216)",
    borderRadius: "10px",
    fontWeight: "bold",
    padding: "10px 20px",
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "5rem",
    fontSize: "1rem",
    "& > svg": {
      color: "rgb(60, 180, 229)",
      fontSize: "1.5rem",
      margin: "0 5px",
    },
  },
});

interface IContentProps {
  qrData: IStudentQRcode;
}
function Content({ qrData }: IContentProps) {
  const { t } = useTranslation();
  const { studentName, gradeName, className, qrCode, studentCode } = qrData;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <p className={classes.studName}>{studentName}</p>
        <div className={classes.studentInfo}>
          <p style={{ margin: "0 15px" }}>
            <span>{t("Grade field")}</span>
            <span>{gradeName}</span>
          </p>
          <p style={{ margin: "0 15px" }}>
            <span>{t("Class field")}</span>
            <span>{className}</span>
          </p>
        </div>
        <div className={classes.qrContainer}>
          <QrCode renderAs="svg" value={qrCode} />
        </div>
        <p className={classes.studentInfo}>{t("QRcode")}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => navigator.clipboard.writeText(`${studentCode}`)}
        >
          <Tooltip title={t("Copy code")}>
            <div>
              <CopyIcon />
            </div>
          </Tooltip>
          <span className={classes.studentCode}> {studentCode}</span>
        </div>
      </div>
      <div className={classes.footer}>
        <InfoRoundedIcon />
        <span>{t("Instrcutions to login")}</span>
      </div>
    </div>
  );
}

export default Content;
