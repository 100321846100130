const useDebounce = <T>() => {
  const starting = (
    func: (event: T) => void,
    timeout = 400
  ): ((event: T) => void) => {
    let timer: ReturnType<typeof setTimeout>;
    return function (event: T) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(null, [event]);
      }, timeout);
    };
  };

  const leading = function (
    func: (event: T) => void,
    timeout = 400
  ): (event: T) => void {
    let timer: ReturnType<typeof setTimeout> | undefined;
    return (event: T) => {
      if (!timer) {
        func.apply(null, [event]);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  };

  return { starting, leading };
};

export default useDebounce;
