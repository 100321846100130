import { SvgIcon, SvgIconProps } from "@mui/material";

export function ExamsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 116 116"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h116v116H0z" />
        <path
          stroke="#A9AFBB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.75"
          d="M67.667 14.5v19.333a4.833 4.833 0 0 0 4.833 4.834h19.333"
        />
        <path
          stroke="#A9AFBB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.75"
          d="M82.167 101.5H33.833c-5.338 0-9.666-4.328-9.666-9.667V24.167c0-5.34 4.328-9.667 9.666-9.667h33.834l24.166 24.167v53.166c0 5.34-4.328 9.667-9.666 9.667z"
        />
        <path
          stroke="#A9AFBB"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2.75"
          d="M43.5 53l9.667 9.667L72.5 43.333M48.333 71.833l19.334 19.334m0-19.334L48.333 91.167"
        />
      </g>
    </SvgIcon>
  );
}
export function ExamsSectionIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 23">
      <path
        fillRule="nonzero"
        d="M2.84 2.578c-.046.208-.07.424-.07.645 0 1.066.93 1.933 2.076 1.933h8.308c1.145 0 2.077-.867 2.077-1.933 0-.221-.025-.437-.07-.645h.762c1.145 0 2.077.867 2.077 1.934v15.554C18 21.133 17.068 22 15.923 22H2.077C.932 22 0 21.133 0 20.066V4.512c0-1.067.932-1.934 2.077-1.934zm10.803 13.08a.729.729 0 0 0-.979 0l-.895.833-.895-.833a.729.729 0 0 0-.979 0 .613.613 0 0 0 0 .911l.895.833-.895.834a.613.613 0 0 0 0 .911.717.717 0 0 0 .49.189.717.717 0 0 0 .49-.189l.894-.833.895.833a.717.717 0 0 0 .49.189.717.717 0 0 0 .49-.189.613.613 0 0 0 0-.911l-.896-.834.895-.833a.613.613 0 0 0 0-.911zm-6.72 1.1h-2.77c-.382 0-.691.288-.691.644 0 .357.31.645.692.645h2.77c.382 0 .691-.288.691-.645 0-.356-.31-.644-.692-.644zm7.413-5.612a.729.729 0 0 0-.98 0l-1.587 1.478-.895-.834a.729.729 0 0 0-.979 0 .613.613 0 0 0 0 .912l1.385 1.289a.717.717 0 0 0 .49.189.717.717 0 0 0 .489-.19l2.077-1.933a.613.613 0 0 0 0-.911zm-7.413 1.1h-2.77c-.382 0-.691.288-.691.645 0 .356.31.644.692.644h2.77c.382 0 .691-.288.691-.644 0-.357-.31-.645-.692-.645zm7.413-4.967a.729.729 0 0 0-.98 0L11.77 8.757l-.895-.834a.729.729 0 0 0-.979 0 .613.613 0 0 0 0 .912l1.385 1.289a.717.717 0 0 0 .49.188.717.717 0 0 0 .489-.188l2.077-1.934a.613.613 0 0 0 0-.911zm-7.413.455h-2.77c-.382 0-.691.289-.691.645 0 .356.31.644.692.644h2.77c.382 0 .691-.288.691-.644 0-.356-.31-.645-.692-.645zM9 0c.903 0 1.673.539 1.959 1.29h.81c1.145 0 2.077.866 2.077 1.933 0 .356-.31.644-.692.644H4.846c-.383 0-.692-.288-.692-.644 0-1.067.932-1.934 2.077-1.934h.81C7.327.54 8.097 0 9 0z"
      />
    </SvgIcon>
  );
}
