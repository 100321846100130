import { Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  IExamFilterType,
  selectedFiltersSet,
  selectGradeFilters,
  selectSelectedFitlers,
} from "features/exams";
import { useEffect, useState } from "react";
import {
  LanguageFilterIcon,
  ListeningFilterIcon,
  ReadingFilterIcon,
  WritingFilterIcon,
} from "components/SVGs";
import { useTranslation } from "react-i18next";
import SearchBy from "./SearchBy";

const customStyles = (dir: "ltr" | "rtl") => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    width: "100%",
    backgroundColor: "rgb(249 249 249)",
    borderRadius: "6px",
    padding: "20px 10px",
  },
  filtersGroup: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },
  filterTitle: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    fontWeight: "bold",
    padding: "10px",
  },
  search: {
    position: "absolute",
    top: "30px",
    direction: "ltr",
    ...(dir === "rtl" ? { left: "10px" } : { right: "10px" }),
  },
  filter: {
    border: "1px solid rgba(214, 214, 214, 1)",
    minWidth: "38px",
    minHeight: "38px",
    borderRadius: "8px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 5px 0",
    backgroundColor: "rgba(255, 255, 255, 1)",
    cursor: "pointer",
    flexWrap: "wrap",
    color: "rgb(105, 123, 140)",
    "& > svg": {
      width: "20px",
      height: "20px",
      margin: "0 5px",
      color: "rgb(197, 197, 197)",
    },
  },
  selectedFilter: {
    backgroundColor: "rgba(98, 195, 238, 1)",
    color: "rgb(255 255 255)",
    border: "none",
    "& > svg": {
      color: "rgb(255 255 255)",
    },
  },
});
const icons = {
  1: <WritingFilterIcon />,
  3: <ReadingFilterIcon />,
  10: <ListeningFilterIcon />,
  11: <LanguageFilterIcon />,
};

interface IFiltersProps {}
const Filters = ({}: IFiltersProps) => {
  const { t, i18n } = useTranslation();
  const filters = useAppSelector(selectGradeFilters);
  const selectedFilters = useAppSelector(selectSelectedFitlers);
  const dispatch = useAppDispatch();
  const [selectedFilter, setSelectedFilter] = useState<IExamFilterType>();
  const [selectedSubFilter, setSelectedSubFilter] = useState<IExamFilterType>();
  const [levelOneTitle, setLevelOneTitle] = useState("");
  const [levelOne, setLevelOne] = useState<IExamFilterType[]>([]);
  const [levleTwoTitle, setLevleTwoTitle] = useState("");
  const [levelTwo, setLevelTwo] = useState<IExamFilterType[]>([]);
  const [isGroupedFilters, setIsGroupedFilters] = useState(false);

  const classes = customStyles(i18n.dir());

  useEffect(() => {
    if (filters) {
      if (filters[0].title === "TOPIC" && filters[1].title === "AXIS") {
        setIsGroupedFilters(false);
        const groupOne = filters[0].subFilters;
        if (groupOne?.length) {
          setLevelOne(groupOne);
          setSelectedFilter(groupOne[0]);
          setLevelOneTitle("Letters");
        }
        const groupTwo = filters[1].subFilters;
        if (groupTwo?.length) {
          setLevelTwo(groupTwo);
          setLevleTwoTitle("Language Domains");
        }
      } else {
        setIsGroupedFilters(true);
        setSelectedFilter(filters[0]);
        setLevelOne(filters);
        setLevelOneTitle("Language Domains");
      }
    }
  }, [filters]);

  useEffect(() => {
    if (
      selectedFilter &&
      filters &&
      filters[0].title !== "TOPIC" &&
      filters[1].title !== "AXIS"
    ) {
      const secondLevel = filters.find(
        (f) => f.code === selectedFilter?.code
      )?.subFilters;
      if (secondLevel) {
        setLevelTwo(secondLevel);
        setLevleTwoTitle("Sub Domains");
        setSelectedSubFilter(secondLevel[0]);
      }
    }
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedFilter)
      dispatch(
        selectedFiltersSet({
          ...selectedFilters,
          [isGroupedFilters ? "groupCode" : "subGroupCode"]:
            selectedFilter.code,
        })
      );
  }, [selectedFilter]);

  useEffect(() => {
    if (selectedSubFilter)
      dispatch(
        selectedFiltersSet({
          ...selectedFilters,
          subGroupCode: selectedSubFilter.code,
        })
      );
  }, [selectedSubFilter]);

  useEffect(() => {
    return () => {
      dispatch(selectedFiltersSet({}));
    };
  }, []);

  return (
    <Typography component="div" sx={classes.root}>
      <Typography component="div" sx={classes.filtersGroup}>
        {filters && FilterLevel(levelOne, levelOneTitle)}
        {filters && FilterLevel(levelTwo, levleTwoTitle, isGroupedFilters)}
      </Typography>
      <Typography component="div" sx={classes.search}>
        <SearchBy />
      </Typography>
    </Typography>
  );

  function FilterLevel(
    filters: IExamFilterType[],
    title: string,
    isSubGroup: boolean = false
  ) {
    return (
      <Typography
        component="div"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography component="div" sx={classes.filterTitle}>
          {t(title)}
        </Typography>
        <Typography
          component="div"
          sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {filters?.map((filter) => (
            <Typography
              component="div"
              key={filter.code}
              sx={{
                ...classes.filter,
                ...((isSubGroup
                  ? selectedSubFilter?.code
                  : selectedFilter?.code) === filter.code
                  ? classes.selectedFilter
                  : {}),
              }}
              onClick={() =>
                isSubGroup
                  ? setSelectedSubFilter(filter)
                  : setSelectedFilter(filter)
              }
            >
              {filter.icon
                ? icons[filter.icon as unknown as keyof typeof icons]
                : null}
              {t(filter.title)}
            </Typography>
          ))}
        </Typography>
      </Typography>
    );
  }
};

export default Filters;
