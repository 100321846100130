import { useEffect, useState } from "react";
import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "../SVGs/Search";
import ClearIcon from "../SVGs/Clear";
import SelectInput from "../SelectInput";
import { useTranslation } from "react-i18next";
import { IFilterOption } from "features/common/Interfaces";

const customStyles = {
  divider: {
    height: "70%",
    margin: "5px 0",
    width: "2px",
    backgroundColor: "rgb(217 216 216)",
    borderStyle: "none",
  },
};

const useStyles = makeStyles({
  searchDiv: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "38px",
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(217, 216, 216, 1)",
    borderRadius: "8px",
    fontSize: "16px",
    cursor: "pointer",
    width: "400px",
    "&:hover": {
      borderColor: "rgb(98 195 238)",
    },
  },
  searchText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
    "& *": {
      border: "none",
      fontSize: "16px",
    },
    "& > svg": {
      fontSize: "20px",
      margin: "0 7px",
    },
    position: "relative",
  },
});

export interface ISearchByProps {
  selectedFilter: IFilterOption;
  filterTypes: IFilterOption[];
  getFilterType(type: { currentTarget: IFilterOption }): void;
  getSearchValue(value: string): void;
  "data-test"?: string;
}
const SearchBy = ({
  getFilterType,
  getSearchValue,
  filterTypes,
  selectedFilter,
  ...rest
}: ISearchByProps) => {
  const [text, setText] = useState("");
  const { t } = useTranslation();
  const handleSearch = (event: any) => {
    let value = event.target.value;
    setText(value);
  };
  useEffect(() => {
    getSearchValue(text);
  }, [text]);

  const clearText = () => {
    setText("");
  };

  const classes = useStyles();
  return (
    <div className={classes.searchDiv} {...rest}>
      <div className={classes.searchText}>
        <SearchIcon />
        <input
          type="text"
          placeholder={t("Search")}
          onChange={handleSearch}
          style={{ outline: "none" }}
          value={text}
        />
      </div>

      <ClearIcon
        onClick={clearText}
        sx={{
          fontSize: "16px",
          margin: "0 5px",
          visibility: text?.length ? "visible" : "hidden",
        }}
      />

      <Divider sx={customStyles.divider} orientation="vertical" />
      <SelectInput
        defaultValue={selectedFilter?.value}
        valueList={filterTypes}
        getOption={getFilterType}
        plain
      />
    </div>
  );
};

export default SearchBy;
