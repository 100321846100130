import { SyntheticEvent, useContext, useState } from "react";
import { AccordionSummary, Accordion, AccordionDetails } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DownIcon } from "components/SVGs";
import BookCard from "./BookCard";
import { MasterDataContext } from "contexts/MasterDataContext";
import { useAppSelector } from "app/hooks";
import { selectClass } from "features/grStudentsProgress";
import { useTranslation } from "react-i18next";
import { IStudentProgress } from "../Content";

const useStyles = makeStyles({
  expanded: {
    backgroundColor: "rgb(255 255 255) !important",
    borderBottomLeftRadius: "unset !important",
    borderBottomRightRadius: "unset !important",
    borderBottom: "none !important",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "14px",
    "@media screen and (max-width: 1024px)": {
      width: "200px",
      fontSize: "12px",
    },
  },
  details: {
    backgroundColor: "rgb(248 248 248)",
    border: "1px solid rgb(199 199 199)",
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    minheight: "200px",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(205px,1fr))",
    gridAutoRows: "minmax(80px,auto)",
  },

  studentName: {
    width: "275px",
    "@media screen and (max-width: 1024px)": {
      width: "200px",
    },
  },
  levels: { flexGrow: 1, padding: "0 10px" },
  spentTime: {
    width: "250px",
    "@media screen and (max-width: 1024px)": {
      width: "170px",
    },
  },
  progress: {
    width: "70px",
    "@media screen and (max-width: 1024px)": {
      width: "50px",
    },
  },
});

interface IContentProps {
  records: IStudentProgress[];
}

function Content({ records }: IContentProps) {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const { logEvent } = useContext(MasterDataContext);
  const [expandedStudentId, setExpandedStudentId] = useState<number>();
  const selectedClass = useAppSelector(selectClass);
  const expandHandler =
    (student: IStudentProgress) =>
    (event: SyntheticEvent<Element, Event>, expanded: boolean) => {
      setExpandedStudentId(expanded ? student.studentId : undefined);
      if (expanded)
        logEvent("GuidedReadingClassReportExpandStudentDetails", {
          ClassId: selectedClass?.value,
          StudentId: student.studentId,
        });
    };

  return (
    <>
      {records.map((data) => (
        <Accordion
          key={"key" + data.studentId}
          sx={{
            boxShadow: "none",
            margin: "3px 0 !important",
            "&:before": {
              background: "none",
            },
          }}
          style={{ pointerEvents: data.books.length === 0 ? "none" : "auto" }}
          expanded={expandedStudentId === data.studentId}
          onChange={expandHandler(data)}
        >
          <AccordionSummary
            classes={{
              expanded: classes.expanded,
              content: classes.content,
            }}
            sx={{
              backgroundColor: "rgb(248 248 248)",
              border: "1px solid rgb(199 199 199)",
              borderRadius: "0.5rem",
            }}
            expandIcon={
              <DownIcon
                style={{
                  fontSize: "1rem",
                  opacity: data.books.length === 0 ? 0.5 : 1,
                  margin: "8px",
                }}
              />
            }
          >
            <span className={classes.studentName}>{data.studentName}</span>
            <span className={classes.levels}>
              {data.bookLevels.join(" ، ")}
            </span>
            <span className={classes.spentTime}>{data.totalSpentTime}</span>
            <span className={classes.progress}>
              {data.totalAssingedBooks
                ? `${data.totalCompletedBooks}${
                    i18n.dir() === "ltr" ? "/" : "\\"
                  }${data.totalAssingedBooks}`
                : "- -"}
            </span>
          </AccordionSummary>
          <AccordionDetails classes={{ root: classes.details }}>
            {data.books.map((book, index) => (
              <BookCard key={"key" + index} {...book} />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}

export default Content;
