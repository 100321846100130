import classNames from "classnames";
import { makeStyles } from "@mui/styles";
import {
  LanguageFilterIcon,
  WritingFilterIcon,
  ReadingFilterIcon,
  GamesFilterIcon,
  WorkSheetFilterIcon,
  ListeningFilterIcon,
  VideosFilterIcon,
  CharFilterIcon,
  ActivitiesFilterIcon,
} from "components/SVGs";
import { useTranslation } from "react-i18next";
import { IFilter } from "features/filters";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: "60px",
    paddingRight: "10px",
    "@media screen and (max-width: 768px)": {
      flexWrap: "wrap",
      width: "100%",
    },
  },
  filter: {
    border: "1px solid rgba(214, 214, 214, 1)",
    minWidth: "38px",
    minHeight: "38px",
    borderRadius: "8px",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "5px 5px 0",
    backgroundColor: "rgba(255, 255, 255, 1)",
    cursor: "pointer",
    flexWrap: "wrap",
    color: "rgb(105, 123, 140)",
    "& > svg": {
      width: "20px",
      height: "20px",
      margin: "0 5px",
      color: "rgb(197, 197, 197)",
    },
  },
  selectedFilter: {
    backgroundColor: "rgba(98, 195, 238, 1)",
    color: "rgb(255 255 255)",
    border: "none",
    "& > svg": {
      color: "rgb(255 255 255)",
    },
  },
});

const icons = {
  Videos: <VideosFilterIcon />,
  "Character Activities": <CharFilterIcon />,
  "Printable Worksheets": <WorkSheetFilterIcon />,
  "E-Books": <ReadingFilterIcon />,
  Activities: <ActivitiesFilterIcon />,
  Games: <GamesFilterIcon />,
  1: <WritingFilterIcon />,
  3: <ReadingFilterIcon />,
  10: <ListeningFilterIcon />,
  11: <LanguageFilterIcon />,
};

export type FilterIcons = keyof typeof icons;

interface IFilterProps {
  data: IFilter[];
  clickHandler(filter: IFilter): void;
  selectedFilter: IFilter;
  title: string;
}

function Filter({ data, clickHandler, selectedFilter, title }: IFilterProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ fontWeight: "bold", padding: "10px" }}>{t(title)}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {data.map((filter) => (
          <div
            key={filter.code}
            className={classNames(
              classes.filter,
              selectedFilter?.code === filter.code
                ? classes.selectedFilter
                : null
            )}
            onClick={() => clickHandler(filter)}
          >
            {filter.icon ? icons[filter.icon] : null}
            {filter.title + (title === "Age" ? t("years") : "")}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Filter;
