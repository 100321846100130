import { useState } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { ResetPasswordSchema } from "./ValidationSchema";
import { renderHeadLine, RenderInput } from "../Elements";
import { PasswordLock } from "components/SVGs/PasswordLock";
import Joi from "joi";
import { useHistory } from "react-router-dom";
import useToast from "hooks/useToast";
import { makeStyles } from "@mui/styles";
import { IValidationErrors } from "features/common/Interfaces";
import { useCallback } from "react";
import {
  ISetNewPasswordRequest,
  useSetNewPasswordMutation,
} from "services/auth";
import { IEdgeCases } from "services/API";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    "& > div": {
      marginBottom: "30px",
    },
  },
  heading: {
    margin: "10px 0 30px",
    textAlign: "center",
    "& p:nth-of-type(2)": {
      color: "rgba(80, 80, 80, 1)",
    },
  },
});

const initialState = {
  confirm_password: "",
  password: "",
};

export interface ISetNewPasswordProps {
  token: string;
}
const edgeCases: IEdgeCases = {
  556: "invalid request",
  634: "invalid password",
  633: "token required",
};
function SetNewPassword(props: ISetNewPasswordProps) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState<IValidationErrors>({});
  const history = useHistory();
  const toast = useToast();

  const [setNewPassword] = useSetNewPasswordMutation();
  const sumbitNewPassword = useCallback(
    async (payload: ISetNewPasswordRequest) => {
      const toastId = "SetNewPasswordMessage";
      try {
        const data = await setNewPassword(payload).unwrap();
        if (data?._statusCode === 200) {
          toast({
            label: t("success"),
            message: t("new password set"),
            rtl: i18n.dir() === "rtl",
            type: "success",
            toastId,
          });
          history.push("/login");
        } else if (
          Object.keys(edgeCases).includes(data?._statusCode.toString())
        ) {
          toast({
            label: t("error"),
            message: t(edgeCases[data._statusCode]),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId,
          });
        } else throw data;
      } catch (error) {
        console.log({ error });
      }
    },
    [setNewPassword]
  );

  const formVars = {
    password: {
      name: "password",
      label: t("NewPassword"),
      placeholder: t("NewPassword"),
      type: "password",
      value: formData.password,
    },
    confirm_password: {
      name: "confirm_password",
      label: t("Confirm Password"),
      placeholder: t("Confirm Password"),
      type: "password",
      value: formData.confirm_password,
    },
  };

  const changeHandler = (event: {
    currentTarget: { name: "password" | "confirm_password"; value: string };
  }) => {
    const data = { ...formData };
    const { currentTarget: input } = event;
    const errorsObject = { ...errors };
    input.value = input.value.trim();
    const errorMesssage = validateProperty(input);
    if (errorMesssage) errorsObject[input.name] = errorMesssage;
    else delete errorsObject[input.name];
    setErrors(errorsObject);
    data[input.name] = input.value;
    setFormData(data);
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: "password" | "confirm_password";
    value: string;
  }) => {
    const obj = { [name]: value };
    const propertySchema = {
      [name]: ResetPasswordSchema[name],
    };
    const { error } = Joi.object(propertySchema).validate(obj);
    if (name === "confirm_password" && value === formData.password) return null;
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.object(ResetPasswordSchema).validate(
      formData,
      options
    );
    let errorsObject = { ...errors };
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };

  const isFormNotValid = Object.keys(validate()).length > 0;

  return (
    <div className={classes.root}>
      <PasswordLock style={{ fontSize: "70px" }} />
      <div className={classes.heading}>
        {renderHeadLine(t("ResetPasswordTitle"))}
        <p>{t("ResetPasswordCaption")}</p>
      </div>
      {RenderInput({
        ...formVars.password,
        errors: t(errors.password),
        changeHandler,
        styles: { width: "300px" },
      })}
      {RenderInput({
        ...formVars.confirm_password,
        errors: t(errors.confirm_password),
        changeHandler,
        styles: { width: "300px" },
      })}
      <Button
        label={t("SubmitNewPassword")}
        action={() =>
          sumbitNewPassword({
            password: formData.password,
            token: props.token,
          })
        }
        styles={{ width: "300px" }}
        disable={isFormNotValid}
      />
    </div>
  );
}

export default SetNewPassword;
