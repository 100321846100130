import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Joi from "joi";
import { useCallback } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "app/hooks";
import { IEdgeCases } from "services/API";
import { useRequestResetPasswordMutation } from "services/auth";
import Button from "components/Button";
import { emailSet, resetPasswordRequested } from "features/auth";
import { IValidationErrors } from "features/common/Interfaces";
import useToast from "hooks/useToast";
import { RenderInput } from "../Elements";
import { ForgotPasswordSchema } from "./ValidationSchema";

type Props = {};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "& > :not(:last-child)": {
      marginBottom: "30px",
    },
  },
  heading: {
    textAlign: "center",
    "& p:nth-of-type(2)": {
      color: "rgba(80, 80, 80, 1)",
    },
  },
  redirectback: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  link: {
    width: "100%",
    margin: "10px 0",
    textAlign: "center",
    "& span": {
      userSelect: "none",
      textDecoration: "underline",
      cursor: "pointer",
      color: "rgb(105 123 140)",
      fontSize: "16px",
      // "&:hover": {
      //   textShadow: "-2px -2px 3px rgb(227 239 248)",
      //   color: "rgb(98 195 238)",
      // },
    },
  },
});

const edgeCases: IEdgeCases = {
  546: "No user found",
  630: "Not a teacher",
  500: "Bad Request",
};
const SendRequest = (props: Props) => {
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<{ email: string }>({
    email: "",
  });
  const [errors, setErrors] = useState<IValidationErrors>({});
  const history = useHistory();

  const toast = useToast();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [requestRestPassword] = useRequestResetPasswordMutation();
  const handleSubmit = useCallback(
    async (email: string): Promise<void> => {
      const toastId = "RequestResetPasswordToastMessage";
      try {
        const data = await requestRestPassword({
          _email: email,
          _lang: i18n.dir() === "rtl" ? "AR" : "EN",
        }).unwrap();
        if (data?._statusCode === 200) {
          dispatch(emailSet(formData.email));
          dispatch(resetPasswordRequested("confirmed"));
        } else if (
          Object.keys(edgeCases).includes(data?._statusCode.toString())
        ) {
          toast({
            label: t("error"),
            message: t(edgeCases[data._statusCode]),
            rtl: i18n.dir() === "rtl",
            type: "error",
            toastId,
          });
        } else throw data;
      } catch (error) {
        console.log({ error });
      }
    },
    [requestRestPassword, formData]
  );

  const changeHandler = (event: {
    currentTarget: { name: "email"; value: string };
  }) => {
    const data = { ...formData };
    const { currentTarget: input } = event;
    const errorsObject = { ...errors };
    input.value = input.value.trim();
    const errorMesssage = validateProperty(input);
    if (errorMesssage) errorsObject[input.name] = errorMesssage;
    else delete errorsObject[input.name];
    setErrors(errorsObject);
    data[input.name] = input.value;
    setFormData(data);
  };

  const validateProperty = ({
    name,
    value,
  }: {
    name: "email";
    value: string;
  }) => {
    const obj = { [name]: value };
    const propertySchema = {
      [name]: ForgotPasswordSchema[name],
    };
    const { error } = Joi.object(propertySchema).validate(obj);
    return error ? error.details[0].message : null;
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.object(ForgotPasswordSchema).validate(
      formData,
      options
    );
    let errorsObject = { ...errors };
    if (error)
      for (let item of error.details) errorsObject[item.path[0]] = item.message;
    return errorsObject;
  };
  const loginHandler = () => {
    dispatch(resetPasswordRequested("init"));
    history.push("/login");
  };
  const isFormNotValid = Object.keys(validate()).length > 0;

  return (
    <>
      <Grid item className={classes.heading}>
        <p>{t("ResetPasswordTitle")}</p>
        <p>{t("SubmitYourEmail")}</p>
      </Grid>
      <Grid
        item
        sx={{
          width: "300px",
        }}
      >
        {RenderInput({
          name: "email",
          label: t("Email"),
          value: formData.email,
          placeholder: t("Email"),
          errors: t(errors.email),
          changeHandler,
          styles: { margin: "25px 0 " },
        })}

        <Button
          label={t("Submit")}
          disable={isFormNotValid}
          action={() => handleSubmit(formData.email)}
        />
        <div className={classes.link} onClick={loginHandler}>
          <span>{t("Login")}</span>
        </div>
      </Grid>
    </>
  );
};

export default SendRequest;
