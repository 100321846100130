import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export function Done(props: SvgIconProps) {
  return (
    <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h88v88H0z" />
        <circle
          cx="44"
          cy="44"
          r="33"
          stroke="#E9E9E9"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.5"
        />
        <path
          stroke="#3CB4E5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="5.5"
          d="M33 44l7.333 7.333L55 36.667"
        />
      </g>
    </SvgIcon>
  );
}
