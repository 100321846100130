import { Grid, Paper } from "@mui/material";
import Content from "./Content";
import Toolbar from "./Toolbar";
import Breadcrumb from "components/Breadcrumb";
import GradesList from "components/GradeList";
import { useAppDispatch, useAppSelector } from "app/hooks";
import {
  selectGrade,
  resetSelectedLo,
  selectCompletedAssessments,
  selecteIsLoadingAssessments,
  selectIsRedirecting,
  selectPendingAssessments,
  gradeSet,
} from "features/evaluation";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ContentSkeleton, { EvaluationSkeleton } from "./EvaluationSkeleton";
import DataNotFound from "components/DataNotFound";
import { IGrade } from "features/common/Interfaces";
import { selectGrades } from "features/filters";

const customStyles = {
  container: {
    background: "rgb(255 255 255)",
    borderRadius: " 0 0 18px 18px",
  },
  toolBar: {
    background: "rgb(247 248 250)",
    margin: "15px 10px 5px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    borderRadius: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
    height: "100%",
    paddingBottom: "20px",
  },
};

export default function Evaluations() {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const pendingAssessments = useAppSelector(selectPendingAssessments);
  const completedAssessments = useAppSelector(selectCompletedAssessments);
  const isLoadingAssessments = useAppSelector(selecteIsLoadingAssessments);
  const isRedirecting = useAppSelector(selectIsRedirecting);
  const selectedGrade = useAppSelector(selectGrade);
  const ownedGrades = useAppSelector(selectGrades).filter(
    (grade) => grade.owned
  );

  const clickHandler = (grade: IGrade) => {
    // once user manually selects a different grade, app remove auto saved values on localStorage.
    localStorage.removeItem("grade-evaluation");
    if (!isRedirecting) localStorage.removeItem("class-evaluation");
    dispatch(gradeSet(grade));
    dispatch(resetSelectedLo());
  };

  return (
    <>
      <Breadcrumb />
      <GradesList
        onGradeChange={clickHandler}
        selectedGrade={selectedGrade}
        getIsGradeLoading={setIsLoading}
      />
      {isLoading ? (
        <EvaluationSkeleton />
      ) : ownedGrades.length > 0 ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          sx={{ ...customStyles.container, direction: i18n.dir() }}
        >
          <Grid item xs={12}>
            <Paper elevation={0} sx={customStyles.toolBar}>
              <Toolbar />
            </Paper>
          </Grid>
          <Grid item xs={12} sx={{ height: "100%" }}>
            <Paper elevation={0} sx={customStyles.content}>
              {isLoadingAssessments ? (
                <ContentSkeleton />
              ) : pendingAssessments?.length || completedAssessments?.length ? (
                <Content />
              ) : (
                <DataNotFound />
              )}
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <DataNotFound text={t("no classes")} />
      )}
    </>
  );
}
