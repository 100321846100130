import { Tooltip as CustomTooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { ReactElement } from "react";

const useStyles = makeStyles({
  tooltipContainer: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    border: "1px solid rgba(218, 218, 218, 1)",
    borderRadius: "4px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    padding: "10px",
    maxWidth: "150px",
    "&:after": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(255, 255, 255, 1)",
      bottom: "-18px",
      right: "48px",
    },
    "&:before": {
      content: '""',
      position: "absolute",
      border: "10px solid transparent",
      borderTop: "10px solid rgba(218, 218, 218, 1)",
      bottom: "-19px",
      right: "48px",
    },
  },
});
type Placement =
  | "bottom"
  | "left"
  | "right"
  | "top"
  | "bottom-end"
  | "bottom-start"
  | "left-end"
  | "left-start"
  | "right-end"
  | "right-start"
  | "top-end"
  | "top-start"
  | undefined;
interface ITooltipProps {
  className?: string;
  title: string | JSX.Element;
  placement?: Placement;
  chartTooltip?: boolean;
  children: ReactElement;
  "data-test"?: string;
}
const Tooltip = styled(
  ({
    className,
    title,
    placement = "top",
    chartTooltip = false,
    children,
    ...rest
  }: ITooltipProps) => {
    const classes = useStyles();

    return chartTooltip ? (
      <div className={classes.tooltipContainer} {...rest}>
        {title}
      </div>
    ) : (
      <CustomTooltip
        {...rest}
        classes={{ popper: className }}
        placement={placement}
        title={title}
        arrow
      >
        {children}
      </CustomTooltip>
    );
  }
)`
  & .MuiTooltip-tooltip {
    border: 1px solid rgba(218, 218, 218, 1);
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
    background: rgba(255, 255, 255, 1) !important;
    color: rgba(105, 123, 140, 1);
    padding: 10px;
    text-align: center;
  },
  & .MuiTooltip-arrow {
    color: rgba(255, 255, 255, 1);
    &:before {
      content: '""';
      border: 1px solid rgba(218, 218, 218, 1);
    },
  },
`;

export default Tooltip;
