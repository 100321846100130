import { useEffect, useState } from "react";
import { DeleteIcon } from "components/SVGs";
import { makeStyles } from "@mui/styles";
import LoThumb from "components/LoThumb";
import {
  IAssignedUnit,
  removeLesson,
  selectAssignedLessons,
} from "features/assignments";
import useToast from "hooks/useToast";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useTranslation } from "react-i18next";
import { ILo } from "features/content";

const useStyles = makeStyles({
  root: {
    margin: "30px",
  },
  losList: {
    display: "flex",
    overflowX: "auto",
    overflowY: "hidden",
    marginBottom: "15px",
  },
});

function LosThumbList() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [assignedLos, setAssignedLos] = useState<IAssignedUnit[]>([]);
  const assignedLessons = useAppSelector(selectAssignedLessons);

  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    const { losList, unitList } = assignedLessons;
    let assignedLos = JSON.parse(JSON.stringify(losList)) as IAssignedUnit[];
    unitList.forEach((unit) => {
      if (unit.childLos) assignedLos.push.apply(assignedLos, unit.childLos);
    });
    setAssignedLos(assignedLos);
  }, [assignedLessons]);

  const removeHandler = (lo: IAssignedUnit) => {
    const { assignedLosCount } = assignedLessons;
    if (assignedLosCount > 1) dispatch(removeLesson(lo));
    else
      toast({
        label: t("Info"),
        message: t("Can't remove all los"),
        rtl: i18n.dir() === "rtl",
        type: "info",
        toastId: "removeItemMessage",
      });
  };

  return (
    <div className={classes.losList}>
      {assignedLos.map((lo) => (
        <LoThumb
          lesson={lo as ILo}
          key={lo.loId}
          actionHandler={() => removeHandler(lo)}
          thumbActionIcon={
            <DeleteIcon
              sx={{ color: "rgba(169, 175, 187, 1)", fontSize: "1rem" }}
            />
          }
          fixedWidth="170px"
        />
      ))}
    </div>
  );
}

export default LosThumbList;
